define("ember-get-config/index", ["exports", "@embroider/macros/runtime"], function (_exports, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var configModulePath = "".concat((0, _runtime.config)("/home/admin/workspace/avaScript_Web_App_release_10.6.0/node_modules/ember-basic-dropdown/node_modules/ember-get-config").modulePrefix, "/config/environment");

  var _default = require(configModulePath).default;

  _exports.default = _default;
});