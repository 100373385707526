define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "15thandl18": "15th and Last",
    "1allorga1": "<sup>1</sup>All organizations using Travel on the free plan must go through account verification with TravelBank.",
    "1stand1517": "1st and 15th",
    "1stops59": "1+ Stops",
    "247trav1": "24/7 travel agent support",
    "N/A": "N/A",
    "abacus11": "Abacus",
    "abacusre6": "Abacus Receipts",
    "about21": "About",
    "accesst1": "Access to negotiated rates with major air carriers, hotels, and car rental agencies",
    "accordin3": "According to this report, you owe the company money. If this is correct, please contact your administrator to arrange repayment.",
    "accordin30": "According to this report, you owe the company money. If this is correct, please contact your administrator to arrange repayment.",
    "accordin31": "According to this report, the employee is required to pay for the expense. If this is correct, please arrange repayment with the employee.",
    "account148": "Account",
    "account45": "Account",
    "accountf64": "Account Failed",
    "accountg111": "Account/GL Codes (External Account ID)",
    "accountg135": "Account/GL Codes (External Account ID)",
    "accounti161": "Account ID",
    "accounti183": "Account ID",
    "accounti65": "Account ID",
    "accounti71": "Account ID",
    "accounti77": "{amount} And Above",
    "accountl62": "Account last 4 digits - {formataccountlast4}",
    "accountn13": "Account Number",
    "accountn146": "Account #",
    "accountn2": "{accountname}...{lastfour}",
    "accountn240": "Account Number last 4 digits {last4digits}",
    "accountn3": "{accountname}...{accountnumber}",
    "accountn62": "Account Not Added",
    "accountn73": "{accountnamecapitalized} {accountnumber} account successfully added.",
    "accountp63": "Account Pending",
    "accountr108": "Account Refreshed.",
    "accounts70": "{name} {accountNumber} account successfully added.",
    "accountt15": "Account Type",
    "accountt85": "Account type - {accounttype}",
    "acompany75": "A company wide billing code or corporate discount that will be used for every booking for that particular car rental company.",
    "acorpora41": "A corporate email is required to set up your company. This enables us to properly manage users on your domain.",
    "actionreq7": "Action Required",
    "activate142": "Activate Card",
    "activate159": "Activate Later",
    "activate19": "Activate Card Now",
    "activate207": "Activate Your Card",
    "activate221": "Activate Now",
    "activati161": "Activation Sent",
    "activati162": "Activation Code",
    "activati181": "Activation Code",
    "active21": "Active",
    "active44": "Active",
    "active51": "Active",
    "active55": "Active",
    "active56": "Active",
    "active58": "Active",
    "active63": "Active",
    "active66": "Active",
    "active68": "Active",
    "active70": "Active",
    "active73": "Active",
    "active77": "Active",
    "activeca330": "Active Cards",
    "activeex17": "Active Expenses",
    "activefi101": "Active Filters: ",
    "activefi6": "Active Filters:",
    "activefi7": "Active Filters:",
    "activere3": "Active Reports",
    "activity114": "Activity-Based Licenses & Pricing",
    "activity27": "Activity",
    "activityproblem85": "There was a problem while loading report activity",
    "add1": "Add",
    "add100": "Add",
    "add13": "Add",
    "add143": "Add",
    "add15": "Add",
    "add16": "Add",
    "add175": "Add",
    "add203": "Add",
    "add208": "Add",
    "add3": "Add",
    "add4": "Add",
    "add5": "Add",
    "add57": "Add",
    "add74": "Add",
    "add8": "Add",
    "add81": "Add",
    "add84": "Add",
    "add99": "Add",
    "addaairl6": "Add a Airline Loyalty Program Number to earn points and unlock status tiers.",
    "addabank102": "Add a bank account to reimburse employees and set your reimbursement schedule.",
    "addabank2": "Add a bank account in order to enable reimbursements through TravelBank for your employee's expenses.",
    "addacomp74": "Add a company wide billing code or corporate discount that will be used for every booking for that particular car rental company.",
    "addacoun109": "Add Account",
    "addacred4": "Add a Credit Card",
    "addaddit1": "Add additional rules",
    "addadepa53": "Add a Department",
    "addadrop68": "Add a dropdown value to begin.",
    "addahote32": "Add a Hotel Loyalty Program Number to earn points and unlock status tiers.",
    "addahote7": "Add a Hotel Loyalty Program Number to earn points and unlock status tiers.",
    "addairli9": "Add Airline Loyalty Program",
    "addalloc9": "Add Allocation",
    "addamoun165": "Add Amount",
    "addanair29": "Add an airline loyalty program to earn points and unlock status tiers",
    "addanend11": "Add an End Address",
    "addanend9": "Add an End Address, required",
    "addanewc209": "Add a new custom field",
    "addanexp31": "Add an Expense",
    "addanoth192": "Add Another Option",
    "addanoth29": "Add Another Expense",
    "addanoth52": "Add Another Expense",
    "addappro144": "Add Approval Rules",
    "addappro89": "Add Approval Rule",
    "addarent8": "Add a Rental Car Loyalty Program Number to earn points and unlock status tiers.",
    "addastar3": "Add a Start Address, required",
    "addastar5": "Add a Start Address",
    "addbulkf123": "Add bulk file",
    "addcarco67": "Add Car Code",
    "addcard7": "Add Card",
    "addcarre72": "Add Car Rental Codes",
    "addcateg166": "Add Category",
    "addcateg167": "Add Category Dropdown",
    "addcateg2": "Add Category",
    "addcateg5": "Add category",
    "addcomme28": "Add Comment",
    "adddefau3": "Add Default Department",
    "adddepar48": "Add Departments",
    "adddepar65": "Add Department",
    "adddepar84": "Add Department",
    "adddescr3": "Add Description",
    "addemail14": "Add Email",
    "addemplo125": "Add employees and their departments by entering email addresses or by uploading a templated .CSV file.",
    "addemplo126": "Add employees by entering email addresses or by uploading a templated .CSV file. On the free plan, all employees are added to the General department.",
    "addemplo34": "Add employees by entering email addresses or by uploading a templated .CSV file.",
    "addemplo35": "Add Employees",
    "addemplo57": "Add Employee",
    "addemplo83": "Add Employee",
    "addexpen1": "Add Expense",
    "addexpen73": "Add Expense Category",
    "addexpen93": "Add Expense Policy",
    "addhosts94": "Add hosts and delegates for you and your employees. Delegates can perform tasks, like approvals, for hosts.",
    "addhotel14": "Add Hotel Loyalty Program",
    "addhotel86": "Add Hotel Rewards",
    "addinteg1": "Add Integrations",
    "addinteg3": "Add Integrations",
    "addinteg4": "Add Integration",
    "addinteg5": "Add {integration}",
    "addition1": "Additional Usage Policy",
    "addition159": "Additional Approver",
    "addition163": "Additional Approver Dropdown",
    "addition172": "Additional Usage Policy",
    "addition289": "Additional Information Required",
    "addition54": "Additional Departments are a Paid Feature",
    "addlodgi104": "Add Lodging Receipts",
    "addmanua7": "Add Manually",
    "addmore7": "Add More",
    "addnewbi19": "Add New Billing Address",
    "addnewca2": "Add New Card",
    "addnewca71": "add new category",
    "addnewho92": "Add New Hosts & Delegates",
    "addnewpa8": "Add New Payment Method",
    "addnewpe97": "Add New Personal Synced Card",
    "addnow37": "Add Now",
    "addpassp22": "Add Passport Information",
    "addpayme31": "Add Payment Method",
    "addpayme68": "Add Payment Account",
    "addphoto1": "Add Photo (Optional)",
    "addphoto3": "Add Photo Receipt",
    "addphoto6": "Add photo receipt",
    "addpolic73": "Add Policy Name",
    "addpolic78": "Add Policy Name",
    "addrecei1": "Add Receipt",
    "addrecei20": "Add Receipt",
    "addrecei8": "Add Receipt",
    "addrecei9": "Add Receipts",
    "addreimb101": "Add Reimbursement Account",
    "addreimb9": "Add Reimbursement Account",
    "addrenta12": "Add Rental Car Loyalty Program",
    "address171": "Address",
    "address192": "Address",
    "address95": "Address",
    "addsplit8": "Add Split",
    "addstop25": "Add Stop",
    "addstops8": "Add Stops",
    "addtitle2": "Add {title}",
    "addtorec43": "Add to Receive Reimbursements",
    "addtrave79": "Add Travel Policy",
    "addtrave9": "Add Traveler Information",
    "adduser68": "Add User",
    "addyourb10": "Add your bank account information to receive reimbursements. TravelBank protects your information and is 100% secure.",
    "addyourb69": "Add your bank account information to receive reimbursements. TravelBank uses enterprise-grade security systems to safeguard your sensitive information.",
    "addyourc9": "Add your credit card information.",
    "addyoure36": "Add Your Employees",
    "addyourh15": "Add your Hotel Loyalty Program Number to earn points and unlock status tiers.",
    "addyourm10": "Add your Mileage Program Number to earn points and unlock status tiers.",
    "addyourp3": "Add your payment method to start booking all travel within TravelBank.",
    "addyourt2": "Add your traveler information to start booking all travel within TravelBank.",
    "adjustse67": "Adjust settings or keep TravelBank's defaults for your company’s expense policies.",
    "admin58": "Admin",
    "admin60": "Admin",
    "admin69": "Admin",
    "adminhas59": "Admin has administrator privileges to your company’s account and full access to company settings, users & analytics.",
    "advanceb103": "Advance Booking Requirements",
    "advanceb142": "Advance Booking Requirement",
    "advanceb99": "Advance Booking Requirements",
    "advanced1": "Advanced integrations available",
    "advanced122": "Advanced booking dropdown: {value} {valueType} selected -",
    "advanced89": "Advanced Permissions",
    "agentnum186": "Agent Number",
    "agentnum49": "Agent Number",
    "airbnban105": "Airbnb and other homesharing receipts forwarded to <a class=\"empty-screen__text_inline-link\" href=\"{emailHref}\" >receipts@travelbank.com</a> will show up here and can be assigned to your lodging budget.",
    "airlinec91": "Airline Confirmation Number",
    "airlinel11": "Airline Loyalty Program Number",
    "airlinel27": "Airline Loyalty Program",
    "airlinel28": "Airline Loyalty Program",
    "airlinel30": "Airline Loyalty Program Number",
    "airlines1": "Airlines",
    "alaskaai302": "Alaska Airlines Business credit card",
    "alicense115": "A license is used once a respective action is performed by a user within the calendar month. Any usage beyond your subscription level is billed monthly according to the additional usage policy.",
    "alinktoa162": "A link to activate your account has been sent to your email.",
    "alinktor21": "A link to reset your password will be sent to this email.",
    "alinktor35": "A link to reset your password has been sent to {email}",
    "alinktov2": "A link to verify your account has been sent to your email.",
    "alistofa17": "A list of all your personal synced and corporate card transactions will appear here.",
    "all234": "All",
    "allbooki122": "All Bookings",
    "allbooki129": "All Bookings",
    "allbooki147": "All Bookings",
    "allbooki60": "All Bookings",
    "allcards329": "All Cards",
    "allcards95": "All Cards",
    "allcateg59": "All Categories",
    "allcateg61": "All Categories",
    "allcurre10": "All Currencies",
    "alldates84": "All Dates",
    "alldepar76": "All Departments",
    "allemplo129": "All employees are part of the default policy unless they have been added to a different policy. Employees can only be in one travel policy.",
    "allemplo142": " All employees are part of the default policy unless they have been added to a different policy. Employees can only be in one expense policy.",
    "allemplo143": "All employees are part of the default policy in the free plan.",
    "allemplo74": "All Employees",
    "allemplo94": "All employees are part of the default policy in the free plan.",
    "allexpen147": "All expense reports during the selected date range will be exported.",
    "allfutur272": "All future transactions will be managed by the new \\ assignee${newuserfirstname}.",
    "allocabove6": "Allocation is {amountError} <span data-test-text=\"side\">above</span> your {expenseAmount} expense total.",
    "allocatabove47": "Allocation is {amountError} above your {expenseAmount} expense total",
    "allocatbelow48": "Allocation is {amountError} under your {expenseAmount} expense total",
    "allocate11": "Allocate Expense",
    "allocate2": "Allocate a percentage of your expense towards specific departments.",
    "allocati1": "Allocation Data Does Not Sync",
    "allocati136": "Allocation Primary Department",
    "allocati79": "Allocation Department Access",
    "allocunder7": "Allocation is {amountError} <span data-test-text=\"side\">under</span> your {expenseAmount} expense total.",
    "alloverl153": "All overlapping approvers will be included.",
    "allowexp105": "Allow Expense Reports",
    "allowtra109": "Allow Travel Requests",
    "allowuse149": "Allow users to book lodging outside of TravelBank with rewards.",
    "allrepor25": "All Report Statuses",
    "allrepor92": "All Report Statuses",
    "alltrans160": "All Transactions.",
    "alltrans165": "All Transactions with {filterlist}",
    "amapwill3": "A map will auto-generate when you add a minimum of 2 addresses.",
    "amembero12": "A member of TravelBank will contact you once the set up is ready.",
    "amembero286": "A member of TravelBank will contact you once the set up is ready.",
    "american265": "American Express Company Number",
    "amex313": "Amex",
    "amount143": "Amount",
    "amount145": "Amount",
    "amount18": "Amount",
    "amount21": "Amount",
    "amount3": "Amount",
    "amount4": "Total Amount",
    "amount6": "Amount",
    "amount79": "Amount",
    "amount93": "Total Amount",
    "amount95": "Amount",
    "amountal122": "Amount (always in {currency})",
    "amountda91": "Amount - {amount}",
    "amountdu63": "Amount due",
    "amountis76": "Amount is empty",
    "amountma188": "Amount Maximum dropdown",
    "amountma43": "Amount Maximum",
    "amountma49": "Amount Maximum",
    "amountmi187": "Amount Minimum dropdown",
    "amountmi42": "Amount Minimum",
    "amountmi48": "Amount Minimum",
    "amountth5": "Amount - this value is negative",
    "amountus29": "{amount} USD",
    "analytic16": "Analytics",
    "analytic20": "Analytics",
    "andxmore28": "{description} and {count} more.",
    "anerrorh112": "An error has occurred.  The cash limit cannot exceed the card program's cash limit.  Please reduce limit and resubmit. ",
    "anerroro165": "An error ocurred when trying to lock/unlock card. Please try again or call customer service at 800-344-5696.",
    "anerroro185": "An error occurred while trying to fetch custom field dropdown values",
    "anerroro26": "An error occurred during scanning. Please try again.",
    "anerroro324": "An error has occurred. To close this card please call the 24/7 Cardmember Services team at 1-800-344-5696.",
    "anerroro61": "An error occurred while loading departments",
    "anerroro71": "An error occurred while adding your account. Please try again.",
    "anerroro74": "An error occurred while adding your account. Please try again.",
    "anerroro98": "An error occurred when trying to create this card.",
    "anewveri216": "A new verification code has been sent via email.",
    "anexisti138": "An existing preset \"{name}\" matches your card controls. It has been applied to the card.",
    "anoption47": "An option exceeded the max length of 200 characters",
    "anorgani328": "An Organization Admin {fullname} from {organizationname} organization requested a corporate card sync integration: - Bank Name: {bankname} - Credit Card program: {bankcardname} - Bank Contact Title: {bankcontacttitle} - Bank Contact Email: {bankcontactemail} - Bank Contact Name: {bankcontactname} - Additional instructions: {instructions} - Organization contact email: {organizationcontactemail} - Organization contact name: {organizationcontactname} - Integration ID: {id} {amexinfo} Requested at: {today}",
    "anovervi2": "An overview of your payment methods.",
    "anovervo2": "An overview of your loyalty programs used to book travel.",
    "anunknow52": "An unknown error occured",
    "anyactiv58": "Any active or pending trips can be found on the travel tab.",
    "anyadded155": "Any Added Approvers",
    "anyadded156": "Any Added Approvers",
    "anychang66": "Any changes made will update all references to this department and cannot be undone.",
    "anyusage1": "Any usage that falls outside your license settings will be billed monthly. That means any users who don't regularly need the app can still use paid features, when they need them.",
    "anyusage173": "Any usage that falls outside your license settings will be billed monthly. That means any users who don't regularly need the app can still use paid features, when they need them.",
    "apitoken": "API Token",
    "apperror58": "App Error",
    "apply129": "Apply",
    "apply173": "Apply",
    "apply177": "Apply",
    "apply24": "Apply",
    "apply44": "Apply",
    "apply50": "Apply",
    "apply68": "Apply",
    "apply71": "Apply",
    "apply72": "Apply",
    "apply75": "Apply",
    "apply76": "Apply",
    "apply77": "Apply",
    "apply80": "Apply",
    "applytoa80": "Apply to Account",
    "approvab1": "approvability flag",
    "approval12": "Approvals | Manage",
    "approval123": "Approvals",
    "approval124": "Approvals",
    "approval148": "Approvals",
    "approval152": "Approval Flow",
    "approval2": "Approvals",
    "approval4": "Approvals",
    "approval94": "Approval Rules",
    "approval95": "Approval Rules",
    "approve76": "Approve",
    "approved126": "Approved by Name",
    "approved127": "Approved by Manager Date",
    "approved128": "Approved by Finance Date",
    "approved143": "Approved by Manager Date",
    "approved144": "Approved by Finance Date",
    "approved169": "Approved by Name",
    "approved23": "Approved",
    "approved34": "Approved",
    "approved35": "Approved and Closed",
    "approved4": "Approved & Closed",
    "approved62": "Approved & Closed",
    "approved79": "Approved",
    "approvee107": "Approve Expense Reports for Self",
    "approvee11": "Approve employee budgets and expense reports in one place.",
    "approver146": "Approver is currently Inactive",
    "approver148": "Approver",
    "approver149": "Approver is currently Inactive",
    "approvet111": "Approve Travel Requests for Self",
    "april": "April",
    "aptsuite110": "Apt, suite, etc required. Please try again. ",
    "aptsuite226": "Apt, suite, etc.",
    "are": "{itemCount, plural, =1 {is} other {are}}",
    "arecente19": "A recent event that TravelBank sponsored or attended",
    "areimbur66": "A reimbursement to your bank account was attempted but failed. In order to receive reimbursements, update your bank account information on TravelBank.",
    "areyousu108": "Are you sure?",
    "areyousu122": "Are you sure?",
    "areyousu148": "Are you sure you want to delete this approval rule?",
    "areyousu200": "Are you sure you want to delete this option?",
    "areyousu25": "Are you sure you want to pair your transaction with this expense?",
    "areyousu321": "Are you sure you want to close this card?",
    "areyousu53": "Are you sure you want to remove your existing bank account used for reimbursements?",
    "areyousu62": "Are you sure you want to cancel this {type} request?",
    "areyousu90": "Are you sure?",
    "areyousu92": "Are you sure?",
    "areyousur87": "Are you sure you want to delete this attachment?",
    "ariaLabel": "Type in to Find Address",
    "asaremin95": "As a reminder, the bank account will be removed from the policies previously mentioned.",
    "asetting67": "A setting is already configured for {carVendorMap}, please edit or remove the existing setting",
    "assign7": "Assign",
    "assignal19": "Assign all receipts to one expense.",
    "assignde1": "Assign delegates for you and other employees in your organization.",
    "assignea17": "Assign each receipt to its own expense.",
    "assigned68": "Assigned",
    "assigned87": "Assigned Delegates",
    "assignee29": "Assignee",
    "assignne99": "Assign New Hosts & Delegates",
    "assignto1": "Assign to Report",
    "assignto111": "Assign To Report",
    "assignto23": "Assign to Report",
    "assignto34": "Assign to Expense Report",
    "assignto56": "Assign to Expense Report",
    "assigntr84": "Assign Travel Policy",
    "assignus2": "Assign User",
    "atleast150": "At least 1 option needs to be added",
    "attachme98": "Attachments",
    "attempti1": "Attempting to retry expense export",
    "atthisti169": "At this time exporting expenses only supports either allocations or line items, not both.",
    "atthisti41": "At this time exporting expenses only supports either allocations or line items, not both.",
    "atthisti88": "At this time, the vendor card is only available as a virtual card.<br/>Select it to continue.",
    "attribut150": "Attributes",
    "august": "August",
    "autocomp61": "Auto Completed Expense",
    "automate75": "Automated micro-deposit linking failed!",
    "automati115": "Automatically approve spend for included categories only and decline all others.",
    "automati117": "Automatically decline spend for included categories only and approve all others.",
    "autopay60": "Auto-pay",
    "autorewa145": "Auto Rewards",
    "availabl39": "Available Credit",
    "availabl77": "Available Credit is the amount available for you to spend after factoring in pending transactions and payments. The number displayed below available credit is the amount set aside to clear the pending transactions and payments.",
    "availabl78": "Available Credit is the amount available for you to spend after factoring in pending transactions and payments.",
    "availpoi30": "{availablepoints} pts",
    "avalidem37": "A valid email is required",
    "avisrequ64": "Avis requires a corporate discount number to be used when a billing code is used",
    "back149": "Back",
    "back38": "Back",
    "back72": "Back",
    "back88": "Back",
    "back90": "Back",
    "back96": "Back",
    "baggage": "{numIncludedBaggage, plural, =0 {No free checked baggage} =1 {1 free checked bag} other {# free checked bags}}",
    "balances88": "Balances",
    "bank178": "Bank",
    "bank19": "Bank",
    "bankacco1": "Bank Accounts",
    "bankacco106": "Bank Account Verification Required for Reimbursements",
    "bankacco14": "Bank Account linking failed! {error}",
    "bankacco144": "Bank Accounts",
    "bankacco190": "Bank account successfully linked!",
    "bankacco5": "Bank account successfully linked!",
    "bankacco6": "Bank account linking failed!",
    "bankandc248": "Bank and Card Search",
    "bankconn109": "Bank connection refresh failed.",
    "bankconn200": "{bankpublicname} Connection Error",
    "bankcont258": "Bank Contact Name",
    "bankcont259": "Bank Contact Title",
    "bankcont260": "Bank Contact Email",
    "bankemai237": "Bank Email",
    "bankinfo253": "Bank Information",
    "bankinst11": "Bank Institution Name",
    "bankinst235": "{bankpublicname} Instant Card Login",
    "bankname133": "{bankname} {virtuallabel} ....{last4}",
    "bankname135": "{bankname} Instant Card {label}",
    "bankname255": "Bank Name",
    "bankofam317": "Bank of America",
    "bankofch283": "Bank of choice not listed? Let us know! Our team will work with you and your bank to enable a seamless transaction sync.",
    "baseurl": "Base URL",
    "beforead78": "Before adding new records and uploading, please delete the example row from the CSV template to ensure accurate data processing.",
    "beforeintegration": "Before the integration can occur, you must set up a SCIM Application within Okta. For more information, please visit <a href=\"{href}\" target=\"_blank\">helpdesk.com</a>.",
    "beforewe197": "Before we help you close this account, we need to determine if that’s what you really want to do.",
    "billable113": "Billable",
    "billable140": "Billable",
    "billable174": "Billable to Customer",
    "billable180": "Billable to Customer?",
    "billable181": "Billable to Customer Dropdown",
    "billable35": "Billable to Customer",
    "billable58": "Billable to Customer",
    "billcomi2": "Bill.com Inbox Integration",
    "billcomi4": "Bill.com Inbox",
    "billedan1": "billed annually, or {monthlyRateDisplay}/user billed monthly",
    "billedmo1": "billed monthly per usage",
    "billinga127": "Billing Address",
    "billingc69": "Billing Code: {billingCode}",
    "billingc81": "Billing Code",
    "billingi11": "Billing Information",
    "biweekly7": "Biweekly",
    "blockaut80": "Block Automatically Joining",
    "blockaut86": "Block auto joining setting (gear) icon",
    "bookanda95": "Book and approve travel, manage policies, and unlock savings with corporate rates and our rewards program.",
    "booking1": "Booking approval workflow",
    "bookingd88": "Booking Details",
    "bookingr109": "Booking Rules",
    "bookingr116": "Booking Rules",
    "bookings4": "Bookings",
    "brex12": "Brex",
    "brex315": "Brex",
    "brexpart20": "Brex Partnership",
    "brexrece7": "Brex Receipts",
    "budgetc1": "Budget control settings",
    "budgetfl107": "Budget Flexibility",
    "budgetfl115": "Budget Flexibility",
    "buildasu202": "Build a subscription that works for your team with a-la-carte, activity-based licenses.",
    "buildaun164": "Build a unique subscription that works for your team. Or, use TravelBank's basic features for free for all your users.",
    "buildyou163": "Build Your TravelBank Plan",
    "bulkimpo95": "Bulk Import",
    "bulkupda100": "Bulk update not implemented yet",
    "business300": "Business Advantage Travel Rewards credit card",
    "business301": "Business Advantage Cash Rewards credit card",
    "business31": "Business Purpose",
    "business311": "Business Extra",
    "business54": "Business Purpose",
    "business56": "Business",
    "bysignin98": "By signing up, I agree to the terms of use and the privacy policy. ",
    "byturnin15": "By turning on this option you authorize TravelBank to withdraw reimbursements from the configured company bank account (...{bankLast4}).",
    "byturnin188": "By turning on this option you authorize TravelBank to withdraw reimbursements from the configured company bank account ({bankname} {banklast4}).",
    "cad123": "CAD",
    "canbethe1": "(Can be the same person)",
    "cancel102": "Cancel",
    "cancel110": "Cancel",
    "cancel111": "Cancel",
    "cancel113": "Cancel",
    "cancel12": "Cancel",
    "cancel121": "Cancel",
    "cancel13": "Cancel",
    "cancel136": "Cancel",
    "cancel14": "Cancel",
    "cancel143": "Cancel",
    "cancel149": "Cancel",
    "cancel16": "Cancel",
    "cancel163": "Cancel",
    "cancel167": "Cancel",
    "cancel170": "Cancel",
    "cancel18": "Cancel",
    "cancel184": "Cancel",
    "cancel185": "Cancel",
    "cancel196": "Cancel",
    "cancel23": "Cancel",
    "cancel26": "Cancel",
    "cancel3": "Cancel",
    "cancel31": "Cancel",
    "cancel37": "Cancel",
    "cancel41": "Cancel",
    "cancel6": "Cancel",
    "cancel73": "Cancel",
    "cancel79": "Cancel",
    "cancel8": "Cancel",
    "cancel87": "Cancel",
    "cancel91": "Cancel",
    "cancel94": "Cancel",
    "cancelcu197": "Cancel Custom Field",
    "cancelcu201": "Cancel Custom Field",
    "cancelpa19": "Cancel Payment",
    "cancelpa4": "Cancel Payment?",
    "cancelre164": "Cancel reimbursement modal",
    "cancelre72": "Cancel Request",
    "cancelth100": "Cancel {title} Modal",
    "cancelty61": "Cancel {type} Request",
    "cannotbe20": "Cannot be higher than posted balance",
    "cannotcreate78": "Your account cannot be created at this time.",
    "cannotex168": "Cannot Export Allocations with Line Items",
    "cannotex40": "Cannot Export Allocations with Line Items",
    "cannotse107": "Cannot set new sync status.",
    "cannotse108": "Cannot set new reimbursable status.",
    "capturee98": "Capture expenses, process reports, integrate with your accounting software, and manage corporate card spend.",
    "card17": "Card",
    "card23": "Card",
    "card332": "Card",
    "card6": "Card",
    "cardandc100": "Card & Cardholder Details",
    "cardchan63": "Card changes saved successfully.",
    "cardclos154": "Card Closed",
    "cardcont100": "Card Controls",
    "cardcont26": "Card controls error",
    "carddeac55": "Card deactivation has failed. Please try again.",
    "carddeta69": "Card Details",
    "carddeta99": "Card Details",
    "cardelli130": "Card ....{last4}",
    "cardellipsisnumber23": "{cardName}...{last4digits}",
    "cardendi138": "Card Ending in {last4}",
    "cardhasb53": "Card has been successfully deactivated.",
    "cardhasb92": "Card Has Been Deactivated",
    "cardhold11": "Cardholder Accounts",
    "cardhold12": "Cardholder",
    "cardhold139": "Cardholder Information",
    "cardhold154": "Cardholder(s) as ",
    "cardhold16": "Cardholder",
    "cardhold162": "Cardholder",
    "cardhold17": "Cardholder - Table header",
    "cardhold190": "Card holder dropdown",
    "cardhold2": "Cardholder",
    "cardhold38": "Cardholder",
    "cardhold48": "Cardholder or Assignee",
    "cardisac64": "Card is Active",
    "cardislo164": "Card is Locked",
    "cardisno65": "Card is not Active",
    "cardisun163": "Card is Unlocked",
    "cardlabe136": "Card {label}",
    "cardlimi100": "Card Limit",
    "cardlimi124": "Card Limit",
    "cardlimi334": "Card Limit",
    "cardname160": "Card Name",
    "cardnick18": "Card Nickname",
    "cardnumb124": "Card #",
    "cardnumb140": "Card number",
    "cardnumb145": "Card number",
    "cardnumb209": "Card Number (Last 4 digits)",
    "cardonly277": "Card Only",
    "cardprog177": "Card Program",
    "cardprog256": "Card Program",
    "cardprog257": "Card Program Name",
    "cardprog346": "Card Program - dropdown",
    "cards105": "Cards",
    "cardsand270": "Card & Transactions",
    "cardtagl62": "Card Tag Legend",
    "cardtags103": "Card Tags",
    "cardtype49": "Card Type",
    "cardtype9": "{cardtype}....{cardlast4}",
    "carloyal36": "Car Loyalty Program Number",
    "carloyal37": "Car Loyalty Number",
    "carrenta1": "Car Rental Codes",
    "carrenta215": "Car Rental Codes",
    "carrenta35": "Car Rental Loyalty - {carLoyaltyName}",
    "cars12": "Cars",
    "cars5": "Cars",
    "carslist7": "Cars List",
    "cashwith125": "Cash Withdrawal Limit",
    "cashwith176": "Cash Withdrawal Limit",
    "categori58": "{length} Categories",
    "category141": "Category Rules",
    "category150": "Category is currently Inactive",
    "category151": "Category",
    "category157": "Category Enabled",
    "category162": "Category's id on external system",
    "category174": "Category Name *",
    "category175": "Category’s name on TravelBank.",
    "category178": "Category",
    "category179": "Category's ID on external system.",
    "category182": "Category Name",
    "category196": "Category Specific",
    "category20": "Category",
    "category33": "Category {category}",
    "category37": "Category",
    "category42": "Category dropdown menu",
    "category43": "Category",
    "category5": "Category",
    "category54": "Category dropdown menu",
    "category63": "Category",
    "category74": "Category, required",
    "category80": "Category",
    "category9": "Category",
    "category95": "Category",
    "cdefault1": "The first {currentLicenses} users only will cost you {totalCostPerMonth}mo. billed annually.",
    "cexpense1": "The first {currentLicenses} users to use expense only will cost you {totalCostPerMonth}/mo. billed annually.",
    "changebi12": "Change Billing Address",
    "changebu122": "Change bulk file",
    "changeem134": "Change Employees in Policy?",
    "changefi91": "Change File",
    "changepa6": "Change Password",
    "changepa78": "Change Password",
    "changeyo12": "Change Your Password",
    "chatkeyv1": "Chat",
    "chatnow189": "Chat Now",
    "chatnow19": "Chat Now",
    "chatwith2": "Chat with Us",
    "chatwith21": "Chat with Us",
    "chatwith22": "Chat with Us",
    "chatwith23": "Chat with Us",
    "chatwith24": "Chat with Us",
    "chatwith25": "Chat with Us",
    "chatwith26": "Chat with Us",
    "chatwith29": "Chat with Us",
    "checkbox103": "checkbox for Advance Booking Requirements",
    "checkbox110": "checkbox for Advance Booking Requirements",
    "checkbox113": "checkbox for Hotel Nightly Rate Hard Cap",
    "checkbox117": "checkbox for Budget Flexibility",
    "checkbox119": "checkbox for Budget Flexibility",
    "checkbox163": "checkbox for Paid Seats",
    "checkin96": "Check-in",
    "checking103": "Checking",
    "checking82": "Checking",
    "checkmail56": "Please check your email for next steps. If you think you may already have an account, try resetting your password.",
    "checkout97": "Check-out",
    "checkyou157": "Check Your Email",
    "checkyou158": "Check your email and activate your TravelBank account. It takes just a few minutes to set up your company and customize your settings.",
    "chooseacorp5": "Choose a corporate expense to merge with your {title} for {currency} on {date} transaction.",
    "chooseacorp7": "Choose a corporate card transaction to merge with your {title} for {currency} on {date} expense. If you don’t see your transaction here, go to Past and Personal Transactions, under My Cards.",
    "choosead128": "Choose a Default Travel Policy",
    "choosead4": "Choose a department that you want to default your non-allocated expenses to. This should be a department you work in most of the time.",
    "chooseapers6": "Choose a personal expense to merge with your {title} for {currency} on {date} transaction.",
    "chooseapers8": "Choose a personal card transaction to merge with your {title} for {currency} on {date} expense. If you don’t see your transaction here, go to Past and Personal Transactions, under My Cards.",
    "chooseho1": "Choose how your expense total should be divided. If you need to split your expense total across multiple departments and/or categories, add splits and fill in the information as needed.",
    "chooseho30": "Choose how often expense reports will be exported.",
    "city15": "City",
    "city227": "City",
    "clear128": "Clear",
    "clear172": "Clear",
    "clear176": "Clear",
    "clear67": "Clear",
    "clear70": "Clear",
    "clear71": "Clear",
    "clear74": "Clear",
    "clear75": "Clear",
    "clear76": "Clear",
    "clear77": "Clear",
    "clear80": "Clear",
    "clearfil171": "Clear Filters",
    "clearfil75": "Clear Filters",
    "clearfil9": "Clear Filters",
    "clearinp8": "Clear Input",
    "clickher167": "Click here to try again.",
    "clicking123": "Clicking confirm will restore the selected expenses. All restored expenses will go back to your Expenses list.",
    "clickonc40": "Click on card number to copy to clipboard",
    "clicktod209": "click to delete this option",
    "close1": "Close",
    "close18": "Close",
    "close22": "Close",
    "close43": "Close",
    "close69": "Close",
    "close84": "Close",
    "close87": "Close",
    "closebul119": "Close bulk import",
    "closecar145": "Close Card",
    "closecar323": "Close Card",
    "closecate145": "Close Category Rules",
    "closed28": "Closed",
    "closenav2": "Close Navigation",
    "closetex1": "Close {text} Modal",
    "columnco114": "Column Configurations",
    "columnco144": "Column Configurations",
    "columnco20": "Column Configurations",
    "columndi28": "Column Display Name",
    "columndi82": "Column Display Name",
    "columnor29": "Column Order",
    "columnor83": "Column Order",
    "combine18": "Combine",
    "comments51": "Comments are visible to {reportownerfullname} and all approvers in this report.",
    "commerc1": "Commercial Rewards Card integration",
    "commerci1": "Commercial Rewards Card integration",
    "commerci26": "Commercial Cards",
    "commerci56": "Commercial Cards",
    "company13": "Company",
    "company19": "Company",
    "company203": "Company",
    "company262": "Company",
    "company76": "Company",
    "companyb1": "Company Bank Account",
    "companyb142": "Company Bank Account",
    "companyb176": "Company Bank Account",
    "companyc157": "• Company Corporate Card",
    "companyc160": "Company Corporate Card",
    "companyc33": "Company Corporate Card",
    "companyi38": "Company Invitations",
    "companyn185": "Company Number",
    "companyn29": "Company Name",
    "companyn48": "Company Number",
    "companys1": "Company Settings",
    "companys104": "Company Settings",
    "companys139": "Company Settings",
    "companys141": "Company Settings",
    "companys147": "Company Settings",
    "companys168": "Company Settings",
    "companys17": "Company Settings",
    "companys171": "Company Settings",
    "companys2": "Company Settings",
    "companys213": "Company Settings",
    "companys281": "Company Settings",
    "companys69": "Company Settings",
    "companys71": "Company Settings",
    "companys76": "Company Settings",
    "companys78": "Company Settings",
    "companys83": "Company Settings",
    "companys88": "Company Settings",
    "companys90": "Company Settings",
    "companyv155": "• Company Virtual Card",
    "companyv158": "Company Virtual Card",
    "comparep1": "Compare Plans",
    "comparep165": "Compare Plan Features",
    "comparep170": "Compare Plans",
    "compareu1": "Compare plans and get started with your paid upgrade.",
    "complete56": "Complete at least 1 Department",
    "completi5": "Completing your traveler profile allows you to save all of your traveler information, including your airline and hotel loyalty numbers, in one place for easy booking.",
    "concatad107": "Advanced booking dropdown: {value} {valueType} selected -",
    "concatbi82": "Billing Code - {errorText}",
    "concatca78": "Car Rental Company - {errorText}",
    "concater84": "Discount Number - {errorText}",
    "concatho105": "Hotel distance dropdown: {value} {valueType} selected -",
    "concatst121": "Stops dropdown: {settingStops} selected -",
    "concur13": "Concur",
    "concurre8": "Concur Receipts",
    "conditio150": "Condition",
    "conditio164": "Condition Dropdown",
    "configur174": "Configure Field Properties",
    "confirm150": "Confirm",
    "confirm168": "Confirm",
    "confirm32": "Confirm",
    "confirm88": "Confirm",
    "confirm9": "Confirm",
    "confirma14": "Confirm Account Number",
    "confirma17": "Confirmation Number",
    "confirma276": "Confirm & Reassign",
    "confirma93": "Confirmation Number",
    "confirmn15": "Confirm New Password",
    "confirmn87": "Confirm New Password",
    "confirmp13": "Confirm Payment",
    "confirmp28": "Confirm Password",
    "confirmp4": "Confirm Password",
    "confirmp6": "Confirm password, Instructions: Passwords must match",
    "confirmw10": "Confirm Work Email",
    "confirmy76": "Confirm Your Account",
    "connect205": "Connect",
    "connectb204": "Connect {bankpublicname}",
    "connecti1": "Connections",
    "connecti232": "Connection broken. Please re-connect to {bankpublicname}.",
    "connecti287": "Connection Pending",
    "connecti291": "Connections",
    "connectionFailed": "Application connection failed.",
    "contact22": "Contact",
    "contacts21": "Contact Support",
    "contacts61": "Contact Support",
    "contactt20": "Contact TravelBank",
    "contactu104": "Contact us at <a class=\"mail\" href=\"mailto:{generalemail}\">{generalemail}</a> to set up daily reimbursements.",
    "contactu33": "Contact Us",
    "contacty9": "Contact your admin to increase your card’s credit limit or allow transactions at a specific merchant.",
    "continue125": "Continue",
    "continue126": "Continue",
    "continue129": "Continue",
    "continue140": "Continue",
    "continue177": "Continue with Free",
    "continue178": "Continue with Paid",
    "continue5": "Continue",
    "continue6": "Continue with Google",
    "continue85": "Continue",
    "continue91": "Continue",
    "controls292": "Control spend, track expenses, and manage budgets with TravelBank + U.S. Bank Integration.",
    "controls293": "Control spend, track expenses, and manage budgets with TravelBank + Brex Integration.",
    "controls294": "Control spend, track expenses, and manage budgets with TravelBank + Elan Financial Services Integration.",
    "controls299": "Control spend, track expenses, and manage budgets with TravelBank + Bank of America Integration.",
    "controls304": "Control spend, track expenses, and manage budgets with TravelBank + Silicon Valley Bank Integration.",
    "controls307": "Control spend, track expenses, and manage budgets with TravelBank + American Express Integration.",
    "conversi3": "Conversion Rate",
    "conversi92": "Conversion Rate",
    "copied": "copied",
    "copied39": "Copied!",
    "copy": "copy",
    "copythescim": "Copy the SCIM credentials below into the Okta provisions tab to enable user import and provisioning features.",
    "coreinsi1": "Core Insights",
    "coreinsi19": "Core Insights",
    "corporat1": "Corporate Cards | Manage",
    "corporat115": "Corporate Card",
    "corporat126": "Corporate Card",
    "corporat130": "Corporate Card Require Merging of Expense with Corporate Card Transaction",
    "corporat133": "Corporate Card Default Payment Method",
    "corporat16": "Corporate Card/Non-Reimbursable",
    "corporat170": "Corporate Card",
    "corporat2": "Corporate Card<br/>Connections",
    "corporat21": "Corporate/Non-Reimbursable",
    "corporat23": "Corporate Card Refund",
    "corporat233": "Corporate Card Connections",
    "corporat26": "Corporate Card",
    "corporat27": "Corporate Cards",
    "corporat3": "Corporate Card/Non-Reimbursable Expense",
    "corporat30": "Corporate Cards",
    "corporat308": "Corporate Green Card",
    "corporat309": "Corporate Gold Card",
    "corporat310": "Corporate Platinum Card",
    "corporat34": "Corporate Cards",
    "corporat37": "Corporate/Non-Reimbursable",
    "corporat45": "Corporate Card/Non-Reimbursable Expense",
    "corporat50": "Corporate",
    "corporat6": "Corporate Card",
    "corporat66": "Corporate Card",
    "corporat73": "Corporate Card",
    "corporat76": "Corporate Cards",
    "corporat77": "Corporate Expenses",
    "corporat78": "Corporate Travel",
    "corporat9": "Corporate Card",
    "corporatecard82": "Corporate Card/Non-Reimbursable Expense",
    "costtitl1": "{title} costs",
    "couldnot10": "Could not load credit cards",
    "couldnot106": "Could not find that card",
    "couldnot11": "Could not load transactions",
    "couldnot115": "Could not submit because some expenses need more information before submitting the expense report.",
    "couldnot119": "Could not load card presets",
    "couldnot161": "Could not load card managing accounts",
    "couldnot201": "Could not load card managing accounts",
    "couldnot49": "Could not load expenses",
    "couldnot50": "Could not load expense reports",
    "couldnot52": "Could not calculate distance. Please complete it manually.",
    "couldnot60": "Could not load your bookings.",
    "couldnot62": "Could not assign user",
    "couldnot77": "Could not submit because some expenses need more information before submitting the expense report.",
    "country16": "Country",
    "country230": "Country",
    "countryc1": "Country Code",
    "countryd231": "Country dropdown list",
    "cpremium1": "The first {currentLicenses} users to use travel and expense will cost you {totalCostPerMonth}/mo. billed annually.",
    "create83": "Create",
    "createac16": "Create a Company",
    "createac32": "Create Account",
    "createac44": "Create a Company",
    "createac7": "Create Account",
    "createan141": "Create a new preset name for card controls to be saved under.",
    "createan173": "Create a new custom field that will be tied to expense reports.",
    "createan176": "Create a new custom field that will be tied to expense reports.",
    "createan2": "Create an Expense",
    "createan34": "Create an Account",
    "createan38": "Create an Expense",
    "createan6": "Create a new report name for expenses to be submitted under.",
    "createan91": "Create an Instant Employee Card",
    "createan94": "Create an Instant Vendor Card",
    "createap4": "Create a Password",
    "createap93": "Create a Physical Employee Card",
    "createap96": "Create a Physical Vendor Card",
    "createar41": "Create a Report",
    "createas55": "Create as many departments as you need for each group of employees who share an approver.",
    "createav92": "Create a Virtual Employee Card",
    "createav95": "Create a Virtual Vendor Card",
    "createca103": "Create Cards",
    "createca156": "Create Category",
    "createca4": "Create Card",
    "createco46": "Create Company Account",
    "createcu1": "Create custom data entry for travel users.",
    "createcu2": "Create custom data entry for expense users.",
    "createdd112": "Created Date",
    "createem89": "Create Employee Card",
    "createex1": "Create expense report",
    "createex38": "Create Expense",
    "createex72": "Create Expense Policy",
    "createex97": "Create Expense",
    "createne139": "Create New",
    "createne40": "Create New Mileage",
    "createne43": "Create New Account",
    "createne48": "Create New Integration",
    "createne5": "Create New Card",
    "createne55": "Create New Custom Field",
    "createne7": "Create New Mileage",
    "createne77": "Create New Policy",
    "createne99": "Create New Custom Field",
    "createon50": "Create one department for each group of employees who share an approver.",
    "createpa15": "Create Password",
    "createpa5": "Create password, Instructions: Minimum {count} characters long, Must include one uppercase and one lowercase character, Must include one number, Must include one special character",
    "createre2": "Create Report",
    "createve90": "Create Vendor Card",
    "creating24": "Creating card...",
    "creating69": "Creating card, check tomorrow...",
    "credenti26": "Credentials",
    "credentialsHaveExpired": "Application credentials have expired.",
    "creditca1": "Credit card reconciliation report",
    "creditca104": "Credit Card successfully added",
    "creditca105": "Credit Card linking failed!",
    "creditca109": "Credit Card successfully reauthenticated.",
    "creditca110": "Credit Card could not be reauthenticated!",
    "creditca111": "Credit Card successfully removed",
    "creditca112": "Credit Card removal failed!",
    "creditca12": "Credit Card Reconcilliation",
    "creditca14": "Credit Card Audit",
    "creditca3": "Credit Card<span>...9687</span>",
    "creditca340": "Credit Cards could not be assigned",
    "creditca42": "Credit Card",
    "creditca6": "Credit Card Information",
    "creditca61": "Credit Cards successfully assigned.",
    "creditca8": "Credit Card Number",
    "creditsm2": "Credits may take up to 24 hours to update. Partially used flight credits are approximate values. Future travel must be booked and flown by the expiration date.",
    "creditut65": "Credit utilization",
    "csv26": "CSV",
    "ctravel1": "The first {currentLicenses} users to use travel only will cost you {totalCostPerMonth}/mo. billed annually.",
    "currency118": "Currency Code",
    "currencyList": {
      "AED": "United Arab Emirates Dirham",
      "AFN": "Afghan Afghani",
      "ALL": "Albanian Lek",
      "AMD": "Armenian Dram",
      "ANG": "Netherlands Antillean Guilder",
      "AOA": "Angolan Kwanza",
      "ARS": "Argentine Peso",
      "AUD": "Australian Dollar",
      "AWG": "Aruban Florin",
      "AZN": "Azerbaijani Manat",
      "BAM": "Bosnia-Herzegovina Convertible Mark",
      "BBD": "Barbadian Dollar",
      "BDT": "Bangladeshi Taka",
      "BGN": "Bulgarian Lev",
      "BHD": "Bahraini Dinar",
      "BIF": "Burundian Franc",
      "BMD": "Bermudan Dollar",
      "BND": "Brunei Dollar",
      "BOB": "Bolivian Boliviano",
      "BRL": "Brazilian Real",
      "BSD": "Bahamian Dollar",
      "BTC": "Bitcoin",
      "BTN": "Bhutanese Ngultrum",
      "BWP": "Botswanan Pula",
      "BYN": "Belarusian Ruble",
      "BZD": "Belize Dollar",
      "CAD": "Canadian Dollar",
      "CDF": "Congolese Franc",
      "CHF": "Swiss Franc",
      "CLP": "Chilean Peso",
      "CNY": "Chinese Yuan",
      "COP": "Colombian Peso",
      "CRC": "Costa Rican Colón",
      "CUC": "Cuban Convertible Peso",
      "CUP": "Cuban Peso",
      "CVE": "Cape Verdean Escudo",
      "CZK": "Czech Republic Koruna",
      "DJF": "Djiboutian Franc",
      "DKK": "Danish Krone",
      "DOP": "Dominican Peso",
      "DZD": "Algerian Dinar",
      "EEK": "Estonian Kroon",
      "EGP": "Egyptian Pound",
      "ERN": "Eritrean Nakfa",
      "ETB": "Ethiopian Birr",
      "EUR": "Euro",
      "FJD": "Fijian Dollar",
      "FKP": "Falkland Islands Pound",
      "GBP": "British Pound Sterling",
      "GEL": "Georgian Lari",
      "GGP": "Guernsey Pound",
      "GHS": "Ghanaian Cedi",
      "GIP": "Gibraltar Pound",
      "GMD": "Gambian Dalasi",
      "GNF": "Guinean Franc",
      "GTQ": "Guatemalan Quetzal",
      "GYD": "Guyanaese Dollar",
      "HKD": "Hong Kong Dollar",
      "HNL": "Honduran Lempira",
      "HRK": "Croatian Kuna",
      "HTG": "Haitian Gourde",
      "HUF": "Hungarian Forint",
      "IDR": "Indonesian Rupiah",
      "ILS": "Israeli New Sheqel",
      "IMP": "Manx pound",
      "INR": "Indian Rupee",
      "IQD": "Iraqi Dinar",
      "IRR": "Iranian Rial",
      "ISK": "Icelandic Króna",
      "JEP": "Jersey Pound",
      "JMD": "Jamaican Dollar",
      "JOD": "Jordanian Dinar",
      "JPY": "Japanese Yen",
      "KES": "Kenyan Shilling",
      "KGS": "Kyrgystani Som",
      "KHR": "Cambodian Riel",
      "KMF": "Comorian Franc",
      "KPW": "North Korean Won",
      "KRW": "South Korean Won",
      "KWD": "Kuwaiti Dinar",
      "KYD": "Cayman Islands Dollar",
      "KZT": "Kazakhstani Tenge",
      "LAK": "Laotian Kip",
      "LBP": "Lebanese Pound",
      "LKR": "Sri Lankan Rupee",
      "LRD": "Liberian Dollar",
      "LSL": "Lesotho Loti",
      "LTL": "Lithuanian Litas",
      "LVL": "Latvian Lats",
      "LYD": "Libyan Dinar",
      "MAD": "Moroccan Dirham",
      "MDL": "Moldovan Leu",
      "MGA": "Malagasy Ariary",
      "MKD": "Macedonian Denar",
      "MMK": "Myanma Kyat",
      "MNT": "Mongolian Tugrik",
      "MOP": "Macanese Pataca",
      "MRO": "Mauritanian Ouguiya",
      "MTL": "Maltese Lira",
      "MUR": "Mauritian Rupee",
      "MVR": "Maldivian Rufiyaa",
      "MWK": "Malawian Kwacha",
      "MXN": "Mexican Peso",
      "MYR": "Malaysian Ringgit",
      "MZN": "Mozambican Metical",
      "NAD": "Namibian Dollar",
      "NGN": "Nigerian Naira",
      "NIO": "Nicaraguan Córdoba",
      "NOK": "Norwegian Krone",
      "NPR": "Nepalese Rupee",
      "NZD": "New Zealand Dollar",
      "OMR": "Omani Rial",
      "PAB": "Panamanian Balboa",
      "PEN": "Peruvian Nuevo Sol",
      "PGK": "Papua New Guinean Kina",
      "PHP": "Philippine Peso",
      "PKR": "Pakistani Rupee",
      "PLN": "Polish Zloty",
      "PYG": "Paraguayan Guarani",
      "QAR": "Qatari Rial",
      "RON": "Romanian Leu",
      "RSD": "Serbian Dinar",
      "RUB": "Russian Ruble",
      "RWF": "Rwandan Franc",
      "SAR": "Saudi Riyal",
      "SBD": "Solomon Islands Dollar",
      "SCR": "Seychellois Rupee",
      "SDG": "Sudanese Pound",
      "SEK": "Swedish Krona",
      "SGD": "Singapore Dollar",
      "SHP": "Saint Helena Pound",
      "SLL": "Sierra Leonean Leone",
      "SOS": "Somali Shilling",
      "SRD": "Surinamese Dollar",
      "STD": "São Tomé and Príncipe Dobra",
      "SVC": "Salvadoran Colón",
      "SYP": "Syrian Pound",
      "SZL": "Swazi Lilangeni",
      "THB": "Thai Baht",
      "TJS": "Tajikistani Somoni",
      "TMT": "Turkmenistani Manat",
      "TND": "Tunisian Dinar",
      "TOP": "Tongan Pa?anga",
      "TRY": "Turkish Lira",
      "TTD": "Trinidad and Tobago Dollar",
      "TWD": "New Taiwan Dollar",
      "TZS": "Tanzanian Shilling",
      "UAH": "Ukrainian Hryvnia",
      "UGX": "Ugandan Shilling",
      "USD": "United States Dollar",
      "UYU": "Uruguayan Peso",
      "UZS": "Uzbekistan Som",
      "VEF": "Venezuelan Bolívar Fuerte",
      "VND": "Vietnamese Dong",
      "VUV": "Vanuatu Vatu",
      "WST": "Samoan Tala",
      "XAF": "CFA Franc BEAC",
      "XCD": "East Caribbean Dollar",
      "XOF": "CFA Franc BCEAO",
      "XPF": "CFP Franc",
      "YER": "Yemeni Rial",
      "ZAR": "South African Rand",
      "ZMW": "Zambian Kwacha",
      "ZWL": "Zimbabwean Dollar"
    },
    "currencylabel": "{name} ({code})",
    "current1": "Current:",
    "currenta132": "Current Approver Name",
    "currenta133": "Current Approver Email",
    "currenta170": "Current Approver Name",
    "currenta171": "Current Approver Email",
    "currentp1": "Current Plan",
    "currentp13": "Current Password",
    "currentp82": "Current Password is required.",
    "currentp85": "Current Password",
    "custom27": "Custom",
    "custom29": "Custom",
    "custom73": "Custom",
    "custom92": "Custom",
    "customap1": "Custom approval structure",
    "customca1": "Custom categories",
    "customer24": "Customer Service Tools",
    "customer41": "Customer Service",
    "customex79": "Custom Expense Policies are a Paid Feature",
    "customfi1": "Custom Fields",
    "customfi172": "Custom Fields",
    "customfi203": "Custom Field",
    "customfi54": "Custom Fields",
    "customfi93": "Custom Fields",
    "customiz2": "Customize Settings Later",
    "customiz76": "Customize Policy",
    "customiz80": "Customize your default expense policy, and add additional custom policies assigned to specific users or departments in company settings.",
    "customiz99": "Customize Settings",
    "custompo198": "Custom Policy",
    "customr1": "Custom reporting available via Premium Insights, with a separate monthly fee",
    "customre1": "Custom reporting available via Premium Insights, with a separate monthly fee",
    "cvc10": "CVC",
    "cvv34": "CVV <span>{cardcvv}</span>",
    "cycleDate1": "Cycle Date",
    "cycleDate112": "Cycle Date (required)",
    "cycleDate2": "This selection is dependant on the managing account selection",
    "cycleDay1": "Cycle Date",
    "cycleclo103": "Cycle Close Date",
    "daily2": "Daily",
    "daily5": "Daily",
    "daily78": "Daily",
    "dailykey1": "Daily",
    "dashamex327": "- Amex Account ID: {amexaccountid}",
    "dashboar1": "Dashboard",
    "dashboar34": "Dashboard",
    "dataexpo1": "Data export for ERP",
    "date1": "Date",
    "date104": "Date",
    "date13": "Date",
    "date47": "Date",
    "date9": "Date",
    "date96": "Date",
    "datedash53": "Date - {paymentdate}",
    "dateofbi167": "Date of Birth",
    "dateofbi188": "Date Of Birth",
    "dateofbi20": "Date of Birth",
    "dateofsubm1": "Date of Submission",
    "dateofsubmsupport1": "Scheduled expenses will be auto-submitted on this date. If you choose today’s date, submissions may begin the following month if you save the setting after {cutoff} local time.",
    "daterang147": "Date Range (required)",
    "daterang148": "Date Range",
    "daterang15": "Date Range",
    "daterang20": "Date Range",
    "daterang8": "Date Range",
    "dayofthemonth1": "Day of the Month",
    "days70": "Days",
    "days75": "Days",
    "daytotalk1": "Monthly total",
    "daytotalk2": "Per Month Limit",
    "daytotalk3": "this month",
    "deactiva11": "Deactivating...",
    "deactiva143": "Deactivate Card",
    "deactiva165": "Deactivating Category",
    "deactiva166": "Deactivating this category will also deactivate all category rules and approval rules related to it.",
    "deactiva331": "Deactivated Cards",
    "deactiva43": "Deactivated",
    "deactiva56": "Deactivated",
    "deactiva80": "Deactivate Excluded Users",
    "deactiva9": "Deactivate Card",
    "december": "December",
    "decidewh115": "Decide what data points to export for every transaction. Rearrange the order in which they appear in the exported file.",
    "declined15": "Declined transactions are available for the past 30 days only",
    "declined27": "Declined",
    "declined5": "Declined",
    "declined8": "Declined",
    "default62": "Default",
    "defaulta1": "Default approval hierarchy",
    "defaulta156": "Default Approval Flow",
    "defaulta158": "Default Approval Flow Dropdown",
    "defaultc1": "Default categories",
    "defaultd49": "Default Department",
    "defaulte66": "Default Expense Policy",
    "defaultp131": "Default Payment Method",
    "defaultt129": "Default Travel Policy",
    "defaultt73": "Default Travel Policy",
    "defineru142": "Define rule sets and guidelines per specific expense categories.",
    "delegat1": "Delegate users to book travel on behalf of others",
    "delegate1": "Delegates",
    "delegate103": "Delegate Access",
    "delegate106": "Delegates can approve expense reports.",
    "delegate108": "Delegates can approve their own expense reports.",
    "delegate110": "Delegates can approve travel requests.",
    "delegate112": "Delegates can approve their own travel requests.",
    "delegate129": "Delegates are employees who can perform tasks on behalf of the host.",
    "delegate2": "Delegate users to perform tasks on behalf of others",
    "delegate46": "Delegates",
    "delegate48": "Delegate users successfully assigned.",
    "delegate51": "Delegates group removed.",
    "delegate53": "Delegates will be auto signed out after {delegateTimeoutValue}.",
    "delegate85": "Delegates",
    "delegate88": "Delegates auto sign out after",
    "delegate90": "Delegates can perform all tasks within TravelBank on the host’s behalf unless otherwise restricted within Delegate Access controls.",
    "delegate97": "Delegates are employees who can perform tasks on behalf of the host",
    "delete102": "Delete",
    "delete151": "Delete",
    "delete198": "Delete",
    "delete1i58": "Delete 1 item?",
    "delete1t120": "Delete 1 transaction?",
    "delete202": "Delete",
    "delete21": "Delete",
    "delete32": "Delete",
    "delete42": "Delete",
    "delete70": "Delete",
    "delete80": "Delete",
    "delete85": "Delete",
    "delete89": "Delete",
    "deleteal119": "Delete all transactions?",
    "deleteal57": "Delete all items?",
    "deleteca104": "Delete Card?",
    "deleteco147": "Delete Confirmation",
    "deleteco199": "Delete Confirmation",
    "deletecon86": "Delete Confirmation",
    "deletecu180": "Delete Custom Field",
    "deletecu183": "Delete Custom Field",
    "deleted3": "Deleted",
    "deletedo74": "Deleted Only",
    "deleteex76": "Delete Expense",
    "deleteho114": "Delete Hosts & Delegates",
    "deleteli8": "Delete Line Item",
    "deletepo107": "Delete Policy",
    "deletepo80": "Delete Policy",
    "deletepo91": "Delete Policy",
    "deleteth22": "Delete this transaction?",
    "deleteth23": "Delete this expense?",
    "deleteth59": "Delete {length} items?",
    "deletetr121": "Delete {selectedtransactions} transactions?",
    "deletetr38": "Delete Traveler Profile",
    "deletetr39": "Delete Traveler Information",
    "deletetr89": "Delete Travel Policy",
    "deleteve86": "Delete {vendorName} codes",
    "deleting109": "Deleting this policy will place its existing users into the default policy.",
    "deleting91": "Deleting this policy will place its existing users into the default policy.",
    "deleting93": "Deleting this policy will place its existing users into the default policy.",
    "delivery33": "Delivery Method",
    "deny79": "Deny",
    "departme1": "Departments",
    "departme105": "Department",
    "departme106": "Department External ID",
    "departme15": "Department: {departmentname}",
    "departme152": "Department(s) as ",
    "departme154": "Department",
    "departme16": "Department",
    "departme172": "Department External ID",
    "departme194": "Department dropdown",
    "departme23": "Department",
    "departme34": "Department {department}",
    "departme38": "Department",
    "departme39": "Department",
    "departme4": "Department",
    "departme47": "Departments",
    "departme5": "Department",
    "departme52": "Department Name",
    "departme58": "Department Name",
    "departme59": "{departmentsCount, plural, one {# Department} other {# Departments}}",
    "departme62": "{departmentName} department successfully added.",
    "departme63": "{departmentName} department successfully updated.",
    "departme71": "Department Name",
    "departme73": "Department",
    "departme77": "Department",
    "departme78": "Departments",
    "departme80": "Department",
    "departme85": "Department Created",
    "departme9": "Department",
    "departme90": "{departmentName} Department",
    "departme91": "{departmentName} Departments",
    "departments": {
      "empty": "It appears your company has not set up any departments yet"
    },
    "descript122": "Description Requirement",
    "descript141": "Description Field",
    "descript32": "Description",
    "descript55": "Description",
    "descript7": "Description",
    "descript97": "Description",
    "deselect18": "Deselect All",
    "details168": "Details",
    "details169": "Cardholder Details",
    "details56": "Details",
    "detailsa153": "Details and instructions will be mailed to the card owner.",
    "detailsa154": "Details and instructions will be mailed to the cardholder.",
    "detailsf72": "Details for expenses paired with a transaction cannot be changed.",
    "determin153": "Determine who should approve incoming expense requests for this expense policy.",
    "determin158": "Determines if category is available for users all throughout TravelBank. Category may be customized further in expense policies.",
    "disable5": "Disable",
    "disabled": "Disabled",
    "disabled1": "Disabled",
    "disabled68": "Disabled",
    "disablei3": "Disable Integration",
    "disablei87": "Disable Integration",
    "disappro78": "Disapprove",
    "discardu214": "Discard unsaved changes?",
    "discount68": "Discount Number: {discountNumber}",
    "discount80": "Discount Number",
    "discount83": "Discount Number ",
    "dismiss106": "Dismiss",
    "dismiss18": "Dismiss",
    "dismiss187": "Dismiss",
    "dismiss69": "Dismiss",
    "displaya117": "Display As",
    "disputed131": "Disputed Status",
    "disputed132": "Disputed Status Date",
    "distance100": "Distance Driven",
    "distance101": "Distance Unit",
    "distance102": "Distance From Search Location",
    "distance154": "Distance From Search Location",
    "distance41": "Distance Details",
    "distance8": "Distance Details",
    "document161": "Documentation: My Cards Transactions",
    "doemploy75": "Do employees have corporate cards?",
    "done15": "Done",
    "done26": "Done",
    "dontsave159": "Don't Save",
    "download1": "Download expense report CSV files specific to dates and departments.",
    "download198": "Download",
    "download200": "Download All",
    "download37": "Download Report",
    "doyouwan44": "Do you want to update your bank account?",
    "doyouwan8": "Do you want to update your bank account?",
    "dragandd11": "Drag and drop your receipts or click anywhere to choose up to 10 files. Accepted file types include .pdf, .jpg, .jpeg, .png and .gif.",
    "dragandd4": "Drag and drop or <span class=\"expense-form__attachment-area__content__highlight\">choose a file</span> to upload your receipt. Accepted file types include: .pdf, .jpg, .jpeg, .png, .gif",
    "dropdown182": "Dropdown Values",
    "dropdown208": "Dropdown values list",
    "dropdown41": "Dropdown menu",
    "dropdown46": "Dropdown",
    "dropdown60": "Dropdown",
    "dropdown63": "Dropdown",
    "duedate40": "Due Date",
    "duplicat40": "Duplicate Receipt",
    "duplicate84": "Duplicate receipt has been deleted",
    "duplicatereceipts95": "Duplicate Receipt(s)",
    "eachempl81": "Each employee can be assigned to a maximum of 20 departments.",
    "economy138": "Economy",
    "economy55": "Economy",
    "edit111": "Edit",
    "edit13": "Edit",
    "edit133": "Edit",
    "edit141": "Edit",
    "edit143": "Edit",
    "edit145": "Edit",
    "edit147": "Edit",
    "edit148": "Edit",
    "edit154": "Edit",
    "edit170": "Edit",
    "edit178": "Edit",
    "edit179": "Edit",
    "edit2": "Edit",
    "edit207": "Edit",
    "edit25": "Edit",
    "edit26": "Edit",
    "edit7": "Edit",
    "edit70": "Edit",
    "edit94": "Edit",
    "edit97": "Edit",
    "edit99": "Edit",
    "editappr142": "Edit Approval Rules",
    "editappr157": "Edit Approval Flow",
    "editappr83": "Edit Approval Flow",
    "editappr84": "Edit Approval Rule",
    "editcarr73": "Edit Car Rental Codes",
    "editcate144": "Edit Category Rules",
    "editcode71": "Edit {vendorName} codes",
    "editcust101": "Edit Custom Field",
    "editcust56": "Edit Custom Field",
    "editdele67": "Edit delegate group",
    "editdepa64": "Edit Department",
    "editempl146": "Edit Employees in Policy",
    "editempl56": "Edit Employee",
    "editexpe3": "Edit Expense Report",
    "editexpe72": "Edit Expense Policy",
    "editexpe93": "Edit Expense Policy",
    "editgene112": "Edit General Rules",
    "edititem180": "Edit {name} text field",
    "edititem181": "Edit {name} dropdown field",
    "editprof1": "Edit Profile Information",
    "editprov8": "Edit {provider}",
    "editreim179": "Edit Reimbursements",
    "editrepo33": "Edit Report",
    "editrepo5": "Edit report information",
    "editrowi87": "Edit Row {index}: {name}",
    "editstop7": "Edit Stops",
    "editthis67": "Edit {title} Expense Category",
    "edittitl1": "Edit {title}",
    "edittrav8": "Edit Traveler Information",
    "edityour2": "Edit your TravelBank profile.",
    "egbycate1": "(e.g. by category, amount, approver...)",
    "elan316": "Elan",
    "elaneasy296": "Elan Easy Pay",
    "elaneasy298": "Elan Easy Pay & Elan One Card",
    "elanonec297": "Elan One Card",
    "elipsisc10": "{ccType}...{ccLast4}",
    "ellipsis137": "....{last4}",
    "ellipsis158": ".... {last4}",
    "ellipsis202": "....{last4}",
    "ellipsis31": "...{last4} | {accountname}",
    "ellipsis46": "...{last4}",
    "ellipsis57": "...{last4}",
    "ellipsis7": "...+{hiddenfilters} more",
    "ellipsis70": "..{last4}",
    "ellipsis77": " ....{last4}",
    "ellipsis9": "...+{hiddenfiltercount} more",
    "email17": "Email",
    "email172": "Email",
    "email190": "Email",
    "email3": "Email",
    "email33": "Email",
    "email4": "Email",
    "email44": "Email",
    "email8": "Email",
    "emailadd22": "Email Address",
    "emailcan4": "Email can't be blank.",
    "emailexp19": "Email Export",
    "emailisr35": "Email is required.",
    "emailkey1": "Email",
    "emailonl1": "Email only",
    "emailplaceholder34": "name@company.com",
    "emailplaceholder9": "name@company.com",
    "emails13": "Emails",
    "emailsdo40": "Emails do not match",
    "emailsen25": "Email Sent",
    "emailsmu11": "Emails must match",
    "emailus31": "Email Us",
    "emailus32": "Email Us",
    "emailus33": "Email Us",
    "emailus34": "Email Us",
    "emailus35": "Email Us",
    "employe1": "Employee rewards; incentivize smart spending",
    "employe2": "Employee department",
    "employee1": "Employees",
    "employee102": "Employee ID",
    "employee106": "Employees",
    "employee118": "{employee}'s Credit Card",
    "employee121": "Employee ID",
    "employee128": "Employees in Policy",
    "employee141": "Employees in Policy",
    "employee173": "Employee ID",
    "employee183": "Employees will view these options in alphanumerical order.",
    "employee185": "Employee Id",
    "employee187": "Employee ID",
    "employee195": "Employee",
    "employee211": "Employees will not see this as an option moving forward. Any expenses associated with it currently will not be affected.",
    "employee62": "*Employees will receive manager approval permissions when added as a manager for another employee.",
    "employee70": "Employee",
    "employee72": "Employees",
    "employee73": "Employee Card",
    "employee76": "Employee",
    "employee77": "Employees",
    "employee78": "Employees in Policy",
    "employee79": "Employees",
    "employee80": "Employee ID",
    "employee82": "Employee Directory",
    "employee84": "Employees",
    "employee86": "Employee Information Saved",
    "employee87": "Employee Information Updated",
    "employee88": "Employee Has Been Terminated",
    "employee89": "Employee Reactivated",
    "employeeOther": "Employees may expense {amount} for {title}.",
    "employeePerDay": "Employees may expense {amount} a day for {title}.",
    "employeePerMonth": "Employees may expense {amount} a month for {title}.",
    "employeePerWeek": "Employees may expense {amount} a week for {title}.",
    "employeePerYear": "Employees may expense {amount} a year for {title}.",
    "employeed1": "Employee Directory",
    "empowere74": "Empower employees to pay for airfare, supplies, fuel, and everyday expenses.<br aria-hidden=\"true\" />Choose from virtual and physical card options.",
    "empty92": "Empty",
    "enablebi60": "enable billable to costumer",
    "enabled188": "Enabled",
    "enabled191": "Enabled",
    "enabled27": "Enabled",
    "enabled278": "Enabled",
    "enabled4": "Enabled",
    "enabled5": "Enabled",
    "enabled67": "Enabled",
    "enabled87": "Enabled",
    "enablepr15": "Enable Premium Insights",
    "enablere186": "Enable Reimbursements",
    "enablere188": "Enable Reimbursement Dismiss",
    "enablere190": "Enable Reimbursement Chat Now",
    "enablere76": "Enable recurring payments for company-wide subscriptions and other charges.<br aria-hidden=\"true\" />Available as a virtual card only.",
    "enablero27": "enable round trip",
    "enableus1": "Enable users to redeem rewards when they come under budget.",
    "enabling110": "Enabling Reimbursements",
    "enabling12": "Enabling Reimbursements",
    "endaddre10": "End Address",
    "endaddre21": "End address",
    "endaddre35": "End Address",
    "enteramo106": "Enter Amount",
    "enteramo118": "Enter Amount",
    "enterane36": "Enter an email",
    "entercod87": "Enter Code",
    "enterdet97": "Enter Details",
    "enterede38": "Entered email is already registered",
    "enterema39": "Enter email confirmation",
    "enterpas43": "Enter password confirmation",
    "enterval67": "Enter Valid Amount between 1 and 100",
    "enterval74": "Enter Valid Amount between 1 and 100",
    "error64": "Error",
    "error7": "Error",
    "errornot15": "Error Notifications",
    "errorremo85": "Error removing receipt",
    "errorupd75": "Error updating your profile.",
    "exchange117": "Exchange Rate",
    "excludef94": "Exclude from Organization Sync",
    "expdate11": "Exp: {date}",
    "expense-reports": {
      "approvers": {
        "genericFinance": "a Finance Manager",
        "genericManager": "a Manager",
        "or": "{userNames} or {last}"
      },
      "edit": {
        "deleteConfirm": {
          "cancel": "No",
          "confirm": "Yes",
          "message": "Discarding this report will delete all associated expenses as well, do you want to continue?",
          "title": "Please Confirm"
        },
        "messages": {
          "discardError": "There was an error discarding your report.",
          "discardOk": "{name} was discarded."
        },
        "save": {
          "selectDepartment": "Please select a department."
        }
      },
      "single": {
        "buttons": {
          "markAsPaid": "Mark as Paid",
          "reimburse": {
            "mobile": "Reimburse",
            "tablet": "Approve for Reimbursement"
          }
        }
      },
      "status": {
        "desktop": {
          "_paymentFailed": {
            "finance": "There has been a bank error with this payment.",
            "manager": "There has been a bank error with this payment.",
            "orgMember": "Error with Payment. Please make sure your bank account information is correct."
          },
          "_paymentPending": {
            "finance": "Reimbursement scheduled for {date}.",
            "manager": "Reimbursement scheduled for {date}.",
            "orgMember": "Reimbursement scheduled for {date}."
          },
          "_transactionProcessing": {
            "finance": "Payment for this report is Processing.",
            "manager": "Payment for this report is Processing.",
            "orgMember": "Payment for this report is Processing."
          },
          "active": {
            "finance": "This Expense Report is still active.",
            "manager": "This Expense Report is still active.",
            "orgMember": "This Expense Report is active.",
            "selfService": "This Expense Report is active."
          },
          "approved": {
            "finance": "This expense report is ready for your review.",
            "manager": "Your expense report was submitted on {submittedDate} and is awaiting approval by {approverList}.",
            "orgMember": "Your expense report was submitted on {submittedDate} and is awaiting approval by {approverList}."
          },
          "bankError": {
            "finance": "A reimbursement account has not been added for this user.",
            "manager": "A reimbursement account has not been added for this user.",
            "orgMember": "Please add a bank account to receive reimbursements."
          },
          "new": {
            "orgMember": "There was an issue submitting this report. Please try unsubmitting and resubmitting the report."
          },
          "paid": {
            "finance": "This expense report was paid on {date}.",
            "manager": "This expense report was paid on {date}.",
            "orgMember": "This expense report was paid on {date}.",
            "selfService": "This expense report was paid on {date}."
          },
          "returned": {
            "finance": "This expense report was returned.",
            "manager": "This expense report was returned.",
            "orgMember": "This expense report was returned. Review and resubmit."
          },
          "submitted": {
            "finance": "Your expense report was submitted on {submittedDate} and is awaiting approval by {approverList}.",
            "manager": "This expense report is ready for your review.",
            "orgMember": "Your expense report was submitted on {submittedDate} and is awaiting approval by {approverList}.",
            "selfService": "This expense report was completed on {submittedDate}."
          }
        },
        "mobile": {
          "_paymentFailed": {
            "finance": "Bank Error",
            "manager": "Bank Error",
            "orgMember": "Bank Error"
          },
          "_paymentPending": {
            "finance": "Reimbursement scheduled for {date}",
            "manager": "Reimbursement scheduled for {date}",
            "orgMember": "Reimbursement scheduled for {date}"
          },
          "_transactionProcessing": {
            "finance": "Payment Processing",
            "manager": "Payment Processing",
            "orgMember": "Payment Processing"
          },
          "active": {
            "finance": "This Expense Report is still active.",
            "manager": "This Expense Report is still active.",
            "orgMember": "This Expense Report is active.",
            "selfService": "This Expense Report is active."
          },
          "approved": {
            "finance": "Ready for review",
            "manager": "Pending finance approval",
            "orgMember": "Pending finance approval"
          },
          "bankError": {
            "finance": "Bank Error",
            "manager": "Bank Error",
            "orgMember": "Bank Error"
          },
          "new": {
            "orgMember": "Error submitting report. Please resubmit."
          },
          "paid": {
            "finance": "Paid on {date}.",
            "manager": "Paid on {date}.",
            "orgMember": "Paid on {date}.",
            "selfService": "Paid on {date}."
          },
          "returned": {
            "finance": "Returned",
            "manager": "Returned",
            "orgMember": "Returned. Please review and resubmit."
          },
          "submitted": {
            "finance": "Pending manager approval.",
            "manager": "Ready for review",
            "orgMember": "Pending manager approval.",
            "selfService": "Completed on {submittedDate}."
          }
        },
        "paidNoDate": "This expense report was paid.",
        "sharedWithFinance": "Shared with Finance on {sharedDate}."
      }
    },
    "expense124": "Duplicate receipt has been deleted",
    "expense2": "Expense",
    "expense30": "Expense",
    "expense38": "expense",
    "expense79": "Expense",
    "expensea101": "Expense Asignee",
    "expensea133": "Expense Assignee Email",
    "expensea134": "Expense Allocation",
    "expensea157": "Expense Assignee(s) as ",
    "expensea17": "Expense Assignee",
    "expensea186": "Expense Assignee dropdown",
    "expensea193": "Expense Assignee",
    "expensea200": "Expense All categories dropdown menu",
    "expensea204": "expense amount",
    "expensea24": "Expense Assignee",
    "expensea69": "Expense and transaction successfully merged.",
    "expenseaddsuccess90": "{length, plural, one {Success! We've added # expense.} other {Success! We've added # expenses.}}",
    "expensec1": "Expense Categories",
    "expensec109": "Expense Category",
    "expensec149": "Expense Categories",
    "expensec155": "Expense Categories as ",
    "expensec175": "Expense Category",
    "expensec176": "Expense Category (optional) dropdown",
    "expensec191": "Expense Category dropdown",
    "expensec199": "Expense Categories",
    "expensec2": "Expense custom fields",
    "expensec35": "Expense Category",
    "expensec41": "Expense Category",
    "expensed3": "Expense Details",
    "expensed6": "Expense Details",
    "expensed87": "Expense Details",
    "expensee1": "Expense Export",
    "expensee75": "Expense Export",
    "expensee93": "Expense Export Failed",
    "expensei96": "Expense Itemization",
    "expensem1": "Expense Management",
    "expensem12": "Expense Management",
    "expensem46": "Expense Management ({provider})",
    "expensem96": "Expense Management",
    "expensep1": "Expense Policy",
    "expensep106": "Expense Policies",
    "expensep122": "Expense Policy",
    "expensep134": "Expense Policy",
    "expensep63": "Expense Policy",
    "expensep65": "Expense Policy Basics",
    "expensep74": "Expense Policy",
    "expensep86": "Expense Policy",
    "expensep90": "Expense Policies",
    "expensep92": "Expense Policies",
    "expenser1": "Expense report approval workflow",
    "expenser107": "Expense Report",
    "expenser108": "Expense Report ID",
    "expenser116": "Expense Report Submitted",
    "expenser139": "Expense Report ID",
    "expenser145": "Expense Report Export",
    "expenser15": "Expense Reports | Manage",
    "expenser192": "Expense Report Status dropdown",
    "expenser2": "Expense Reports",
    "expenser21": "Expense Report",
    "expenser3": "Expense Report",
    "expenser37": "Expense Report Status",
    "expenser43": "Expense Report Status",
    "expenser5": "Expense Reports",
    "expenser50": "Expense Report Approved",
    "expenser53": "Expense Reports",
    "expenser65": "Expense Reports",
    "expenser86": "Expense Reporting",
    "expenser94": "Expense Report Details",
    "expenses1": "Expenses",
    "expenses10": "Expenses and transactions that have been previously merged cannot be merged with another item.",
    "expenses105": "Expenses",
    "expenses109": "Expenses Are Disabled For Your Company",
    "expenses120": "Expenses & Transactions Assigned to Report",
    "expenses142": "Expenses",
    "expenses148": "Expenses",
    "expenses16": "Expenses & Transactions",
    "expenses172": "Expenses",
    "expenses19": "Expenses",
    "expenses24": "Expense successfully updated",
    "expenses25": "Expense successfully discarded.",
    "expenses28": "Expenses & Transactions",
    "expenses3": "Expenses & Transactions",
    "expenses39": "Expenses & Transactions",
    "expenses43": "{count, plural, one {1 Expense or Transaction Item Selected} other {# Expense or Transaction Items Selected}}",
    "expenses47": "Expenses & Transactions",
    "expenses79": "Expenses and transactions that have been previously merged cannot be merged with another item.",
    "expenses89": "Expenses",
    "expenses91": "Expenses",
    "expensescreated": "Expenses created by your employees will be automatically assigned to an auto-created report according to the frequency you've set. By default, this setting applies to all expenses created by your employees.",
    "expensesmessage23": "{createdCount, plural, one {{createdCount} Expense created but we couldn't complete the {failedCount} expense details.} other {{createdCount} Expenses created but we couldn't complete the {failedCount} expense details.}}",
    "expenset3": "Expense & Transaction Details Don’t Match",
    "expenset83": " Expense & Transaction",
    "expensif14": "Expensify",
    "expensif9": "Expensify Receipts",
    "expirati211": "Expiration Date",
    "expirati9": "Expiration Date",
    "expiry37": "Expiry <span>{expirydate}</span>",
    "expiryda141": "Expiry date",
    "expiryda147": "Expiry date",
    "expiryda180": "Expiry Date",
    "expiryva16": "Expiry/Validity",
    "export162": "Export",
    "export5": "Export",
    "exportal159": "Export Allocations",
    "exportal43": "Export Allocations",
    "exportco116": "Export Columns",
    "exportco161": "Export Columns",
    "exportco25": "Export Columns",
    "exportco81": "Export Columns",
    "exportcs119": "Export CSV",
    "exportde28": "Export Details",
    "exportfr31": "Export Frequency",
    "exportli157": "Export Line Items",
    "exportli38": "Export Line Items",
    "exportra143": "Export Range",
    "exportse163": "Export Sent to Email",
    "exportsu118": "Export Summary",
    "exporttr108": "Export Transactions | Manage",
    "exporttr163": "Export Transactions",
    "external159": "External Account ID (Optional)",
    "external163": "External Category ID",
    "external164": "External ID",
    "external176": "External Account ID (Optional)",
    "external178": "External Category ID",
    "external184": "External ID",
    "external65": "External ID",
    "external72": "External IDs",
    "failedt41": "Failed to download. Please try again.",
    "failedto148": "Failed to export. Please try again.",
    "failedto15": "Failed to download. Please try again.",
    "failedto162": "Failed to generate expense report",
    "failedto196": "Failed to download. Please try again.",
    "failedto210": "Failed to Add/Edit custom field",
    "failedto339": "Failed to reassign credit card.",
    "failedto71": "Failed to save expense category",
    "failedto74": "Failed to save expense category",
    "failedto91": "Failed to generate expense report",
    "fareclas120": "Fare class dropdown: {settingFareClass} selected -",
    "fareclas136": "Fare Class",
    "fareclas95": "Fare Class",
    "february": "February",
    "female19": "Female",
    "fieldmod195": "{title} - Rules - Enabled",
    "fieldmod198": "{title} - Rules - Category Specific",
    "fieldmod205": "{title} - Rules - Required",
    "fieldnam175": "Field name cannot be changed after creation.",
    "fieldnam178": "Field name cannot be changed after creation.",
    "fieldnam185": "Field Name",
    "fieldnam187": "Field Name",
    "fieldnam188": "Field name - this cannot be changed after creation.",
    "fieldtyp186": "Field Type",
    "fieldtyp187": "Field Type Dropdown",
    "fieldtyp189": "Field Type",
    "fieldtyp190": "Field Type Dropdown",
    "fieldtyp96": "Field Type",
    "fieldtype178": "Field type cannot be changed after creation.",
    "fifteenm156": "15 Miles",
    "fileproc125": "File processing. You will receive an email when the upload is complete.",
    "filetype18": "File Type",
    "filetype53": "File types of {invalidFileType} are not supported at this time.",
    "filter17": "Filter",
    "filter3": "Filter",
    "filter35": "Filter",
    "filter4": "Filter",
    "filter99": "Filter",
    "filters109": "Filters",
    "filtersl164": "...+{filterlength} more filter(s)",
    "finalamo2": "Final Amount",
    "finalamo91": "Final Amount",
    "finance57": "Finance",
    "finance60": "Finance",
    "finance61": "Finance",
    "financea90": "Finance Approver for Entire Organization",
    "financea91": "Finance Approver Departments",
    "financee61": "Finance Email",
    "financew61": "Finance will be sent expense reports after they have been approved by managers. Finance approval will authorize reimbursement.",
    "findanot71": "Find Another",
    "finish138": "Finish",
    "finish39": "Finish",
    "finish84": "Finish",
    "finishem148": "Finish Employees in Policy",
    "finishla1": "Finish Later",
    "first154": "First",
    "first155": "First",
    "first57": "First",
    "firstcfv35": "First CF Values {customFields}",
    "firstnam14": "First Name",
    "firstnam25": "First Name",
    "firstnam26": "First Name",
    "firstnam29": "First name must be at least two characters.",
    "firstnam30": "First name is required.",
    "firstnam4": "First Name",
    "firstnam76": "First Name",
    "firstnameplaceholder5": "John",
    "firsttim120": "First time? Download the <a class=\"link\" href=\"{url}\">CSV template</a>.",
    "firsttim75": "First-time CSV user? Download the <a style=\"text-decoration: underline;\" class=\"link\" href=\"{url}\">ready-to-use CSV template</a> to ensure a smooth start.",
    "flight57": "Flight",
    "flightCreds": "{numFlightCredits, plural, =1 {Flight Credit} other {Flight Credits}}",
    "flightco101": "Flight Cost Hard Cap",
    "flightcr1": "Flight Credits",
    "flightcr20": "Flight Credit",
    "flightli5": "Flight List",
    "flightnu89": "Flight Numbers",
    "flightpo134": "Flight Policy",
    "flightpo76": "Flight Policy",
    "flightpo92": "Flight Policy",
    "flights": "{itemCount, plural, =1 {flight} other {flights}}",
    "flights3": "Flights",
    "foramile12": "For a <span class=\"emphasis-text\">Mileage or Repeat Expense,</span> please use the menu items below.",
    "foremplo1": "For employees who travel often and need access to travel features.",
    "foremplo2": "For employees who don't travel often and need access to expense features.",
    "forfurth104": "For further control, you can restrict delegate access to certain tasks below by turning the permission off.",
    "forgotpa14": "Forgot Password?",
    "form40": "Form",
    "formatam58": "{formatamount} USD",
    "formatam72": "{formatamount} | {fxamount}",
    "formatam73": "{formatamount} over {length} Splits | {fxamount}",
    "formatam74": "{formatamount} over {length} Splits",
    "formatam75": "{formatamount} | {fxamount}",
    "formatra1": "{total} /month billed annually",
    "formatra2": "{total} /month billed monthly",
    "forms": {
      "login": {
        "email": {
          "data-msg-required": "Email is required.",
          "placeholder": "Email Address"
        },
        "password": {
          "data-msg-required": "Password is required.",
          "placeholder": "Password"
        }
      }
    },
    "forsecuritypurposes": "For security purposes, this is your unique API Token, and it will only be displayed once. If you lose or forget the code, please click the \"Reset API Token\" button to retrieve a new API Token. When you select the \"Reset API Token\" button, the previous API Token will be deactivated.",
    "forstate202": "For statements older than 24 months, please reach out to customer support.",
    "fourteen144": "14 Days",
    "freeplan1": "Free Plan",
    "frequenc29": "Frequency",
    "frequency30": "Frequency",
    "frequencysupport1": "This specifies how often expenses are auto-submitted.",
    "frequent117": "Frequently Asked Questions",
    "friday14": "Friday",
    "friendor18": "Friend or Colleague",
    "futuretr271": "Future transactions and existing un-submitted transactions \\ will be managed by the new assignee{newuserfirstname}.",
    "fxamount116": "FX Amount",
    "fxinform123": "Fx Information",
    "gender17": "Gender",
    "generale41": "General Expenses",
    "generale49": "General Expenses",
    "generall122": "General Ledger Integration",
    "generalr115": "General Rules Receipt Requirement text box",
    "generalr118": "General Rules Mileage Reimbursement Rate text box",
    "generalr119": "General Rules Scheduled Report frequency dropdown",
    "generalr120": "General Rules Scheduled Report date of submission dropdown",
    "generalr124": "General Rules Description Requirement",
    "generalr125": "General Rules Corporate Card",
    "generalr138": "General Rules Split Department Access",
    "generalr81": "General Report {today}",
    "generalr90": "General Rules",
    "generate34": "Generate PDF",
    "generate40": "Generating PDF, navigating away will cancel your download.",
    "generati42": "Generating PDF",
    "getstart1": "Get Started",
    "getstart195": "Get started without a policy and easily make changes later if you change your mind.",
    "goback10": "Go Back",
    "goback49": "Go back",
    "goti90": "Got It",
    "gotit5": "Got It",
    "gotobank95": "Go to Bank Accounts",
    "gotoexpe35": "Go To Expenses",
    "gotoreim97": "Go to Reimbursements",
    "gotorepo117": "Go To Report",
    "gotorepo36": "Go To Reports",
    "greatert338": "Greater than: $",
    "greatert58": "Greater than: $",
    "hardcapp105": "Hard Cap Price",
    "hardcapp114": "Hard Cap Price",
    "hasrecei25": "Has Receipt",
    "hasrecei342": "Has Receipt",
    "havingtr6": "Having trouble adding your account?",
    "heckboxf104": "checkbox for Flight Cost Hard Cap",
    "helpcent90": "Help Center article",
    "hereisalist89": "Here is a list of duplicate receipts. If applicable, either delete them or consider adding a comment on the activity board in the report details for further clarification for your approver.",
    "hidesplits86": "Hide Splits",
    "hifirstn73": "Hi, {firstname}!",
    "hiimtryi180": "Hi, I'm trying to onboard as a flash user, and have been met with an error page",
    "hiitried27": "Hi, I tried adding my credit card and got declined",
    "hiname1": "Hi, {first} {last}",
    "hitherei36": "Hi there, I have been using TravelBank and would like to learn more about using Analytics to track my team's spend in real-time.",
    "holdtigh20": "Hold tight! We’re scanning your receipts...",
    "host45": "Host",
    "hostaree96": "Host are employees who have delegates perform tasks on their behalf",
    "hosts45": "Hosts",
    "hosts86": "Hosts",
    "hostsand70": "Hosts and their assigned delegates will appear here when added.",
    "hostsare128": "Hosts are employees who have delegates perform tasks on their behalf.",
    "hotel58": "Hotel",
    "hotel94": "Hotel",
    "hotelcla151": "Hotel Class",
    "hotelcla98": "Hotel Class",
    "hotellis6": "Hotel List",
    "hotelloy16": "Hotel Loyalty Program Number",
    "hotelloy28": "Hotel Loyalty Program",
    "hotelloy31": "Hotel Loyalty Program - {hotelLoyaltyName}",
    "hotelloy33": "Hotel Loyalty Program Number",
    "hotelloy34": "Hotel Loyalty Number",
    "hotelnig111": "Hotel Nightly Rate Hard Cap",
    "hotelpol135": "Hotel Policy",
    "hotelrat96": "{settingHotelRating} Star Hotels",
    "hotelrew85": "Hotel Rewards",
    "hotels": "{itemCount, plural, =1 {hotel} other {hotels}}",
    "hotels4": "Hotels",
    "hour57": "{timeoutvalue} hour",
    "hours58": "{timeoutvalue} hours",
    "howdidyo31": "How did you hear about TravelBank?",
    "howoften144": "How Often",
    "howwilly90": "How will you use TravelBank?",
    "id136": "ID",
    "id94": "ID",
    "idealfor81": "Ideal for long-term usage, can be used in person or online.",
    "idealfor84": "Ideal for online purchases, and long-term usage. Can also be added to a mobile wallet for in-person use.",
    "idealfor86": "Ideal for online purchases and short-term or one-time usage. Can also be added to a mobile wallet for in-person use.",
    "ifnotspe150": "If not specified, all report statuses will be exported.",
    "ifnotspe153": "If not specified, export will export all departments in your expense export.",
    "ifnotspe36": "If not specified, all report statuses will be exported.",
    "ifthecon85": "If the <span>condition</span> below is met, <span>{approver}</span> will be added for approval.",
    "iftherep97": "If the Report Contains",
    "iftodayi34": "If today is a weekend, Thanksgiving, Christmas Day, New Years Day, or it’s past 3:59 PM CT, the payment will be processed on the next business day.",
    "ifturned156": "If turned on, the export will include only expenses where payment method is reimbursable/personal card.",
    "ifyouare6": "If you are an approver, you can take action on them after submission.",
    "ifyouclo322": "If you close this card, it can no longer be used. The cardholder will NOT be notified",
    "ifyoulea89": "If you leave without saving, any changes will be discarded. This cannot be reversed",
    "ifyounee7": "If you need to change the bank account you're using for reimbursements please contact us at <a href=\"mailto:support@travelbank.com\">support@travelbank.com</a>",
    "ifyoupro78": "If you proceed, you can use filters to find deleted items and restore them.",
    "ifyousel199": "If you select this option, proceed to company settings after setup to customize your policy.",
    "ihaverea4": "I have read and accept the latest terms and conditions. ",
    "import124": "Import",
    "import85": "Import",
    "importho116": "Import Hosts & Delegates",
    "importus70": "Import Users",
    "importvi117": "Import via a CSV file to add several hosts and delegates at once. Please read our <a href=\"{url}\" target=\"blank\" class=\"link\">Help Center article</a> to learn more.",
    "inAppOnly2": "In-App Only",
    "inactive45": "Inactive",
    "inactive52": "Inactive",
    "inactive6": "Inactive",
    "inactive69": "Inactive",
    "inactive71": "Inactive",
    "inactive88": "Inactive",
    "includea87": "· Include Allocations",
    "includel86": "· Include Line Items",
    "incorrec1": "Incorrect attachment format. Please try with another file.",
    "incorrec114": "Incorrect verification code.",
    "indexoft9": "{index} of {total}",
    "innovato305": "Innovators Card",
    "inputfie178": " Input field type values",
    "inputthe12": "Input the emails addresses of the recipients who will receive the export (add a maximum of 5 email addresses).",
    "inputthe16": "Input the email address of the recipient who will receive notifications (i.e. error messages).",
    "instantc85": "Instant Card",
    "instantc93": "Instant Card",
    "instante173": "Instant Employee Card",
    "instante65": "Instant Employee Card",
    "integrat1": "Integrations",
    "integrat2": "Integration created successfully!",
    "integrat234": "Integrate with the bank of your choice and start managing and tracking all your corporate card spend.",
    "integrat3": "Integrations",
    "integrat4": "Integration disabled successfully!",
    "integrat44": "Integrations",
    "integrat45": "Integrations - Set by Organization",
    "integrat6": "Integrations Are Available on the Paid Plan",
    "integrationBannerInfoButton": "Refresh Page",
    "integrationBannerInfoText": "data is loading and may take up to 30 minutes. Refresh the page periodically to check on load status.",
    "integrationBannerReady": "is now ready for configuration.",
    "integrationEditButton": "Edit Integration",
    "integrationFetching": "Fetching external data...",
    "integrationsetup": "Integration Setup",
    "invalidTax1": "Tax amount cannot exceed {amount}.",
    "invalidc54": "Invalid Credentials.",
    "invalide5": "Invalid Email",
    "invalidi73": "Invalid image type",
    "invalids2": "Invalid Stops",
    "inviteem33": "Invite Employees",
    "invites20": "Invites ({count})",
    "invitese56": "Invite Sent",
    "invitese57": "Invite Sent",
    "invitese60": "Invite Sent",
    "invitese75": "Invite Sent",
    "irsrate74": "IRS Rate",
    "irsrate93": "{rateName} Rate",
    "issuerel134": "{issuer} ....{last4}",
    "issuerla13": "{issuer}....{last4}",
    "isthisca196": "Is this card lost or stolen?",
    "iteminde2": "Item {index} Amount",
    "iteminde4": "Item {index} Category",
    "iteminde6": "Item {index} Description",
    "itemizat98": "Itemization Requirement",
    "itemizee30": "Itemize Expense",
    "itemizee53": "Itemize Expense",
    "items18": "{n, plural, one {1 Item} other {{n} Items}}",
    "items22": "{n, plural, one {1 Item} other {{n} Items}}",
    "itemsare51": "Items are <span data-test-error=\"mismatch\">{overspendAmount}</span> over your total.",
    "itemsare52": "Items are <span data-test-error=\"mismatch\">{overspendAmount}</span> under your total.",
    "itemsover27": "Items are <span data-test-error=\"mismatch\">{amount}</span> over your total.",
    "itemsunder28": "Items are <span data-test-error=\"mismatch\">{amount}</span> under your total.",
    "itemsyou60": "Item/s You Selected Cannot Be Deleted",
    "itemsyou77": "Items You Selected Cannot Be Merged",
    "itemsyou8": "Items You Selected Cannot Be Merged",
    "itisvery320": "It is very important that the card be reported lost or stolen. We can help you with next steps.",
    "itmaytak88": "It may take a minute to receive your code.",
    "itseemsy61": "It seems you have selected a corporate card transaction or a manual expense merged with a corporate card transaction.",
    "january": "January",
    "johnplaceholder26": "John",
    "july": "July",
    "june": "June",
    "justlook185": "Just Looking Around",
    "keepcust4": "Keep Customizing",
    "keepsepa16": "Keep Separate",
    "keyerror250": "{key}Error",
    "kmdriven12": "KM Driven",
    "knowntra21": "Known Traveler Number",
    "label": "Street Address",
    "labeldat149": "{label} Date {startstr} - {endstr}",
    "last4wee7": "Last 4 weeks",
    "lastauth41": "Last Auth Type",
    "lastfour23": "Last 4 digits - {last4}",
    "lastfour84": "Last 4 digits - {last4}",
    "lastly82": "Lastly",
    "lastly88": "Lastly",
    "lastname16": "Last Name",
    "lastname27": "Last Name",
    "lastname31": "Last name must be at least two characters.",
    "lastname32": "Last name is required.",
    "lastname6": "Last Name",
    "lastname77": "Last Name",
    "lastnameplaceholder7": "Smith",
    "lastpaym80": "Last Payment",
    "laststat13": "Last Statement N/A",
    "laststat21": "Last Statement Date - Table header",
    "laststat25": "Last Statement N/A disabled",
    "laststat26": "Last Statement Date - {laststatementdate}",
    "laststat27": "Last Statement Date - Empty",
    "laststat5": "Last Statement Date",
    "latesttr2": "Latest Transactions",
    "launchcu7": "Launch Customized Settings Walkthrough",
    "launchse9": "Launch Setup",
    "learnmor1": "Learn More about Plans And Pricing",
    "learnmor130": "Learn More >",
    "learnmor132": "Learn More",
    "learnmor175": "Learn More about Plans And Pricing",
    "learnmor32": "Learn More",
    "learnmor62": "Learn more about <a href=\"https://travelbank.com/\">TravelBank</a>.",
    "learnmorereportsubm1": "Learn more About Scheduled Report Submissions",
    "ledgerin121": "Ledger Integration",
    "legalfir164": "Legal First Name",
    "legallas166": "Legal Last Name",
    "legalmid165": "Legal Middle Name",
    "letemplo137": "Let employees select and save their primary department for expenses. Turning this off requires manual entry for every expense.",
    "letemplo80": "Let employees select any department for split. When off, employees may only select from their assigned departments.",
    "letemplo81": "Let employees select any department for allocation. When off, employees may only select from their assigned departments.",
    "licensec174": "<div class=\"onboarding-subscriptions__overage-policy-info-box__license-name\">{title} costs</div><div class=\"onboarding-subscriptions__overage-policy-info-box__license-price\">${additionalusagecost} /user/mo.</div><div class=\"onboarding-subscriptions__overage-policy-info-box__license-billing\">billed monthly per usage</div>",
    "lifetime18": "Lifetime Savings",
    "limit15": "Limit",
    "limit66": "Limit",
    "limits123": "Limits",
    "limits152": "Limits",
    "lineitem39": "line-items",
    "linkalla98": "Link all associated card transactions for reimbursements. This is not a payment method.",
    "list35": "List",
    "listall90": "List all previously submitted or approved duplicate receipts. Review and consider adding comments on the activity board for further clarification from your employees.",
    "loading": "Loading...",
    "loading2": "Loading...",
    "loading213": "Loading...",
    "loading55": "Loading...",
    "loadingO99": "Loading options...",
    "loadinge161": "Loading Employees...",
    "loadinge165": "Loading Expense Export…",
    "loadinge41": "Loading Employees...",
    "loadinge46": "Loading Employees...",
    "loadingo1": "Loading options...",
    "loadingo7": "Loading options...",
    "loadprev15": "Load Previous Month",
    "locked25": "Locked",
    "lodgingp77": "Lodging Policy",
    "lodgingp94": "Lodging Policy",
    "lodgingx148": "LodgingX",
    "lodgingx151": "Lodging-X: Company Bank Account Dropdown",
    "lodgingx154": "Lodging-X: Reimbursement Schedule Dropdown",
    "loggedin14": "Logged in as {userfullname}",
    "loggedin4": "Logged in as {first} {last}",
    "login48": "Login",
    "logout3": "Log Out",
    "lookarou42": "Look Around First",
    "lookslik1": "Looks like you’re on a mobile device.",
    "lookslik29": "Looks like no one at your company has booked travel with TravelBank yet. Come back after your company has made a few bookings to view Analytics.",
    "loststol130": "Lost/Stolen account",
    "lowerCaseTravelers": "{numTravelers, plural, =0 {traveler} =1 {1 traveler} other {# travelers}}",
    "lowertha337": "Lower than: $",
    "lowertha57": "Lower than: $",
    "loyaltyp1": "Loyalty Programs",
    "maincont263": "Main Contact Email",
    "maincont264": "Main Contact Name",
    "makeaboo67": "Make a Booking",
    "makeanot11": "Make Another Payment",
    "makeaone21": "Make a One-time Payment",
    "makeapay42": "Make a Payment",
    "makeitea10": "Make it easy for employees to track spend. Review and approve expense reports from any device. We’ll notify employees when you’ve taken action.",
    "makeitea8": "Make it easy for employees to track spend. Review and approve expense reports from any device. We’ll notify employees when you’ve taken action.",
    "male18": "Male",
    "manage1": "Manage",
    "manage2": "Manage",
    "manage3": "Manage",
    "manage52": "Manage",
    "manage64": "Manage",
    "manage67": "Manage",
    "manage9": "Manage",
    "manageal14": "Manage all your reimbursements and integrate your expenses easily within Travelbank. TravelBank protects your information and is 100% secure.",
    "manageal177": "Manage all your custom fields assigned to expense reports. Employees will see this when submitting expenses.",
    "manageal6": "Manage all your payment methods and start booking all travel within Travelbank.",
    "manageca1": "Manage categories employees select from and edit GL mapping.",
    "managecu201": "Manage Custom Fields",
    "managecu202": "Manage custom fields for travel bookings. Up to 5 custom fields can be active at a time and visible to employees when booking trips.",
    "manageex173": "Manage Expense Custom Fields",
    "manageex7": "Manage expense reports for your entire company.",
    "manageex9": "Manage Expense Reports Like a Pro",
    "managefi176": "Manage Fields",
    "manageim1": "Manage, import and export your employees in TravelBank.",
    "manageke1": "Manage",
    "manageor93": "Manage Organization Hosts & Delegates",
    "manager1": "Manager approvals required when outside of the budget or policy settings",
    "manager56": "Manager",
    "manager62": "Manager",
    "manager70": "Manager",
    "manager82": "Manager",
    "managers95": "Managers will be notified if any of these rules are broken.",
    "managert86": "Manager, then Finance",
    "managetr170": "Manage Travel Custom Fields",
    "manageyo1": "Manage your employees and delegates in TravelBank.",
    "manageyo2": "Manage your employees and departments in TravelBank.",
    "manageyo5": "Manage Your Cards",
    "manageyo6": "Manage your card spending limits and see the status of your cards within TravelBank, all in one place.",
    "manageyo93": "Manage your business travel or automate spend management. Use both for an all-in-one experience.",
    "manageyou45": "Manage your employees car policies.",
    "managing10": "Managing Accounts",
    "managing113": "Managing Account",
    "managing153": "Managing Account as {label}",
    "managing156": "Managing Account",
    "managing157": "Managing account dropdown",
    "managing183": "Managing Account Name",
    "managing184": "Managing Account Number",
    "managing20": "Managing Account - Table header",
    "managing22": "Managing Account dropdown",
    "managing24": "Managing account - {managingaccount}",
    "managing4": "Managing Account",
    "managing47": "Managing Account Name",
    "managing69": "Managing Accounts",
    "managing7": "Managing Account",
    "manualex13": "Manual Expense Entry",
    "manualex43": "Manual Expense",
    "maprecei1": "Map Receipt",
    "march": "March",
    "marktrav13": "Mark traveler as myself",
    "maxamoun159": "Max Amount as ${max}",
    "maxcardl52": "Max. Card Limit",
    "maxlengt49": "Max Length 200",
    "may": "May",
    "mcc126": "MCC",
    "mccandde85": "MCC & Description",
    "mcccontr126": "MCC Controls",
    "mccdescr127": "MCC Description",
    "mccmerch133": "MCC (Merchant Category Code) Templates",
    "mccmerch134": "MCC (Merchant Category Code) Templates dropdown",
    "measuret22": "Measure Travel Spend",
    "merchant12": "Merchant",
    "merchant128": "Merchant City",
    "merchant129": "Merchant State",
    "merchant151": "Merchant Category Controls",
    "merchant19": "Merchant",
    "merchant2": "Merchant",
    "merchant46": "Merchant",
    "merchant73": "Merchant, required",
    "merchant84": "Merchant Location",
    "merchant99": "Merchant",
    "merge110": "Merge",
    "merge13": "Merge",
    "mergeMappingInfoTooltip": "This is a template of the information that will pre-populate on the expense in your application. Information in brackets will be specific to your data.",
    "mergeany11": "Merge Anyway",
    "mergeexp20": "Merge Expense",
    "mergeexp9": "Merge Expense",
    "messagee111": "{message} Error code {code}",
    "middlena15": "Middle Name",
    "mileage10": "Mileage",
    "mileage12": "Mileage Expense",
    "mileage13": "Mileage",
    "mileage28": "Mileage",
    "mileage80": "Mileage",
    "mileaged28": "Mileage Details",
    "mileaged4": "Mileage Details",
    "mileagee4": "Mileage Expense",
    "mileagee46": "Mileage Expense",
    "mileagee78": "Mileage expenses cannot be merged. Try selecting a different expense or transaction.",
    "mileagee9": "Mileage expenses cannot be merged. Try selecting a different expense or transaction.",
    "mileagem4": "Mileage Map",
    "mileager116": "Mileage Reimbursement Rate",
    "mileager120": "Mileage Reimbursement Rate Custom",
    "mileager121": "Mileage Reimbursement Rate IRS Rate",
    "mileager15": "Mileage Rate",
    "mileager33": "{mileageRate}x",
    "mileager71": "Mileage Reimbursement Rate",
    "mileager88": "Mileage Rate",
    "mileaget31": "{mileageTitle} (Read Only)",
    "mileaget66": "Mileage Title",
    "miles76": "Miles",
    "milesdri14": "Miles Driven",
    "milesdri87": "Miles Driven",
    "minamoun158": "Min Amount as ${min}",
    "mincardl51": "Min. Card Limit",
    "minimum8152": "Minimum 12 characters long",
    "minimume16": "Minimum 12 characters long",
    "minimumo5": "Minimum of {count} characters",
    "minutes56": "{timeoutvalue} minutes",
    "minutest99": "Minutes to sign out delegates",
    "missingc24": "Missing Cost",
    "missingc25": "Missing Category",
    "missingd23": "Missing Date",
    "missinge23": "Missing Expense Assignee",
    "missingi114": "Missing Information",
    "missingi2": "Missing Info",
    "missingi76": "Missing Information",
    "missingl69": "Missing link token, please contact support.",
    "missingm19": "Missing Merchant",
    "missingm21": "Missing Merchant",
    "missingm26": "Missing Merchant",
    "missingr40": "Missing required information.",
    "missingt40": "Missing Title",
    "missingt62": "Missing Transaction",
    "modify10": "Modify",
    "modifyca146": "Modify Card",
    "modifyca246": "Modify Card",
    "modifycr24": "Modify Credentials",
    "modifyre96": "Modify report approval flow based on report custom conditions.",
    "monday10": "Monday",
    "monitoro27": "Monitor overall savings against your travel policy, and view employees booking over and under budget.",
    "monthly4": "Monthly",
    "monthly80": "Monthly",
    "monthly9": "Monthly",
    "monthlyf1": "Monthly",
    "monthlyk1": "Monthly",
    "moredepa89": "{visibleDepartmentNames}, +{length} more Departments ",
    "moreitem75": "More Items",
    "mostchan247": "Most changes take effect immediately. Changes to MCC Card Controls may take upto 24 hours.",
    "multicar306": "Multi Card Mastercard",
    "multilevke1": "Multi-level approvals",
    "multilevke2": "Multi-level expense approvals",
    "multiple1": "Multiple custom policies",
    "multiple94": "Multiple Cards Selected",
    "mustbeat93": "Must be at least 8 characters",
    "mustincl151": "Must include one special character",
    "mustincl153": "Must include one uppercase and one lowercase character",
    "mustincl154": "Must include one number",
    "myaccoun15": "My Account",
    "myaccoun2": "My Account",
    "mycards1": "My Cards",
    "mycards11": "My Cards",
    "mycardst1": "My Cards & Transactions",
    "mydashbo75": "My Dashboard",
    "myexpens16": "My Expenses & Reports",
    "mytravel52": "My Travel Bookings",
    "na1": "N/A",
    "na326": "N/A",
    "na41": "N/A",
    "na70": "N/A",
    "nadditio1": "{n, plural, =0 {} one {1 Additional Stop} other {{n} Additional Stops}}",
    "name1": "Name",
    "name224": "Name",
    "name94": "Name",
    "namedash28": "Name - {name}",
    "namedash61": "Name - {accountname}",
    "nameexists1": "A travel policy with that name already exists.",
    "nameisre81": "Name is required",
    "namelast16": "{name}<span>...{last4}</span>",
    "namelast17": "{bankname}<span class=\"last-four\" style=\"flex: 1\">...{banklast4}</span>",
    "nameonca7": "Name on Card",
    "nametabl18": "Name - Table header",
    "navigati71": "Navigation",
    "needhelp1": " Need help choosing a plan or have a question about licenses? Email us at <a href=mailto:{emailHref} class=\"link\"><b>{emailHref}</b></a> and we'll be happy to assist!",
    "needhelp176": "Need help choosing a plan or have a question about licenses? Email us at <a href=\"mailto:upgrade@travelbank.com\" class=\"link\"><b>upgrade@travelbank.com</b></a> and we'll be happy to assist!",
    "negative21": "Negative Reimbursement Report",
    "new75": "New",
    "new94": "New",
    "new98": "New",
    "newexpen4": "New Expense Report",
    "newexpen51": "New Expense Report",
    "newlycre74": "Newly created categories will be added to all existing expense policies",
    "newpassw14": "New Password",
    "newpassw3": "New Password",
    "newpassw80": "New Password is required.",
    "newpassw86": "New Password",
    "newpassw94": "New password is required.",
    "newpassw95": "New password must be at least 8 characters",
    "newpassw96": "New password should not be the same as the old password.",
    "newtothe72": "New to the process? Dive into our <a style=\"text-decoration: underline;\" class=\"link\" target=\"_blank\" href=\"{url}\">Help Center article</a> for detailed instructions.",
    "newtotra33": "New to TravelBank?",
    "nexonia15": "Nexonia",
    "nexoniar10": "Nexonia Receipts",
    "next13": "next",
    "next35": "Next",
    "next57": "Next",
    "next73": "Next",
    "nextcycl208": "Next cycle end date is on {nextstatementdate}. Statement dates will vary if the cycle date falls on a weekend or holiday.",
    "nextcycl22": "Next Cycle End Date - Table header - Statement dates will vary if the cycle date falls on a weekend or holiday",
    "nextcycl29": "Next Cycle End Date - {cycleenddate}",
    "nextcycl30": "Next Cycle End Date - Empty",
    "nextcycl6": "Next Cycle End Date",
    "nextdued64": "Next due date",
    "ninetyni23": "99+",
    "no-expenses": {
      "addMessage": "Tap the + button to add a new expense.",
      "defaultMessage": "You don't have any unassigned expenses.",
      "swipeMessage": "Swipe left to add a new expense."
    },
    "no108": "No",
    "no13": "No.",
    "no195": "No",
    "no46": "No",
    "no55": "No",
    "no9": "No",
    "noalloca12": "No allocations.",
    "noapprov63": "No Approvals",
    "noapprov70": "No Approvals",
    "noapprov71": "No Approvals",
    "noattach99": "No attachments.",
    "nobookin28": "No Bookings Data",
    "nocompat11": "No Compatible Items Found",
    "nodepart11": "No departments found",
    "nodepart19": "No departments found",
    "nodepart6": "No departments found",
    "nodepart7": "No departments found",
    "noemploy184": "<div><div>No employees found. Cards can only be assigned to employees who have an active account on TravelBank.</div><br><div>Click <a class=\"link\" href=\"/organizations/{orgid}/employees/user-directory\">here</a> to add an employee.</div></div>",
    "noemploy59": "No Employee Assigned",
    "noemploy72": "<div><div>No employees found. Cards can only be assigned to employees who have an active account on TravelBank.</div><br> <div>Click <a class=\"link\" tabindex=\"0\" role=\"button\" href=\"/organizations/{orgid}/employees/user-directory\">here</a> to add an employee.</div></div>",
    "noexpens10": "No expenses available.",
    "noflight3": "No flight credits available. Credits may take up to 24 hours to update.",
    "nohistor10": "No Historical Transactions Yet!",
    "nomatche171": "No matches for <strong>{searchCriteria}</strong>",
    "nomatche175": "No matches for <strong>{searchCriteria}</strong>",
    "nomatche70": "No matches for \"<strong>{settingSearchCriteria}</strong>\"",
    "nomatche72": "No matches for \"<strong>{settingSearchCriteria}</strong>\"",
    "nomatche73": "No matches for \"<strong>{settingSearchCriteria}</strong>\"",
    "nomatche74": "No matches for \"<strong>{settingSearchCriteria}</strong>\"",
    "nomatche76": "No matches for \"<strong>{settingSearchCriteria}</strong>\"",
    "nomatchi131": "No matching users.",
    "nomatchi147": "No matching users.",
    "nomoredup1": "You have successfully resolved all of your duplicate receipt issues.",
    "nomoredup2": "All set, there are no more duplicates left in this expense!",
    "nomoredup3": "All set, no more duplicates left in this report!",
    "none45": "None",
    "none59": "None",
    "nonotifi62": "No Notifications",
    "nonotifi70": "No Notifications",
    "nonotifi79": "No Notifications",
    "nonrefunda121": "Non-Refundable",
    "nonreimb72": "Non-Reimbursable",
    "nonstop141": "Nonstop",
    "nopaymen27": "No payment due at this time.",
    "nopaymen50": "No payments found.",
    "nopaymen81": "No payment accounts available. Please add one.",
    "noperiod3": "No.",
    "noperiod76": "No.",
    "nopolicy85": "No Policy Set",
    "noreceip19": "No receipt",
    "noreceip343": "No Receipt",
    "noreceip55": "No Receipt",
    "noreport5": "No reports found",
    "noresult05": "No results found",
    "noresult11": "No results found",
    "noresult12": "No results found",
    "noresult16": "No results found.",
    "noresult162": "No Results Found",
    "noresult169": "No results found",
    "noresult20": "No results found",
    "noresult40": "No results found",
    "noresult45": "No results found",
    "noresult47": "No results found",
    "noresult55": "No results found.",
    "noresult6": "No results found",
    "noresult63": "No results found for \"{searchTerm}\"",
    "noresult68": "No results.",
    "noresult77": "No results found",
    "noresult81": "No results found.",
    "noresult84": "No results found",
    "noreward203": "No Rewards",
    "nosplits13": "No Splits.",
    "nosplits30": "No Splits yet. Add from the expense or transaction detail page.",
    "nostatem207": "No statements available.",
    "notetran3": "Note: Transactions may take 2-4 days to appear.",
    "notifType4": "{type}",
    "notifica109": "Notifications",
    "notifica115": "Notifications and Approvals",
    "notifica118": "Notifications and Approvals",
    "notifica119": "Notifications and Approvals",
    "notifica120": "Notifications",
    "notifica145": "Notifications",
    "notifica199": "Notifications",
    "notifica92": "Notifications and approvals are disabled. Users can book any flight without budget restrictions.",
    "notifica93": "Notifications and approvals are disabled. Users can book any hotel without budget restrictions.",
    "notinclu178": "Not included in this cardholder’s expense policy",
    "notinvit56": "Not Invited",
    "notinvit59": "Not Invited",
    "notinvit74": "Not Invited",
    "notitleg1": "No title given",
    "notransa16": "No Transactions Yet!",
    "notransa3": "No transactions to show from the past 60 days.",
    "notransa8": "No transactions match your search criteria.",
    "notransac1": "No Transactions Yet!",
    "notransac2": "Your transactions will appear here.",
    "notransac3": "No transactions found.",
    "notransac4": "Try using a different keyword or filter.",
    "notravel194": "No Travel Policy",
    "notsubmi36": "Not Submitted",
    "nouserso132": "No users on Policy",
    "november": "November",
    "numalloc26": "{count, plural, =0 {No Allocations} one {1 Allocation} other {# Allocations}}",
    "number89": "Number",
    "numberof30": "Number of Employees",
    "numberta19": "Number - Table header",
    "numemplo77": "{num, plural, one {# Employee} other {# Employees}}",
    "numsplit25": "{count, plural, =0 {No Splits} one {1 Split} other {# Splits}}",
    "numtotal132": "{transactions} Total Transactions",
    "numtrans131": "{selectedtransactions, plural, one {1 Transaction Selected} other {# Transactions Selected}}",
    "october": "October",
    "off3": "Off",
    "off56": "Off",
    "off78": "Off",
    "off86": "Off",
    "ograniza261": "Organization Information",
    "ok112": "OK",
    "ok19": "OK",
    "ok26": "OK",
    "ok88": "OK",
    "okay127": "Okay",
    "okay13": "Okay",
    "okay14": "Okay",
    "okay155": "Okay",
    "okay164": "Okay",
    "okay212": "Okay",
    "okay3": "Okay",
    "okay30": "Okay",
    "okay39": "Okay",
    "okay42": "Okay",
    "okay81": "Okay",
    "okayclos42": "Okay - close modal",
    "oktaintegration": "Okta Integration",
    "oldpassw91": "Old password is required.",
    "oldpassw92": "Old password must be at least 8 characters.",
    "on57": "On",
    "on77": "On",
    "on85": "On",
    "onboardi201": "Onboarding Title Missing",
    "onboardi3": "Onboarding",
    "onboardi6": "Onboarding Walkthrough",
    "onceveri107": "Once verified, you’ll be able to set your reimbursement schedule. Contact us at <a class=\"mail\" href=\"mailto:{generalemail}\">{generalemail}</a> to get verified.",
    "onedefa1": "One default policy for all users",
    "onedepar1": "One department for all users",
    "oneexpen17": "One expense has been flagged and may require further review.",
    "onefinan1": "One finance approver for payment",
    "onemana1": "One manager approves",
    "oneorgan171": "<sup>1</sup>All organizations using Travel on the free plan must go through account verification with TravelBank.",
    "oneormor80": "One or more fields has an error.",
    "onlinese14": "Online search",
    "only610c59": "Only 6-10 characters allowed",
    "onlyallo114": "Only Allow",
    "onlyallo150": "Only Allow",
    "onlydecl116": "Only Decline",
    "onlydecl149": "Only Decline",
    "onlyfina87": "Only Finance",
    "onlymana88": "Only Manager",
    "onlyreim88": "· Only Reimbursables",
    "onlytran1": "Only transactions posted in the past 3 years are available. Please chat with us to request older transactions. ",
    "onstop112": "Nonstop",
    "onthefre131": "On the free plan, your main travel policy for your organization has been set for you. It dictates how trip budgets are calculated and guides travelers' booking selections.",
    "onthefre51": "On the free plan, all employees will be assigned to the “General” department.",
    "onthefre68": "On the free plan, your main expense policy for your organization has been set for you.",
    "oops46": "Oops!",
    "open44": "Open",
    "openfiel59": "Open Field",
    "openfiel62": "Open Field",
    "opentext45": "Open Text",
    "optionLabel6": "{label} {selected}",
    "optional1": "Optional",
    "optional103": "Optional",
    "optional23": "(Optional)",
    "optional3": "Optional",
    "optional4": "(optional)",
    "optional40": "(Optional)",
    "optional53": "Optional",
    "optional61": "Optional",
    "optional72": "Optional",
    "optionna191": "Option name",
    "optionna206": "Option name",
    "optionsw181": "Options will be displayed alphanumerically for employees.",
    "or1": " or request manager approval at checkout",
    "or18": "Or",
    "organiza159": "Organization Name",
    "organiza182": "Organization Name",
    "organization": {
      "create": {
        "notCorporate": {
          "message": {
            "desktop": "Please sign up with a corporate email address first, then click the \"Create a Company\" link from that account.",
            "mobile": "Please sign up with a corporate email address first, then tap the \"Create a Company\" link from that account."
          }
        },
        "success": {
          "message": "Nice work! Your company has been successfully created.",
          "title": "Success!"
        },
        "unverifiedUser": {
          "buttonText": "Resend Email",
          "message": {
            "desktop": "Please activate your account first. Click the button in the email we sent to",
            "mobile": "Please activate your account first. Tap the button in the email we sent to"
          },
          "title": "Want to Set Up Your Company?"
        }
      }
    },
    "orgname203": "Org Name",
    "original1": "Original Amount",
    "original90": "Original Amount",
    "orthisbi1": "or {billedMonthlyText}",
    "other21": "Other",
    "other30": "Other",
    "other312": "Other",
    "otherapp78": "Other approvers are assigned to reports of {nextMinAmount} and above.",
    "othercar241": "Other Cards",
    "otherdet153": "Other Details",
    "outofpoc1": "Out of pocket expense tracking (including mileage)",
    "outofpoc2": "Out of pocket reimbursement",
    "outofpol125": "Out of Policy",
    "outofpol129": "Out of Policy",
    "outofpol150": "Out of Policy",
    "outofpol61": "Out of Policy",
    "outofpol92": "Out of Policy",
    "overspen93": "{firstName} <strong>overspent {overspendAmount}</strong> based on their expense policy.",
    "owneroft90": "If the <span>condition</span> below is met, <span>owner of {email} address</span> will be added for approval.",
    "paid45": "Paid",
    "paid61": "Paid",
    "paiddate125": "Paid Date",
    "paidlice1": "Paid Licenses",
    "paidlice166": "Paid Licenses",
    "paidplan1": "Paid Plan",
    "paidseats45": "Paid Seats",
    "pair27": "Pair",
    "pairerror89": "There was an error while trying to pair this expense.",
    "pairsuccess88": "Transaction successfully paired.",
    "passport24": "Passport Information",
    "passport25": "Passport Number",
    "passport26": "Passport Country",
    "passport27": "Passport Expiration",
    "password1": "Password does not meet requirements.",
    "password2": "Password do not match.",
    "password238": "Password",
    "password29": "Passwords must match",
    "password3": "Password",
    "password4": "Password is required.",
    "password42": "Password is required",
    "password44": "Passwords do not match",
    "password47": "Password is required.",
    "password5": "Password successfully updated",
    "password7": "Password",
    "password79": "Password successfully updated",
    "password81": "Password must be longer than 8 characters.",
    "password83": "Password Confirmation is required.",
    "password84": "Password Confirmation must be longer than 8 characters.",
    "password90": "Password",
    "pastdue59": "Past Due",
    "path46": "Path",
    "paymentDetails57": "Payment Details",
    "paymentDetails8": "Payment Details",
    "paymenta33": "Payment Approved",
    "paymenta70": "Payment Accounts",
    "paymenta8": "Payment Account",
    "paymentc15": "Payment Confirmed",
    "paymentd32": "Payment Date",
    "paymentd80": "Payment Details",
    "paymentf66": "Payment Failed",
    "paymentm1": "Payment Method",
    "paymentm11": "Payment Methods",
    "paymentm15": "Payment Method",
    "paymentm49": "Payment Method",
    "paymentm60": "Payment Method",
    "paymentp67": "Payment Pending",
    "payments37": "Payment successfully canceled.",
    "payments5": "Payments can be cancelled or modified up to 3:59 PM CT on the payment date.",
    "payments55": "Payment Scheduled",
    "payments57": "Payments",
    "payments8": "Payments",
    "paymentscan12": "At this time, you cannot make a payment on the app. You can make a payment through your bank as a bill payment or call us directly at 800-588-8065.",
    "pending": "Pending Configuration",
    "pending26": "Pending",
    "pending4": "Pending",
    "pending46": "Pending",
    "pending50": "Pending",
    "pendinga71": "Pending Approval",
    "pendingo204": "Pending or recurring transactions could be processed.",
    "pendingt11": "Pending ({total})",
    "pendingt14": "Pending transactions are available for the past 30 days only",
    "pendingt30": "Pending ({totalpendingtransactions})",
    "pendingt63": "Pending Transaction",
    "percenta20": "Percentage",
    "perexpen141": "Per Expense Limit",
    "perexpen144": "Per Expense",
    "permissi10": "Permissions",
    "permissi70": "Permissions",
    "permissi81": "Permissions",
    "personal156": "• Personal Synced Card",
    "personal159": "Personal Synced Card",
    "personal17": "Personal Card/Reimbursable",
    "personal2": "Personal Card/Reimbursable Expense",
    "personal20": "Personal Card/Reimbursable",
    "personal22": "Personal Card Refund",
    "personal29": "Personal",
    "personal3": "Personal Transactions",
    "personal44": "Personal Card/Reimbursable Expense",
    "personal5": "Personal Card/Reimbursable",
    "personal52": "Personal",
    "personal8": "Personal/Reimbursable",
    "personalcard83": "Personal Card/Reimbursable Expense",
    "phone10": "Phone",
    "phone189": "Phone",
    "phone39": "Phone",
    "phonekey1": "Phone",
    "phonenum170": "Phone Number",
    "phonenum2": "Phone Number",
    "phoneopt36": "Phone <span class='tb-input-group__optional'>(Optional)</span>",
    "phoneplaceholder11": "(XXX) XXXX-XXX",
    "photorec10": "Photo Receipt Required",
    "photorec2": "Photo Receipt Required",
    "physical54": "Physical",
    "physical78": "Physical Card",
    "physical79": "Physical Corporate Card",
    "physical8": "Physical Corporate Card",
    "placeholder": "Find an Address",
    "plansand1": "Plans & Pricing",
    "platinum303": "Platinum Plus® Mastercard® Business card",
    "pleaseca319": "Please call customer service<br />800-344-5696",
    "pleaseco1": "Please contact Customer Service via chat or email us at {emailHref}. ",
    "pleaseco10": "Please Confirm",
    "pleaseco115": "Please confirm that you want to delete this host and delegate group.",
    "pleaseco13": "Please contact TravelBank to enable daily reimbursements. You can chat with us in the app or email us at <a href=\"mailto:{generalSupportEmail}\" target=\"blank\">{generalSupportEmail}</a>",
    "pleaseco17": "Please contact TravelBank to enable premium insights. You can chat with us in the app or email us at <a href=\"mailto:{generalSupportEmail}\" target=\"blank\">{generalSupportEmail}</a>",
    "pleaseco181": "Please confirm you want to delete this custom field.",
    "pleaseco184": "Please confirm you want to delete this custom field.",
    "pleaseco22": "Please confirm you want to remove this card.",
    "pleaseco24": "Please Confirm",
    "pleaseco40": "Please confirm you want to delete {name}'s profile.",
    "pleaseco45": "Please Confirm",
    "pleaseco76": "Please confirm you want to remove this credit card.",
    "pleaseco93": "Please confirm that you want to terminate this employee’s account.",
    "pleasedo120": "Please don't leave the page. Your CSV file will automatically download once ready.",
    "pleasedo2": "Please don't leave the page. Your files will automatically download once ready.",
    "pleasedo28": "{error} Please double-check your information, add a new card or contact us for help. ",
    "pleaseen117": "Please enter all {revealcodelength} numbers.",
    "pleaseen12": "Please ensure traveler information is correct.",
    "pleaseen63": "Please enter a discount number or billing code before saving.",
    "pleasefi252": "Please fill in the required information. TravelBank will work with the requested bank to display your information.",
    "pleasel29": "Please select your preference for refundable or non-refundable tickets for your employees.",
    "pleasere198": "Please reconnect to {bankpublicname}.",
    "pleasere199": "Please reconnect to {bankpublicname} ({bankdescription}).",
    "pleasere290": "Please reconnect the bank connection to complete setup.",
    "pleasere60": "Please, re-enter your credentials.",
    "pleasese16": "Please select fewer and try again.",
    "pleasese186": "Please select an option.",
    "pleasese187": "Please select at least one option.",
    "pleasese51": "Please select fewer and try again.",
    "pleasese62": "Please select a rental car company.",
    "pleasese75": " Please select either \"Finance Approver for Entire Organization\" or specify finance departments",
    "pleasesi2": "Please sign in to your account.",
    "pleaseup49": "Please upload a .csv file.",
    "policyna74": "Policy Name is required.",
    "policyna75": "Policy Name is required.",
    "policyna79": "Policy Name is required.",
    "policyna82": "Policy name is required.",
    "port9": "Port",
    "postalco4": "Postal Code",
    "postalcode347": "Postal Code",
    "posted24": "Posted {date}",
    "posted28": "Posted",
    "postedba14": "Posted Balance",
    "postedba28": "Posted Balance",
    "postedba38": "Posted Balance",
    "postedda1": "Posted date - {monthAndDay}",
    "postedda129": "Posted Date",
    "postedda14": "Posted Date",
    "postedda166": "Posted Date Range (required)",
    "postedda2": "Posted date - No posted date",
    "postedda28": "Posted {date}",
    "postedda344": "Posted Date",
    "postedda56": "Posted {defaultdaterangetext}",
    "poweredb31": "Powered by",
    "predictc24": "Predict Costs",
    "preferred1": "Preferences",
    "preferred2": "Preferred Airlines",
    "preferred3": "Preferred Airlines dropdown",
    "premiumi18": "Premium Insights",
    "preparin1": "Preparing Download...",
    "preparing168": "Preparing Export...",
    "presentn142": "Preset Name",
    "presetfo137": "Preset Found",
    "presetop121": "Preset (optional)",
    "presetop122": "Preset (optional) dropdown",
    "previous12": "previous",
    "previous73": "Previously Selected",
    "previous74": "Previously Selected",
    "previous75": "Previously Selected",
    "previous76": "Previously Selected",
    "pricerat1": "{yearlyRateDisplay}/user/month",
    "pricewit1": "{oldYearlyRateDisplay}",
    "primaryd1": "Primary department saved.",
    "primaryd27": "Primary Department ({name})",
    "primaryd87": "Primary Department",
    "privacyp51": "Privacy Policy",
    "proceeda90": "Proceed Anyway",
    "processi11": "Processing...",
    "products92": "Products",
    "profilei2": "profile image",
    "profilei3": "Profile Information",
    "properti177": "Properties",
    "provider1": "{provider} Receipt Integration",
    "provider70": "{providerLabel} Category",
    "province2": "Province",
    "province348": "Province",
    "provincedro349": "Province dropdown list",
    "publicem45": "Public emails are not allowed. Please use your company email",
    "publicke8": "Public Key",
    "quickboo1": "Quickbooks Online (self-service)",
    "quickboo88": "QuickBooks GL Class",
    "quickfil6": "Quick Filters:",
    "quicklya71": "Quickly Add or Update Your Employees via CSV",
    "quicksca7": "QuickScan",
    "rate119": "Rate",
    "rate1234": "Rate",
    "rate205": "Rate",
    "reactiva99": "Reactivate",
    "readterm5": "Read Terms & Conditions",
    "readthep100": "Read the Privacy Policy",
    "readthet99": "Read the Terms of Use",
    "readytos1": "Ready to Submit",
    "reasonfo27": "Reason for decline - {reason}.",
    "reasonfo59": "Reason for decline - {declinereason}",
    "reasonfo78": "Reason for decline - {declinereason}",
    "reassign273": "Reassign to {newuserfullname}?",
    "reassign274": "Reassign card?",
    "reauthen78": "Reauthenticate",
    "receipt17": "Receipt",
    "receipt22": "Receipt",
    "receipt4": "Receipt",
    "receipt98": "Receipt",
    "receipta1": "Receipt attachment",
    "receiptp18": "receipt preview image",
    "receiptr113": "Receipt Requirement",
    "receipts156": "Receipt Status as {label}",
    "receipts193": "Receipt Status dropdown",
    "receipts38": "Receipt Status",
    "receipts44": "Receipt Status",
    "recently6": "Recently Scanned",
    "recently9": "Recently Used",
    "recentre20": "Recent Reports",
    "recommen2": "Recommended",
    "recommended1": "Recommended",
    "recommended51": "Recommended",
    "reconnec269": "Reconnect",
    "recurrin11": "Recurring Email Export",
    "recurrin141": "Recurring Spend Limit",
    "redirect12": "Redirecting you to <span>{ssoprovidername}</span>.",
    "refineth110": "Refine the data you want to export. If there are filters applied on the previous screen, they will also be in effect here.",
    "refresh86": "Refresh",
    "refund110": "Refundability",
    "refund19": "Refund",
    "refund24": "Refund",
    "refund29": "Refund",
    "refundable119": "Refundable",
    "refundro345": "Refundability dropdown: {value} selected -",
    "refundto12": "Refund to Company",
    "refundto48": "Refund to Company",
    "refundto5": "Refund to Company",
    "refundto6": "Refund to Company",
    "refundtoc81": "Refund to Company",
    "reimburs1": "Reimbursements",
    "reimburs100": "Reimbursement Account",
    "reimburs105": "Reimbursement Schedule",
    "reimburs114": "Reimbursable",
    "reimburs123": "Reimbursement Date",
    "reimburs152": "Reimbursement Schedule",
    "reimburs153": "Reimbursements can be run daily, monthly (1st), and semi monthly (1st and 15th).",
    "reimburs155": "Reimbursable Expenses Only",
    "reimburs16": "Reimbursement",
    "reimburs161": "Reimbursements are not enabled",
    "reimburs165": "Reimbursements",
    "reimburs172": "Reimbursements",
    "reimburs180": "Reimbursements Company Bank Account Dropdown",
    "reimburs181": "Reimbursement Schedule",
    "reimburs182": "Reimbursements can be run daily, monthly (1st), and semi monthly (1st and 15th).",
    "reimburs183": "Reimbursements Reimbursement Schedule Dropdown",
    "reimburs22": "Reimbursable",
    "reimburs24": "Reimbursment Fee",
    "reimburs27": "Reimbursable",
    "reimburs30": "| Reimbursable",
    "reimburs42": "Reimbursement Account",
    "reimburs57": "Reimbursement Account Failed",
    "reimburs58": "Reimbursement to Bank Account Failed",
    "reimburs6": "Reimbursement Schedule",
    "reimburs60": "Reimbursement Account Added",
    "reimburs7": "Reimbursements can be run daily, monthly (1st), and semi monthly (1st and 15th).",
    "reimburs89": "Reimbursement",
    "remnotif8": "Remove notification",
    "remnotifdesc9": "Remove notification {notificationDesc}",
    "remnotifstack10": "Remove notifications stack",
    "remnotifstackdesc11": "Remove notifications stack {notificationStackDesc}",
    "remove12": "Remove",
    "remove14": "Remove",
    "remove2": "Remove",
    "remove22": "Remove",
    "remove24": "Remove",
    "remove3": "Remove",
    "remove30": "Remove",
    "remove54": "Remove",
    "remove77": "Remove",
    "remove87": "Remove",
    "removeac94": "Remove Account",
    "removeba93": "Remove bank account?",
    "removeca20": "Remove Card",
    "removeca75": "Remove Card",
    "removecr21": "Remove Credit Card",
    "removedr210": "Remove Dropdown Value",
    "removein5": "Remove Integration",
    "removeintegration": "Remove Integration",
    "removere52": "Remove Reimbursement Account",
    "removing6": "Removing Integration",
    "removing7": "Removing Integration",
    "rentalca17": "Rental Car Loyalty Program Number",
    "rentalca29": "Rental Car Loyalty Program",
    "rentalca59": "Rental Car",
    "repass42": "Reset Password",
    "repeated17": "Repeated Stop",
    "repeated18": "Repeated stop",
    "repeatex1": "Repeat Expenses",
    "repeatex11": "Repeat Expense",
    "repeatex47": "Repeat Expense",
    "repeatex5": "Repeat Expense",
    "report94": "Report",
    "reportac53": "Report Active",
    "reportap51": "Report Approved",
    "reportco110": "Report Comments",
    "reportco145": "Report Comments",
    "reportin1": "Reporting",
    "reportna12": "Report Name",
    "reportna7": "Report Name",
    "reports10": "Reports",
    "reports46": "Reports",
    "reportsa11": "Reports Approved By Finance",
    "reportsa57": "Reports Are Disabled For Your Company",
    "reportsa8": "Reports Are Disabled For Your Company",
    "reportsc1": "Reports created but not submitted (accrual)",
    "reportsc13": "Reports Created",
    "reportst112": "Report Status",
    "reportst119": "Report Status",
    "reportst151": "Report Status",
    "reportst37": "Report Status",
    "reportsu14": "<b>Report Submitted</b> by <b>{fullname}</b>",
    "reportsu52": "Report Submitted",
    "reportun54": "Report Unassigned",
    "reportwillbesubmitted1": "Report will be submitted on the {submissionDaySuffix} of each month, starting {nextSubmissionDate}",
    "request267": "Request",
    "requesta284": "Request a Bank",
    "requestb251": "Request Bank Sync",
    "requeste254": "Requested Bank",
    "requeste345": "Requested Bank - dropdown",
    "requestm282": "Request more Banks or Card programs",
    "requestn220": "Request a New Code",
    "requests11": "Request Submitted",
    "requests285": "Request Submitted",
    "requests295": "Request Sync",
    "required1": "Required",
    "required118": "Required",
    "required161": "Required",
    "required19": "Required",
    "required193": "Required",
    "required22": "Required",
    "required23": "Required",
    "required25": "Required",
    "required26": "Required",
    "required3": "Required",
    "required47": "Required.",
    "required58": "Required",
    "required6": "Required",
    "required60": "Required",
    "required67": "Required",
    "required70": "Required",
    "required73": "Required",
    "required92": "Required",
    "requiree99": "Require Expense Splitting",
    "requirem128": "Require Merging of Expense with Corporate Card Transaction",
    "requirem204": "Requirement",
    "requirem97": "Requirement",
    "resendem160": "Resend Email",
    "resendem6": "Resend Email",
    "resendem7": "Resend Email",
    "resendem89": "Resend Email",
    "resendem9": "Resend Email",
    "resendin97": "Save & Resend Invite",
    "reset10": "Reset",
    "reset103": "Reset",
    "reset25": "Reset",
    "reset45": "Reset",
    "reset8": "Reset",
    "resetapitoken": "Reset API Token",
    "resetpas2": "Reset Password",
    "resetpas20": "Reset Password",
    "restore124": "Restore",
    "restoree112": "Restore Expenses",
    "retry53": "Retry",
    "returned65": "Returned",
    "returnto67": "Return to Report",
    "returnto95": "Return to report",
    "returnto96": "Return to Expense Details",
    "returnto97": "Return To Report",
    "returntodetails92": "Return to Expense Details",
    "returntoreport93": "Return to Report",
    "reveal91": "Reveal",
    "revealca38": "Reveal Card Number",
    "reviewany91": "Review any duplicates. If applicable, either delete them or consider adding comment on activity board on report details for further clarification for your approver.",
    "reviewdu10": "Review Duplicates",
    "reviewdu96": "Review Duplicates",
    "reviewpl15": "Review platform (e.g. G2 Crowd or Capterra)",
    "rewardpo1": "Reward Policy",
    "rewardpo141": "Reward Policy",
    "rewardpo147": "Reward Policy: Auto Rewards",
    "rewardpo150": "Reward Policy: Lodging-X",
    "rewardpo166": "Reward Policy: Rewards amount - percentage",
    "rewards12": "Rewards",
    "rewardsa155": "Rewards Amount",
    "rewardsb16": "Rewards Balance",
    "rewardsg87": "Rewards give your employees an incentive to spend less than the lodging budget dictated by your travel policy. Select what percentage of their savings you'd like to give back to them as rewards points, redeemable in our rewards store.",
    "rewardsp167": "Rewards Policy: Save",
    "rewardyo146": "Reward your employees with a portion of savings when booking a hotel. Rewards are processed automatically and included in the cost of the hotel booking.",
    "rooms": "{itemCount, plural, =1 {room} other {rooms}}",
    "roundtri12": "Round Trip",
    "roundtri20": "Round Trip",
    "roundtri25": "Round Trip",
    "roundtri84": "Round Trip",
    "routeand92": "Route & Dates",
    "routingn12": "Routing Number",
    "rule131": "Rule",
    "ruledrop132": "Rule dropdown list",
    "rules179": "Rules",
    "salesTaxAmount134": "Sales Tax Amount",
    "salestax83": "Sales Tax",
    "saturday15": "Saturday",
    "save102": "Save",
    "save124": "Save",
    "save125": "Save",
    "save129": "Save",
    "save137": "Save",
    "save144": "Save",
    "save160": "Save",
    "save169": "Save",
    "save17": "Save",
    "save171": "Save",
    "save186": "Save",
    "save207": "Save",
    "save20wi206": "Save 20% with All-in-One",
    "save23": "Save",
    "save48": "Save",
    "save58": "Save",
    "save7": "Save",
    "save72": "Save",
    "save74": "Save",
    "save82": "Save",
    "save86": "Save",
    "save87": "Save",
    "save89": "Save",
    "save9": "Save",
    "save98": "Save",
    "saveappr145": "Save Approval Rules",
    "saveaspr136": "Save as Preset",
    "savebill1": "Save Bill.com",
    "savecate145": "Save Category Rules",
    "savechan157": "Save Changes?",
    "saveexpe77": "Save Expense",
    "saveforl95": "Save for Later",
    "savegene139": "Save General Rules",
    "savenewo74": "Save New Option",
    "savepoli101": "Save Policy",
    "savereim184": "Save Reimbursements",
    "savesendinvi101": "Save & Send Invite",
    "savethes135": "Save these card controls as a preset to be able to apply them easily to other cards.",
    "savewith1": "Save with All-in-One",
    "savingpr179": "Saving Progress",
    "savings104": "Savings",
    "savings83": "Savings",
    "sawanadv16": "Saw an advertisement",
    "schedule1": "Scheduled Expense Export",
    "schedule51": "Schedule a payment to view it here.",
    "schreportsubm": "Scheduled Report Submission",
    "search1": "Search...",
    "search13": "Search",
    "search14": "<i class=\"icon-search\"></i> Search",
    "search169": "<i class=\"icon-search\"></i> Search",
    "search170": "Search",
    "search174": "Search",
    "search336": "<i class=\"icon-search\"></i> Search",
    "search40": "Search",
    "search45": "Search",
    "search54": "<i class=\"icon-search\"></i> Search",
    "search55": "<i class=\"icon-search\"></i> Search",
    "search62": "<i class=\"icon-search\"></i> Search",
    "search7": "Search",
    "search70": "<i class=\"icon-search\"></i> Search",
    "search71": "Search",
    "search72": "Search",
    "search73": "Search",
    "search74": "Search",
    "search75": "Search",
    "search83": "Search",
    "search9": "<i class=\"icon-search\"></i> Search",
    "search98": "search",
    "searchKey9": "Search",
    "searchPlaceholder": "Find Street Address",
    "searchde10": "Search Department",
    "searchde18": "Search Department",
    "searchde5": "Search Department",
    "searchde6": "Search Department",
    "searchfo10": "Search for Categories...",
    "searchfo168": "Search for Categories...",
    "searchfo17": "Search for departments...",
    "searchfo177": "Search for Expense Categories...",
    "searchfo249": "Search for your bank and card program",
    "searchfo33": "Search for Expense Reports...",
    "searchfo34": "Search for Cardholder...",
    "searchfo36": "Search for Expense Category...",
    "searchfo39": "Search for Cardholder...",
    "searchfo42": "Search for Expense Category...",
    "searchfo44": "Search for Categories...",
    "searchfo57": "Search for Expense Reports...",
    "searchfo6": "Search for places",
    "searchre4": "Search Report",
    "secondcf36": "Second CF Values {customFields}",
    "seeallpe13": "See All Pending Transactions",
    "seeallpe32": "See All Pending Transactions",
    "select1e68": "Select 1 each - an expense and transaction - to merge.",
    "select2": "Select",
    "select3": "Please select your preferred airline for corporate travel. Note that this selection is for approved airlines only and does not include marketing carriers.",
    "selectad65": "Select a Department.",
    "selectal19": "Select All",
    "selectan66": "Select an Expense Report.",
    "selectap120": "Select a preset to copy limits and MCC controls to this card. You can override them on the cardholder account.",
    "selectbu66": "Select Budget or Percentage",
    "selectbu73": "Select Budget or Percentage",
    "selectca79": "Select Car Rental Company",
    "selectca87": "Select Card Type",
    "selectco143": "Select company account to reimburse employees with. To add or manage your accounts, go to",
    "selectco94": "Select company account to reimburse employees with. To add or manage your accounts, go to Bank Accounts. ",
    "selectco96": "Select company account to reimburse employees with. To add or manage your accounts, go to Reimbursements. ",
    "selectda146": "Select Date Range",
    "selectda22": "Select Date Range",
    "selectda32": "Select Date Range",
    "selectda36": "Select Date Range",
    "selectde101": "Select delegates to perform tasks on the host’s behalf. Please note that if the host is an admin but the delegate is not, the delegate will not be able to access company settings.",
    "selectde152": "Select Department",
    "selectde99": "Select Department dropdown list",
    "selected210": "Selected Transactions Amount: {selectedamount}",
    "selected44": "Selected Amount: {amount}",
    "selected46": "Selected Pending Amount: {amount}",
    "selected48": "Selected Declined Amount: {amount}",
    "selected50": "Selected Pending Declined Amount: {amount}",
    "selected70": "Selected items",
    "selectem100": "Select employees to have tasks performed for.",
    "selectem130": "Select employees to be included in this travel policy. All employees are part of the default policy unless they have been added to a different policy. Employees can only be in one travel policy.",
    "selectem81": "\"Select Employees\" ",
    "selectem83": "Select Employees",
    "selecten14": "Select End Date",
    "selecten170": "Select End Date",
    "selecten21": "Select End Date",
    "selecten33": "Select End Date",
    "selecten37": "Select End Date",
    "selectex14": "Select Expense Date",
    "selectex17": "Select Existing Billing Address",
    "selectex48": "Select Expense Date",
    "selectha65": "Select Hard Cap Price",
    "selectha72": "Select Hard Cap Price",
    "selectho34": "Select how you would like to receive your scheduled export.",
    "selectmu102": "Select multiple delegates",
    "selectmu99": "Select multiple hosts",
    "selectnu108": "Select Number of Days",
    "selectnu64": "Select Number of Days",
    "selectpr1": "Select Provider",
    "selectpu77": "Select Purpose",
    "selectre149": "Select Report Status",
    "selectre35": "Select Report Status",
    "selectst13": "Select Start Date",
    "selectth19": "Select the file type for your export.",
    "selecttr22": "Select Transactions To Assign",
    "selectwh111": "Select when managers should be notified about flight bookings. This will not block travelers from booking.",
    "selectwh115": "Select when managers should approve car bookings. This will not block travelers from booking.",
    "selectwh118": "Select when managers should approve flight bookings. Travel will be booked automatically upon approval.",
    "selectwh121": "Select when managers should be notified about hotel bookings. This will not block travelers from booking.",
    "selectwh124": "Select when managers should approve hotel bookings. Travel will be booked automatically upon approval.",
    "selectwh128": "Select when managers should approve car bookings. Travel will be booked automatically upon approval.",
    "selectwh146": "Select when managers should be notified about flight bookings. This will not block travelers from booking.",
    "selectwh149": "Select when managers should approve flight bookings. Travel will be booked automatically upon approval.",
    "selectwh157": "Select which expense approvers are required by default.",
    "semimont3": "Semi-Monthly",
    "semimont8": "Semi-Monthly",
    "send29": "Send",
    "sendinvi96": "Send Invite",
    "september": "September",
    "setamile83": "Set a Mileage Rate.",
    "setarece82": "Set a Receipt Requirement.",
    "setcompa1": "Set company bank accounts for employee reimbursements.",
    "setcusto197": "Set custom field to only appear on specific expense categories",
    "setlinei97": "Set line items for expenses in this category. Required forces employees to itemize every expense they submit in this category.",
    "setpolic1": "Set policies, categories and custom fields. Export expenses.",
    "setrecei91": "Set receipt requirements, approval steps and more for all expenses.",
    "setrecur142": "Set recurring spending limits for this category.",
    "setrules1": "Set rules, approvals and notifications for travel.",
    "setrules142": " Set Rules",
    "setrules182": "Set Rules",
    "setrules190": "Set Rules Enabled",
    "setrules195": "Set Rules Required",
    "setrules2": "Set rules for expense policy.",
    "setspend142": "Set spending limits for individual expenses.",
    "setthepe156": "Set the percentage to reward your employees when they save while booking a hotel, from 20% up to 50%.",
    "settinga104": "Setting a Budget for Lodging",
    "settinga117": "Setting a Budget for Flights",
    "settings3": "Settings",
    "settings47": "Settings",
    "settingu183": "Setting Up My Company",
    "settrave1": "Set travel policies, approvals, rewards and notifications.",
    "setupint124": "Set Up Integrations",
    "setuprep1": "Set up report data exports to your general ledger software.",
    "setuprep2": "Set up repeat expenses to automatically create your expense on a regular basis.",
    "setwhene174": "Set when employees in this policy will be reimbursed.",
    "setwhich144": "Set which employees and departments this policy applies to.",
    "setyourf1": "Set your financing and frequency for employee reimbursements.",
    "sftp20": "SFTP",
    "sharethe49": "Share the expense report with your finance team to get reimbursed. Additional emails may be separated by comma.",
    "sharethe60": "Share the expense report with your finance team to get reimbursed. Additional emails may be separated by comma.",
    "shareyou48": "Share Your Expense Report",
    "showever85": "Show Everyone's",
    "showonly84": "Show Only Mine",
    "showsplits87": "Show Splits",
    "signin13": "Sign In",
    "signupwi40": "Sign Up With Corporate Email",
    "siliconv318": "Silicon Valley Bank",
    "singleex19": "Single expense option - selected",
    "singleex20": "Single Expense",
    "sitelang1": "Site Language",
    "smithplaceholder28": "Smith",
    "smonthly1": "Semi-monthly",
    "smskeyva1": "SMS",
    "socialme17": "Social media",
    "somecred341": "Some Credit Cards could not be assigned",
    "sometemp128": "Some templates are restricted and automatically set to decline. Contact your bank representative to request a change or exception.",
    "sometemp268": "Some templates are restricted and automatically set to decline.<br /> Contact your bank representative to request a change or exception.",
    "somethin118": "Something Went Wrong",
    "somethin119": "Something went wrong when submitting your report. Please try again.",
    "somethin211": "Something went wrong adding or updating your custom field, please try again later and contact support if the issue persists.",
    "somethin25": "Something went wrong.",
    "somethin75": "Something went wrong, please try again.",
    "something46": "Something went wrong",
    "sortby3": "Sort by:",
    "sortbyhe86": "sort by {heading}",
    "sortbyna71": "sort by name",
    "source": "Source",
    "sourceDescription": "Field created during {integrationName} integration data mapping on {date}.",
    "speciali266": "Special Instructions",
    "spendman2": "Spend Management",
    "spendman97": "Spend Management",
    "spendthi101": "Spend this Month",
    "spentthi209": "Spent this Month",
    "splitabove4": "Split is {amountError} <span data-test-text=\"side\">above</span> your {expenseAmount} expense total.",
    "splitabove45": "Split is {amountError} above your {expenseAmount} expense total",
    "splitamount1": "Split Amount",
    "splitbelow46": "Split is {amountError} under your {expenseAmount} expense total",
    "splitdep78": "Split Department Access",
    "splitexp10": "Split Expense",
    "splitexp22": "Split expense option - selected",
    "splitexp23": "Split Expense",
    "splitpercentage1": "Split Percentage",
    "splitsmu14": "Splits must be unique.",
    "splitsmu42": "Splits must be unique.",
    "splittaxamount1": "Split Tax Amount",
    "splitunder5": "Split is {amountError} <span data-test-text=\"side\">under</span> your {expenseAmount} expense total.",
    "spremium1": "Premium T & E",
    "standard1": "Standard CSV file extract",
    "starLabel": "{numStars, plural, =1 {1 star} other {# stars}}",
    "starcoun100": "star-count",
    "startadd20": "Start address",
    "startadd34": "Start Address",
    "startadd4": "Start Address",
    "state1": "State",
    "state228": "State",
    "statedro229": "State dropdown list",
    "statemen16": "Statement Date",
    "statemen169": "Statements",
    "statemen24": "Statement Balance",
    "statemen25": "statement balance info icon",
    "statemen43": "Statement Balance - {statementdate}",
    "statemen58": "Statements",
    "statemen7": "Statement dates will vary if the cycle date falls on a weekend or holiday.",
    "statemen8": "Statements",
    "statepro3": "State/Province/Region",
    "status102": "Status",
    "status107": "Status",
    "status172": "Status",
    "status18": "Status",
    "status180": "Status",
    "status205": "Status",
    "status50": "Status",
    "status52": "Status",
    "status57": "Status",
    "status64": "Status",
    "status70": "Status",
    "status80": "Status",
    "status84": "Status",
    "status98": "Status",
    "statusda54": "Status - {paymentstatus}",
    "statusproblem84": "There was a problem while loading report status",
    "stepcurr11": "Step {currentstepnumber} of {length}",
    "stepstep148": "Step {step} of 3",
    "stopaddr23": "Stop address",
    "stopinde36": "Stop {index}",
    "stopnumb22": "Stop {number}",
    "stops": "{numStops, plural, =0 {Nonstop} =1 {1 stop} other {# stops}}",
    "stops139": "Stops",
    "stops97": "Stops",
    "streetad13": "Street Address",
    "streetad14": "Street Address 2",
    "streetad225": "Street Address",
    "streetst191": "{street} {street2label}<br>{city} {state} {postalcode}<br>USA",
    "string85": "string...",
    "submissi6": "Submission Date",
    "submit113": "Submit",
    "submit24": "Submit",
    "submit81": "Submit",
    "submit9": "Submit",
    "submit97": "Submit",
    "submitne1": "Submit New Expense Report",
    "submitrepo1": "Submit Report",
    "submitte124": "Submitted Date",
    "submitte142": "Submitted Date",
    "submitte16": "Submitted Date: {submittedat}",
    "submitte22": "Submitted",
    "submitte32": "Submitted {expenseTitle} (Read Only)",
    "submitte39": "Submitted",
    "submitte64": "Submitted",
    "submitte78": "Submitted",
    "succesfu3": "Succesfully updated Bill.com Inbox",
    "success82": "Success!",
    "successf11": "Successfully saved your payment settings!",
    "successf35": "Successfully created expense report!",
    "successf4": "Successfully saved expense export settings.",
    "successf41": "Successfully edited expense report!",
    "successf60": "Successfully removed rental code",
    "successf65": "Successfully saved rental codes",
    "successf70": "Successfully saved expense category!",
    "successf71": "Successfully deleted bank account.",
    "successf73": "Successfully saved expense category!",
    "successf74": "Successfully updated your profile.",
    "successf75": "Successfully saved expense category policy.",
    "successw75": "{length, plural, one {Success! We've added 1 expense.} other {Success! We've added # expenses.}}",
    "suggeste64": "Suggested Employees",
    "summary118": "Summary",
    "summaryofduplicates94": "Summary of Duplicate Receipts",
    "sunday16": "Sunday",
    "supportText50": "This support text can be used to describe the purpose of the button group when inactive or the description of each option when active.",
    "supportf21": "Support FAQs",
    "switchac8": "Switch Account",
    "switchba77": "Switch Back",
    "sync2": "Sync",
    "syncaper12": "Sync a Personal Card",
    "syncarei61": "Sync a Reimbursement Account",
    "syncedca130": "Synced Card last 4",
    "syncedca58": "Synced Card",
    "syncedca63": "Synced Card Details",
    "syncedpe7": "Synced Personal Card",
    "synctogl12": "Sync to GL",
    "tabnumbe243": "Tab number {number}",
    "tags335": "Tags",
    "tags7": "Tags",
    "tags74": "Tags",
    "tailoryo1": "Tailor your annual subscription by mixing and matching to buy the number of licenses you need on an ongoing basis. If you ever go over your allocated licenses, we will simply bill you for your additional usage monthly.",
    "tailoryo167": "Tailor your annual subscription by mixing and matching to buy the number of licenses you need on an ongoing basis. If you ever go over your allocated licenses, we will simply bill you for your additional usage monthly.",
    "tax21": "Tax",
    "taxAmountError1": "Split tax amount exceeds the maximum",
    "taxAria21": "Tax enabled",
    "taxSup21": "When enabled, employees are required to enter the total tax amount on their expense. Mileage is not included.",
    "taxamount2": "Tax Amount",
    "tellusab25": "Tell Us About Yourself",
    "tellusab45": "Tell Us About Your Company",
    "template244": "{templatecode} - Template Detail",
    "terminat58": "Terminated",
    "terminat61": "Terminated",
    "terminat92": "Terminate",
    "termsofu50": "Terms of Use",
    "theaccou160": "The Account ID is used to map your chart of accounts on your ERP tool to your TravelBank expense categories.",
    "theaccou177": "The Account ID is used to map your chart of accounts on your ERP tool to your TravelBank expense categories.",
    "thebudge86": "The budget policy for the following employees will change, do you want to continue?",
    "thecarda223": "The card and instructions to activate the card will be sent to this address. It is also the billing address used for making purchases.",
    "thechose18": "The chosen expense category requires splitting to account for all line items.",
    "thechose50": "The chosen expense category requires splitting to account for all line items.",
    "thecombabove3": "The combined splits are {amountError}  <span data-test-text=\"side\">above</span> your {expenseAmount} expense total.",
    "thecombabove43": "The combined splits are {amountError} above your {expenseAmount} expense total",
    "thecombbelow44": "The combined splits are {amountError} under your {expenseAmount} expense total",
    "thecombunder3": "The combined splits are {amountError} <span data-test-text=\"side\">under</span> your {expenseAmount} expense total.",
    "thecurre174": "The current assignee will be able to manage completed and pending transactions prior to date of reassignment. All future transactions will be managed by the new assignee.",
    "thecusto206": "The custom field limit of 5 has been reached. To add a new custom field, please delete an existing custom field.",
    "thedolla114": "The dollar amount threshold that employees must attach receipts to their expenses.",
    "theexpen38": "The expense report is taking too long to generate a PDF, contact us for help through the chat link in the menu.",
    "thefligh137": "The flight budget is based on average prices of the selected fare class.",
    "thefligh140": "The flight budget is based on average prices of flights with the selected number of stops.",
    "thefligh96": "The flight budget is based on average prices of the selected fare class.",
    "thefligh98": "The flight budget is based on average prices of flights with the selected number of stops.",
    "thefree832": "The free plan includes one default expense policy.",
    "thefreep78": "The free plan includes one default expense policy. Upgrade your plan to add and edit expense policies. ",
    "thefreep83": "The free plan includes one default travel policy. Upgrade your plan to add and edit travel policies. ",
    "theirsst70": "The IRS standard is $75.",
    "thelodgi102": "The lodging budget is based on average prices of hotels within the selected search radius.",
    "thelodgi155": "The lodging budget is based on average prices of hotels within the selected search radius.",
    "thelodgi99": "The lodging budget is based on average prices of the selected hotel star rating.",
    "thelodin152": "The lodging budget is based on average prices of the selected hotel star rating.",
    "themaxim174": "The maximum number of custom fields for expenses has been reached, please reach out to support to make any changes.",
    "themulti117": "The multiplier that will be used to convert employee submitted mileage{km} to dollars for reimbursement.",
    "themulti72": "The multiplier that will be used to convert employee submitted mileage to dollars for reimbursement.",
    "then81": "Then",
    "then87": "Then",
    "thenfora98": "Then for Approval",
    "thenumbe79": "The number displayed below available credit is the amount set aside to clear the pending transactions and payments.",
    "thepassw1": "The password reset link has expired.",
    "thereare116": "There are no credit cards that meet your search criteria.",
    "thereare170": "There are no expense categories that meet your search criteria.",
    "thereare173": "There are no expense categories.",
    "thereare178": "There are no custom fields.",
    "thereare73": "There are no employees that meet your search criteria.",
    "thereare78": "{descriptionText} There are no expenses in this expense report.",
    "thereism23": "There is missing information from the form. Please click on the activation link in your activation email.",
    "therewas113": "There was an error while trying to send the verification code. Please try again.",
    "therewas115": "There was an error while verifying your code. Please try again.",
    "therewas126": "There was an error while trying to pair this expense.",
    "therewas2": "There was an error when attempting to retry expense export",
    "therewas24": "There was an error opening Marketplace. Please try again later.",
    "therewas25": "There was a problem opening advanced analytics",
    "therewas27": "There was an error while trying to pair this expense.",
    "therewas3": "There was an error saving expense export settings.",
    "therewas39": "There was an error generating the PDF, contact us for help through the chat link in the menu.",
    "therewas54": "There was an error while trying to save. Please try again.",
    "therewas61": "There was an error removing rental code.",
    "therewas62": "There was an error while trying to delete these expenses. Please try again.",
    "therewas63": "There was an error while canceling your {type} request.",
    "therewas64": "There was an error while trying to restore these expenses. Please try again.",
    "therewas65": "There was an error while deleting your {type} request.",
    "therewas66": "There was an error saving rental codes",
    "therewas70": "There was an error while trying to merge this expense and transaction.",
    "therewas72": "There was an error deleting bank account.",
    "therewas74": "There was an error saving expense category policy.",
    "therewas75": "There was an error while creating this expense policy. Please try again.",
    "therewas76": "There was an error while saving this expense policy. Please try again.",
    "theselec2": "The selected expenses and transactions can be viewed by going to the report and will no longer be visible on this Expenses & Transactions page.",
    "theserep5": "These reports are not yet submitted by the employee.",
    "theseset93": "These settings are used to calculate a budget for flight prices based on trip location and dates. Flight bookings will be considered in-policy if they are less expensive than the budgeted price. This will not restrict the traveler from booking different fare class or stops as long as the cost is within the budgeted amount.",
    "theseset95": "These settings are used to calculate a budget for hotel prices based on trip location and dates. Hotel bookings will be considered in-policy if they are less expensive than the budgeted price. This will not restrict the traveler from booking different hotel class or radius as long as the cost is within the budgeted amount.",
    "thesetra12": "These transactions are pending authorization and the final posted amount may vary. You can assign, merge or submit pending transactions after they are posted.",
    "thesetra31": "These transactions are pending authorizations and the final posted amount may vary.",
    "thespeci13": "The specified date range is not valid",
    "theupdat158": "The updates you’ve made to the company settings have not been saved.",
    "theycanb121": "They can be found in “Reports” > “{title}”. Note that the report is still active and has not been submitted.",
    "thisappr149": "This approver will be added to the default approval flow if any condition below is met.",
    "thisappr84": "This approval rule has pending expense reports, it cannot be deleted.",
    "thiscann105": "This cannot be reversed.",
    "thiscard1": "This card is not assigned. Please assign a user to manage transactions. ",
    "thiscard144": "This card has been deactivated and can no longer be used.",
    "thiscard183": "This card will automatically lock on this date. It is different from the <strong>expiry date ({expirydate})</strong> used to authenticate purchases.",
    "thiscard197": "This card account was closed on {closeddate}. Card details and transactions can be accessed here until {closeddetailsdateuntil}.",
    "thiscard205": "This card is not assigned to an Expense Assignee. Please assign to a user to manage transactions.",
    "thiscard46": "This card is Not Expired.",
    "thiscard47": "This card is Deactivated.",
    "thiscard48": "This card has not been activated yet.",
    "thiscard49": "This card is Expired.",
    "thiscard51": "This card was assigned to you by your organization. Expenses from these cards are non-reimbursable.",
    "thiscard53": "This card was added by you. Expenses from these cards are reimbursable.",
    "thiscard55": "This card is a physical card within your organization that can be used for expenses.",
    "thiscard57": "This card is a virtual card within your organization that can be used for expenses.",
    "thiscard59": "This card has been synced via Plaid. All associated card transactions can be found within your ‘Card Transactions’ to be assigned to expenses. This is not a payment method.",
    "thiscard61": "This card can be used as payment for TravelBank bookings. Transactions here won’t be synced into TravelBank.",
    "thiscard83": "This card has been deactivated by your admin and can no longer be used.",
    "thiscate179": "This category is currently not included on this cardholder’s expense policy. You can continue creating this card and then update their expense policy under company settings.",
    "thiscate75": " This category requires line items.",
    "thiscomp81": "This company virtual card is currently active and can be used to make purchases within TravelBank.",
    "thiscomp84": "This company virtual card isn’t fully set up by your admin yet.",
    "thiscomp85": "This company virtual card has expired.",
    "thisconn288": "This connection requires extra time to verify. You will be notified through email once the connection has been completed.",
    "thiscont97": "this contains duplicate expenses",
    "thiscorp82": "This corporate card is currently active.",
    "thiscurr1": "{currentLicenses} Licenses",
    "thisempl78": "{employeeCount, plural, =0 {No employees found.} one {{employeeCount} Employee} other {{employeeCount} Employees}}",
    "thisesti1": "This estimate only includes annual license fees.",
    "thisesti12": "This estimate only includes annual license fees.",
    "thisexpe21": "This expense does not need to be split and can be submitted as one line item.",
    "thisexpe24": "This expense needs to be split up by departments, categories, or other details.",
    "thisexpe25": "this expense has missing information",
    "thisexpe27": "This expense contains errors and is missing a paired transaction.",
    "thisexpe28": "This expense is missing a paired transaction.",
    "thisexpe29": "This expense contains errors or missing details.",
    "thisexpe39": "this expense is a duplicate",
    "thisfeat2": "This feature isn’t available on mobile browser. To view and submit expenses, download the app or switch to desktop.",
    "thishidd102": "...+{hiddenFilters} more",
    "thisinfo239": "This information is safe and secure.",
    "thisinte2": "This integration does not support syncing over allocation data at this time.",
    "thisisad1": "This is a dialog window which overlays the main content of the page. Pressing the Close Modal button will close the modal and bring you back to where you were on the page.",
    "thisisaf212": "This is a 4-digit code provided by your admin. You can also find it under card details on the My Cards page on the TravelBank app.",
    "thisisar101": "This is a representation of what the card will look like across the TravelBank platform. It may differ on your mobile wallet or other apps.",
    "thisisth26": "This is the amount on your last statement and does not include any payments made after the statement date.",
    "thispers66": "This personal card is currently synced and is marked as non-reimbursable.",
    "thispers67": "This personal card is currently synced and is marked as reimbursable.",
    "thispers68": "This personal card is expired.",
    "thisrepo32": "This report contains errors or missing details.",
    "thissele1": "{selectedLicensesText} for <b>{totalCostPerMonth}/month</b>",
    "thistemp245": "This template contains multiple merchant categories. A rule can be applied to <i>only allow</i> or <i>only decline</i> this template and all categories included in it.",
    "thistran68": "This transaction is pending authorization and the final posted amount may vary. You can assign, merge or submit pending transactions after they are posted.",
    "thistran79": "This transaction is pending authorization and the final posted amount may vary. Pending transactions reduce available credit limit.",
    "thistype64": "This {type} request does not exist.",
    "thistype66": "This {type} request does not exist.",
    "thisunsa77": "{unsavedRulesCount, plural, one {# Unsaved Change} other {# Unsaved Changes}}",
    "thiswill130": "This will be the master travel policy for your organization. It dictates how trip budgets are calculated and guides travelers' booking selections. Additional policies can be created and assigned to specific users or departments in company settings.",
    "thiswill21": "This will continue in the background. <br /> Feel free to close this page.",
    "thiswill72": "This will send all pending expenses to your manager",
    "thiswill96": "This will permanently remove the account from TravelBank.",
    "threesta153": "3 Star Hotels",
    "thursday13": "Thursday",
    "title155": "Title",
    "titletit242": "Title {title}",
    "toaddand43": "to add and edit expense policies.",
    "today1": "Today",
    "today33": "Today",
    "today85": "Today",
    "todeacti10": "To deactivate this card, please enter your U.S. Bank credentials.",
    "tolearnm118": "to learn more.",
    "tosetupo1": "To set up or manage auto-pay, chat with us or email us at {emailHref}. ",
    "tostartc236": "To start creating instant cards, please enter your {bankpublicname} credentials to start the process.",
    "totalAmount134": "Total Amount (always in {currency})",
    "totalAmount19": "Total Amount (always in {currency})",
    "totalamo211": "Total Transaction Amount: {totalamount}",
    "totalamo45": "Total Amount: {amount}",
    "totalamo82": "Total Amount",
    "totalbo1": "Total Bookings (travel transactions)",
    "totalcos25": "Total Cost",
    "totaldec49": "Total Declined Amount: {amount}",
    "totalerr41": "{totalErrors} errors with expense splits.",
    "totalexp9": "Total Expenses",
    "totalpen47": "Total Pending Amount: {amount}",
    "totalpen51": "Total Pending Declined Amount: {amount}",
    "totalper182": "${total} /month billed annually",
    "totalper191": "${total} /month billed monthly",
    "totalrei1": "Total reimbursed",
    "totalrei10": "Total Reimbursed",
    "totalrei26": "Total Reimbursable",
    "totalrep1": "Total reports approved by finance",
    "totaltax27": "Total Tax",
    "trackingCategoryHeader": "Classification Mapping",
    "tracksav26": "Track Savings",
    "tracktra31": "Track travel spend, employee savings, and travel trends with Analytics. Contact our team to unlock this feature.",
    "trackyou106": "Track Your Expenses",
    "trandetails4": "Transaction details from<br /> <b>{transactionVenueTitle}</b> on <b>{transactionDate}</b> for <b>{transactionCurrency}</b><br /> will override the respective fields on the expense<br /> <b>{expenseVenueTitle}</b> on <b>{expenseDate}</b> for <b>{expenseCurrency}</b><br /><br /> This cannot be reversed.",
    "transact1": "Transactions",
    "transact110": "Transactions | Manage",
    "transact111": "Transaction Status (required)",
    "transact12": "Transaction Sync Is Disabled For Your Company",
    "transact122": "Transactions successfully deleted",
    "transact123": "Transactions deletion failed!",
    "transact124": "Transactions assignement failed!",
    "transact125": "Transaction successfully paired.",
    "transact131": "Transaction Reference ID",
    "transact150": "Transaction Status as {label}",
    "transact189": "Transaction Status dropdown",
    "transact2": "Transactions",
    "transact26": "Transaction successfully paired.",
    "transact275": "<sup class=\"cc-verify-save-modal__superscript\">*</sup>Transactions already assigned to a report will continue to be managed by the current assignee{currentuser}.",
    "transact28": "Transactions Assigned",
    "transact31": "Transaction Status",
    "transact33": "Transaction Status",
    "transact4": "Transaction History",
    "transact42": "{transactionCount, plural, one {1 Transaction} other {# Transactions}}",
    "transact56": "Transaction {defaultdaterangetext}",
    "transact6": "Transactions",
    "transact70": "Transaction Sync",
    "transact76": "Transaction Detail",
    "transact81": "Transaction Date",
    "transacti167": "Transaction Date Range (required)",
    "transdat15": "Trans Date",
    "travel140": "Travel",
    "travel169": "Travel",
    "travel214": "Travel",
    "travel3": "Travel",
    "travel70": "Travel",
    "travel72": "Travel",
    "travel9": "Travel",
    "travelat23": "Travelator Terms of Use",
    "travelba133": "TravelBank Recommended Policy",
    "travelba196": "TravelBank Recommended",
    "travelba29": "TravelBank user who can view transactions, create manual expenses, and (if applicable) submit expense reports on behalf of the cardholder.",
    "travelba32": "Travelbank Logo",
    "travelba7": "TravelBank integrates with the software and tools you’re already using to keep your business running smoothly, like Netsuite, Workday, and more!",
    "travelba90": "TravelBank Confirmation Number",
    "travelc1": "Travel custom fields",
    "travelcu171": "Travel custom fields are currently disabled.",
    "traveler1": "Traveler Information",
    "traveler10": "Traveler Profile",
    "travelerLabel": "{numTravelers, plural, =0 {None} =1 {1 other} other {# others}}",
    "travelers": "{numTravelers, plural, =0 {Travelers} =1 {1 Traveler} other {# Travelers}}",
    "travelke1": "Travel",
    "travelma94": "Travel Management",
    "travelpo1": "Travel Policy",
    "travelpo127": "Travel Policy",
    "travelpo70": "Travel Policy",
    "travelpo71": "Travel Policy",
    "travelpo83": "Travel Policy",
    "travelpo85": "Travel Policy",
    "travelre4": "Travel Requests",
    "travels1": "Travel<sup>1</sup>",
    "traveltr1": "Travel Tracker",
    "traveltr8": "Travel Transactions",
    "travistr80": "Travis Traveler",
    "triggeredRule": {
      "intervalMessage": {
        "perDay": "<strong>Over {limit} spend</strong> this day for {name}.",
        "perExpense": "<strong>Over {limit} spend</strong> per expense for {name}.",
        "perMonth": "<strong>Over {limit} spend</strong> this month for {name}.",
        "perWeek": "<strong>Over {limit} spend</strong> this week for {name}.",
        "perYear": "<strong>Over {limit} spend</strong> this year for {name}.",
        "unknown": "<strong>Over {limit} spend</strong> unknown interval for {name}."
      },
      "limitIntervalName": {
        "perDay": "Per Day Limit",
        "perExpense": "Per Expense Limit",
        "perMonth": "Per Month Limit",
        "perWeek": "Per Week Limit",
        "perYear": "Per Year Limit"
      },
      "totalIntervalMessage": {
        "perDay": "Day total: {total}",
        "perMonth": "Monthly total: {total}",
        "perWeek": "Weekly total: {total}",
        "perYear": "Yearly total: {total}"
      }
    },
    "trip103": "Trip",
    "trip137": "Trip",
    "tripid104": "Trip ID",
    "tripid138": "Trip ID",
    "trips": {
      "empty": "Start a new trip and #TravelHappy.",
      "title": "Manage Trips"
    },
    "tryagain27": "Try Again",
    "tryloggi30": "Try logging in or use the forget password if you don’t remember your password.",
    "tryusing17": "Try using a different keyword or filter.",
    "tryusing21": "Try using a different keyword or filter",
    "tryusing56": "Try using a different keyword.",
    "tryusing82": "Try using a different keyword or filter.",
    "tsysId1": "Unique Txn Id",
    "tuesday11": "Tuesday",
    "turnonif127": "Turn on if employees use a corporate card. These expenses will not be reimbursed.",
    "turnonre173": "Turn on reimbursements to reimburse expenses in this policy through TravelBank.",
    "turnonto135": "Turn on to give employees the option to allocate their expenses to multiple departments. Allocation data does not sync over to integrations like QBO, NetSuite and Xero.",
    "turnthis158": "Turn this on to export line items from expenses.",
    "turnthis160": "Turn this on to export allocations from expenses.",
    "turnthis39": "Turn this on to export line items from expenses.",
    "turnthis44": "Turn this on to export allocations from expenses.",
    "type19": "Type",
    "typedisplaynames": {
      "expensemanagement": "Expense Management",
      "generalledger": "General Ledger",
      "hris": "Human Resources",
      "humanresources": "Human Resources"
    },
    "typeinto204": "Type in to search",
    "typemana83": "Type manager name to search",
    "typeofac90": "Type of Account",
    "typerequ74": "{type} Request Cancelled.",
    "typetose160": "Type to search employee name...",
    "uid212": "UID (for troubleshooting)",
    "unableTo5": "Unable to save your changes. Please try again.",
    "unableto12": "Unable to cancel the payment as it is past the cancelation cut-off. Payments must be canceled before 3:59 PM CT on the payment date.",
    "unableto166": "Unable to set MCC controls.",
    "unableto181": "Unable to link bank account.",
    "unableto189": "Unable to link bank account. ({error})",
    "unableto27": "Unable to create card",
    "unableto55": "Unable to get QBO Classes, try again later.",
    "unableto61": "Unable to set QBO Class, please try again.",
    "unableto69": "Unable to save option",
    "unableto75": "Unable to save option",
    "unableto90": "Unable to toggle block auto joining",
    "unapprov77": "Unapprove Report",
    "unassign24": "Unassigned",
    "unassign60": "Unassigned",
    "unassign80": "Unassigned",
    "unitedst109": "United States",
    "unknowni9": "Unknown integration provider: {provider}.",
    "unlimite1": "Unlimited departments",
    "unlockal1": "Unlock all of TravelBank's premium features from Travel and Expense in one license.",
    "unlockan30": "Unlock Analytics",
    "unlocked47": "Unlocked",
    "unsavedc88": "Unsaved Changes Will Be Discarded",
    "update16": "Update",
    "update20": "Update",
    "update5": "Update",
    "update7": "Update",
    "updateai21": "Update Airline Loyalty Program",
    "updatebi1": "Update billing codes and discount numbers for car rentals.",
    "updateca18": "Update Car Loyalty Program",
    "updatecr14": "Update Credit Card Ending in {ccLast4}",
    "updateho23": "Update Hotel Loyalty Program",
    "updatepa1": "Update Password",
    "updatepa37": "Update Password",
    "updatere55": "Update Reimbursement Account",
    "updateyo15": "Update your credit card information.",
    "updateyo19": "Update your Rental Car Loyalty Program Number to earn points and unlock status tiers.",
    "updateyo22": "Update your Airline Loyalty Program Number to earn points and unlock status tiers.",
    "updateyo24": "Update your Hotel Loyalty Program Number to earn points and unlock status tiers.",
    "updating56": "Updating this information will replace your existing bank account used for reimbursements.",
    "upgrade652": "Upgrade your plan",
    "upgradep33": "Upgrade Plan",
    "upgradep79": "Upgrade Plan",
    "upgradep84": "Upgrade Plan",
    "upgrades234": "Upgrades",
    "upgradet1": "Upgrade to Premium Insights to access more reports and data. ",
    "upgradet103": "Upgrade to Edit",
    "upgradet113": "Upgrade to Get More from TravelBank",
    "upgradet114": "Upgrade to Edit",
    "upgradet126": "Upgrade to Edit",
    "upgradet140": "Upgrade to Edit",
    "upgradet146": "Upgrade to Edit",
    "upgradet164": "Upgrade to Edit",
    "upgradet185": "Upgrade to Edit",
    "upgradet2": "Upgrade to Premium Insights",
    "upgradet79": "Upgrade to Add Travel Policy",
    "upgradet8": "Upgrade to Unlock",
    "upgradet85": "Upgrade to Add",
    "upgradet94": "Upgrade to Add Expense Policy",
    "upgradey82": "Upgrade your plan",
    "uploadar107": "Upload a receipt and we'll fill in the rest. You can also forward email expenses to <a class=\"empty-screen__text_inline-link\" href=\"{emailHref}\" >receipts@travelbank.com</a>.",
    "uploadcs69": "Upload CSV File",
    "uploadcs82": "Upload .csv file",
    "uploadfi79": "upload file",
    "upto152": "Up to {amount}",
    "usagecos1": "{cost} /user/mo.",
    "usbank314": "US Bank",
    "usbankke1": "U.S. Bank",
    "usbankse113": "U.S. Bank server error.",
    "usd206": "USD",
    "usd23": "USD",
    "usd71": "USD",
    "usehisto25": "Use historical data as benchmarks to accurately forecast future spend and improve employee behavior.",
    "useourre197": "Use our recommended settings out of the box now, with the flexibility to adjust them later.",
    "useourre200": "Use our recommended settings out of the box now.",
    "user": {
      "login": {
        "button": "Log In",
        "formheader": "Log In",
        "privacy_description": {
          "p1": "By signing up, I agree to the",
          "p2": "and the ",
          "privacy_policy": "privacy policy",
          "terms_of_use": "terms of use "
        }
      },
      "needsVerification": {
        "message": "To join the {companyName} team on TravelBank, tap the button in the email we sent to ",
        "title": "Check Your Email"
      },
      "needsVerificationNoOrg": {
        "message": "To join TravelBank, tap the button in the email we sent to ",
        "title": "Check Your Email"
      },
      "settings": {
        "removeBankAccount": {
          "fail": "There was a problem removing the bank account.",
          "link": "Remove Reimbursement Account",
          "success": "Successfully removed bank account."
        }
      },
      "signup": {
        "account_exists": {
          "message": "Try logging in or use the forget password if you don’t remember your password.",
          "passwordForgotButton": "Forgot Password",
          "title": "You already have an account"
        },
        "button": "Sign Up",
        "confirmAccount": {
          "message": "When you're ready, confirm your account by clicking the link we sent to your email.",
          "title": "We sent you an email"
        },
        "formheader": "Sign Up",
        "invite_exists": {
          "message": "We need you to verify your email address by accepting the invite in your email. We resent it so you have it at the top of your inbox.",
          "title": "You've already been invited!"
        }
      }
    },
    "user102": "User",
    "useremai120": "User Email",
    "username6": "Username",
    "usethein68": "Use the input above to search for options.",
    "usethisf208": "Use this form only if your employer has sent you a physical credit card issued by {banklabel}.",
    "usingfor184": "Using for Myself",
    "utofpoli113": "Out of Policy",
    "validema4": "Valid email address",
    "validema9": "Valid email address",
    "validto333": "Valid To",
    "validunt179": "Valid Until",
    "validunt182": "Valid Until",
    "vendorca75": "Vendor Card",
    "vendorna163": "Vendor Name",
    "vendorvi171": "Vendor Virtual Card",
    "vendorvi68": "Vendor Virtual Card",
    "verifica1": "Verification Link Sent",
    "verify4": "Verify",
    "verify86": "Verify",
    "verifyac13": "Verify Account to Manage All Expenses",
    "verifyac4": "Verify Account to Add Traveler Profile",
    "verifyac5": "Verify Account to Complete Setup",
    "verifyin222": "Verifying...",
    "verifyit218": "Verify It’s You",
    "verifyyo59": "Verify Your Reimbursement Account",
    "version49": "Version",
    "versionv22": "Version {versionNumber}",
    "vertical279": "| {accountname}",
    "view": "{numFlightCredits, plural, =1 {View Credit} other {View Credits}}",
    "view123": "View",
    "view199": "View",
    "view3": "View",
    "view44": "View",
    "viewacti1": "View active travelers and their trip details.",
    "viewall14": "View All",
    "viewanex82": "View an Expense",
    "viewdele108": "View Deleted Expenses",
    "viewedit1": "View, edit and manage the employees in your organization.",
    "viewedit2": "View, edit and manage the departments in your organization.",
    "viewexpe96": "View Expense",
    "viewlast12": "View Last Statement",
    "viewmore15": "View More Cards",
    "viewmore18": "View More Expenses",
    "viewolde4": "View Older Transactions",
    "viewpast21": "View Past Reports",
    "viewpast42": "View Past Reports",
    "viewpast54": "View Past Bookings",
    "viewpast69": "View Past Bookings",
    "viewrest129": "Restricted MCCs",
    "viewrewa17": "View Rewards",
    "viewsemi280": "View:",
    "viewsupp10": "View Support Article",
    "viewtota23": "View total travel spend on a company, department, or employee basis.",
    "viewtran79": "View Transactions",
    "viewtrav2": "View TravelBank Integrations",
    "virtual56": "Virtual",
    "virtual83": "Virtual",
    "virtualc172": "Virtual Corporate Card",
    "virtualc325": "Virtual Card Details",
    "virtualc5": "Virtual Corporate Card",
    "virtualc67": "Virtual Corporate Card",
    "virtualc80": "Virtual Card Details",
    "virtualc82": "Virtual Card",
    "visitthe119": "Visit the company settings tab to customize your travel and expense policies, set up approval flows, add integrations and invite employees.",
    "visitthe120": "Visit the company settings tab to view your travel and expense policies, invite employees, and add integrations.",
    "visitthe83": "Visit the company settings tab to further customize your travel and expense policies, set up approval flows, add integrations and invite employees.",
    "waitingf37": "Waiting for Approval",
    "waitingf38": "Waiting for Reimbursement",
    "wearesor24": "We are sorry, we are experiencing technical difficulties.",
    "wecouldn70": "We couldn't capture the information from this receipt.",
    "wednesda12": "Wednesday",
    "weekly6": "Weekly",
    "weekly79": "Weekly",
    "weencoun1": "We encountered an issue. Please check your entries and try again. If it persists, please chat with us to let us know. ",
    "weencoun12": "We encountered an issue. Please check the form and try again. If the issue persists, chat with us or email us at {emailHref}. ",
    "weencoun13": "We encountered an issue. Please try again.<br />If the issue persists, chat with us or email us at {emailHref}. ",
    "weencoun14": "We encountered an issue. Please try again.<br />If the issue persists, chat with us or email us at {emailHref}. ",
    "weencoun4": "We encountered an issue. Please check the form and try again. If the issue persists, chat with us or email us at {emailHref}. ",
    "welcome1": "Welcome!",
    "welcomeb17": "Welcome Back!",
    "welcomet10": "Welcome to TravelBank",
    "welcomet206": "Welcome to {agencylabel}",
    "welcomet217": "Welcome To {agencylabel}",
    "wellguid8": "We'll guide you through a few essential settings so you can customize your account.",
    "weneedyo32": "We need you to verify your email address by accepting the invite in your email. We resent it so you have it at the top of your inbox.",
    "werecomm123": "We recommend setting up any general ledger integrations before inviting employees to use the app.",
    "weresorr59": "We’re sorry, we’ve run into an error.",
    "wereunab60": "We’re unable to continue with your account setup at this time. Please reach out to TravelBank support for further assistance.",
    "wereyoui18": "Were you issued a credit card from your employer?",
    "wesentfo219": "We sent a 4-digit verification code to your email. Please check your inbox and enter the code below.",
    "wevedete11": "We've detected {duplicateCount, plural, one {a duplicate} other {# duplicates}} and it requires your review.",
    "wevedete95": "We've detected duplicate receipt(s) that require your review",
    "wevesent83": "We've sent a PDF copy of the report to your email.",
    "wevesent86": "We’ve sent an Instant Card One Time Passcode to your email at {useremail}.",
    "wevesucc25": "We've successfully added your receipts, but couldn't read the data. Please fill in manually.",
    "whatsnex82": "What’s next?",
    "whatsthe69": "What’s the minimum expense amount that requires a receipt?",
    "when32": "When",
    "whenenab100": "When enabled, employees must split the expense by categories to account for all line items. When off, expense splitting is optional.",
    "whenenab101": "When enabled, travelers must book flights within the selected number of days before departure to be considered in policy.",
    "whenenab102": "When enabled, travelers must select flights cheaper than the defined cap to be considered in policy.",
    "whenenab106": "When enabled, travelers must book hotels within the selected number of days before departure to be considered in policy.",
    "whenenab108": "When enabled, travelers are given a percentage or dollar amount flexibility with their budget to be considered in policy.",
    "whenenab112": "When enabled, travelers must select hotels cheaper than the defined cap to be considered in policy.",
    "whenenab116": "When enabled, travelers are given a percentage or dollar amount flexibility with their budget to be considered in policy.",
    "whenenab123": "When enabled, employees are required to add a description to all of their submitted manual expenses. Mileage expenses will always require a description.",
    "whenenab129": "When enabled, employees are required to merge their manual corporate card expense with the respective corporate card transaction before they can submit their expense report. Please note, this will not apply to refund to company, mileage or travel expenses.",
    "whenenab132": "When enabled, the employee's payment method will default to corporate card on Repeat and Refund to Company expenses.",
    "whenenab143": "When enabled, travelers must book flights within the selected number of days before departure to be considered in policy.",
    "whenenab162": "When enabled, employees can book paid seats with an admin or manager approval.",
    "whenenab181": " When enabled, this category will be visible for employees by default and any other relevant expense policies.",
    "whenenab189": "When enabled, employees will see this custom field when submitting an expense.",
    "whenenab192": "When enabled, employees will see this custom field when submitting an expense.",
    "whenenab3": "When enabled, the integration for {title} general ledger will be turned on",
    "whenenab36": "When enabled, this expense will be marked as a charge to the customer.",
    "whenenab4": "When enabled, the Scheduled Expense Export integration will be turned on.",
    "whenenab59": "When enabled, this expense will be marked as a charge to the customer.",
    "whenenab71": "When enabled, all transactions on this card will show up in your Expense list.",
    "whenenab73": "When enabled, this expense will not be reimbursed back to you.",
    "whenenab76": "When enabled, expenses made with a corporate card can be marked as Non-Reimbursable.",
    "whenenab81": "When enabled, users with your organization's email domain won't auto-join your account when they sign up for TravelBank.",
    "whenrequ194": "When required, employees must answer this question.",
    "whenyoua14": "When you are assigned a corporate card, it will appear here along with your most recent transactions.",
    "whenyout11": "When you take action on your new transactions, these transactions will appear here for your records, up to a period of 60 days.",
    "workemai3": "Work Email",
    "workemai43": "Work Email",
    "workemai78": "Work Email",
    "workemai8": "Work Email",
    "year201": "Year",
    "yearly81": "Yearly",
    "yes107": "Yes",
    "yes194": "Yes",
    "yes47": "Yes",
    "yes56": "Yes",
    "yesterda2": "Yesterday",
    "youalrea27": "You already have an account",
    "youareal24": "You are already verified. Please log in.",
    "youareno11": "You are now editing <b>{name}'s</b> profile. To make a new traveler profile, please add a new traveler.",
    "youareus30": "You are using a custom export template. Please contact TravelBank's support team to review your export file.",
    "youcanat15": "You Can Attach A Maximum Of 10 Receipts",
    "youcanat50": "You Can Attach A Maximum Of 10 Receipts",
    "youcanno147": "You cannot modify this card when it's locked.",
    "youcanno162": "You cannot enable LodgingX if reimbursements are not enabled",
    "youcanno62": "You cannot re-invite an active user.",
    "youcanno67": "You Cannot Merge More Than 2 Items At Once",
    "youcanno80": "You cannot merge a positive and negative expense.",
    "youcanre31": "You can restore deleted items on the Expenses & Transactions page by going to Filters and selecting deleted status filter.",
    "youcansh127": "You can choose to <i>only allow</i> or <i>only decline</i> merchant categories by selecting up to 9 templates.",
    "youcanst3": "You can start using TravelBank right away. Finish customizing your company's setup later on the Company Settings page.",
    "youcanta186": "You can't add more than 100 options when creating a custom field. Please save first.",
    "youdonot12": "You do not have any unmerged expenses or transactions to merge with.",
    "youdonot36": "You do not have a balance to pay off at this time.",
    "youdonth1": "You don't have any expense reports.",
    "youdonth10": "You don’t have any outstanding approvals.",
    "youdonth13": "You don’t have any expense reports.",
    "youdonth19": "You don’t have any active expenses to show.",
    "youdonth22": "You don’t have any reports to show.",
    "youdonth23": "You don’t have any active expenses or reports.",
    "youdonth68": "You don't have any upcoming bookings for flights, hotels, or cars.",
    "youhavea8": "You have attached the maximum of 10 receipts.",
    "youhavee135": "You have edited the employee list for this policy.",
    "youhaven75": "You haven't been reimbursed for any expense reports.",
    "youhaveo14": "You have older expenses.",
    "youhaver72": "You have reached the limit ({globalCustomFieldLimit}) custom fields for all categories.",
    "youissue150": "You issued a virtual employee card.",
    "youissue151": "You issued a physical employee card.",
    "youissue152": "You issued a virtual vendor card.",
    "youllget71": "You'll get a copy of the report to your email.",
    "yourbank65": "Your bank account cannot be added at this time. Please verify your account information and try again or add your bank account manually in order to receive reimbursements.",
    "yourbank68": "Your bank account is set to receive reimbursements. Update your reimbursement account to change the bank account you have on file.",
    "yourchan126": "Your changes will be available in TravelBank when the file has processed.",
    "yourcomp108": "Your Company Bank Account",
    "yourcsvd50": "Your csv doesn’t contain the correct columns.",
    "yourcsve167": "Your CSV export was sent to <span data-test-email>{email}</span>",
    "yourcsvf166": "Your CSV file will automatically download once ready.",
    "youreing116": "You're in Good Company",
    "yourempl88": "Your employees will <span class=\"rewardAmount\">receive {rewardsamount} of their savings</span> on hotel bookings.",
    "yourempl89": "Your employees will not receive a share of their savings on hotel bookings.",
    "youresav1": "You're saving on license costs with your preferred {preferredRateBrand} rate!",
    "youresav168": "You're saving on license costs with your preferred {preferredratebrand} rate!",
    "youresav192": "You're saving with your {preferredratebrand} preferred rate!",
    "youresav193": "You're saving with your preferred rate!",
    "youresav2": "You're saving with your {preferredRateBrand} preferred rate!",
    "youresav3": "You're saving with your preferred rate!",
    "yourexpe56": "Your expense report was submitted as “{expenseReportName}”.",
    "yourexpe63": "Your expenses have been restored",
    "yourorga31": "Your organization does not have a custom template. Please contact support to set one up.",
    "yourorga91": "Your organization's expense policy requires you to merge manually created corporate expenses with a corresponding corporate card transaction.",
    "yourpaid1": "Your Paid Plan Estimate",
    "yourpaid169": "<div class=\"onboarding-subscriptions__calculator__estimation__title\">Your Paid Plan Estimate <InfoIcon id=\"estimate-info\"/></div><div class=\"onboarding-subscriptions__calculator__estimation__billed-annually\">{billedannuallytext}</div><div class=\"onboarding-subscriptions__calculator__estimation__billed-monthly\">or {billedmonthlytext}</div>",
    "yourpdfd36": "Your PDF download should begin automatically. If it doesn't, you can click the link below to download the file.",
    "yourphon28": "Your Phone Number",
    "yourplan234": "Your plan includes one default policy and allows you to edit the general rules section only.",
    "yourreim67": "Your reimbursement account is waiting for your verification. Once verified, your bank account will be able to receive reimbursements.",
    "yourrepo59": "Your report has been submitted!",
    "yourrewa68": "Your rewards and budget options have been saved!",
    "yourteam22": "Your team's corporate cards will appear here",
    "yourtran29": "Your transaction was assigned to “<span>{transactionassignreportname}</span>”",
    "yourtrav10": "Your traveler information will be used to book all travel within TravelBank. Please ensure your traveler information matches your government ID used for travel.",
    "youvealr31": "You've already been invited!",
    "youvecom81": "You've completed initial setup!",
    "youwilll215": "You will lose any data you have entered.",
    "youwillr16": "You will receive an email confirmation on the payment date.",
    "zerozero146": "0000 0000 0000 0000",
    "zipcode210": "Zip Code",
    "zipcode5": "Zip Code"
  }], ["eo", {
    "15thandl18": "[①⑤ţĥ åñð Ļåšţ---П國カ내]",
    "1allorga1": "[<sup>①<⁄sup>Åļļ öŕĝåñîžåţîöñš ûšîñĝ Ţŕåṽéļ öñ ţĥé ƒŕéé þļåñ ɱûšţ ĝö ţĥŕöûĝĥ åççöûñţ ṽéŕîƒîçåţîöñ ŵîţĥ ŢŕåṽéļƁåñķ·----------------------------------------------------------------------------------------------------------------------------------------П國カ내]",
    "1stand1517": "[①šţ åñð ①⑤ţĥ---П國カ내]",
    "1stops59": "[①⁺ Šţöþš--П國カ내]",
    "247trav1": "[②④⁄⑦ ţŕåṽéļ åĝéñţ šûþþöŕţ------П國カ내]",
    "abacus11": "[Åƀåçûš--П國カ내]",
    "abacusre6": "[Åƀåçûš Ŕéçéîþţš-----П國カ내]",
    "about21": "[Åƀöûţ--П國カ내]",
    "accesst1": "[Åççéšš ţö ñéĝöţîåţéð ŕåţéš ŵîţĥ ɱåĵöŕ åîŕ çåŕŕîéŕš، ĥöţéļš، åñð çåŕ ŕéñţåļ åĝéñçîéš-----------------П國カ내]",
    "accordin3": "[Åççöŕðîñĝ ţö ţĥîš ŕéþöŕţ، ýöû öŵé ţĥé çöɱþåñý ɱöñéý· Îƒ ţĥîš îš çöŕŕéçţ، þļéåšé çöñţåçţ ýöûŕ åðɱîñîšţŕåţöŕ ţö åŕŕåñĝé ŕéþåýɱéñţ·---------------------------------------П國カ내]",
    "accordin30": "[Åççöŕðîñĝ ţö ţĥîš ŕéþöŕţ، ýöû öŵé ţĥé çöɱþåñý ɱöñéý· Îƒ ţĥîš îš çöŕŕéçţ، þļéåšé çöñţåçţ ýöûŕ åðɱîñîšţŕåţöŕ ţö åŕŕåñĝé ŕéþåýɱéñţ·--------------------------П國カ내]",
    "accordin31": "[Åççöŕðîñĝ ţö ţĥîš ŕéþöŕţ، ţĥé éɱþļöýéé îš ŕéǫûîŕéð ţö þåý ƒöŕ ţĥé éẋþéñšé· Îƒ ţĥîš îš çöŕŕéçţ، þļéåšé åŕŕåñĝé ŕéþåýɱéñţ ŵîţĥ ţĥé éɱþļöýéé·----------------------------П國カ내]",
    "account148": "[Åççöûñţ--П國カ내]",
    "account45": "[Åççöûñţ--П國カ내]",
    "accountf64": "[Åççöûñţ Ƒåîļéð-----П國カ내]",
    "accountg111": "[Åççöûñţ⁄ĜĻ Çöðéš (Éẋţéŕñåļ Åççöûñţ ÎÐ)--------П國カ내]",
    "accountg135": "[Åççöûñţ⁄ĜĻ Çöðéš (Éẋţéŕñåļ Åççöûñţ ÎÐ)--------П國カ내]",
    "accounti161": "[Åççöûñţ ÎÐ---П國カ내]",
    "accounti183": "[Åççöûñţ ÎÐ---П國カ내]",
    "accounti65": "[Åççöûñţ ÎÐ---П國カ내]",
    "accounti71": "[Åççöûñţ ÎÐ---П國カ내]",
    "accounti77": "[{amount} Åñð Åƀöṽé----------------------П國カ내]",
    "accountl62": "[Åççöûñţ ļåšţ ④ ðîĝîţš ‐ {formataccountlast4}---------П國カ내]",
    "accountn13": "[Åççöûñţ Ñûɱƀéŕ-----П國カ내]",
    "accountn146": "[Åççöûñţ ♯--П國カ내]",
    "accountn2": "[{accountname}···{lastfour}------П國カ내]",
    "accountn240": "[Åççöûñţ Ñûɱƀéŕ ļåšţ ④ ðîĝîţš {last4digits}---------П國カ내]",
    "accountn3": "[{accountname}···{accountnumber}-------П國カ내]",
    "accountn62": "[Åççöûñţ Ñöţ Åððéð------П國カ내]",
    "accountn73": "[{accountnamecapitalized} {accountnumber} åççöûñţ šûççéššƒûļļý åððéð·--------------П國カ내]",
    "accountp63": "[Åççöûñţ Þéñðîñĝ-----П國カ내]",
    "accountr108": "[Åççöûñţ Ŕéƒŕéšĥéð·----П國カ내]",
    "accounts70": "[{name} {accountNumber} account successfully added.----------------П國カ내]",
    "accountt15": "[Åççöûñţ Ţýþé----П國カ내]",
    "accountt85": "[Åççöûñţ ţýþé ‐ {accounttype}------П國カ내]",
    "acompany75": "[Å çöɱþåñý ŵîðé ƀîļļîñĝ çöðé öŕ çöŕþöŕåţé ðîšçöûñţ ţĥåţ ŵîļļ ƀé ûšéð ƒöŕ éṽéŕý ƀööķîñĝ ƒöŕ ţĥåţ þåŕţîçûļåŕ çåŕ ŕéñţåļ çöɱþåñý·--------------------------П國カ내]",
    "acorpora41": "[Å çöŕþöŕåţé éɱåîļ îš ŕéǫûîŕéð ţö šéţ ûþ ýöûŕ çöɱþåñý· Ţĥîš éñåƀļéš ûš ţö þŕöþéŕļý ɱåñåĝé ûšéŕš öñ ýöûŕ ðöɱåîñ·-----------------------П國カ내]",
    "activate142": "[Åçţîṽåţé Çåŕð---П國カ내]",
    "activate159": "[Åçţîṽåţé Ļåţéŕ---П國カ내]",
    "activate19": "[Åçţîṽåţé Çåŕð Ñöŵ----П國カ내]",
    "activate207": "[Åçţîṽåţé Ýöûŕ Çåŕð----П國カ내]",
    "activate221": "[Åçţîṽåţé Ñöŵ---П國カ내]",
    "activati161": "[Åçţîṽåţîöñ Šéñţ----П國カ내]",
    "activati162": "[Åçţîṽåţîöñ Çöðé----П國カ내]",
    "activati181": "[Åçţîṽåţîöñ Çöðé----П國カ내]",
    "active21": "[Åçţîṽé--П國カ내]",
    "active44": "[Åçţîṽé--П國カ내]",
    "active51": "[Åçţîṽé--П國カ내]",
    "active55": "[Åçţîṽé--П國カ내]",
    "active56": "[Åçţîṽé--П國カ내]",
    "active58": "[Åçţîṽé--П國カ내]",
    "active63": "[Åçţîṽé--П國カ내]",
    "active66": "[Åçţîṽé--П國カ내]",
    "active68": "[Åçţîṽé--П國カ내]",
    "active70": "[Åçţîṽé--П國カ내]",
    "active73": "[Åçţîṽé--П國カ내]",
    "active77": "[Åçţîṽé--П國カ내]",
    "activeca330": "[Åçţîṽé Çåŕðš---П國カ내]",
    "activeex17": "[Åçţîṽé Éẋþéñšéš----П國カ내]",
    "activefi101": "[Åçţîṽé Ƒîļţéŕš∶ ----П國カ내]",
    "activefi6": "[Åçţîṽé Ƒîļţéŕš∶----П國カ내]",
    "activefi7": "[Åçţîṽé Ƒîļţéŕš∶----П國カ내]",
    "activere3": "[Active Reports-----П國カ내]",
    "activity114": "[Åçţîṽîţý‐Ɓåšéð Ļîçéñšéš ⅋ Þŕîçîñĝ-------П國カ내]",
    "activity27": "[Åçţîṽîţý--П國カ내]",
    "activityproblem85": "[Ţĥéŕé ŵåš å þŕöƀļéɱ ŵĥîļé ļöåðîñĝ ŕéþöŕţ åçţîṽîţý----------П國カ내]",
    "add1": "[Åðð-П國カ내]",
    "add100": "[Åðð-П國カ내]",
    "add13": "[Åðð-П國カ내]",
    "add143": "[Åðð-П國カ내]",
    "add15": "[Åðð-П國カ내]",
    "add16": "[Åðð-П國カ내]",
    "add175": "[Åðð-П國カ내]",
    "add203": "[Åðð-П國カ내]",
    "add208": "[Åðð-П國カ내]",
    "add3": "[Åðð-П國カ내]",
    "add4": "[Åðð-П國カ내]",
    "add5": "[Åðð-П國カ내]",
    "add57": "[Åðð-П國カ내]",
    "add74": "[Åðð-П國カ내]",
    "add8": "[Åðð-П國カ내]",
    "add81": "[Åðð-П國カ내]",
    "add84": "[Åðð-П國カ내]",
    "add99": "[Åðð-П國カ내]",
    "addaairl6": "[Åðð å Åîŕļîñé Ļöýåļţý Þŕöĝŕåɱ Ñûɱƀéŕ ţö éåŕñ þöîñţš åñð ûñļöçķ šţåţûš ţîéŕš·-----------------------П國カ내]",
    "addabank102": "[Åðð å ƀåñķ åççöûñţ ţö ŕéîɱƀûŕšé éɱþļöýééš åñð šéţ ýöûŕ ŕéîɱƀûŕšéɱéñţ šçĥéðûļé·----------------П國カ내]",
    "addabank2": "[Åðð å ƀåñķ åççöûñţ îñ öŕðéŕ ţö éñåƀļé ŕéîɱƀûŕšéɱéñţš ţĥŕöûĝĥ ŢŕåṽéļƁåñķ ƒöŕ ýöûŕ éɱþļöýéé´š éẋþéñšéš·---------------------П國カ내]",
    "addacomp74": "[Åðð å çöɱþåñý ŵîðé ƀîļļîñĝ çöðé öŕ çöŕþöŕåţé ðîšçöûñţ ţĥåţ ŵîļļ ƀé ûšéð ƒöŕ éṽéŕý ƀööķîñĝ ƒöŕ ţĥåţ þåŕţîçûļåŕ çåŕ ŕéñţåļ çöɱþåñý·--------------------------П國カ내]",
    "addacoun109": "[Åðð Åççöûñţ---П國カ내]",
    "addacred4": "[Åðð å Çŕéðîţ Çåŕð----П國カ내]",
    "addaddit1": "[Åðð åððîţîöñåļ ŕûļéš-----П國カ내]",
    "addadepa53": "[Åðð å Ðéþåŕţɱéñţ----П國カ내]",
    "addadrop68": "[Åðð å ðŕöþðöŵñ ṽåļûé ţö ƀéĝîñ·-------П國カ내]",
    "addahote32": "[Åðð å Ĥöţéļ Ļöýåļţý Þŕöĝŕåɱ Ñûɱƀéŕ ţö éåŕñ þöîñţš åñð ûñļöçķ šţåţûš ţîéŕš·-----------------------П國カ내]",
    "addahote7": "[Åðð å Ĥöţéļ Ļöýåļţý Þŕöĝŕåɱ Ñûɱƀéŕ ţö éåŕñ þöîñţš åñð ûñļöçķ šţåţûš ţîéŕš·-----------------------П國カ내]",
    "addairli9": "[Åðð Åîŕļîñé Ļöýåļţý Þŕöĝŕåɱ---------П國カ내]",
    "addalloc9": "[Åðð Åļļöçåţîöñ-----П國カ내]",
    "addamoun165": "[Åðð Åɱöûñţ---П國カ내]",
    "addanair29": "[Åðð åñ åîŕļîñé ļöýåļţý þŕöĝŕåɱ ţö éåŕñ þöîñţš åñð ûñļöçķ šţåţûš ţîéŕš---------------------П國カ내]",
    "addanend11": "[Åðð åñ Éñð Åððŕéšš------П國カ내]",
    "addanend9": "[Åðð åñ Éñð Åððŕéšš، ŕéǫûîŕéð---------П國カ내]",
    "addanewc209": "[Åðð å ñéŵ çûšţöɱ ƒîéļð-----П國カ내]",
    "addanexp31": "[Åðð åñ Éẋþéñšé---П國カ내]",
    "addanoth192": "[Åðð Åñöţĥéŕ Öþţîöñ----П國カ내]",
    "addanoth29": "[Åðð Åñöţĥéŕ Éẋþéñšé------П國カ내]",
    "addanoth52": "[Åðð Åñöţĥéŕ Éẋþéñšé----П國カ내]",
    "addappro144": "[Åðð Åþþŕöṽåļ Ŕûļéš----П國カ내]",
    "addappro89": "[Åðð Åþþŕöṽåļ Ŕûļé----П國カ내]",
    "addarent8": "[Åðð å Ŕéñţåļ Çåŕ Ļöýåļţý Þŕöĝŕåɱ Ñûɱƀéŕ ţö éåŕñ þöîñţš åñð ûñļöçķ šţåţûš ţîéŕš·------------------------П國カ내]",
    "addastar3": "[Åðð å Šţåŕţ Åððŕéšš، ŕéǫûîŕéð---------П國カ내]",
    "addastar5": "[Åðð å Šţåŕţ Åððŕéšš------П國カ내]",
    "addbulkf123": "[Åðð ƀûļķ ƒîļé---П國カ내]",
    "addcarco67": "[Åðð Çåŕ Çöðé---П國カ내]",
    "addcard7": "[Åðð Çåŕð--П國カ내]",
    "addcarre72": "[Åðð Çåŕ Ŕéñţåļ Çöðéš-----П國カ내]",
    "addcateg166": "[Åðð Çåţéĝöŕý---П國カ내]",
    "addcateg167": "[Åðð Çåţéĝöŕý Ðŕöþðöŵñ-----П國カ내]",
    "addcateg2": "[Åðð Çåţéĝöŕý----П國カ내]",
    "addcateg5": "[Åðð çåţéĝöŕý----П國カ내]",
    "addcomme28": "[Åðð Çöɱɱéñţ---П國カ내]",
    "adddefau3": "[Åðð Ðéƒåûļţ Ðéþåŕţɱéñţ-------П國カ내]",
    "adddepar48": "[Åðð Ðéþåŕţɱéñţš----П國カ내]",
    "adddepar65": "[Åðð Ðéþåŕţɱéñţ---П國カ내]",
    "adddepar84": "[Åðð Ðéþåŕţɱéñţ---П國カ내]",
    "adddescr3": "[Åðð Ðéšçŕîþţîöñ-----П國カ내]",
    "addemail14": "[Åðð Éɱåîļ--П國カ내]",
    "addemplo125": "[Åðð éɱþļöýééš åñð ţĥéîŕ ðéþåŕţɱéñţš ƀý éñţéŕîñĝ éɱåîļ åððŕéššéš öŕ ƀý ûþļöåðîñĝ å ţéɱþļåţéð ·ÇŠṼ ƒîļé·---------------------П國カ내]",
    "addemplo126": "[Åðð éɱþļöýééš ƀý éñţéŕîñĝ éɱåîļ åððŕéššéš öŕ ƀý ûþļöåðîñĝ å ţéɱþļåţéð ·ÇŠṼ ƒîļé· Öñ ţĥé ƒŕéé þļåñ، åļļ éɱþļöýééš åŕé åððéð ţö ţĥé Ĝéñéŕåļ ðéþåŕţɱéñţ·------------------------------П國カ내]",
    "addemplo34": "[Åðð éɱþļöýééš ƀý éñţéŕîñĝ éɱåîļ åððŕéššéš öŕ ƀý ûþļöåðîñĝ å ţéɱþļåţéð ·ÇŠṼ ƒîļé·-----------------П國カ내]",
    "addemplo35": "[Åðð Éɱþļöýééš---П國カ내]",
    "addemplo57": "[Åðð Éɱþļöýéé---П國カ내]",
    "addemplo83": "[Åðð Éɱþļöýéé---П國カ내]",
    "addexpen1": "[Åðð Éẋþéñšé----П國カ내]",
    "addexpen73": "[Åðð Éẋþéñšé Çåţéĝöŕý-----П國カ내]",
    "addexpen93": "[Åðð Éẋþéñšé Þöļîçý----П國カ내]",
    "addhosts94": "[Åðð ĥöšţš åñð ðéļéĝåţéš ƒöŕ ýöû åñð ýöûŕ éɱþļöýééš· Ðéļéĝåţéš çåñ þéŕƒöŕɱ ţåšķš، ļîķé åþþŕöṽåļš، ƒöŕ ĥöšţš·----------------------П國カ내]",
    "addhotel14": "[Åðð Ĥöţéļ Ļöýåļţý Þŕöĝŕåɱ--------П國カ내]",
    "addhotel86": "[Åðð Ĥöţéļ Ŕéŵåŕðš----П國カ내]",
    "addinteg1": "[Åðð Îñţéĝŕåţîöñš----П國カ내]",
    "addinteg3": "[Åðð Îñţéĝŕåţîöñš----П國カ내]",
    "addinteg4": "[Åðð Îñţéĝŕåţîöñš----П國カ내]",
    "addinteg5": "[Åðð {integration}----П國カ내]",
    "addition1": "[Åððîţîöñåļ Ûšåĝé Þöļîçý-----П國カ내]",
    "addition159": "[Åððîţîöñåļ Åþþŕöṽéŕ----П國カ내]",
    "addition163": "[Åððîţîöñåļ Åþþŕöṽéŕ Ðŕöþðöŵñ------П國カ내]",
    "addition172": "[Åððîţîöñåļ Ûšåĝé Þöļîçý-----П國カ내]",
    "addition289": "[Åððîţîöñåļ Îñƒöŕɱåţîöñ Ŕéǫûîŕéð-------П國カ내]",
    "addition54": "[Åððîţîöñåļ Ðéþåŕţɱéñţš åŕé å Þåîð Ƒéåţûŕé---------П國カ내]",
    "addlodgi104": "[Åðð Ļöðĝîñĝ Ŕéçéîþţš-----П國カ내]",
    "addmanua7": "[Åðð Ṁåñûåļļý----П國カ내]",
    "addmore7": "[Åðð Ṁöŕé--П國カ내]",
    "addnewbi19": "[Åðð Ñéŵ Ɓîļļîñĝ Åððŕéšš-------П國カ내]",
    "addnewca2": "[Åðð Ñéŵ Çåŕð---П國カ내]",
    "addnewca71": "[åðð ñéŵ çåţéĝöŕý----П國カ내]",
    "addnewho92": "[Åðð Ñéŵ Ĥöšţš ⅋ Ðéļéĝåţéš------П國カ내]",
    "addnewpa8": "[Åðð Ñéŵ Þåýɱéñţ Ṁéţĥöð-----П國カ내]",
    "addnewpe97": "[Åðð Ñéŵ Þéŕšöñåļ Šýñçéð Çåŕð------П國カ내]",
    "addnow37": "[Åðð Ñöŵ--П國カ내]",
    "addpassp22": "[Åðð Þåššþöŕţ Îñƒöŕɱåţîöñ--------П國カ내]",
    "addpayme31": "[Åðð Þåýɱéñţ Ṁéţĥöð----П國カ내]",
    "addpayme68": "[Åðð Þåýɱéñţ Åççöûñţ----П國カ내]",
    "addphoto1": "[Åðð Þĥöţö (Öþţîöñåļ)-----П國カ내]",
    "addphoto3": "[Åðð Þĥöţö Ŕéçéîþţ----П國カ내]",
    "addphoto6": "[Åðð þĥöţö ŕéçéîþţ----П國カ내]",
    "addpolic73": "[Åðð Þöļîçý Ñåɱé----П國カ내]",
    "addpolic78": "[Åðð Þöļîçý Ñåɱé----П國カ내]",
    "addrecei1": "[Åðð Ŕéçéîþţ---П國カ내]",
    "addrecei20": "[Åðð Ŕéçéîþţ----П國カ내]",
    "addrecei8": "[Åðð Ŕéçéîþţ----П國カ내]",
    "addrecei9": "[Åðð Ŕéçéîþţš----П國カ내]",
    "addreimb101": "[Åðð Ŕéîɱƀûŕšéɱéñţ Åççöûñţ------П國カ내]",
    "addreimb9": "[Åðð Ŕéîɱƀûŕšéɱéñţ Åççöûñţ--------П國カ내]",
    "addrenta12": "[Åðð Ŕéñţåļ Çåŕ Ļöýåļţý Þŕöĝŕåɱ----------П國カ내]",
    "address171": "[Åððŕéšš--П國カ내]",
    "address192": "[Åððŕéšš--П國カ내]",
    "address95": "[Åððŕéšš--П國カ내]",
    "addsplit8": "[Åðð Šþļîţ---П國カ내]",
    "addstop25": "[Åðð Šţöþ---П國カ내]",
    "addstops8": "[Åðð Šţöþš---П國カ내]",
    "addtitle2": "[Åðð {title}---П國カ내]",
    "addtorec43": "[Åðð ţö Ŕéçéîṽé Ŕéîɱƀûŕšéɱéñţš---------П國カ내]",
    "addtrave79": "[Åðð Ţŕåṽéļ Þöļîçý----П國カ내]",
    "addtrave9": "[Åðð Ţŕåṽéļéŕ Îñƒöŕɱåţîöñ-----П國カ내]",
    "adduser68": "[Åðð Ûšéŕ--П國カ내]",
    "addyourb10": "[Åðð ýöûŕ ƀåñķ åççöûñţ îñƒöŕɱåţîöñ ţö ŕéçéîṽé ŕéîɱƀûŕšéɱéñţš· ŢŕåṽéļƁåñķ þŕöţéçţš ýöûŕ îñƒöŕɱåţîöñ åñð îš ①⓪⓪‰ šéçûŕé·------------------------------------П國カ내]",
    "addyourb69": "[Åðð ýöûŕ ƀåñķ åççöûñţ îñƒöŕɱåţîöñ ţö ŕéçéîṽé ŕéîɱƀûŕšéɱéñţš· ŢŕåṽéļƁåñķ ûšéš éñţéŕþŕîšé‐ĝŕåðé šéçûŕîţý šýšţéɱš ţö šåƒéĝûåŕð ýöûŕ šéñšîţîṽé îñƒöŕɱåţîöñ·----------------------------------------------П國カ내]",
    "addyourc9": "[Åðð ýöûŕ çŕéðîţ çåŕð îñƒöŕɱåţîöñ·-------П國カ내]",
    "addyoure36": "[Åðð Ýöûŕ Éɱþļöýééš----П國カ내]",
    "addyourh15": "[Åðð ýöûŕ Ĥöţéļ Ļöýåļţý Þŕöĝŕåɱ Ñûɱƀéŕ ţö éåŕñ þöîñţš åñð ûñļöçķ šţåţûš ţîéŕš·------------------------П國カ내]",
    "addyourm10": "[Åðð ýöûŕ Ṁîļéåĝé Þŕöĝŕåɱ Ñûɱƀéŕ ţö éåŕñ þöîñţš åñð ûñļöçķ šţåţûš ţîéŕš·----------------------П國カ내]",
    "addyourp3": "[Åðð ýöûŕ þåýɱéñţ ɱéţĥöð ţö šţåŕţ ƀööķîñĝ åļļ ţŕåṽéļ ŵîţĥîñ ŢŕåṽéļƁåñķ·---------------П國カ내]",
    "addyourt2": "[Åðð ýöûŕ ţŕåṽéļéŕ îñƒöŕɱåţîöñ ţö šţåŕţ ƀööķîñĝ åļļ ţŕåṽéļ ŵîţĥîñ ŢŕåṽéļƁåñķ·----------------П國カ내]",
    "adjustse67": "[Åðĵûšţ šéţţîñĝš öŕ ķééþ ŢŕåṽéļƁåñķ´š ðéƒåûļţš ƒöŕ ýöûŕ çöɱþåñý’š éẋþéñšé þöļîçîéš·-----------------П國カ내]",
    "admin58": "[Åðɱîñ--П國カ내]",
    "admin60": "[Åðɱîñ--П國カ내]",
    "admin69": "[Åðɱîñ--П國カ내]",
    "adminhas59": "[Åðɱîñ ĥåš åðɱîñîšţŕåţöŕ þŕîṽîļéĝéš ţö ýöûŕ çöɱþåñý’š åççöûñţ åñð ƒûļļ åççéšš ţö çöɱþåñý šéţţîñĝš، ûšéŕš ⅋ åñåļýţîçš·------------------------П國カ내]",
    "advanceb103": "[Åðṽåñçé Ɓööķîñĝ Ŕéǫûîŕéɱéñţš------П國カ내]",
    "advanceb142": "[Åðṽåñçé Ɓööķîñĝ Ŕéǫûîŕéɱéñţ------П國カ내]",
    "advanceb99": "[Åðṽåñçé Ɓööķîñĝ Ŕéǫûîŕéɱéñţš------П國カ내]",
    "advanced1": "[Åðṽåñçéð îñţéĝŕåţîöñš åṽåîļåƀļé-------П國カ내]",
    "advanced122": "[Åðṽåñçéð ƀööķîñĝ ðŕöþðöŵñ∶ {value} {valueType} selected - ‐------------П國カ내]",
    "advanced89": "[Åðṽåñçéð Þéŕɱîššîöñš-----П國カ내]",
    "agentnum186": "[Åĝéñţ Ñûɱƀéŕ---П國カ내]",
    "agentnum49": "[Åĝéñţ Ñûɱƀéŕ---П國カ내]",
    "airbnban105": "[Åîŕƀñƀ åñð öţĥéŕ ĥöɱéšĥåŕîñĝ ŕéçéîþţš ƒöŕŵåŕðéð ţö <a class=\"empty-screen__text_inline-link\" href=\"{emailHref}\" >receipts@travelbank.com</a> ŵîļļ šĥöŵ ûþ ĥéŕé åñð çåñ ƀé åššîĝñéð ţö ýöûŕ ļöðĝîñĝ ƀûðĝéţ·------------------------------------------П國カ내]",
    "airlinec91": "[Åîŕļîñé Çöñƒîŕɱåţîöñ Ñûɱƀéŕ------П國カ내]",
    "airlinel11": "[Åîŕļîñé Ļöýåļţý Þŕöĝŕåɱ Ñûɱƀéŕ----------П國カ내]",
    "airlinel27": "[Åîŕļîñé Ļöýåļţý Þŕöĝŕåɱ-------П國カ내]",
    "airlinel28": "[Åîŕļîñé Ļöýåļţý Þŕöĝŕåɱ-------П國カ내]",
    "airlinel30": "[Åîŕļîñé Ļöýåļţý Þŕöĝŕåɱ Ñûɱƀéŕ----------П國カ내]",
    "alaskaai302": "[Åļåšķå Åîŕļîñéš Ɓûšîñéšš çŕéðîţ çåŕð--------П國カ내]",
    "alicense115": "[Å ļîçéñšé îš ûšéð öñçé å ŕéšþéçţîṽé åçţîöñ îš þéŕƒöŕɱéð ƀý å ûšéŕ ŵîţĥîñ ţĥé çåļéñðåŕ ɱöñţĥ· Åñý ûšåĝé ƀéýöñð ýöûŕ šûƀšçŕîþţîöñ ļéṽéļ îš ƀîļļéð ɱöñţĥļý åççöŕðîñĝ ţö ţĥé åððîţîöñåļ ûšåĝé þöļîçý·---------------------------------------П國カ내]",
    "alinktoa162": "[Å ļîñķ ţö åçţîṽåţé ýöûŕ åççöûñţ ĥåš ƀééñ šéñţ ţö ýöûŕ éɱåîļ·-------------П國カ내]",
    "alinktor21": "[Å ļîñķ ţö ŕéšéţ ýöûŕ þåššŵöŕð ŵîļļ ƀé šéñţ ţö ţĥîš éɱåîļ·------------П國カ내]",
    "alinktor35": "[Å ļîñķ ţö ŕéšéţ ýöûŕ þåššŵöŕð ĥåš ƀééñ šéñţ ţö {email}-----------П國カ내]",
    "alinktov2": "[Å ļîñķ ţö ṽéŕîƒý ýöûŕ åççöûñţ ĥåš ƀééñ šéñţ ţö ýöûŕ éɱåîļ·------------------П國カ내]",
    "alistofa17": "[Å ļîšţ öƒ åļļ ýöûŕ þéŕšöñåļ šýñçéð åñð çöŕþöŕåţé çåŕð ţŕåñšåçţîöñš ŵîļļ åþþéåŕ ĥéŕé·-----------------П國カ내]",
    "allbooki122": "[Åļļ Ɓööķîñĝš---П國カ내]",
    "allbooki147": "[Åļļ Ɓööķîñĝš---П國カ내]",
    "allbooki60": "[Åļļ Ɓööķîñĝš---П國カ내]",
    "allcards329": "[Åļļ Çåŕðš--П國カ내]",
    "allcards95": "[Åļļ Çåŕðš--П國カ내]",
    "allcateg59": "[Åļļ Çåţéĝöŕîéš---П國カ내]",
    "allcateg61": "[Åļļ Çåţéĝöŕîéš---П國カ내]",
    "allcurre10": "[Åļļ Çûŕŕéñçîéš---П國カ내]",
    "alldates84": "[Åļļ Ðåţéš--П國カ내]",
    "alldepar76": "[Åļļ Ðéþåŕţɱéñţš----П國カ내]",
    "allemplo129": "[Åļļ éɱþļöýééš åŕé þåŕţ öƒ ţĥé ðéƒåûļţ þöļîçý ûñļéšš ţĥéý ĥåṽé ƀééñ åððéð ţö å ðîƒƒéŕéñţ þöļîçý· Éɱþļöýééš çåñ öñļý ƀé îñ öñé ţŕåṽéļ þöļîçý·----------------------------П國カ내]",
    "allemplo142": "[ Åļļ éɱþļöýééš åŕé þåŕţ öƒ ţĥé ðéƒåûļţ þöļîçý ûñļéšš ţĥéý ĥåṽé ƀééñ åððéð ţö å ðîƒƒéŕéñţ þöļîçý· Éɱþļöýééš çåñ öñļý ƀé îñ öñé éẋþéñšé þöļîçý·-----------------------------П國カ내]",
    "allemplo143": "[Åļļ éɱþļöýééš åŕé þåŕţ öƒ ţĥé ðéƒåûļţ þöļîçý îñ ţĥé ƒŕéé þļåñ·-------------П國カ내]",
    "allemplo74": "[Åļļ Éɱþļöýééš---П國カ내]",
    "allemplo94": "[Åļļ éɱþļöýééš åŕé þåŕţ öƒ ţĥé ðéƒåûļţ þöļîçý îñ ţĥé ƒŕéé þļåñ·-------------П國カ내]",
    "allexpen147": "[Åļļ éẋþéñšé ŕéþöŕţš ðûŕîñĝ ţĥé šéļéçţéð ðåţé ŕåñĝé ŵîļļ ƀé éẋþöŕţéð·--------------П國カ내]",
    "allfutur272": "[Åļļ ƒûţûŕé ţŕåñšåçţîöñš ŵîļļ ƀé ɱåñåĝéð ƀý ţĥé ñéŵ ∖∖ åššîĝñéé€{newuserfirstname}·-----------------П國カ내]",
    "allocabove6": "[Åļļöçåţîöñ îš {amountError} <span data-test-text=\"side\">above</span> ýöûŕ {expenseAmount} éẋþéñšé ţöţåļ·--------------------------------П國カ내]",
    "allocatabove47": "[Åļļöçåţîöñ îš {amountError} åƀöṽé ýöûŕ {expenseAmount} éẋþéñšé ţöţåļ---------------------П國カ내]",
    "allocatbelow48": "[Åļļöçåţîöñ îš {amountError} ƀéļöŵ ýöûŕ {expenseAmount} éẋþéñšé ţöţåļ---------------------П國カ내]",
    "allocate11": "[Åļļöçåţé Éẋþéñšé-----П國カ내]",
    "allocate2": "[Åļļöçåţé å þéŕçéñţåĝé öƒ ýöûŕ éẋþéñšé ţöŵåŕðš šþéçîƒîç ðéþåŕţɱéñţš·---------------------П國カ내]",
    "allocati1": "[Åļļöçåţîöñ Ðåţå Ðöéš Ñöţ Šýñç------П國カ내]",
    "allocati136": "[Åļļöçåţîöñ Þŕîɱåŕý Ðéþåŕţɱéñţ------П國カ내]",
    "allocati79": "[Åļļöçåţîöñ Ðéþåŕţɱéñţ Åççéšš------П國カ내]",
    "allocunder7": "[Åļļöçåţîöñ îš {amountError} <span data-test-text=\"side\">under</span> ýöûŕ {expenseAmount} éẋþéñšé ţöţåļ·--------------------------------П國カ내]",
    "alloverl153": "[Åļļ öṽéŕļåþþîñĝ åþþŕöṽéŕš ŵîļļ ƀé îñçļûðéð·---------П國カ내]",
    "allowexp105": "[Åļļöŵ Éẋþéñšé Ŕéþöŕţš-----П國カ내]",
    "allowtra109": "[Åļļöŵ Ţŕåṽéļ Ŕéǫûéšţš-----П國カ내]",
    "allowuse149": "[Åļļöŵ ûšéŕš ţö ƀööķ ļöðĝîñĝ öûţšîðé öƒ ŢŕåṽéļƁåñķ ŵîţĥ ŕéŵåŕðš·-------------П國カ내]",
    "allrepor25": "[Åļļ Ŕéþöŕţ Šţåţûšéš----П國カ내]",
    "allrepor92": "[Åļļ Ŕéþöŕţ Šţåţûšéš----П國カ내]",
    "alltrans160": "[Åļļ Ţŕåñšåçţîöñš·----П國カ내]",
    "alltrans165": "[Åļļ Ţŕåñšåçţîöñš ŵîţĥ {filterlist}---------П國カ내]",
    "amapwill3": "[Å ɱåþ ŵîļļ åûţö‐ĝéñéŕåţé ŵĥéñ ýöû åðð å ɱîñîɱûɱ öƒ ② åððŕéššéš·-------------------П國カ내]",
    "amembero12": "[Å ɱéɱƀéŕ öƒ ŢŕåṽéļƁåñķ ŵîļļ çöñţåçţ ýöû öñçé ţĥé šéţ ûþ îš ŕéåðý·--------------П國カ내]",
    "amembero286": "[Å ɱéɱƀéŕ öƒ ŢŕåṽéļƁåñķ ŵîļļ çöñţåçţ ýöû öñçé ţĥé šéţ ûþ îš ŕéåðý·--------------П國カ내]",
    "american265": "[Åɱéŕîçåñ Éẋþŕéšš Çöɱþåñý Ñûɱƀéŕ-------П國カ내]",
    "amex313": "[Åɱéẋ-П國カ내]",
    "amount143": "[Åɱöûñţ--П國カ내]",
    "amount145": "[Åɱöûñţ--------П國カ내]",
    "amount18": "[Åɱöûñţ--П國カ내]",
    "amount21": "[Åɱöûñţ--П國カ내]",
    "amount3": "[Åɱöûñţ--П國カ내]",
    "amount4": "[Åɱöûñţ--П國カ내]",
    "amount6": "[Åɱöûñţ--П國カ내]",
    "amount79": "[Åɱöûñţ--П國カ내]",
    "amount93": "[Åɱöûñţ--П國カ내]",
    "amount95": "[Åɱöûñţ--П國カ내]",
    "amountal122": "[Åɱöûñţ (åļŵåýš îñ ÛŠÐ)-----П國カ내]",
    "amountda91": "[Åɱöûñţ ‐ (åɱöûñţ)----П國カ내]",
    "amountdu63": "[Åɱöûñţ ðûé---П國カ내]",
    "amountis76": "[Åɱöûñţ îš éɱþţý----П國カ내]",
    "amountma188": "[Åɱöûñţ Ṁåẋîɱûɱ ðŕöþðöŵñ-----П國カ내]",
    "amountma43": "[Åɱöûñţ Ṁåẋîɱûɱ---П國カ내]",
    "amountma49": "[Åɱöûñţ Ṁåẋîɱûɱ---П國カ내]",
    "amountmi187": "[Åɱöûñţ Ṁîñîɱûɱ ðŕöþðöŵñ-----П國カ내]",
    "amountmi42": "[Åɱöûñţ Ṁîñîɱûɱ---П國カ내]",
    "amountmi48": "[Åɱöûñţ Ṁîñîɱûɱ---П國カ내]",
    "amountth5": "[Åɱöûñţ ‐ ţĥîš ṽåļûé îš ñéĝåţîṽé-------П國カ내]",
    "amountus29": "[{amount} ÛŠÐ----П國カ내]",
    "analytic16": "[Åñåļýţîçš---П國カ내]",
    "analytic20": "[Åñåļýţîçš--П國カ내]",
    "andxmore28": "[{description} åñð {count} ɱöŕé·--------П國カ내]",
    "anerrorh112": "[Åñ éŕŕöŕ ĥåš öççûŕŕéð·  Ţĥé çåšĥ ļîɱîţ çåññöţ éẋçééð ţĥé çåŕð þŕöĝŕåɱ´š çåšĥ ļîɱîţ·  Þļéåšé ŕéðûçé ļîɱîţ åñð ŕéšûƀɱîţ· ------------------------П國カ내]",
    "anerroro165": "[Åñ éŕŕöŕ öçûŕŕéð ŵĥéñ ţŕýîñĝ ţö ļöçķ⁄ûñļöçķ çåŕð· Þļéåšé ţŕý åĝåîñ öŕ çåļļ çûšţöɱéŕ šéŕṽîçé åţ ⑧⓪⓪‐③④④‐⑤⑥⑨⑥·----------------------П國カ내]",
    "anerroro185": "[Åñ éŕŕöŕ öççûŕŕéð ŵĥîļé ţŕýîñĝ ţö ƒéţçĥ çûšţöɱ ƒîéļð ðŕöþðöŵñ ṽåļûéš--------------П國カ내]",
    "anerroro26": "[Åñ éŕŕöŕ öççûŕŕéð ðûŕîñĝ šçåññîñĝ· Þļéåšé ţŕý åĝåîñ·----------------П國カ내]",
    "anerroro324": "[Åñ éŕŕöŕ ĥåš öççûŕŕéð· Ţö çļöšé ţĥîš çåŕð þļéåšé çåļļ ţĥé ②④⁄⑦ Çåŕðɱéɱƀéŕ Šéŕṽîçéš ţéåɱ åţ ①‐⑧⓪⓪‐③④④‐⑤⑥⑨⑥·----------------------П國カ내]",
    "anerroro61": "[Åñ éŕŕöŕ öççûŕŕéð ŵĥîļé ļöåðîñĝ ðéþåŕţɱéñţš---------П國カ내]",
    "anerroro71": "[Åñ éŕŕöŕ öççûŕŕéð ŵĥîļé åððîñĝ ýöûŕ åççöûñţ· Þļéåšé ţŕý åĝåîñ·-------------------П國カ내]",
    "anerroro74": "[Åñ éŕŕöŕ öççûŕŕéð ŵĥîļé åððîñĝ ýöûŕ åççöûñţ· Þļéåšé ţŕý åĝåîñ·-------------П國カ내]",
    "anerroro98": "[Åñ éŕŕöŕ öççûŕŕéð ŵĥéñ ţŕýîñĝ ţö çŕéåţé ţĥîš çåŕð·-----------П國カ내]",
    "anewveri216": "[Å ñéŵ ṽéŕîƒîçåţîöñ çöðé ĥåš ƀééñ šéñţ ṽîå éɱåîļ·----------П國カ내]",
    "anexisti138": "[Åñ éẋîšţîñĝ þŕéšéţ ∖″{name}∖″ ɱåţçĥéš ýöûŕ çåŕð çöñţŕöļš· Îţ ĥåš ƀééñ åþþļîéð ţö ţĥé çåŕð·-------------------П國カ내]",
    "anoption47": "[Åñ öþţîöñ éẋçééðéð ţĥé ɱåẋ ļéñĝţĥ öƒ ②⓪⓪ çĥåŕåçţéŕš-----------П國カ내]",
    "anorgani328": "[Åñ Öŕĝåñîžåţîöñ Åðɱîñ {fullname} ƒŕöɱ {organizationname} öŕĝåñîžåţîöñ ŕéǫûéšţéð å çöŕþöŕåţé çåŕð šýñç îñţéĝŕåţîöñ∶ ‐ Ɓåñķ Ñåɱé∶ {bankname} ‐ Çŕéðîţ Çåŕð þŕöĝŕåɱ∶ {bankcardname} ‐ Ɓåñķ Çöñţåçţ Ţîţļé∶ {bankcontacttitle} ‐ Ɓåñķ Çöñţåçţ Éɱåîļ∶ {bankcontactemail} ‐ Ɓåñķ Çöñţåçţ Ñåɱé∶ {bankcontactname} ‐ Åððîţîöñåļ îñšţŕûçţîöñš∶ {instructions} ‐ Öŕĝåñîžåţîöñ çöñţåçţ éɱåîļ∶ {organizationcontactemail} ‐ Öŕĝåñîžåţîöñ çöñţåçţ ñåɱé∶ {organizationcontactname} ‐ Îñţéĝŕåţîöñ ÎÐ∶ {id} {amexinfo} Ŕéǫûéšţéð åţ∶ {today}------------------------------------------------------------------------------------------------------П國カ내]",
    "anovervi2": "[Åñ öṽéŕṽîéŵ öƒ ýöûŕ þåýɱéñţ ɱéţĥöðš·--------П國カ내]",
    "anovervo2": "[Åñ öṽéŕṽîéŵ öƒ ýöûŕ ļöýåļţý þŕöĝŕåɱš ûšéð ţö ƀööķ ţŕåṽéļ·------------------П國カ내]",
    "anunknow52": "[Åñ ûñķñöŵñ éŕŕöŕ öççûŕéð-----П國カ내]",
    "anyactiv58": "[Åñý åçţîṽé öŕ þéñðîñĝ ţŕîþš çåñ ƀé ƒöûñð öñ ţĥé ţŕåṽéļ ţåƀ·------------П國カ내]",
    "anyadded155": "[Åñý Åððéð Åþþŕöṽéŕš----П國カ내]",
    "anyadded156": "[Åñý Åððéð Åþþŕöṽéŕš----П國カ내]",
    "anychang66": "[Åñý çĥåñĝéš ɱåðé ŵîļļ ûþðåţé åļļ ŕéƒéŕéñçéš ţö ţĥîš ðéþåŕţɱéñţ åñð çåññöţ ƀé ûñðöñé·-----------------П國カ내]",
    "anyusage1": "[Åñý ûšåĝé ţĥåţ ƒåļļš öûţšîðé ýöûŕ ļîçéñšé šéţţîñĝš ŵîļļ ƀé ƀîļļéð ɱöñţĥļý· Ţĥåţ ɱéåñš åñý ûšéŕš ŵĥö ðöñ´ţ ŕéĝûļåŕļý ñééð ţĥé åþþ çåñ šţîļļ ûšé þåîð ƒéåţûŕéš، ŵĥéñ ţĥéý ñééð ţĥéɱ·------------------------------------П國カ내]",
    "anyusage173": "[Åñý ûšåĝé ţĥåţ ƒåļļš öûţšîðé ýöûŕ ļîçéñšé šéţţîñĝš ŵîļļ ƀé ƀîļļéð ɱöñţĥļý· Ţĥåţ ɱéåñš åñý ûšéŕš ŵĥö ðöñ´ţ ŕéĝûļåŕļý ñééð ţĥé åþþ çåñ šţîļļ ûšé þåîð ƒéåţûŕéš، ŵĥéñ ţĥéý ñééð ţĥéɱ·------------------------------------П國カ내]",
    "apitoken": "[ÅÞÎ Ţöķéñ--П國カ내]",
    "apperror58": "[Åþþ Éŕŕöŕ--П國カ내]",
    "apply129": "[Åþþļý--П國カ내]",
    "apply173": "[Åþþļý--П國カ내]",
    "apply177": "[Åþþļý--П國カ내]",
    "apply24": "[Åþþļý--П國カ내]",
    "apply44": "[Åþþļý--П國カ내]",
    "apply50": "[Åþþļý--П國カ내]",
    "apply68": "[Åþþļý--П國カ내]",
    "apply71": "[Åþþļý--П國カ내]",
    "apply72": "[Åþþļý--П國カ내]",
    "apply75": "[Åþþļý--П國カ내]",
    "apply76": "[Åþþļý--П國カ내]",
    "apply77": "[Åþþļý--П國カ내]",
    "apply80": "[Åþþļý--П國カ내]",
    "applytoa80": "[Åþþļý ţö Åççöûñţ----П國カ내]",
    "approvab1": "[åþþŕöṽåƀîļîţý ƒļåĝ----П國カ내]",
    "approval12": "[Åþþŕöṽåļš | Ṁåñåĝé-----П國カ내]",
    "approval123": "[Åþþŕöṽåļš--П國カ내]",
    "approval148": "[Åþþŕöṽåļš--П國カ내]",
    "approval152": "[Åþþŕöṽåļ Ƒļöŵ---П國カ내]",
    "approval2": "[Åþþŕöṽåļš--П國カ내]",
    "approval4": "[Åþþŕöṽåļš--П國カ내]",
    "approval94": "[Åþþŕöṽåļ Ŕûļéš---П國カ내]",
    "approval95": "[Åþþŕöṽåļ Ŕûļéš---П國カ내]",
    "approve76": "[Åþþŕöṽé--П國カ내]",
    "approved126": "[Åþþŕöṽéð ƀý Ñåɱé----П國カ내]",
    "approved127": "[Åþþŕöṽéð ƀý Ṁåñåĝéŕ Ðåţé-----П國カ내]",
    "approved128": "[Åþþŕöṽéð ƀý Ƒîñåñçé Ðåţé-----П國カ내]",
    "approved143": "[Åþþŕöṽéð ƀý Ṁåñåĝéŕ Ðåţé-----П國カ내]",
    "approved144": "[Åþþŕöṽéð ƀý Ƒîñåñçé Ðåţé-----П國カ내]",
    "approved169": "[Åþþŕöṽéð ƀý Ñåɱé-----П國カ내]",
    "approved23": "[Åþþŕöṽéð--П國カ내]",
    "approved34": "[Åþþŕöṽéð--П國カ내]",
    "approved35": "[Åþþŕöṽéð åñð Çļöšéð----П國カ내]",
    "approved4": "[Åþþŕöṽéð ⅋ Çļöšéð----П國カ내]",
    "approved62": "[Åþþŕöṽéð ⅋ Çļöšéð----П國カ내]",
    "approved79": "[Åþþŕöṽéð--П國カ내]",
    "approvee107": "[Åþþŕöṽé Éẋþéñšé Ŕéþöŕţš ƒöŕ Šéļƒ-------П國カ내]",
    "approvee11": "[Åþþŕöṽé éɱþļöýéé ƀûðĝéţš åñð éẋþéñšé ŕéþöŕţš îñ öñé þļåçé·------------П國カ내]",
    "approver146": "[Åþþŕöṽéŕ îš çûŕŕéñţļý Îñåçţîṽé-------------------------------------П國カ내]",
    "approver148": "[Åþþŕöṽéŕ--П國カ내]",
    "approver149": "[Åþþŕöṽéŕ îš çûŕŕéñţļý Îñåçţîṽé-------------------------------------П國カ내]",
    "approvet111": "[Åþþŕöṽé Ţŕåṽéļ Ŕéǫûéšţš ƒöŕ Šéļƒ-------П國カ내]",
    "aptsuite110": "[Åþţ، šûîţé، éţç ŕéǫûîŕéð· Þļéåšé ţŕý åĝåîñ· ---------П國カ내]",
    "aptsuite226": "[Åþţ، šûîţé، éţç·----П國カ내]",
    "arecente19": "[Å ŕéçéñţ éṽéñţ ţĥåţ ŢŕåṽéļƁåñķ šþöñšöŕéð öŕ åţţéñðéð-----------П國カ내]",
    "areimbur66": "[Å ŕéîɱƀûŕšéɱéñţ ţö ýöûŕ ƀåñķ åççöûñţ ŵåš åţţéɱþţéð ƀûţ ƒåîļéð· Îñ öŕðéŕ ţö ŕéçéîṽé ŕéîɱƀûŕšéɱéñţš، ûþðåţé ýöûŕ ƀåñķ åççöûñţ îñƒöŕɱåţîöñ öñ ŢŕåṽéļƁåñķ·----------------------------------------------П國カ내]",
    "areyousu108": "[Åŕé ýöû šûŕé¿---П國カ내]",
    "areyousu122": "[Åŕé ýöû šûŕé¿---П國カ내]",
    "areyousu148": "[Åŕé ýöû šûŕé ýöû ŵåñţ ţö ðéļéţé ţĥîš åþþŕöṽåļ ŕûļé¿-----------П國カ내]",
    "areyousu200": "[Åŕé ýöû šûŕé ýöû ŵåñţ ţö ðéļéţé ţĥîš öþţîöñ¿---------П國カ내]",
    "areyousu25": "[Åŕé ýöû šûŕé ýöû ŵåñţ ţö þåîŕ ýöûŕ ţŕåñšåçţîöñ ŵîţĥ ţĥîš éẋþéñšé¿--------------П國カ내]",
    "areyousu321": "[Åŕé ýöû šûŕé ýöû ŵåñţ ţö çļöšé ţĥîš çåŕð¿---------П國カ내]",
    "areyousu53": "[Åŕé ýöû šûŕé ýöû ŵåñţ ţö ŕéɱöṽé ýöûŕ éẋîšţîñĝ ƀåñķ åççöûñţ ûšéð ƒöŕ ŕéîɱƀûŕšéɱéñţš¿-------------------------П國カ내]",
    "areyousu62": "[Åŕé ýöû šûŕé ýöû ŵåñţ ţö çåñçéļ ţĥîš {type} ŕéǫûéšţ¿-----------П國カ내]",
    "areyousu90": "[Åŕé ýöû šûŕé¿---П國カ내]",
    "areyousu92": "[Åŕé ýöû šûŕé¿---П國カ내]",
    "asaremin95": "[Åš å ŕéɱîñðéŕ، ţĥé ƀåñķ åççöûñţ ŵîļļ ƀé ŕéɱöṽéð ƒŕöɱ ţĥé þöļîçîéš þŕéṽîöûšļý ɱéñţîöñéð·------------------П國カ내]",
    "asetting67": "[Å šéţţîñĝ îš åļŕéåðý çöñƒîĝûŕéð ƒöŕ {carVendorMap}، þļéåšé éðîţ öŕ ŕéɱöṽé ţĥé éẋîšţîñĝ šéţţîñĝ-----------------------------------------------------------------------------------------------------------------П國カ내]",
    "assign7": "[Åššîĝñ--П國カ내]",
    "assignal19": "[Åššîĝñ åļļ ŕéçéîþţš ţö öñé éẋþéñšé·-----------П國カ내]",
    "assignde1": "[Åššîĝñ ðéļéĝåţéš ƒöŕ ýöû åñð öţĥéŕ éɱþļöýééš îñ ýöûŕ öŕĝåñîžåţîöñ·--------------П國カ내]",
    "assignea17": "[Åššîĝñ éåçĥ ŕéçéîþţ ţö îţš öŵñ éẋþéñšé·------------П國カ내]",
    "assigned68": "[Åššîĝñéð----П國カ내]",
    "assigned87": "[Åššîĝñéð Ðéļéĝåţéš----П國カ내]",
    "assignee29": "[Åššîĝñéé--П國カ내]",
    "assignne99": "[Åššîĝñ Ñéŵ Ĥöšţš ⅋ Ðéļéĝåţéš------П國カ내]",
    "assignto1": "[Åššîĝñ ţö Ŕéþöŕţ-----П國カ내]",
    "assignto111": "[Åššîĝñ Ţö Ŕéþöŕţ----П國カ내]",
    "assignto23": "[Åššîĝñ ţö Ŕéþöŕţ----П國カ내]",
    "assignto34": "[Åššîĝñ ţö Éẋþéñšé Ŕéþöŕţ--------П國カ내]",
    "assignto56": "[Åššîĝñ ţö Éẋþéñšé Ŕéþöŕţ-----П國カ내]",
    "assigntr84": "[Åššîĝñ Ţŕåṽéļ Þöļîçý-----П國カ내]",
    "assignus2": "[Åššîĝñ Ûšéŕ----П國カ내]",
    "atleast150": "[Åţ ļéåšţ ① öþţîöñ ñééðš ţö ƀé åððéð--------П國カ내]",
    "attachme98": "[Åţţåçĥɱéñţš---П國カ내]",
    "attempti1": "[Åţţéɱþţîñĝ ţö ŕéţŕý éẋþéñšé éẋþöŕţ-------П國カ내]",
    "atthisti169": "[Åţ ţĥîš ţîɱé éẋþöŕţîñĝ éẋþéñšéš öñļý šûþþöŕţš éîţĥéŕ åļļöçåţîöñš öŕ ļîñé îţéɱš، ñöţ ƀöţĥ·------------------П國カ내]",
    "atthisti41": "[Åţ ţĥîš ţîɱé éẋþöŕţîñĝ éẋþéñšéš öñļý šûþþöŕţš éîţĥéŕ åļļöçåţîöñš öŕ ļîñé îţéɱš، ñöţ ƀöţĥ·------------------П國カ내]",
    "atthisti88": "[Åţ ţĥîš ţîɱé، ţĥé ṽéñðöŕ çåŕð îš öñļý åṽåîļåƀļé åš å ṽîŕţûåļ çåŕð·<br/>Šéļéçţ îţ ţö çöñţîñûé·-------------------П國カ내]",
    "attribut150": "[Åţţŕîƀûţéš---П國カ내]",
    "autocomp61": "[Åûţö Çöɱþļéţéð Éẋþéñšé-----П國カ내]",
    "automate75": "[Åûţöɱåţéð ɱîçŕö‐ðéþöšîţ ļîñķîñĝ ƒåîļéð¡--------П國カ내]",
    "automati115": "[Åûţöɱåţîçåļļý åþþŕöṽé šþéñð ƒöŕ îñçļûðéð çåţéĝöŕîéš öñļý åñð ðéçļîñé åļļ öţĥéŕš·-----------------П國カ내]",
    "automati117": "[Åûţöɱåţîçåļļý ðéçļîñé šþéñð ƒöŕ îñçļûðéð çåţéĝöŕîéš öñļý åñð åþþŕöṽé åļļ öţĥéŕš·-----------------П國カ내]",
    "autopay60": "[Åûţö‐þåý--П國カ내]",
    "autorewa145": "[Åûţö Ŕéŵåŕðš---П國カ내]",
    "availabl39": "[Åṽåîļåƀļé Çŕéðîţ----П國カ내]",
    "availabl77": "[Åṽåîļåƀļé Çŕéðîţ îš ţĥé åɱöûñţ åṽåîļåƀļé ƒöŕ ýöû ţö šþéñð åƒţéŕ ƒåçţöŕîñĝ îñ þéñðîñĝ ţŕåñšåçţîöñš åñð þåýɱéñţš· Ţĥé ñûɱƀéŕ ðîšþļåýéð ƀéļöŵ åṽåîļåƀļé çŕéðîţ îš ţĥé åɱöûñţ šéţ åšîðé ţö çļéåŕ ţĥé þéñðîñĝ ţŕåñšåçţîöñš åñð þåýɱéñţš·----------------------------------------------П國カ내]",
    "availabl78": "[Åṽåîļåƀļé Çŕéðîţ îš ţĥé åɱöûñţ åṽåîļåƀļé ƒöŕ ýöû ţö šþéñð åƒţéŕ ƒåçţöŕîñĝ îñ þéñðîñĝ ţŕåñšåçţîöñš åñð þåýɱéñţš·-----------------------П國カ내]",
    "availpoi30": "[{availablepoints}þţš-----П國カ내]",
    "avalidem37": "[Å ṽåļîð éɱåîļ îš ŕéǫûîŕéð------П國カ내]",
    "avisrequ64": "[Åṽîš ŕéǫûîŕéš å çöŕþöŕåţé ðîšçöûñţ ñûɱƀéŕ ţö ƀé ûšéð ŵĥéñ å ƀîļļîñĝ çöðé îš ûšéð-------------------------------------------------------------------------------------------------П國カ내]",
    "back149": "[Ɓåçķ-П國カ내]",
    "back38": "[Ɓåçķ-П國カ내]",
    "back72": "[Ɓåçķ-П國カ내]",
    "back88": "[Ɓåçķ--П國カ내]",
    "back90": "[Ɓåçķ-П國カ내]",
    "back96": "[Ɓåçķ-П國カ내]",
    "balances88": "[Ɓåļåñçéš--П國カ내]",
    "bank178": "[Ɓåñķ-П國カ내]",
    "bank19": "[Ɓåñķ-П國カ내]",
    "bankacco1": "[Ɓåñķ Åççöûñţš---П國カ내]",
    "bankacco106": "[Ɓåñķ Åççöûñţ Ṽéŕîƒîçåţîöñ Ŕéǫûîŕéð ƒöŕ Ŕéîɱƀûŕšéɱéñţš-----------П國カ내]",
    "bankacco14": "[Ɓåñķ Åççöûñţ ļîñķîñĝ ƒåîļéð¡ {error}--------П國カ내]",
    "bankacco144": "[Ɓåñķ Åççöûñţš---П國カ내]",
    "bankacco190": "[Ɓåñķ åççöûñţ šûççéššƒûļļý ļîñķéð¡-------П國カ내]",
    "bankacco5": "[Ɓåñķ åççöûñţ šûççéššƒûļļý ļîñķéð¡-------П國カ내]",
    "bankacco6": "[Ɓåñķ åççöûñţ ļîñķîñĝ ƒåîļéð¡------П國カ내]",
    "bankandc248": "[Ɓåñķ åñð Çåŕð Šéåŕçĥ-----П國カ내]",
    "bankconn109": "[Ɓåñķ çöññéçţîöñ ŕéƒŕéšĥ ƒåîļéð·--------П國カ내]",
    "bankconn200": "[{bankpublicname} Çöññéçţîöñ Éŕŕöŕ-------П國カ내]",
    "bankcont258": "[Ɓåñķ Çöñţåçţ Ñåɱé----П國カ내]",
    "bankcont259": "[Ɓåñķ Çöñţåçţ Ţîţļé----П國カ내]",
    "bankcont260": "[Ɓåñķ Çöñţåçţ Éɱåîļ----П國カ내]",
    "bankemai237": "[Ɓåñķ Éɱåîļ---П國カ내]",
    "bankinfo253": "[Ɓåñķ Îñƒöŕɱåţîöñ----П國カ내]",
    "bankinst11": "[Ɓåñķ Îñšţîţûţîöñ Ñåɱé-------П國カ내]",
    "bankinst235": "[{bankpublicname} Îñšţåñţ Çåŕð Ļöĝîñ--------П國カ내]",
    "bankname133": "[{bankname} {virtuallabel} ····{last4}--------П國カ내]",
    "bankname135": "[{bankname} Îñšţåñţ Çåŕð {label}-------П國カ내]",
    "bankname255": "[Ɓåñķ Ñåɱé--П國カ내]",
    "bankofam317": "[Ɓåñķ öƒ Åɱéŕîçå----П國カ내]",
    "bankofch283": "[Ɓåñķ öƒ çĥöîçé ñöţ ļîšţéð¿ Ļéţ ûš ķñöŵ¡ Öûŕ ţéåɱ ŵîļļ ŵöŕķ ŵîţĥ ýöû åñð ýöûŕ ƀåñķ ţö éñåƀļé å šéåɱļéšš ţŕåñšåçţîöñ šýñç·-------------------------П國カ내]",
    "baseurl": "[Ɓåšé ÛŔĻ--П國カ내]",
    "beforead78": "[Ɓéƒöŕé åððîñĝ ñéŵ ŕéçöŕðš åñð ûþļöåðîñĝ، þļéåšé ðéļéţé ţĥé éẋåɱþļé ŕöŵ ƒŕöɱ ţĥé ÇŠṼ ţéɱþļåţé ţö éñšûŕé åççûŕåţé ðåţå þŕöçéššîñĝ·--------------------------П國カ내]",
    "beforeintegration": "[Ɓéƒöŕé ţĥé îñţéĝŕåţîöñ çåñ öççûŕ، ýöû ɱûšţ šéţ ûþ å ŠÇÎṀ Åþþļîçåţîöñ ŵîţĥîñ Okta· Ƒöŕ ɱöŕé îñƒöŕɱåţîöñ، þļéåšé ṽîšîţ <a href=\"{href}\" target=\"_blank\">helpdesk.com</a>·-----------------------------------П國カ내]",
    "beforewe197": "[Ɓéƒöŕé ŵé ĥéļþ ýöû çļöšé ţĥîš åççöûñţ، ŵé ñééð ţö ðéţéŕɱîñé îƒ ţĥåţ’š ŵĥåţ ýöû ŕéåļļý ŵåñţ ţö ðö·--------------------П國カ내]",
    "billable113": "[Ɓîļļåƀļé--П國カ내]",
    "billable140": "[Ɓîļļåƀļé--П國カ내]",
    "billable174": "[Ɓîļļåƀļé ţö Çûšţöɱéŕ-----П國カ내]",
    "billable180": "[Ɓîļļåƀļé ţö Çûšţöɱéŕ¿-----П國カ내]",
    "billable181": "[Ɓîļļåƀļé ţö Çûšţöɱéŕ Ðŕöþðöŵñ------П國カ내]",
    "billable35": "[Ɓîļļåƀļé ţö Çûšţöɱéŕ-------П國カ내]",
    "billable58": "[Ɓîļļåƀļé ţö Çûšţöɱéŕ-----П國カ내]",
    "billcomi2": "[Ɓîļļ·çöɱ Îñƀöẋ Îñţéĝŕåţîöñ------П國カ내]",
    "billcomi4": "[Ɓîļļ·çöɱ Îñƀöẋ---П國カ내]",
    "billedan1": "[ƀîļļéð åññûåļļý، öŕ {monthlyRateDisplay}⁄ûšéŕ ƀîļļéð ɱöñţĥļý-------------П國カ내]",
    "billedmo1": "[ƀîļļéð ɱöñţĥļý þéŕ ûšåĝé-----П國カ내]",
    "billinga127": "[Ɓîļļîñĝ Åððŕéšš----П國カ내]",
    "billingc69": "[Ɓîļļîñĝ Çöðé∶ {billingCode}------П國カ내]",
    "billingc81": "[Ɓîļļîñĝ Çöðé---П國カ내]",
    "billingi11": "[Ɓîļļîñĝ Îñƒöŕɱåţîöñ------П國カ내]",
    "biweekly7": "[Ɓîŵééķļý--П國カ내]",
    "blockaut80": "[Ɓļöçķ Åûţöɱåţîçåļļý Ĵöîñîñĝ------П國カ내]",
    "blockaut86": "[Ɓļöçķ åûţö ĵöîñîñĝ šéţţîñĝ (ĝéåŕ) îçöñ--------П國カ내]",
    "bookanda95": "[Ɓööķ åñð åþþŕöṽé ţŕåṽéļ، ɱåñåĝé þöļîçîéš، åñð ûñļöçķ šåṽîñĝš ŵîţĥ çöŕþöŕåţé ŕåţéš åñð öûŕ ŕéŵåŕðš þŕöĝŕåɱ·----------------------П國カ내]",
    "booking1": "[Ɓööķîñĝ åþþŕöṽåļ ŵöŕķƒļöŵ------П國カ내]",
    "bookingd88": "[Ɓööķîñĝ Ðéţåîļš----П國カ내]",
    "bookingr109": "[Ɓööķîñĝ Ŕûļéš---П國カ내]",
    "bookingr116": "[Ɓööķîñĝ Ŕûļéš---П國カ내]",
    "bookings4": "[Ɓööķîñĝš---П國カ내]",
    "brex12": "[Ɓŕéẋ--П國カ내]",
    "brex315": "[Ɓŕéẋ-П國カ내]",
    "brexpart20": "[Ɓŕéẋ Þåŕţñéŕšĥîþ----П國カ내]",
    "brexrece7": "[Ɓŕéẋ Ŕéçéîþţš----П國カ내]",
    "budgetc1": "[Ɓûðĝéţ çöñţŕöļ šéţţîñĝš-----П國カ내]",
    "budgetfl107": "[Ɓûðĝéţ Ƒļéẋîƀîļîţý----П國カ내]",
    "budgetfl115": "[Ɓûðĝéţ Ƒļéẋîƀîļîţý----П國カ내]",
    "buildasu202": "[Ɓûîļð å šûƀšçŕîþţîöñ ţĥåţ ŵöŕķš ƒöŕ ýöûŕ ţéåɱ ŵîţĥ å-ļå-çåŕţé, åçţîṽîţý‐ƀåšéð ļîçéñšéš·--------------------------------------П國カ내]",
    "buildaun164": "[Ɓûîļð å ûñîǫûé šûƀšçŕîþţîöñ ţĥåţ ŵöŕķš ƒöŕ ýöûŕ ţéåɱ· Öŕ، ûšé ŢŕåṽéļƁåñķ´š ƀåšîç ƒéåţûŕéš ƒöŕ ƒŕéé ƒöŕ åļļ ýöûŕ ûšéŕš·------------------------П國カ내]",
    "buildyou163": "[Ɓûîļð Ýöûŕ ŢŕåṽéļƁåñķ Þļåñ------П國カ내]",
    "bulkimpo95": "[Ɓûļķ Îɱþöŕţ---П國カ내]",
    "bulkupda100": "[Ɓûļķ ûþðåţé ñöţ îɱþļéɱéñţéð ýéţ-------П國カ내]",
    "business300": "[Ɓûšîñéšš Åðṽåñţåĝé Ţŕåṽéļ Ŕéŵåŕðš çŕéðîţ çåŕð----------П國カ내]",
    "business301": "[Ɓûšîñéšš Åðṽåñţåĝé Çåšĥ Ŕéŵåŕðš çŕéðîţ çåŕð---------П國カ내]",
    "business31": "[Ɓûšîñéšš Þûŕþöšé-----П國カ내]",
    "business311": "[Ɓûšîñéšš Éẋţŕå---П國カ내]",
    "business54": "[Ɓûšîñéšš Þûŕþöšé----П國カ내]",
    "business56": "[Ɓûšîñéšš--П國カ내]",
    "bysignin98": "[Ɓý šîĝñîñĝ ûþ، Î åĝŕéé ţö ţĥé ţéŕɱš öƒ ûšé åñð ţĥé þŕîṽåçý þöļîçý·--------------П國カ내]",
    "byturnin15": "[Ɓý ţûŕñîñĝ öñ ţĥîš öþţîöñ ýöû åûţĥöŕîžé ŢŕåṽéļƁåñķ ţö ŵîţĥðŕåŵ ŕéîɱƀûŕšéɱéñţš ƒŕöɱ ţĥé çöñƒîĝûŕéð çöɱþåñý ƀåñķ åççöûñţ (···{bankLast4})·----------------------------П國カ내]",
    "byturnin188": "[Ɓý ţûŕñîñĝ öñ ţĥîš öþţîöñ ýöû åûţĥöŕîžé ŢŕåṽéļƁåñķ ţö ŵîţĥðŕåŵ ŕéîɱƀûŕšéɱéñţš ƒŕöɱ ţĥé çöñƒîĝûŕéð çöɱþåñý ƀåñķ åççöûñţ ({bankname} {banklast4})·-----------------------------П國カ내]",
    "canbethe1": "[(Çåñ ƀé ţĥé šåɱé þéŕšöñ)-----П國カ내]",
    "cancel102": "[Çåñçéļ--П國カ내]",
    "cancel110": "[Çåñçéļ--П國カ내]",
    "cancel111": "[Çåñçéļ--П國カ내]",
    "cancel113": "[Çåñçéļ--П國カ내]",
    "cancel12": "[Çåñçéļ--П國カ내]",
    "cancel121": "[Çåñçéļ--П國カ내]",
    "cancel13": "[Çåñçéļ--П國カ내]",
    "cancel136": "[Çåñçéļ--П國カ내]",
    "cancel14": "[Çåñçéļ--П國カ내]",
    "cancel143": "[Çåñçéļ--П國カ내]",
    "cancel149": "[Çåñçéļ--П國カ내]",
    "cancel16": "[Çåñçéļ--П國カ내]",
    "cancel163": "[Çåñçéļ--П國カ내]",
    "cancel167": "[Çåñçéļ--П國カ내]",
    "cancel170": "[Çåñçéļ--П國カ내]",
    "cancel18": "[Çåñçéļ--П國カ내]",
    "cancel184": "[Çåñçéļ--П國カ내]",
    "cancel185": "[Çåñçéļ--П國カ내]",
    "cancel196": "[Çåñçéļ--П國カ내]",
    "cancel23": "[Çåñçéļ--П國カ내]",
    "cancel26": "[Çåñçéļ--П國カ내]",
    "cancel3": "[Çåñçéļ--П國カ내]",
    "cancel31": "[Çåñçéļ--П國カ내]",
    "cancel37": "[Çåñçéļ--П國カ내]",
    "cancel41": "[Çåñçéļ--П國カ내]",
    "cancel6": "[Çåñçéļ--П國カ내]",
    "cancel73": "[Çåñçéļ--П國カ내]",
    "cancel79": "[Çåñçéļ--П國カ내]",
    "cancel8": "[Çåñçéļ--П國カ내]",
    "cancel87": "[Çåñçéļ--П國カ내]",
    "cancel91": "[Çåñçéļ--П國カ내]",
    "cancel94": "[Çåñçéļ--П國カ내]",
    "cancelcu197": "[Çåñçéļ Çûšţöɱ Ƒîéļð----П國カ내]",
    "cancelcu201": "[Çåñçéļ Çûšţöɱ Ƒîéļð----П國カ내]",
    "cancelpa19": "[Çåñçéļ Þåýɱéñţ---П國カ내]",
    "cancelpa4": "[Çåñçéļ Þåýɱéñţ¿----П國カ내]",
    "cancelre164": "[Çåñçéļ ŕéîɱƀûŕšéɱéñţ ɱöðåļ------П國カ내]",
    "cancelre72": "[Çåñçéļ Ŕéǫûéšţ---П國カ내]",
    "cancelth100": "[Çåñçéļ {title} Ṁöðåļ-----П國カ내]",
    "cancelty61": "[Çåñçéļ {type} Ŕéǫûéšţ-----П國カ내]",
    "cannotbe20": "[Çåññöţ ƀé ĥîĝĥéŕ ţĥåñ þöšţéð ƀåļåñçé--------П國カ내]",
    "cannotex168": "[Çåññöţ Éẋþöŕţ Åļļöçåţîöñš ŵîţĥ Ļîñé Îţéɱš---------П國カ내]",
    "cannotex40": "[Çåññöţ Éẋþöŕţ Åļļöçåţîöñš ŵîţĥ Ļîñé Îţéɱš---------П國カ내]",
    "cannotse107": "[Çåññöţ šéţ ñéŵ šýñç šţåţûš·------П國カ내]",
    "cannotse108": "[Çåññöţ šéţ ñéŵ ŕéîɱƀûŕšåƀļé šţåţûš·--------П國カ내]",
    "capturee98": "[Çåþţûŕé éẋþéñšéš، þŕöçéšš ŕéþöŕţš، îñţéĝŕåţé ŵîţĥ ýöûŕ åççöûñţîñĝ šöƒţŵåŕé، åñð ɱåñåĝé çöŕþöŕåţé çåŕð šþéñð·----------------------П國カ내]",
    "card17": "[Çåŕð-П國カ내]",
    "card23": "[Çåŕð--П國カ내]",
    "card332": "[Çåŕð-П國カ내]",
    "card6": "[Çåŕð--П國カ내]",
    "cardandc100": "[Çåŕð ⅋ Çåŕðĥöļðéŕ Ðéţåîļš------П國カ내]",
    "cardchan63": "[Çåŕð çĥåñĝéš šåṽéð šûççéššƒûļļý·-------П國カ내]",
    "cardclos154": "[Çåŕð Çļöšéð---П國カ내]",
    "cardcont100": "[Çåŕð Çöñţŕöļš---П國カ내]",
    "cardcont26": "[Çåŕð çöñţŕöļš éŕŕöŕ----П國カ내]",
    "carddeac55": "[Çåŕð ðéåçţîṽåţîöñ ĥåš ƒåîļéð· Þļéåšé ţŕý åĝåîñ·----------П國カ내]",
    "carddeta69": "[Çåŕð Ðéţåîļš---П國カ내]",
    "carddeta99": "[Çåŕð Ðéţåîļš---П國カ내]",
    "cardelli130": "[Çåŕð ····{last4}----П國カ내]",
    "cardellipsisnumber23": "[{cardName}···{last4digits}-----П國カ내]",
    "cardendi138": "[Çåŕð Éñðîñĝ îñ {last4}-----П國カ내]",
    "cardhasb53": "[Çåŕð ĥåš ƀééñ šûççéššƒûļļý ðéåçţîṽåţéð·--------П國カ내]",
    "cardhasb92": "[Çåŕð Ĥåš Ɓééñ Ðéåçţîṽåţéð------П國カ내]",
    "cardhold11": "[Çåŕðĥöļðéŕ Åççöûñţš----П國カ내]",
    "cardhold12": "[Çåŕðĥöļðéŕ---П國カ내]",
    "cardhold139": "[Çåŕðĥöļðéŕ Îñƒöŕɱåţîöñ-----П國カ내]",
    "cardhold154": "[Çåŕðĥöļðéŕ(š) åš ----П國カ내]",
    "cardhold16": "[Çåŕðĥöļðéŕ---П國カ내]",
    "cardhold162": "[Çåŕðĥöļðéŕ---П國カ내]",
    "cardhold17": "[Çåŕðĥöļðéŕ ‐ Ţåƀļé ĥéåðéŕ------П國カ내]",
    "cardhold190": "[Çåŕð ĥöļðéŕ ðŕöþðöŵñ-----П國カ내]",
    "cardhold2": "[Çåŕðĥöļðéŕ---П國カ내]",
    "cardhold38": "[Çåŕðĥöļðéŕ---П國カ내]",
    "cardhold48": "[Çåŕðĥöļðéŕ öŕ Åššîĝñéé-----П國カ내]",
    "cardisac64": "[Çåŕð îš Åçţîṽé---П國カ내]",
    "cardislo164": "[Çåŕð îš Ļöçķéð---П國カ내]",
    "cardisno65": "[Çåŕð îš ñöţ Åçţîṽé----П國カ내]",
    "cardisun163": "[Çåŕð îš Ûñļöçķéð----П國カ내]",
    "cardlabe136": "[Çåŕð {label}---П國カ내]",
    "cardlimi100": "[Çåŕð Ļîɱîţ---П國カ내]",
    "cardlimi124": "[Çåŕð Ļîɱîţ---П國カ내]",
    "cardlimi334": "[Çåŕð Ļîɱîţ---П國カ내]",
    "cardname160": "[Çåŕð Ñåɱé--П國カ내]",
    "cardnick18": "[Çåŕð Ñîçķñåɱé---П國カ내]",
    "cardnumb124": "[Çåŕð ♯--П國カ내]",
    "cardnumb140": "[Çåŕð ñûɱƀéŕ----П國カ내]",
    "cardnumb145": "[Çåŕð ñûɱƀéŕ---П國カ내]",
    "cardnumb209": "[Çåŕð Ñûɱƀéŕ (Ļåšţ ④ ðîĝîţš)------П國カ내]",
    "cardonly277": "[Çåŕð Öñļý--П國カ내]",
    "cardprog177": "[Çåŕð Þŕöĝŕåɱ---П國カ내]",
    "cardprog256": "[Çåŕð Þŕöĝŕåɱ---П國カ내]",
    "cardprog257": "[Çåŕð Þŕöĝŕåɱ Ñåɱé----П國カ내]",
    "cardprog346": "[Çåŕð Þŕöĝŕåɱ ‐ ðŕöþðöŵñ-----П國カ내]",
    "cards105": "[Çåŕðš--П國カ내]",
    "cardsand270": "[Çåŕð ⅋ Ţŕåñšåçţîöñš----П國カ내]",
    "cardtagl62": "[Çåŕð Ţåĝ Ļéĝéñð----П國カ내]",
    "cardtags103": "[Çåŕð Ţåĝš--П國カ내]",
    "cardtype49": "[Çåŕð Ţýþé--П國カ내]",
    "cardtype9": "[{cardtype}····{cardlast4}------П國カ내]",
    "carloyal36": "[Çåŕ Ļöýåļţý Þŕöĝŕåɱ Ñûɱƀéŕ--------П國カ내]",
    "carloyal37": "[Çåŕ Ļöýåļţý Ñûɱƀéŕ------П國カ내]",
    "carrenta1": "[Çåŕ Ŕéñţåļ Çöðéš----П國カ내]",
    "carrenta215": "[Çåŕ Ŕéñţåļ Çöðéš--------------------П國カ내]",
    "carrenta35": "[Çåŕ Ŕéñţåļ Ļöýåļţý ‐ {carLoyaltyName}------------П國カ내]",
    "cars5": "[Çåŕš--П國カ내]",
    "carslist7": "[Çåŕš Ļîšţ---П國カ내]",
    "cashwith125": "[Çåšĥ Ŵîţĥðŕåŵåļ Ļîɱîţ-----П國カ내]",
    "cashwith176": "[Çåšĥ Ŵîţĥðŕåŵåļ Ļîɱîţ-----П國カ내]",
    "categori58": "[{length} Çåţéĝöŕîéš----П國カ내]",
    "category141": "[Çåţéĝöŕý Ŕûļéš---П國カ내]",
    "category150": "[Çåţéĝöŕý îš çûŕŕéñţļý Îñåçţîṽé-------------------------------------П國カ내]",
    "category151": "[Çåţéĝöŕý----------П國カ내]",
    "category157": "[Çåţéĝöŕý Éñåƀļéð----П國カ내]",
    "category162": "[Çåţéĝöŕý´š îð öñ éẋţéŕñåļ šýšţéɱ-------П國カ내]",
    "category174": "[Çåţéĝöŕý Ñåɱé ⁎----П國カ내]",
    "category175": "[Çåţéĝöŕý’š ñåɱé öñ ŢŕåṽéļƁåñķ.------П國カ내]",
    "category178": "[Çåţéĝöŕý--П國カ내]",
    "category179": "[Çåţéĝöŕý´š ÎÐ öñ éẋţéŕñåļ šýšţéɱ·-------П國カ내]",
    "category182": "[Çåţéĝöŕý Ñåɱé---П國カ내]",
    "category196": "[Çåţéĝöŕý Šþéçîƒîç----П國カ내]",
    "category20": "[Çåţéĝöŕý--П國カ내]",
    "category33": "[Çåţéĝöŕý {category}------П國カ내]",
    "category37": "[Çåţéĝöŕý---П國カ내]",
    "category42": "[Çåţéĝöŕý ðŕöþðöŵñ ɱéñû-----П國カ내]",
    "category43": "[Çåţéĝöŕý--П國カ내]",
    "category5": "[Çåţéĝöŕý---П國カ내]",
    "category54": "[Çåţéĝöŕý ðŕöþðöŵñ ɱéñû-------П國カ내]",
    "category63": "[Çåţéĝöŕý--П國カ내]",
    "category74": "[Çåţéĝöŕý، ŕéǫûîŕéð----П國カ내]",
    "category80": "[Çåţéĝöŕý--П國カ내]",
    "category9": "[Çåţéĝöŕý---П國カ내]",
    "category95": "[Çåţéĝöŕý--П國カ내]",
    "cdefault1": "[Ţĥé ƒîŕšţ {currentLicenses} ûšéŕš öñļý ŵîļļ çöšţ ýöû {totalCostPerMonth})ɱö· ƀîļļéð åññûåļļý·--------------------П國カ내]",
    "cexpense1": "[Ţĥé ƒîŕšţ {currentLicenses} ûšéŕš ţö ûšé éẋþéñšé öñļý ŵîļļ çöšţ ýöû {totalCostPerMonth}⁄ɱö· ƀîļļéð åññûåļļý·-----------------------П國カ내]",
    "changebi12": "[Çĥåñĝé Ɓîļļîñĝ Åððŕéšš-------П國カ내]",
    "changebu122": "[Çĥåñĝé ƀûļķ ƒîļé----П國カ내]",
    "changeem134": "[Çĥåñĝé Éɱþļöýééš îñ Þöļîçý¿------П國カ내]",
    "changefi91": "[Çĥåñĝé Ƒîļé---П國カ내]",
    "changepa6": "[Çĥåñĝé Þåššŵöŕð-----П國カ내]",
    "changepa78": "[Çĥåñĝé Þåššŵöŕð-----П國カ내]",
    "changeyo12": "[Çĥåñĝé Ýöûŕ Þåššŵöŕð-------П國カ내]",
    "chatkeyv1": "[Çĥåţ-П國カ내]",
    "chatnow189": "[Çĥåţ Ñöŵ--П國カ내]",
    "chatnow19": "[Çĥåţ Ñöŵ---П國カ내]",
    "chatwith2": "[Çĥåţ ŵîţĥ Ûš----П國カ내]",
    "chatwith21": "[Çĥåţ ŵîţĥ Ûš----П國カ내]",
    "chatwith22": "[Çĥåţ ŵîţĥ Ûš---П國カ내]",
    "chatwith23": "[Çĥåţ ŵîţĥ Ûš----П國カ내]",
    "chatwith24": "[Çĥåţ ŵîţĥ Ûš----П國カ내]",
    "chatwith25": "[Çĥåţ ŵîţĥ Ûš----П國カ내]",
    "chatwith26": "[Çĥåţ ŵîţĥ Ûš----П國カ내]",
    "chatwith29": "[Çĥåţ ŵîţĥ Ûš----П國カ내]",
    "checkbox103": "[çĥéçķƀöẋ ƒöŕ Åðṽåñçé Ɓööķîñĝ Ŕéǫûîŕéɱéñţš---------П國カ내]",
    "checkbox110": "[çĥéçķƀöẋ ƒöŕ Åðṽåñçé Ɓööķîñĝ Ŕéǫûîŕéɱéñţš---------П國カ내]",
    "checkbox113": "[çĥéçķƀöẋ ƒöŕ Ĥöţéļ Ñîĝĥţļý Ŕåţé Ĥåŕð Çåþ---------П國カ내]",
    "checkbox117": "[çĥéçķƀöẋ ƒöŕ Ɓûðĝéţ Ƒļéẋîƀîļîţý-------П國カ내]",
    "checkbox119": "[çĥéçķƀöẋ ƒöŕ Ɓûðĝéţ Ƒļéẋîƀîļîţý-------П國カ내]",
    "checkin96": "[Çĥéçķ‐îñ--П國カ내]",
    "checking103": "[Çĥéçķîñĝ--П國カ내]",
    "checking82": "[Çĥéçķîñĝ--П國カ내]",
    "checkout97": "[Çĥéçķ‐öûţ--П國カ내]",
    "checkyou157": "[Çĥéçķ Ýöûŕ Éɱåîļ----П國カ내]",
    "checkyou158": "[Çĥéçķ ýöûŕ éɱåîļ åñð åçţîṽåţé ýöûŕ ŢŕåṽéļƁåñķ åççöûñţ· Îţ ţåķéš ĵûšţ å ƒéŵ ɱîñûţéš ţö šéţ ûþ ýöûŕ çöɱþåñý åñð çûšţöɱîžé ýöûŕ šéţţîñĝš·---------------------------П國カ내]",
    "chooseacorp5": "[Çĥööšé å çöŕþöŕåţé éẋþéñšé ţö ɱéŕĝé ŵîţĥ ýöûŕ {title} ƒöŕ {currency} öñ {date} ţŕåñšåçţîöñ·----------------------------П國カ내]",
    "chooseacorp7": "[Çĥööšé å çöŕþöŕåţé çåŕð ţŕåñšåçţîöñ ţö ɱéŕĝé ŵîţĥ ýöûŕ {title} ƒöŕ {currency} öñ {date} éẋþéñšé· Îƒ ýöû ðöñ’ţ šéé ýöûŕ ţŕåñšåçţîöñ ĥéŕé، ĝö ţö Þåšţ åñð Þéŕšöñåļ Ţŕåñšåçţîöñš، ûñðéŕ Ṁý Çåŕðš·----------------------------------------------------------П國カ내]",
    "choosead128": "[Çĥööšé å Ðéƒåûļţ Ţŕåṽéļ Þöļîçý-------П國カ내]",
    "choosead4": "[Çĥööšé å ðéþåŕţɱéñţ ţĥåţ ýöû ŵåñţ ţö ðéƒåûļţ ýöûŕ ñöñ‐åļļöçåţéð éẋþéñšéš ţö· Ţĥîš šĥöûļð ƀé å ðéþåŕţɱéñţ ýöû ŵöŕķ îñ ɱöšţ öƒ ţĥé ţîɱé·-----------------------------------------П國カ내]",
    "chooseapers6": "[Çĥööšé å þéŕšöñåļ éẋþéñšé ţö ɱéŕĝé ŵîţĥ ýöûŕ {title} ƒöŕ {currency} öñ {date} ţŕåñšåçţîöñ·----------------------------П國カ내]",
    "chooseapers8": "[Çĥööšé å þéŕšöñåļ çåŕð ţŕåñšåçţîöñ ţö ɱéŕĝé ŵîţĥ ýöûŕ {title} ƒöŕ {currency} öñ {date} éẋþéñšé· Îƒ ýöû ðöñ’ţ šéé ýöûŕ ţŕåñšåçţîöñ ĥéŕé، ĝö ţö Þåšţ åñð Þéŕšöñåļ Ţŕåñšåçţîöñš، ûñðéŕ Ṁý Çåŕðš·---------------------------------------------------------П國カ내]",
    "chooseho1": "[Çĥööšé ĥöŵ ýöûŕ éẋþéñšé ţöţåļ šĥöûļð ƀé ðîṽîðéð· Îƒ ýöû ñééð ţö šþļîţ ýöûŕ éẋþéñšé ţöţåļ åçŕöšš ɱûļţîþļé ðéþåŕţɱéñţš åñð⁄öŕ çåţéĝöŕîéš، åðð šþļîţš åñð ƒîļļ îñ ţĥé îñƒöŕɱåţîöñ åš ñééðéð·--------------------------------------------------------П國カ내]",
    "chooseho30": "[Çĥööšé ĥöŵ öƒţéñ éẋþéñšé ŕéþöŕţš ŵîļļ ƀé éẋþöŕţéð·-----------П國カ내]",
    "city15": "[Çîţý--П國カ내]",
    "city227": "[Çîţý-П國カ내]",
    "clear128": "[Çļéåŕ--П國カ내]",
    "clear172": "[Çļéåŕ--П國カ내]",
    "clear176": "[Çļéåŕ--П國カ내]",
    "clear67": "[Çļéåŕ--П國カ내]",
    "clear70": "[Çļéåŕ--П國カ내]",
    "clear71": "[Çļéåŕ--П國カ내]",
    "clear74": "[Çļéåŕ--П國カ내]",
    "clear75": "[Çļéåŕ--П國カ내]",
    "clear76": "[Çļéåŕ--П國カ내]",
    "clear77": "[Çļéåŕ--П國カ내]",
    "clear80": "[Çļéåŕ--П國カ내]",
    "clearfil171": "[Çļéåŕ Ƒîļţéŕš---П國カ내]",
    "clearfil75": "[Çļéåŕ Ƒîļţéŕš---П國カ내]",
    "clearfil9": "[Çļéåŕ Ƒîļţéŕš---П國カ내]",
    "clearinp8": "[Çļéåŕ Îñþûţ---П國カ내]",
    "clickher167": "[Çļîçķ ĥéŕé ţö ţŕý åĝåîñ·-----П國カ내]",
    "clicking123": "[Çļîçķîñĝ çöñƒîŕɱ ŵîļļ ŕéšţöŕé ţĥé šéļéçţéð éẋþéñšéš· Åļļ ŕéšţöŕéð éẋþéñšéš ŵîļļ ĝö ƀåçķ ţö ýöûŕ Éẋþéñšéš ļîšţ·-----------------------П國カ내]",
    "clickonc40": "[Çļîçķ öñ çåŕð ñûɱƀéŕ ţö çöþý ţö çļîþƀöåŕð---------П國カ내]",
    "clicktod209": "[çļîçķ ţö ðéļéţé ţĥîš öþţîöñ------П國カ내]",
    "close1": "[Çļöšé--П國カ내]",
    "close18": "[Çļöšé--П國カ내]",
    "close22": "[Çļöšé--П國カ내]",
    "close43": "[Çļöšé--П國カ내]",
    "close69": "[Çļöšé--П國カ내]",
    "close84": "[Çļöšé--П國カ내]",
    "closebul119": "[Çļöšé ƀûļķ îɱþöŕţ----П國カ내]",
    "closecar145": "[Çļöšé Çåŕð---П國カ내]",
    "closecar323": "[Çļöšé Çåŕð---П國カ내]",
    "closed28": "[Çļöšéð--П國カ내]",
    "closenav2": "[Çļöšé Ñåṽîĝåţîöñ----П國カ내]",
    "closetex1": "[Çļöšé {text} Ṁöðåļ----П國カ내]",
    "columnco114": "[Çöļûɱñ Çöñƒîĝûŕåţîöñš-----П國カ내]",
    "columnco144": "[Çöļûɱñ Çöñƒîĝûŕåţîöñš-----П國カ내]",
    "columnco20": "[Çöļûɱñ Çöñƒîĝûŕåţîöñš-----П國カ내]",
    "columndi28": "[Çöļûɱñ Ðîšþļåý Ñåɱé----П國カ내]",
    "columndi82": "[Çöļûɱñ Ðîšþļåý Ñåɱé----П國カ내]",
    "columnor29": "[Çöļûɱñ Öŕðéŕ---П國カ내]",
    "columnor83": "[Çöļûɱñ Öŕðéŕ---П國カ내]",
    "combine18": "[Çöɱƀîñé---П國カ내]",
    "comments51": "[Çöɱɱéñţš åŕé ṽîšîƀļé ţö {reportownerfullname} åñð åļļ åþþŕöṽéŕš îñ ţĥîš ŕéþöŕţ·----------------П國カ내]",
    "commerc1": "[Çöɱɱéŕçîåļ Ŕéŵåŕðš Çåŕð îñţéĝŕåţîöñ--------П國カ내]",
    "commerci1": "[Çöɱɱéŕçîåļ Ŕéŵåŕðš Çåŕð îñţéĝŕåţîöñ--------П國カ내]",
    "commerci26": "[Çöɱɱéŕçîåļ Çåŕðš----П國カ내]",
    "commerci56": "[Çöɱɱéŕçîåļ Çåŕðš----П國カ내]",
    "company13": "[Çöɱþåñý---П國カ내]",
    "company19": "[Çöɱþåñý---П國カ내]",
    "company203": "[Çöɱþåñý--П國カ내]",
    "company262": "[Çöɱþåñý--П國カ내]",
    "company76": "[Çöɱþåñý--П國カ내]",
    "companyb1": "[Çöɱþåñý Ɓåñķ Åççöûñţ-----П國カ내]",
    "companyb142": "[Çöɱþåñý Ɓåñķ Åççöûñţ-----П國カ내]",
    "companyb176": "[Çöɱþåñý Ɓåñķ Åççöûñţ-----П國カ내]",
    "companyc157": "[• Çöɱþåñý Çöŕþöŕåţé Çåŕð-----П國カ내]",
    "companyc160": "[Çöɱþåñý Çöŕþöŕåţé Çåŕð------П國カ내]",
    "companyc33": "[Çöɱþåñý Çöŕþöŕåţé Çåŕð-----П國カ내]",
    "companyi38": "[Çöɱþåñý Îñṽîţåţîöñš------П國カ내]",
    "companyn185": "[Çöɱþåñý Ñûɱƀéŕ---П國カ내]",
    "companyn29": "[Çöɱþåñý Ñåɱé---П國カ내]",
    "companyn48": "[Çöɱþåñý Ñûɱƀéŕ---П國カ내]",
    "companys1": "[Çöɱþåñý Šéţţîñĝš----П國カ내]",
    "companys104": "[Çöɱþåñý Šéţţîñĝš----П國カ내]",
    "companys139": "[Çöɱþåñý Šéţţîñĝš----П國カ내]",
    "companys141": "[Çöɱþåñý Šéţţîñĝš----П國カ내]",
    "companys147": "[Çöɱþåñý Šéţţîñĝš----П國カ내]",
    "companys168": "[Çöɱþåñý Šéţţîñĝš----П國カ내]",
    "companys17": "[Çöɱþåñý Šéţţîñĝš----П國カ내]",
    "companys171": "[Çöɱþåñý Šéţţîñĝš----П國カ내]",
    "companys2": "[Çöɱþåñý Šéţţîñĝš----П國カ내]",
    "companys213": "[Çöɱþåñý Šéţţîñĝš--------------------П國カ내]",
    "companys281": "[Çöɱþåñý Šéţţîñĝš----П國カ내]",
    "companys69": "[Çöɱþåñý Šéţţîñĝš----П國カ내]",
    "companys71": "[Çöɱþåñý Šéţţîñĝš----П國カ내]",
    "companys76": "[Çöɱþåñý Šéţţîñĝš----П國カ내]",
    "companys78": "[Çöɱþåñý Šéţţîñĝš----П國カ내]",
    "companys83": "[Çöɱþåñý Šéţţîñĝš----П國カ내]",
    "companys88": "[Çöɱþåñý Šéţţîñĝš----П國カ내]",
    "companys90": "[Çöɱþåñý Šéţţîñĝš----П國カ내]",
    "companyv155": "[• Çöɱþåñý Ṽîŕţûåļ Çåŕð-----П國カ내]",
    "companyv158": "[Çöɱþåñý Ṽîŕţûåļ Çåŕð------П國カ내]",
    "comparep1": "[Çöɱþåŕé Þļåñš---П國カ내]",
    "comparep165": "[Çöɱþåŕé Þļåñ Ƒéåţûŕéš-----П國カ내]",
    "comparep170": "[Çöɱþåŕé Þļåñš---П國カ내]",
    "compareu1": "[Çöɱþåŕé þļåñš åñð ĝéţ šţåŕţéð ŵîţĥ ýöûŕ þåîð ûþĝŕåðé·-----------П國カ내]",
    "complete56": "[Çöɱþļéţé åţ ļéåšţ ① Ðéþåŕţɱéñţ-------П國カ내]",
    "completi5": "[Çöɱþļéţîñĝ ýöûŕ ţŕåṽéļéŕ þŕöƒîļé åļļöŵš ýöû ţö šåṽé åļļ öƒ ýöûŕ ţŕåṽéļéŕ îñƒöŕɱåţîöñ، îñçļûðîñĝ ýöûŕ åîŕļîñé åñð ĥöţéļ ļöýåļţý ñûɱƀéŕš، îñ öñé þļåçé ƒöŕ éåšý ƀööķîñĝ·----------------------------------П國カ내]",
    "concatad107": "[Åðṽåñçéð ƀööķîñĝ ðŕöþðöŵñ∶ {value} {valueType} selected - ‐------------П國カ내]",
    "concatbi82": "[Ɓîļļîñĝ Çöðé ‐ {errorText}------П國カ내]",
    "concatca78": "[Çåŕ Ŕéñţåļ Çöɱþåñý ‐ {errorText}-------П國カ내]",
    "concater84": "[Ðîšçöûñţ Ñûɱƀéŕ ‐ {errorText}------П國カ내]",
    "concatho105": "[Ĥöţéļ ðîšţåñçé ðŕöþðöŵñ∶ {value} {valueType} selected - ‐------------П國カ내]",
    "concatst121": "[Šţöþš ðŕöþðöŵñ∶ {settingStops} selected - ---------П國カ내]",
    "concur13": "[Çöñçûŕ--П國カ내]",
    "concurre8": "[Çöñçûŕ Ŕéçéîþţš-----П國カ내]",
    "conditio150": "[Çöñðîţîöñ--П國カ내]",
    "conditio164": "[Çöñðîţîöñ Ðŕöþðöŵñ----П國カ내]",
    "configur174": "[Çöñƒîĝûŕé Ƒîéļð Þŕöþéŕţîéš------П國カ내]",
    "confirm150": "[Çöñƒîŕɱ--П國カ내]",
    "confirm168": "[Çöñƒîŕɱ--П國カ내]",
    "confirm32": "[Çöñƒîŕɱ--П國カ내]",
    "confirm9": "[Çöñƒîŕɱ--П國カ내]",
    "confirma14": "[Çöñƒîŕɱ Åççöûñţ Ñûɱƀéŕ-------П國カ내]",
    "confirma17": "[Çöñƒîŕɱåţîöñ Ñûɱƀéŕ----П國カ내]",
    "confirma276": "[Çöñƒîŕɱ ⅋ Ŕéåššîĝñ----П國カ내]",
    "confirma93": "[Çöñƒîŕɱåţîöñ Ñûɱƀéŕ----П國カ내]",
    "confirmn15": "[Çöñƒîŕɱ Ñéŵ Þåššŵöŕð-------П國カ내]",
    "confirmn87": "[Çöñƒîŕɱ Ñéŵ Þåššŵöŕð-------П國カ내]",
    "confirmp13": "[Çöñƒîŕɱ Þåýɱéñţ----П國カ내]",
    "confirmp28": "[Çöñƒîŕɱ Þåššŵöŕð----П國カ내]",
    "confirmp4": "[Çöñƒîŕɱ Þåššŵöŕð----П國カ내]",
    "confirmp6": "[Çöñƒîŕɱ þåššŵöŕð، Îñšţŕûçţîöñš∶ Þåššŵöŕðš ɱûšţ ɱåţçĥ-----------П國カ내]",
    "confirmw10": "[Çöñƒîŕɱ Ŵöŕķ Éɱåîļ----П國カ내]",
    "confirmy76": "[Çöñƒîŕɱ Ýöûŕ Åççöûñţ-------П國カ내]",
    "connect205": "[Çöññéçţ--П國カ내]",
    "connectb204": "[Çöññéçţ {bankpublicname}-----П國カ내]",
    "connecte1": "[Çöññéçţéð Îñţéĝŕåţîöñš-----П國カ내]",
    "connecti1": "[Çöññéçţîöñš---П國カ내]",
    "connecti232": "[Çöññéçţîöñ ƀŕöķéñ· Þļéåšé ŕé‐çöññéçţ ţö {bankpublicname}·------------П國カ내]",
    "connecti287": "[Çöññéçţîöñ Þéñðîñĝ----П國カ내]",
    "connecti291": "[Çöññéçţîöñš---П國カ내]",
    "contact22": "[Çöñţåçţ--П國カ내]",
    "contacts21": "[Çöñţåçţ Šûþþöŕţ----П國カ내]",
    "contacts61": "[Çöñţåçţ Šûþþöŕţ----П國カ내]",
    "contactt20": "[Çöñţåçţ ŢŕåṽéļƁåñķ---П國カ내]",
    "contactu104": "[Çöñţåçţ ûš åţ <a class=\"mail\" href=\"mailto:{generalemail}\">{generalemail}</a> ţö šéţ ûþ ðåîļý ŕéîɱƀûŕšéɱéñţš·-----------------------П國カ내]",
    "contactu33": "[Çöñţåçţ Ûš----П國カ내]",
    "contacty9": "[Çöñţåçţ ýöûŕ åðɱîñ ţö îñçŕéåšé ýöûŕ çåŕð’š çŕéðîţ ļîɱîţ öŕ åļļöŵ ţŕåñšåçţîöñš åţ å šþéçîƒîç ɱéŕçĥåñţ·-------------------------------П國カ내]",
    "continue125": "[Çöñţîñûé--П國カ내]",
    "continue126": "[Çöñţîñûé--П國カ내]",
    "continue140": "[Çöñţîñûé--П國カ내]",
    "continue177": "[Çöñţîñûé ŵîţĥ Ƒŕéé----П國カ내]",
    "continue178": "[Çöñţîñûé ŵîţĥ Þåîð----П國カ내]",
    "continue5": "[Çöñţîñûé--П國カ내]",
    "continue6": "[Çöñţîñûé ŵîţĥ Ĝööĝļé-----П國カ내]",
    "continue85": "[Çöñţîñûé--П國カ내]",
    "continue91": "[Çöñţîñûé--П國カ내]",
    "controls292": "[Çöñţŕöļ šþéñð، ţŕåçķ éẋþéñšéš، åñð ɱåñåĝé ƀûðĝéţš ŵîţĥ ŢŕåṽéļƁåñķ ⁺ Û·Š· Ɓåñķ Îñţéĝŕåţîöñ·-------------------П國カ내]",
    "controls293": "[Çöñţŕöļ šþéñð، ţŕåçķ éẋþéñšéš، åñð ɱåñåĝé ƀûðĝéţš ŵîţĥ ŢŕåṽéļƁåñķ ⁺ Ɓŕéẋ Îñţéĝŕåţîöñ·------------------П國カ내]",
    "controls294": "[Çöñţŕöļ šþéñð، ţŕåçķ éẋþéñšéš، åñð ɱåñåĝé ƀûðĝéţš ŵîţĥ ŢŕåṽéļƁåñķ ⁺ Éļåñ Ƒîñåñçîåļ Šéŕṽîçéš Îñţéĝŕåţîöñ·---------------------П國カ내]",
    "controls299": "[Çöñţŕöļ šþéñð، ţŕåçķ éẋþéñšéš، åñð ɱåñåĝé ƀûðĝéţš ŵîţĥ ŢŕåṽéļƁåñķ ⁺ Ɓåñķ öƒ Åɱéŕîçå Îñţéĝŕåţîöñ·--------------------П國カ내]",
    "controls304": "[Çöñţŕöļ šþéñð، ţŕåçķ éẋþéñšéš، åñð ɱåñåĝé ƀûðĝéţš ŵîţĥ ŢŕåṽéļƁåñķ ⁺ Šîļîçöñ Ṽåļļéý Ɓåñķ Îñţéĝŕåţîöñ·---------------------П國カ내]",
    "controls307": "[Çöñţŕöļ šþéñð، ţŕåçķ éẋþéñšéš، åñð ɱåñåĝé ƀûðĝéţš ŵîţĥ ŢŕåṽéļƁåñķ ⁺ Åɱéŕîçåñ Éẋþŕéšš Îñţéĝŕåţîöñ·--------------------П國カ내]",
    "conversi3": "[Çöñṽéŕšîöñ Ŕåţé----П國カ내]",
    "conversi92": "[Çöñṽéŕšîöñ Ŕåţé----П國カ내]",
    "copied": "[çöþîéð--П國カ내]",
    "copied39": "[Çöþîéð¡--П國カ내]",
    "copy": "[çöþý-П國カ내]",
    "copythescim": "[Çöþý ţĥé ŠÇÎṀ çŕéðéñţîåļš ƀéļöŵ îñţö ţĥé Okta þŕöṽîšîöñš ţåƀ ţö éñåƀļé ûšéŕ îɱþöŕţ åñð þŕöṽîšîöñîñĝ ƒéåţûŕéš·----------------------П國カ내]",
    "coreinsi1": "[Çöŕé Îñšîĝĥţš----П國カ내]",
    "coreinsi19": "[Çöŕé Îñšîĝĥţš---П國カ내]",
    "corporat1": "[Çöŕþöŕåţé Çåŕð<br⁄>Çöññéçţîöñš-------П國カ내]",
    "corporat115": "[Çöŕþöŕåţé Çåŕð---П國カ내]",
    "corporat126": "[Çöŕþöŕåţé Çåŕð---П國カ내]",
    "corporat130": "[Çöŕþöŕåţé Çåŕð Ŕéǫûîŕé Ṁéŕĝîñĝ öƒ Éẋþéñšé ŵîţĥ Çöŕþöŕåţé Çåŕð Ţŕåñšåçţîöñ---------------П國カ내]",
    "corporat133": "[Çöŕþöŕåţé Çåŕð Ðéƒåûļţ Þåýɱéñţ Ṁéţĥöð--------П國カ내]",
    "corporat16": "[Çöŕþöŕåţé Çåŕð⁄Ñöñ‐Ŕéîɱƀûŕšåƀļé----------П國カ내]",
    "corporat170": "[Çöŕþöŕåţé Çåŕð---П國カ내]",
    "corporat21": "[Çöŕþöŕåţé⁄Ñöñ‐Ŕéîɱƀûŕšåƀļé------П國カ내]",
    "corporat23": "[Çöŕþöŕåţé Çåŕð Ŕéƒûñð-----П國カ내]",
    "corporat233": "[Çöŕþöŕåţé Çåŕð Çöññéçţîöñš------П國カ내]",
    "corporat26": "[Çöŕþöŕåţé Çåŕð-----П國カ내]",
    "corporat27": "[Çöŕþöŕåţé Çåŕðš----П國カ내]",
    "corporat3": "[Çöŕþöŕåţé Çåŕð⁄Ñöñ‐Ŕéîɱƀûŕšåƀļé Éẋþéñšé------------П國カ내]",
    "corporat30": "[Çöŕþöŕåţé Çåŕðš----П國カ내]",
    "corporat308": "[Çöŕþöŕåţé Ĝŕééñ Çåŕð-----П國カ내]",
    "corporat309": "[Çöŕþöŕåţé Ĝöļð Çåŕð----П國カ내]",
    "corporat310": "[Çöŕþöŕåţé Þļåţîñûɱ Çåŕð-----П國カ내]",
    "corporat34": "[Çöŕþöŕåţé Çåŕðš----П國カ내]",
    "corporat37": "[Çöŕþöŕåţé⁄Ñöñ‐Ŕéîɱƀûŕšåƀļé------П國カ내]",
    "corporat45": "[Çöŕþöŕåţé Çåŕð⁄Ñöñ‐Ŕéîɱƀûŕšåƀļé Éẋþéñšé--------П國カ내]",
    "corporat50": "[Çöŕþöŕåţé--П國カ내]",
    "corporat6": "[Çöŕþöŕåţé Çåŕð---П國カ내]",
    "corporat66": "[Çöŕþöŕåţé Çåŕð---П國カ내]",
    "corporat73": "[Çöŕþöŕåţé Çåŕð---П國カ내]",
    "corporat76": "[Çöŕþöŕåţé Çåŕðš----П國カ내]",
    "corporat77": "[Çöŕþöŕåţé Éẋþéñšéš----П國カ내]",
    "corporat78": "[Çöŕþöŕåţé Ţŕåṽéļ----П國カ내]",
    "corporat9": "[Çöŕþöŕåţé Çåŕð-----П國カ내]",
    "corporatecard82": "[Çöŕþöŕåţé Çåŕð⁄Ñöñ‐Ŕéîɱƀûŕšåƀļé Éẋþéñšé--------П國カ내]",
    "costtitl1": "[{title} çöšţš---П國カ내]",
    "couldnot10": "[Çöûļð ñöţ ļöåð çŕéðîţ çåŕðš------П國カ내]",
    "couldnot106": "[Çöûļð ñöţ ƒîñð ţĥåţ çåŕð-----П國カ내]",
    "couldnot11": "[Çöûļð ñöţ ļöåð ţŕåñšåçţîöñš------П國カ내]",
    "couldnot115": "[Çöûļð ñöţ šûƀɱîţ ƀéçåûšé šöɱé éẋþéñšéš ñééð ɱöŕé îñƒöŕɱåţîöñ ƀéƒöŕé šûƀɱîţţîñĝ ţĥé éẋþéñšé ŕéþöŕţ·--------------------П國カ내]",
    "couldnot119": "[Çöûļð ñöţ ļöåð çåŕð þŕéšéţš------П國カ내]",
    "couldnot161": "[Çöûļð ñöţ ļöåð çåŕð ɱåñåĝîñĝ åççöûñţš--------П國カ내]",
    "couldnot201": "[Çöûļð ñöţ ļöåð çåŕð ɱåñåĝîñĝ åççöûñţš--------П國カ내]",
    "couldnot49": "[Çöûļð ñöţ ļöåð éẋþéñšéš-----П國カ내]",
    "couldnot50": "[Çöûļð ñöţ ļöåð éẋþéñšé ŕéþöŕţš-------П國カ내]",
    "couldnot52": "[Çöûļð ñöţ çåļçûļåţé ðîšţåñçé· Þļéåšé çöɱþļéţé îţ ɱåñûåļļý·------------------П國カ내]",
    "couldnot60": "[Çöûļð ñöţ ļöåð ýöûŕ ƀööķîñĝš·------П國カ내]",
    "couldnot62": "[Çöûļð ñöţ åššîĝñ ûšéŕ-----П國カ내]",
    "couldnot77": "[Çöûļð ñöţ šûƀɱîţ ƀéçåûšé šöɱé éẋþéñšéš ñééð ɱöŕé îñƒöŕɱåţîöñ ƀéƒöŕé šûƀɱîţţîñĝ ţĥé éẋþéñšé ŕéþöŕţ·--------------------П國カ내]",
    "country16": "[Çöûñţŕý---П國カ내]",
    "country230": "[Çöûñţŕý--П國カ내]",
    "countryc1": "[Çöûñţŕý Çöðé----П國カ내]",
    "countryd231": "[Çöûñţŕý ðŕöþðöŵñ ļîšţ-----П國カ내]",
    "cpremium1": "[Ţĥé ƒîŕšţ {currentLicenses} ûšéŕš ţö ûšé ţŕåṽéļ åñð éẋþéñšé ŵîļļ çöšţ ýöû {totalCostPerMonth}⁄ɱö· ƀîļļéð åññûåļļý·------------------------П國カ내]",
    "create83": "[Çŕéåţé--П國カ내]",
    "createac16": "[Çŕéåţé å Çöɱþåñý----П國カ내]",
    "createac32": "[Çŕéåţé Åççöûñţ---П國カ내]",
    "createac44": "[Çŕéåţé å Çöɱþåñý----П國カ내]",
    "createac7": "[Çŕéåţé Åççöûñţ---П國カ내]",
    "createan141": "[Çŕéåţé å ñéŵ þŕéšéţ ñåɱé ƒöŕ çåŕð çöñţŕöļš ţö ƀé šåṽéð ûñðéŕ·-------------П國カ내]",
    "createan173": "[Çŕéåţé å ñéŵ çûšţöɱ ƒîéļð ţĥåţ ŵîļļ ƀé ţîéð ţö éẋþéñšé ŕéþöŕţš·-------------П國カ내]",
    "createan176": "[Çŕéåţé å ñéŵ çûšţöɱ ƒîéļð ţĥåţ ŵîļļ ƀé ţîéð ţö éẋþéñšé ŕéþöŕţš·-------------П國カ내]",
    "createan2": "[Çŕéåţé åñ Éẋþéñšé------П國カ내]",
    "createan34": "[Çŕéåţé åñ Åççöûñţ------П國カ내]",
    "createan38": "[Çŕéåţé åñ Éẋþéñšé----П國カ내]",
    "createan6": "[Çŕéåţé å ñéŵ ŕéþöŕţ ñåɱé ƒöŕ éẋþéñšéš ţö ƀé šûƀɱîţţéð ûñðéŕ·-------------П國カ내]",
    "createan91": "[Çŕéåţé åñ Îñšţåñţ Éɱþļöýéé Çåŕð-------П國カ내]",
    "createan94": "[Çŕéåţé åñ Îñšţåñţ Ṽéñðöŕ Çåŕð------П國カ내]",
    "createap4": "[Çŕéåţé å Þåššŵöŕð----П國カ내]",
    "createap93": "[Çŕéåţé å Þĥýšîçåļ Éɱþļöýéé Çåŕð-------П國カ내]",
    "createap96": "[Çŕéåţé å Þĥýšîçåļ Ṽéñðöŕ Çåŕð------П國カ내]",
    "createar41": "[Çŕéåţé å Ŕéþöŕţ----П國カ내]",
    "createas55": "[Çŕéåţé åš ɱåñý ðéþåŕţɱéñţš åš ýöû ñééð ƒöŕ éåçĥ ĝŕöûþ öƒ éɱþļöýééš ŵĥö šĥåŕé åñ åþþŕöṽéŕ·------------------П國カ내]",
    "createav92": "[Çŕéåţé å Ṽîŕţûåļ Éɱþļöýéé Çåŕð-------П國カ내]",
    "createav95": "[Çŕéåţé å Ṽîŕţûåļ Ṽéñðöŕ Çåŕð------П國カ내]",
    "createca103": "[Çŕéåţé Çåŕðš---П國カ내]",
    "createca156": "[Çŕéåţé Çåţéĝöŕý----П國カ내]",
    "createca4": "[Çŕéåţé Çåŕð---П國カ내]",
    "createco46": "[Çŕéåţé Çöɱþåñý Åççöûñţ-----П國カ내]",
    "createcu1": "[Çŕéåţé çûšţöɱ ðåţå éñţŕý ƒöŕ ţŕåṽéļ ûšéŕš·---------П國カ내]",
    "createcu2": "[Çŕéåţé çûšţöɱ ðåţå éñţŕý ƒöŕ éẋþéñšé ûšéŕš·---------П國カ내]",
    "createdd112": "[Çŕéåţéð Ðåţé---П國カ내]",
    "createem89": "[Çŕéåţé Éɱþļöýéé Çåŕð-----П國カ내]",
    "createex1": "[Çŕéåţé éẋþéñšé ŕéþöŕţ-----П國カ내]",
    "createex38": "[Çŕéåţé Éẋþéñšé-----П國カ내]",
    "createex72": "[Çŕéåţé Éẋþéñšé Þöļîçý-----П國カ내]",
    "createex97": "[Çŕéåţé Éẋþéñšé---П國カ내]",
    "createne139": "[Çŕéåţé Ñéŵ---П國カ내]",
    "createne40": "[Çŕéåţé Ñéŵ Ṁîļéåĝé----П國カ내]",
    "createne43": "[Çŕéåţé Ñéŵ Åççöûñţ----П國カ내]",
    "createne48": "[Çŕéåţé Ñéŵ Îñţéĝŕåţîöñ-------П國カ내]",
    "createne5": "[Çŕéåţé Ñéŵ Çåŕð----П國カ내]",
    "createne55": "[Çŕéåţé Ñéŵ Çûšţöɱ Ƒîéļð-----П國カ내]",
    "createne7": "[Çŕéåţé Ñéŵ Ṁîļéåĝé------П國カ내]",
    "createne77": "[Çŕéåţé Ñéŵ Þöļîçý----П國カ내]",
    "createne99": "[Çŕéåţé Ñéŵ Çûšţöɱ Ƒîéļð-----П國カ내]",
    "createon50": "[Çŕéåţé öñé ðéþåŕţɱéñţ ƒöŕ éåçĥ ĝŕöûþ öƒ éɱþļöýééš ŵĥö šĥåŕé åñ åþþŕöṽéŕ·---------------П國カ내]",
    "createpa15": "[Çŕéåţé Þåššŵöŕð----П國カ내]",
    "createpa5": "[Çŕéåţé þåššŵöŕð، Îñšţŕûçţîöñš∶ Ṁîñîɱûɱ {count} çĥåŕåçţéŕš ļöñĝ، Ṁûšţ îñçļûðé öñé ûþþéŕçåšé åñð öñé ļöŵéŕçåšé çĥåŕåçţéŕ، Ṁûšţ îñçļûðé öñé ñûɱƀéŕ، Ṁûšţ îñçļûðé öñé šþéçîåļ çĥåŕåçţéŕ------------------------------------П國カ내]",
    "createre2": "[Çŕéåţé Ŕéþöŕţ---П國カ내]",
    "createve90": "[Çŕéåţé Ṽéñðöŕ Çåŕð----П國カ내]",
    "creating24": "[Çŕéåţîñĝ çåŕð···----П國カ내]",
    "creating69": "[Çŕéåţîñĝ çåŕð، çĥéçķ ţöɱöŕŕöŵ···-------П國カ내]",
    "credenti26": "[Çŕéðéñţîåļš---П國カ내]",
    "creditca1": "[Çŕéðîţ çåŕð ŕéçöñçîļîåţîöñ ŕéþöŕţ-------П國カ내]",
    "creditca104": "[Çŕéðîţ Çåŕð šûççéššƒûļļý åððéð-------П國カ내]",
    "creditca105": "[Çŕéðîţ Çåŕð ļîñķîñĝ ƒåîļéð¡------П國カ내]",
    "creditca109": "[Çŕéðîţ Çåŕð šûççéššƒûļļý ŕéåûţĥéñţîçåţéð·---------П國カ내]",
    "creditca110": "[Çŕéðîţ Çåŕð çöûļð ñöţ ƀé ŕéåûţĥéñţîçåţéð¡---------П國カ내]",
    "creditca111": "[Çŕéðîţ Çåŕð šûççéššƒûļļý ŕéɱöṽéð-------П國カ내]",
    "creditca112": "[Çŕéðîţ Çåŕð ŕéɱöṽåļ ƒåîļéð¡------П國カ내]",
    "creditca12": "[Çŕéðîţ Çåŕð Ŕéçöñçîļļîåţîöñ---------П國カ내]",
    "creditca14": "[Çŕéðîţ Çåŕð Åûðîţ------П國カ내]",
    "creditca3": "[Çŕéðîţ Çåŕð<span>···⑨⑥⑧⑦</span>-------П國カ내]",
    "creditca340": "[Çŕéðîţ Çåŕðš çöûļð ñöţ ƀé åššîĝñéð-------П國カ내]",
    "creditca42": "[Çŕéðîţ Çåŕð---П國カ내]",
    "creditca6": "[Çŕéðîţ Çåŕð Îñƒöŕɱåţîöñ-------П國カ내]",
    "creditca61": "[Çŕéðîţ Çåŕðš šûççéššƒûļļý åššîĝñéð·--------П國カ내]",
    "creditca8": "[Çŕéðîţ Çåŕð Ñûɱƀéŕ------П國カ내]",
    "creditsm2": "[Çŕéðîţš ɱåý ţåķé ûþ ţö ②④ ĥöûŕš ţö ûþðåţé· Þåŕţîåļļý ûšéð ƒļîĝĥţ çŕéðîţš åŕé åþþŕöẋîɱåţé ṽåļûéš· Ƒûţûŕé ţŕåṽéļ ɱûšţ ƀé ƀööķéð åñð ƒļöŵñ ƀý ţĥé éẋþîŕåţîöñ ðåţé·------------------------------------------------П國カ내]",
    "creditut65": "[Çŕéðîţ ûţîļîžåţîöñ----П國カ내]",
    "csv26": "[ÇŠṼ-П國カ내]",
    "ctravel1": "[Ţĥé ƒîŕšţ {currentLicenses} ûšéŕš ţö ûšé ţŕåṽéļ öñļý ŵîļļ çöšţ ýöû {totalCostPerMonth}⁄ɱö· ƀîļļéð åññûåļļý·-----------------------П國カ내]",
    "currency118": "[Çûŕŕéñçý Çöðé---П國カ내]",
    "currencyList": {
      "AED": "[Ûñîţéð Åŕåƀ Éɱîŕåţéš Ðîŕĥåɱ------П國カ내]",
      "AFN": "[Åƒĝĥåñ Åƒĝĥåñî---П國カ내]",
      "ALL": "[Åļƀåñîåñ Ļéķ---П國カ내]",
      "AMD": "[Åŕɱéñîåñ Ðŕåɱ---П國カ내]",
      "ANG": "[Ñéţĥéŕļåñðš Åñţîļļéåñ Ĝûîļðéŕ------П國カ내]",
      "AOA": "[Åñĝöļåñ Ķŵåñžå---П國カ내]",
      "ARS": "[Åŕĝéñţîñé Þéšö---П國カ내]",
      "AUD": "[Åûšţŕåļîåñ Ðöļļåŕ----П國カ내]",
      "AWG": "[Åŕûƀåñ Ƒļöŕîñ---П國カ내]",
      "AZN": "[Åžéŕƀåîĵåñî Ṁåñåţ----П國カ내]",
      "BAM": "[Ɓöšñîå‐Ĥéŕžéĝöṽîñå Çöñṽéŕţîƀļé Ṁåŕķ--------П國カ내]",
      "BBD": "[Ɓåŕƀåðîåñ Ðöļļåŕ----П國カ내]",
      "BDT": "[Ɓåñĝļåðéšĥî Ţåķå----П國カ내]",
      "BGN": "[Ɓûļĝåŕîåñ Ļéṽ---П國カ내]",
      "BHD": "[Ɓåĥŕåîñî Ðîñåŕ---П國カ내]",
      "BIF": "[Ɓûŕûñðîåñ Ƒŕåñç----П國カ내]",
      "BMD": "[Ɓéŕɱûðåñ Ðöļļåŕ----П國カ내]",
      "BND": "[Ɓŕûñéî Ðöļļåŕ---П國カ내]",
      "BOB": "[Ɓöļîṽîåñ Ɓöļîṽîåñö----П國カ내]",
      "BRL": "[Ɓŕåžîļîåñ Ŕéåļ---П國カ내]",
      "BSD": "[Ɓåĥåɱîåñ Ðöļļåŕ----П國カ내]",
      "BTC": "[Ɓîţçöîñ--П國カ내]",
      "BTN": "[Ɓĥûţåñéšé Ñĝûļţŕûɱ----П國カ내]",
      "BWP": "[Ɓöţšŵåñåñ Þûļå---П國カ내]",
      "BYN": "[Ɓéļåŕûšîåñ Ŕûƀļé----П國カ내]",
      "BZD": "[Ɓéļîžé Ðöļļåŕ---П國カ내]",
      "CAD": "[Çåñåðîåñ Ðöļļåŕ----П國カ내]",
      "CDF": "[Çöñĝöļéšé Ƒŕåñç----П國カ내]",
      "CHF": "[Šŵîšš Ƒŕåñç---П國カ내]",
      "CLP": "[Çĥîļéåñ Þéšö---П國カ내]",
      "CNY": "[Çĥîñéšé Ýûåñ---П國カ내]",
      "COP": "[Çöļöɱƀîåñ Þéšö---П國カ내]",
      "CRC": "[Çöšţå Ŕîçåñ Çöļóñ----П國カ내]",
      "CUC": "[Çûƀåñ Çöñṽéŕţîƀļé Þéšö-----П國カ내]",
      "CUP": "[Çûƀåñ Þéšö---П國カ내]",
      "CVE": "[Çåþé Ṽéŕðéåñ Éšçûðö----П國カ내]",
      "CZK": "[Çžéçĥ Ŕéþûƀļîç Ķöŕûñå-----П國カ내]",
      "DJF": "[Ðĵîƀöûţîåñ Ƒŕåñç----П國カ내]",
      "DKK": "[Ðåñîšĥ Ķŕöñé---П國カ내]",
      "DOP": "[Ðöɱîñîçåñ Þéšö---П國カ내]",
      "DZD": "[Åļĝéŕîåñ Ðîñåŕ---П國カ내]",
      "EEK": "[Éšţöñîåñ Ķŕööñ---П國カ내]",
      "EGP": "[Éĝýþţîåñ Þöûñð---П國カ내]",
      "ERN": "[Éŕîţŕéåñ Ñåķƒå---П國カ내]",
      "ETB": "[Éţĥîöþîåñ Ɓîŕŕ---П國カ내]",
      "EUR": "[Éûŕö-П國カ내]",
      "FJD": "[Ƒîĵîåñ Ðöļļåŕ---П國カ내]",
      "FKP": "[Ƒåļķļåñð Îšļåñðš Þöûñð-----П國カ내]",
      "GBP": "[Ɓŕîţîšĥ Þöûñð Šţéŕļîñĝ-----П國カ내]",
      "GEL": "[Ĝéöŕĝîåñ Ļåŕî---П國カ내]",
      "GGP": "[Ĝûéŕñšéý Þöûñð---П國カ내]",
      "GHS": "[Ĝĥåñåîåñ Çéðî---П國カ내]",
      "GIP": "[Ĝîƀŕåļţåŕ Þöûñð----П國カ내]",
      "GMD": "[Ĝåɱƀîåñ Ðåļåšî---П國カ내]",
      "GNF": "[Ĝûîñéåñ Ƒŕåñç---П國カ내]",
      "GTQ": "[Ĝûåţéɱåļåñ Ǫûéţžåļ----П國カ내]",
      "GYD": "[Ĝûýåñåéšé Ðöļļåŕ----П國カ내]",
      "HKD": "[Ĥöñĝ Ķöñĝ Ðöļļåŕ----П國カ내]",
      "HNL": "[Ĥöñðûŕåñ Ļéɱþîŕå----П國カ내]",
      "HRK": "[Çŕöåţîåñ Ķûñå---П國カ내]",
      "HTG": "[Ĥåîţîåñ Ĝöûŕðé---П國カ내]",
      "HUF": "[Ĥûñĝåŕîåñ Ƒöŕîñţ----П國カ내]",
      "IDR": "[Îñðöñéšîåñ Ŕûþîåĥ----П國カ내]",
      "ILS": "[Îšŕåéļî Ñéŵ Šĥéǫéļ----П國カ내]",
      "IMP": "[Ṁåñẋ þöûñð---П國カ내]",
      "INR": "[Îñðîåñ Ŕûþéé---П國カ내]",
      "IQD": "[Îŕåǫî Ðîñåŕ---П國カ내]",
      "IRR": "[Îŕåñîåñ Ŕîåļ---П國カ내]",
      "ISK": "[Îçéļåñðîç Ķŕóñå----П國カ내]",
      "JEP": "[Ĵéŕšéý Þöûñð---П國カ내]",
      "JMD": "[Ĵåɱåîçåñ Ðöļļåŕ----П國カ내]",
      "JOD": "[Ĵöŕðåñîåñ Ðîñåŕ----П國カ내]",
      "JPY": "[Ĵåþåñéšé Ýéñ---П國カ내]",
      "KES": "[Ķéñýåñ Šĥîļļîñĝ----П國カ내]",
      "KGS": "[Ķýŕĝýšţåñî Šöɱ---П國カ내]",
      "KHR": "[Çåɱƀöðîåñ Ŕîéļ---П國カ내]",
      "KMF": "[Çöɱöŕîåñ Ƒŕåñç---П國カ내]",
      "KPW": "[Ñöŕţĥ Ķöŕéåñ Ŵöñ----П國カ내]",
      "KRW": "[Šöûţĥ Ķöŕéåñ Ŵöñ----П國カ내]",
      "KWD": "[Ķûŵåîţî Ðîñåŕ---П國カ내]",
      "KYD": "[Çåýɱåñ Îšļåñðš Ðöļļåŕ-----П國カ내]",
      "KZT": "[Ķåžåķĥšţåñî Ţéñĝé----П國カ내]",
      "LAK": "[Ļåöţîåñ Ķîþ---П國カ내]",
      "LBP": "[Ļéƀåñéšé Þöûñð---П國カ내]",
      "LKR": "[Šŕî Ļåñķåñ Ŕûþéé----П國カ내]",
      "LRD": "[Ļîƀéŕîåñ Ðöļļåŕ----П國カ내]",
      "LSL": "[Ļéšöţĥö Ļöţî---П國カ내]",
      "LTL": "[Ļîţĥûåñîåñ Ļîţåš----П國カ내]",
      "LVL": "[Ļåţṽîåñ Ļåţš---П國カ내]",
      "LYD": "[Ļîƀýåñ Ðîñåŕ---П國カ내]",
      "MAD": "[Ṁöŕöççåñ Ðîŕĥåɱ----П國カ내]",
      "MDL": "[Ṁöļðöṽåñ Ļéû---П國カ내]",
      "MGA": "[Ṁåļåĝåšý Åŕîåŕý----П國カ내]",
      "MKD": "[Ṁåçéðöñîåñ Ðéñåŕ----П國カ내]",
      "MMK": "[Ṁýåñɱå Ķýåţ---П國カ내]",
      "MNT": "[Ṁöñĝöļîåñ Ţûĝŕîķ----П國カ내]",
      "MOP": "[Ṁåçåñéšé Þåţåçå----П國カ내]",
      "MRO": "[Ṁåûŕîţåñîåñ Öûĝûîýå----П國カ내]",
      "MTL": "[Ṁåļţéšé Ļîŕå---П國カ내]",
      "MUR": "[Ṁåûŕîţîåñ Ŕûþéé----П國カ내]",
      "MVR": "[Ṁåļðîṽîåñ Ŕûƒîýåå----П國カ내]",
      "MWK": "[Ṁåļåŵîåñ Ķŵåçĥå----П國カ내]",
      "MXN": "[Ṁéẋîçåñ Þéšö---П國カ내]",
      "MYR": "[Ṁåļåýšîåñ Ŕîñĝĝîţ----П國カ내]",
      "MZN": "[Ṁöžåɱƀîçåñ Ṁéţîçåļ----П國カ내]",
      "NAD": "[Ñåɱîƀîåñ Ðöļļåŕ----П國カ내]",
      "NGN": "[Ñîĝéŕîåñ Ñåîŕå---П國カ내]",
      "NIO": "[Ñîçåŕåĝûåñ Çóŕðöƀå----П國カ내]",
      "NOK": "[Ñöŕŵéĝîåñ Ķŕöñé----П國カ내]",
      "NPR": "[Ñéþåļéšé Ŕûþéé---П國カ내]",
      "NZD": "[Ñéŵ Žéåļåñð Ðöļļåŕ----П國カ내]",
      "OMR": "[Öɱåñî Ŕîåļ---П國カ내]",
      "PAB": "[Þåñåɱåñîåñ Ɓåļƀöå----П國カ내]",
      "PEN": "[Þéŕûṽîåñ Ñûéṽö Šöļ----П國カ내]",
      "PGK": "[Þåþûå Ñéŵ Ĝûîñéåñ Ķîñå-----П國カ내]",
      "PHP": "[Þĥîļîþþîñé Þéšö----П國カ내]",
      "PKR": "[Þåķîšţåñî Ŕûþéé----П國カ내]",
      "PLN": "[Þöļîšĥ Žļöţý---П國カ내]",
      "PYG": "[Þåŕåĝûåýåñ Ĝûåŕåñî----П國カ내]",
      "QAR": "[Ǫåţåŕî Ŕîåļ---П國カ내]",
      "RON": "[Ŕöɱåñîåñ Ļéû---П國カ내]",
      "RSD": "[Šéŕƀîåñ Ðîñåŕ---П國カ내]",
      "RUB": "[Ŕûššîåñ Ŕûƀļé---П國カ내]",
      "RWF": "[Ŕŵåñðåñ Ƒŕåñç---П國カ내]",
      "SAR": "[Šåûðî Ŕîýåļ---П國カ내]",
      "SBD": "[Šöļöɱöñ Îšļåñðš Ðöļļåŕ-----П國カ내]",
      "SCR": "[Šéýçĥéļļöîš Ŕûþéé----П國カ내]",
      "SDG": "[Šûðåñéšé Þöûñð---П國カ내]",
      "SEK": "[Šŵéðîšĥ Ķŕöñå---П國カ내]",
      "SGD": "[Šîñĝåþöŕé Ðöļļåŕ----П國カ내]",
      "SHP": "[Šåîñţ Ĥéļéñå Þöûñð----П國カ내]",
      "SLL": "[Šîéŕŕå Ļéöñéåñ Ļéöñé-----П國カ내]",
      "SOS": "[Šöɱåļî Šĥîļļîñĝ----П國カ내]",
      "SRD": "[Šûŕîñåɱéšé Ðöļļåŕ----П國カ내]",
      "STD": "[Šãö Ţöɱé åñð Þŕíñçîþé Ðöƀŕå------П國カ내]",
      "SVC": "[Šåļṽåðöŕåñ Çöļóñ----П國カ내]",
      "SYP": "[Šýŕîåñ Þöûñð---П國カ내]",
      "SZL": "[Šŵåžî Ļîļåñĝéñî----П國カ내]",
      "THB": "[Ţĥåî Ɓåĥţ--П國カ내]",
      "TJS": "[Ţåĵîķîšţåñî Šöɱöñî----П國カ내]",
      "TMT": "[Ţûŕķɱéñîšţåñî Ṁåñåţ----П國カ내]",
      "TND": "[Ţûñîšîåñ Ðîñåŕ---П國カ내]",
      "TOP": "[Ţöñĝåñ Þå¿åñĝå---П國カ내]",
      "TRY": "[Ţûŕķîšĥ Ļîŕå---П國カ내]",
      "TTD": "[Ţŕîñîðåð åñð Ţöƀåĝö Ðöļļåŕ------П國カ내]",
      "TWD": "[Ñéŵ Ţåîŵåñ Ðöļļåŕ----П國カ내]",
      "TZS": "[Ţåñžåñîåñ Šĥîļļîñĝ----П國カ내]",
      "UAH": "[Ûķŕåîñîåñ Ĥŕýṽñîå----П國カ내]",
      "UGX": "[Ûĝåñðåñ Šĥîļļîñĝ----П國カ내]",
      "USD": "[Ûñîţéð Šţåţéš Ðöļļåŕ-----П國カ내]",
      "UYU": "[Ûŕûĝûåýåñ Þéšö---П國カ내]",
      "UZS": "[Ûžƀéķîšţåñ Šöɱ---П國カ내]",
      "VEF": "[Ṽéñéžûéļåñ Ɓöļíṽåŕ Ƒûéŕţé------П國カ내]",
      "VND": "[Ṽîéţñåɱéšé Ðöñĝ----П國カ내]",
      "VUV": "[Ṽåñûåţû Ṽåţû---П國カ내]",
      "WST": "[Šåɱöåñ Ţåļå---П國カ내]",
      "XAF": "[ÇƑÅ Ƒŕåñç ƁÉÅÇ---П國カ내]",
      "XCD": "[Éåšţ Çåŕîƀƀéåñ Ðöļļåŕ-----П國カ내]",
      "XOF": "[ÇƑÅ Ƒŕåñç ƁÇÉÅÖ----П國カ내]",
      "XPF": "[ÇƑÞ Ƒŕåñç--П國カ내]",
      "YER": "[Ýéɱéñî Ŕîåļ---П國カ내]",
      "ZAR": "[Šöûţĥ Åƒŕîçåñ Ŕåñð----П國カ내]",
      "ZMW": "[Žåɱƀîåñ Ķŵåçĥå---П國カ내]",
      "ZWL": "[Žîɱƀåƀŵéåñ Ðöļļåŕ----П國カ내]"
    },
    "currencylabel": "[{name} |{code}|----П國カ내]",
    "current1": "[Çûŕŕéñţ∶--П國カ내]",
    "currenta132": "[Çûŕŕéñţ Åþþŕöṽéŕ Ñåɱé----П國カ내]",
    "currenta133": "[Çûŕŕéñţ Åþþŕöṽéŕ Éɱåîļ----П國カ내]",
    "currenta170": "[Çûŕŕéñţ Åþþŕöṽéŕ Ñåɱé-----П國カ내]",
    "currenta171": "[Çûŕŕéñţ Åþþŕöṽéŕ Éɱåîļ-----П國カ내]",
    "currentp1": "[Çûŕŕéñţ Þļåñ---П國カ내]",
    "currentp13": "[Çûŕŕéñţ Þåššŵöŕð-----П國カ내]",
    "currentp82": "[Çûŕŕéñţ Þåššŵöŕð îš ŕéǫûîŕéð·---------П國カ내]",
    "currentp85": "[Çûŕŕéñţ Þåššŵöŕð-----П國カ내]",
    "custom27": "[Çûšţöɱ--П國カ내]",
    "custom29": "[Çûšţöɱ--П國カ내]",
    "custom73": "[Çûšţöɱ--П國カ내]",
    "custom92": "[Çûšţöɱ--П國カ내]",
    "customap1": "[Çûšţöɱ åþþŕöṽåļ šţŕûçţûŕé------П國カ내]",
    "customca1": "[Çûšţöɱ çåţéĝöŕîéš----П國カ내]",
    "customer24": "[Çûšţöɱéŕ Šéŕṽîçé Ţööļš-------П國カ내]",
    "customer41": "[Çûšţöɱéŕ Šéŕṽîçé-----П國カ내]",
    "customex79": "[Çûšţöɱ Éẋþéñšé Þöļîçîéš åŕé å Þåîð Ƒéåţûŕé---------П國カ내]",
    "customfi1": "[Çûšţöɱ Ƒîéļðš---П國カ내]",
    "customfi172": "[Çûšţöɱ Ƒîéļðš---П國カ내]",
    "customfi203": "[Çûšţöɱ Ƒîéļð---П國カ내]",
    "customfi54": "[Çûšţöɱ Ƒîéļðš---П國カ내]",
    "customfi93": "[Çûšţöɱ Ƒîéļðš---П國カ내]",
    "customiz2": "[Çûšţöɱîžé Šéţţîñĝš Ļåţéŕ-----П國カ내]",
    "customiz76": "[Çûšţöɱîžé Þöļîçý----П國カ내]",
    "customiz80": "[Çûšţöɱîžé ýöûŕ ðéƒåûļţ éẋþéñšé þöļîçý، åñð åðð åððîţîöñåļ çûšţöɱ þöļîçîéš åššîĝñéð ţö šþéçîƒîç ûšéŕš öŕ ðéþåŕţɱéñţš îñ çöɱþåñý šéţţîñĝš·----------------------------П國カ내]",
    "customiz99": "[Çûšţöɱîžé Šéţţîñĝš----П國カ내]",
    "custompo198": "[Çûšţöɱ Þöļîçý---П國カ내]",
    "customr1": "[Çûšţöɱ ŕéþöŕţîñĝ åṽåîļåƀļé ṽîå Þŕéɱîûɱ Îñšîĝĥţš، ŵîţĥ å šéþåŕåţé ɱöñţĥļý ƒéé----------------П國カ내]",
    "customre1": "[Çûšţöɱ ŕéþöŕţîñĝ åṽåîļåƀļé ṽîå Þŕéɱîûɱ Îñšîĝĥţš، ŵîţĥ å šéþåŕåţé ɱöñţĥļý ƒéé----------------П國カ내]",
    "cvc10": "[ÇṼÇ-П國カ내]",
    "cvv34": "[ÇṼṼ <span>{cardcvv}</span>------П國カ내]",
    "cycleclo103": "[Çýçļé Çļöšé Ðåţé----П國カ내]",
    "daily2": "[Ðåîļý--П國カ내]",
    "daily5": "[Ðåîļý--П國カ내]",
    "daily78": "[Ðåîļý--П國カ내]",
    "dailykey1": "[Ðåîļý--П國カ내]",
    "dashamex327": "[‐ Åɱéẋ Åççöûñţ ÎÐ∶ {amexaccountid}-------П國カ내]",
    "dashboar1": "[Ðåšĥƀöåŕð--П國カ내]",
    "dashboar34": "[Ðåšĥƀöåŕð---П國カ내]",
    "dataexpo1": "[Ðåţå éẋþöŕţ ƒöŕ ÉŔÞ----П國カ내]",
    "date1": "[Ðåţé--П國カ내]",
    "date104": "[Ðåţé-П國カ내]",
    "date13": "[Ðåţé--П國カ내]",
    "date47": "[Ðåţé-П國カ내]",
    "date9": "[Ðåţé-П國カ내]",
    "date96": "[Ðåţé-П國カ내]",
    "datedash53": "[Ðåţé ‐ {paymentdate}-----П國カ내]",
    "dateofbi167": "[Ðåţé öƒ Ɓîŕţĥ---П國カ내]",
    "dateofbi188": "[Ðåţé Öƒ Ɓîŕţĥ---П國カ내]",
    "dateofbi20": "[Ðåţé öƒ Ɓîŕţĥ----П國カ내]",
    "daterang147": "[Ðåţé Ŕåñĝé (ŕéǫûîŕéð)-----П國カ내]",
    "daterang148": "[Ðåţé Ŕåñĝé---П國カ내]",
    "daterang15": "[Ðåţé Ŕåñĝé---П國カ내]",
    "daterang20": "[Ðåţé Ŕåñĝé----П國カ내]",
    "daterang8": "[Ðåţé Ŕåñĝé---П國カ내]",
    "days70": "[Ðåýš-П國カ내]",
    "days75": "[Ðåýš-П國カ내]",
    "daytotalk1": "[Ṁöñţĥļý ţöţåļ---П國カ내]",
    "daytotalk2": "[Þéŕ Ṁöñţĥ Ļîɱîţ----П國カ내]",
    "daytotalk3": "[ţĥîš ɱöñţĥ---П國カ내]",
    "deactiva11": "[Ðéåçţîṽåţîñĝ···----П國カ내]",
    "deactiva143": "[Ðéåçţîṽåţé Çåŕð----П國カ내]",
    "deactiva165": "[Ðéåçţîṽåţîñĝ Çåţéĝöŕý-----П國カ내]",
    "deactiva166": "[Ðéåçţîṽåţîñĝ ţĥîš çåţéĝöŕý ŵîļļ åļšö ðéåçţîṽåţé åļļ çåţéĝöŕý ŕûļéš åñð åþþŕöṽåļ ŕûļéš ŕéļåţéð ţö îţ·---------------------П國カ내]",
    "deactiva331": "[Ðéåçţîṽåţéð Çåŕðš----П國カ내]",
    "deactiva43": "[Ðéåçţîṽåţéð---П國カ내]",
    "deactiva56": "[Ðéåçţîṽåţéð---П國カ내]",
    "deactiva80": "[Ðéåçţîṽåţé Éẋçļûðéð Ûšéŕš------П國カ내]",
    "deactiva9": "[Ðéåçţîṽåţé Çåŕð----П國カ내]",
    "decidewh115": "[Ðéçîðé ŵĥåţ ðåţå þöîñţš ţö éẋþöŕţ ƒöŕ éṽéŕý ţŕåñšåçţîöñ· Ŕéåŕŕåñĝé ţĥé öŕðéŕ îñ ŵĥîçĥ ţĥéý åþþéåŕ îñ ţĥé éẋþöŕţéð ƒîļé·------------------------П國カ내]",
    "declined15": "[Ðéçļîñéð ţŕåñšåçţîöñš åŕé åṽåîļåƀļé ƒöŕ ţĥé þåšţ ③⓪ ðåýš öñļý-------------------П國カ내]",
    "declined27": "[Ðéçļîñéð--П國カ내]",
    "declined5": "[Ðéçļîñéð---П國カ내]",
    "declined8": "[Ðéçļîñéð---П國カ내]",
    "default62": "[Ðéƒåûļţ--П國カ내]",
    "defaulta1": "[Ðéƒåûļţ åþþŕöṽåļ ĥîéŕåŕçĥý------П國カ내]",
    "defaulta156": "[Ðéƒåûļţ Åþþŕöṽåļ Ƒļöŵ-----П國カ내]",
    "defaulta158": "[Ðéƒåûļţ Åþþŕöṽåļ Ƒļöŵ Ðŕöþðöŵñ-------П國カ내]",
    "defaultc1": "[Ðéƒåûļţ çåţéĝöŕîéš----П國カ내]",
    "defaultd49": "[Ðéƒåûļţ Ðéþåŕţɱéñţ----П國カ내]",
    "defaulte66": "[Ðéƒåûļţ Éẋþéñšé Þöļîçý-----П國カ내]",
    "defaultp131": "[Ðéƒåûļţ Þåýɱéñţ Ṁéţĥöð-----П國カ내]",
    "defaultt129": "[Ðéƒåûļţ Ţŕåṽéļ Þöļîçý-----П國カ내]",
    "defaultt73": "[Ðéƒåûļţ Ţŕåṽéļ Þöļîçý-----П國カ내]",
    "defineru142": "[Ðéƒîñé ŕûļé šéţš åñð ĝûîðéļîñéš þéŕ šþéçîƒîç éẋþéñšé çåţéĝöŕîéš·-------------П國カ내]",
    "delegat1": "[Ðéļéĝåţé ûšéŕš ţö ƀööķ ţŕåṽéļ öñ ƀéĥåļƒ öƒ öţĥéŕš----------П國カ내]",
    "delegate1": "[Ðéļéĝåţéš--П國カ내]",
    "delegate103": "[Ðéļéĝåţé Åççéšš----П國カ내]",
    "delegate106": "[Ðéļéĝåţéš çåñ åþþŕöṽé éẋþéñšé ŕéþöŕţš·--------П國カ내]",
    "delegate108": "[Ðéļéĝåţéš çåñ åþþŕöṽé ţĥéîŕ öŵñ éẋþéñšé ŕéþöŕţš·----------П國カ내]",
    "delegate110": "[Ðéļéĝåţéš çåñ åþþŕöṽé ţŕåṽéļ ŕéǫûéšţš·--------П國カ내]",
    "delegate112": "[Ðéļéĝåţéš çåñ åþþŕöṽé ţĥéîŕ öŵñ ţŕåṽéļ ŕéǫûéšţš·----------П國カ내]",
    "delegate129": "[Ðéļéĝåţéš åŕé éɱþļöýééš ŵĥö çåñ þéŕƒöŕɱ ţåšķš öñ ƀéĥåļƒ öƒ ţĥé ĥöšţ·--------------П國カ내]",
    "delegate2": "[Ðéļéĝåţé ûšéŕš ţö þéŕƒöŕɱ ţåšķš öñ ƀéĥåļƒ öƒ öţĥéŕš-----------П國カ내]",
    "delegate46": "[Ðéļéĝåţéš--П國カ내]",
    "delegate48": "[Ðéļéĝåţé ûšéŕš šûççéššƒûļļý åššîĝñéð·--------П國カ내]",
    "delegate51": "[Ðéļéĝåţéš ĝŕöûþ ŕéɱöṽéð·-----П國カ내]",
    "delegate53": "[Ðéļéĝåţéš ŵîļļ ƀé åûţö šîĝñéð öûţ åƒţéŕ {delegateTimeoutValue}·-------------П國カ내]",
    "delegate85": "[Ðéļéĝåţéš--П國カ내]",
    "delegate88": "[Ðéļéĝåţéš åûţö šîĝñ öûţ åƒţéŕ------П國カ내]",
    "delegate90": "[Ðéļéĝåţéš çåñ þéŕƒöŕɱ åļļ ţåšķš ŵîţĥîñ ŢŕåṽéļƁåñķ öñ ţĥé ĥöšţ’š ƀéĥåļƒ ûñļéšš öţĥéŕŵîšé ŕéšţŕîçţéð ŵîţĥîñ Ðéļéĝåţé Åççéšš çöñţŕöļš·---------------------------П國カ내]",
    "delegate97": "[Ðéļéĝåţéš åŕé éɱþļöýééš ŵĥö çåñ þéŕƒöŕɱ ţåšķš öñ ƀéĥåļƒ öƒ ţĥé ĥöšţ--------------П國カ내]",
    "delete102": "[Ðéļéţé--П國カ내]",
    "delete151": "[Ðéļéţé--П國カ내]",
    "delete198": "[Ðéļéţé--П國カ내]",
    "delete1i58": "[Ðéļéţé ① îţéɱ¿---П國カ내]",
    "delete1t120": "[Ðéļéţé ① ţŕåñšåçţîöñ¿-----П國カ내]",
    "delete202": "[Ðéļéţé--П國カ내]",
    "delete21": "[Ðéļéţé--П國カ내]",
    "delete32": "[Ðéļéţé--П國カ내]",
    "delete42": "[Ðéļéţé--П國カ내]",
    "delete70": "[Ðéļéţé--П國カ내]",
    "delete80": "[Ðéļéţé--П國カ내]",
    "delete85": "[Ðéļéţé--П國カ내]",
    "delete89": "[Ðéļéţé--П國カ내]",
    "deleteal119": "[Ðéļéţé åļļ ţŕåñšåçţîöñš¿-----П國カ내]",
    "deleteal57": "[Ðéļéţé åļļ îţéɱš¿----П國カ내]",
    "deleteca104": "[Ðéļéţé Çåŕð¿---П國カ내]",
    "deleteco147": "[Ðéļéţé Çöñƒîŕɱåţîöñ----П國カ내]",
    "deleteco199": "[Ðéļéţé Çöñƒîŕɱåţîöñ----П國カ내]",
    "deletecu180": "[Ðéļéţé Çûšţöɱ Ƒîéļð----П國カ내]",
    "deletecu183": "[Ðéļéţé Çûšţöɱ Ƒîéļð----П國カ내]",
    "deleted3": "[Ðéļéţéð---П國カ내]",
    "deletedo74": "[Ðéļéţéð Öñļý---П國カ내]",
    "deleteex76": "[Ðéļéţé Éẋþéñšé---П國カ내]",
    "deleteho114": "[Ðéļéţé Ĥöšţš ⅋ Ðéļéĝåţéš-----П國カ내]",
    "deleteli8": "[Ðéļéţé Ļîñé Îţéɱ-----П國カ내]",
    "deletepo107": "[Ðéļéţé Þöļîçý---П國カ내]",
    "deletepo80": "[Ðéļéţé Þöļîçý---П國カ내]",
    "deletepo91": "[Ðéļéţé Þöļîçý---П國カ내]",
    "deleteth22": "[Ðéļéţé ţĥîš ţŕåñšåçţîöñ¿-----П國カ내]",
    "deleteth23": "[Ðéļéţé ţĥîš éẋþéñšé¿-----П國カ내]",
    "deleteth59": "[Ðéļéţé {length} îţéɱš¿-----П國カ내]",
    "deletetr121": "[Ðéļéţé {selectedtransactions} ţŕåñšåçţîöñš¿---------П國カ내]",
    "deletetr38": "[Ðéļéţé Ţŕåṽéļéŕ Þŕöƒîļé-------П國カ내]",
    "deletetr39": "[Ðéļéţé Ţŕåṽéļéŕ Îñƒöŕɱåţîöñ---------П國カ내]",
    "deletetr89": "[Ðéļéţé Ţŕåṽéļ Þöļîçý-----П國カ내]",
    "deleteve86": "[Ðéļéţé {vendorName} çöðéš------П國カ내]",
    "deleting109": "[Ðéļéţîñĝ ţĥîš þöļîçý ŵîļļ þļåçé îţš éẋîšţîñĝ ûšéŕš îñţö ţĥé ðéƒåûļţ þöļîçý·----------------П國カ내]",
    "deleting91": "[Ðéļéţîñĝ ţĥîš þöļîçý ŵîļļ þļåçé îţš éẋîšţîñĝ ûšéŕš îñţö ţĥé ðéƒåûļţ þöļîçý·----------------П國カ내]",
    "deleting93": "[Ðéļéţîñĝ ţĥîš þöļîçý ŵîļļ þļåçé îţš éẋîšţîñĝ ûšéŕš îñţö ţĥé ðéƒåûļţ þöļîçý·----------------П國カ내]",
    "delivery33": "[Ðéļîṽéŕý Ṁéţĥöð----П國カ내]",
    "deny79": "[Ðéñý-П國カ내]",
    "departme1": "[Ðéþåŕţɱéñţš---П國カ내]",
    "departme105": "[Ðéþåŕţɱéñţ---П國カ내]",
    "departme106": "[Ðéþåŕţɱéñţ Éẋţéŕñåļ ÎÐ-----П國カ내]",
    "departme15": "[Ðéþåŕţɱéñţ∶ {departmentname}------П國カ내]",
    "departme152": "[Ðéþåŕţɱéñţ(š) åš ----П國カ내]",
    "departme154": "[Ðéþåŕţɱéñţ---П國カ내]",
    "departme16": "[Ðéþåŕţɱéñţ----П國カ내]",
    "departme172": "[Ðéþåŕţɱéñţ Éẋţéŕñåļ ÎÐ-----П國カ내]",
    "departme194": "[Ðéþåŕţɱéñţ ðŕöþðöŵñ----П國カ내]",
    "departme23": "[Ðéþåŕţɱéñţ---П國カ내]",
    "departme34": "[Ðéþåŕţɱéñţ {department}-------П國カ내]",
    "departme38": "[Ðéþåŕţɱéñţ----П國カ내]",
    "departme39": "[Ðéþåŕţɱéñţ---П國カ내]",
    "departme4": "[Ðéþåŕţɱéñţ----П國カ내]",
    "departme47": "[Ðéþåŕţɱéñţš---П國カ내]",
    "departme5": "[Ðéþåŕţɱéñţ----П國カ내]",
    "departme52": "[Ðéþåŕţɱéñţ Ñåɱé----П國カ내]",
    "departme59": "{departmentsCount, plural, one {[# Ðéþåŕţɱéñţ---П國カ내]} other {[# Ðéþåŕţɱéñţš---П國カ내]}}",
    "departme62": "[{departmentName} ðéþåŕţɱéñţ šûççéššƒûļļý åððéð·----------П國カ내]",
    "departme63": "[{departmentName} ðéþåŕţɱéñţ šûççéššƒûļļý ûþðåţéð·----------П國カ내]",
    "departme71": "[Ðéþåŕţɱéñţ Ñåɱé----П國カ내]",
    "departme73": "[Ðéþåŕţɱéñţ---П國カ내]",
    "departme77": "[Ðéþåŕţɱéñţ---П國カ내]",
    "departme78": "[Ðéþåŕţɱéñţš---П國カ내]",
    "departme80": "[Ðéþåŕţɱéñţ---П國カ내]",
    "departme85": "[Ðéþåŕţɱéñţ Çŕéåţéð----П國カ내]",
    "departme9": "[Ðéþåŕţɱéñţ---П國カ내]",
    "departme90": "[{departmentName} Ðéþåŕţɱéñţ------П國カ내]",
    "departme91": "[{departmentName} Ðéþåŕţɱéñţš------П國カ내]",
    "descript122": "[Ðéšçŕîþţîöñ Ŕéǫûîŕéɱéñţ-----П國カ내]",
    "descript141": "[Ðéšçŕîþţîöñ Ƒîéļð----П國カ내]",
    "descript32": "[Ðéšçŕîþţîöñ----П國カ내]",
    "descript55": "[Ðéšçŕîþţîöñ---П國カ내]",
    "descript7": "[Ðéšçŕîþţîöñ----П國カ내]",
    "descript97": "[Ðéšçŕîþţîöñ---П國カ내]",
    "deselect18": "[Ðéšéļéçţ Åļļ---П國カ내]",
    "details168": "[Ðéţåîļš--П國カ내]",
    "details169": "[Çåŕðĥöļðéŕ Ðéţåîļš----П國カ내]",
    "details56": "[Ðéţåîļš--П國カ내]",
    "detailsa153": "[Ðéţåîļš åñð îñšţŕûçţîöñš ŵîļļ ƀé ɱåîļéð ţö ţĥé çåŕð öŵñéŕ·------------П國カ내]",
    "detailsa154": "[Ðéţåîļš åñð îñšţŕûçţîöñš ŵîļļ ƀé ɱåîļéð ţö ţĥé çåŕðĥöļðéŕ·------------П國カ내]",
    "detailsf72": "[Ðéţåîļš ƒöŕ éẋþéñšéš þåîŕéð ŵîţĥ å ţŕåñšåçţîöñ çåññöţ ƀé çĥåñĝéð·--------------П國カ내]",
    "determin153": "[Ðéţéŕɱîñé ŵĥö šĥöûļð åþþŕöṽé îñçöɱîñĝ éẋþéñšé ŕéǫûéšţš ƒöŕ ţĥîš éẋþéñšé þöļîçý·----------------П國カ내]",
    "determin158": "[Ðéţéŕɱîñéš îƒ çåţéĝöŕý îš åṽåîļåƀļé ƒöŕ ûšéŕš åļļ ţĥŕöûĝĥöûţ ŢŕåṽéļƁåñķ· Çåţéĝöŕý ɱåý ƀé çûšţöɱîžéð ƒûŕţĥéŕ îñ éẋþéñšé þöļîçîéš·--------------------------П國カ내]",
    "disable5": "[Ðîšåƀļé---П國カ내]",
    "disabled1": "[Ðîšåƀļéð--П國カ내]",
    "disabled68": "[Ðîšåƀļéð--П國カ내]",
    "disablei3": "[Ðîšåƀļé Îñţéĝŕåţîöñ------П國カ내]",
    "disappro78": "[Ðîšåþþŕöṽé---П國カ내]",
    "discardu214": "[Ðîšçåŕð ûñšåṽéð çĥåñĝéš¿-----П國カ내]",
    "discount68": "[Ðîšçöûñţ Ñûɱƀéŕ∶ {discountNumber}-------П國カ내]",
    "discount80": "[Ðîšçöûñţ Ñûɱƀéŕ----П國カ내]",
    "discount83": "[Ðîšçöûñţ Ñûɱƀéŕ ----П國カ내]",
    "dismiss106": "[Ðîšɱîšš--П國カ내]",
    "dismiss18": "[Ðîšɱîšš---П國カ내]",
    "dismiss187": "[Ðîšɱîšš--П國カ내]",
    "dismiss69": "[Ðîšɱîšš--П國カ내]",
    "displaya117": "[Ðîšþļåý Åš---П國カ내]",
    "disputed131": "[Ðîšþûţéð Šţåţûš----П國カ내]",
    "disputed132": "[Ðîšþûţéð Šţåţûš Ðåţé-----П國カ내]",
    "distance100": "[Ðîšţåñçé Ðŕîṽéñ----П國カ내]",
    "distance101": "[Ðîšţåñçé Ûñîţ---П國カ내]",
    "distance102": "[Ðîšţåñçé Ƒŕöɱ Šéåŕçĥ Ļöçåţîöñ------П國カ내]",
    "distance154": "[Ðîšţåñçé Ƒŕöɱ Šéåŕçĥ Ļöçåţîöñ------П國カ내]",
    "distance41": "[Ðîšţåñçé Ðéţåîļš----П國カ내]",
    "distance8": "[Ðîšţåñçé Ðéţåîļš-----П國カ내]",
    "document161": "[Documentation: My Cards Transactions-----П國カ내]",
    "doemploy75": "[Ðö éɱþļöýééš ĥåṽé çöŕþöŕåţé çåŕðš¿-------П國カ내]",
    "done15": "[Ðöñé--П國カ내]",
    "done26": "[Ðöñé--П國カ내]",
    "dontsave159": "[Ðöñ´ţ Šåṽé---П國カ내]",
    "download1": "[Ðöŵñļöåð éẋþéñšé ŕéþöŕţ ÇŠṼ ƒîļéš šþéçîƒîç ţö ðåţéš åñð ðéþåŕţɱéñţš·--------------П國カ내]",
    "download198": "[Ðöŵñļöåð--П國カ내]",
    "download200": "[Ðöŵñļöåð Åļļ---П國カ내]",
    "download37": "[Ðöŵñļöåð Ŕéþöŕţ----П國カ내]",
    "doyouwan44": "[Ðö ýöû ŵåñţ ţö ûþðåţé ýöûŕ ƀåñķ åççöûñţ¿-------------П國カ내]",
    "doyouwan8": "[Ðö ýöû ŵåñţ ţö ûþðåţé ýöûŕ ƀåñķ åççöûñţ¿---------П國カ내]",
    "dragandd11": "[Ðŕåĝ åñð ðŕöþ ýöûŕ ŕéçéîþţš öŕ çļîçķ åñýŵĥéŕé ţö çĥööšé ûþ ţö ①⓪ ƒîļéš· Åççéþţéð ƒîļé ţýþéš îñçļûðé ·þðƒ، ·ĵþĝ، ·ĵþéĝ، ·þñĝ åñð ·ĝîƒ·----------------------------------------П國カ내]",
    "dragandd4": "[Ðŕåĝ åñð ðŕöþ öŕ <span class=\"expense-form__attachment-area__content__highlight\">çĥööšé å ƒîļé</span> ţö ûþļöåð ýöûŕ ŕéçéîþţ· Åççéþţéð ƒîļé ţýþéš îñçļûðé∶ ·þðƒ، ·ĵþĝ، ·ĵþéĝ، ·þñĝ، ·ĝîƒ-------------------------------------------------------------П國カ내]",
    "dropdown182": "[Ðŕöþðöŵñ Ṽåļûéš----П國カ내]",
    "dropdown208": "[Ðŕöþðöŵñ ṽåļûéš ļîšţ-----П國カ내]",
    "dropdown41": "[Ðŕöþðöŵñ ɱéñû---П國カ내]",
    "dropdown46": "[Ðŕöþðöŵñ--П國カ내]",
    "dropdown60": "[Ðŕöþðöŵñ--П國カ내]",
    "dropdown63": "[Ðŕöþðöŵñ--П國カ내]",
    "duedate40": "[Ðûé Ðåţé--П國カ내]",
    "duplicat40": "[Ðûþļîçåţé Ŕéçéîþţ----П國カ내]",
    "eachempl81": "[Éåçĥ éɱþļöýéé çåñ ƀé åššîĝñéð ţö å ɱåẋîɱûɱ öƒ ②⓪ ðéþåŕţɱéñţš·-------------П國カ내]",
    "economy138": "[Éçöñöɱý--П國カ내]",
    "economy55": "[Éçöñöɱý--П國カ내]",
    "edit111": "[Éðîţ-П國カ내]",
    "edit13": "[Éðîţ-П國カ내]",
    "edit133": "[Éðîţ-П國カ내]",
    "edit141": "[Éðîţ-П國カ내]",
    "edit143": "[Éðîţ-П國カ내]",
    "edit145": "[Éðîţ-П國カ내]",
    "edit147": "[Éðîţ-----П國カ내]",
    "edit148": "[Éðîţ-----П國カ내]",
    "edit154": "[Éðîţ-П國カ내]",
    "edit170": "[Éðîţ-П國カ내]",
    "edit178": "[Éðîţ-П國カ내]",
    "edit179": "[Éðîţ-П國カ내]",
    "edit2": "[Éðîţ-П國カ내]",
    "edit207": "[Éðîţ-П國カ내]",
    "edit25": "[Éðîţ--П國カ내]",
    "edit26": "[Éðîţ--П國カ내]",
    "edit7": "[Éðîţ--П國カ내]",
    "edit70": "[Éðîţ-П國カ내]",
    "edit94": "[Éðîţ-П國カ내]",
    "edit97": "[Éðîţ-П國カ내]",
    "editappr142": "[Éðîţ Åþþŕöṽåļ Ŕûļéš----П國カ내]",
    "editappr157": "[Éðîţ Åþþŕöṽåļ Ƒļöŵ----П國カ내]",
    "editappr83": "[Éðîţ Åþþŕöṽåļ Ƒļöŵ----П國カ내]",
    "editappr84": "[Éðîţ Åþþŕöṽåļ Ŕûļé----П國カ내]",
    "editcarr73": "[Éðîţ Çåŕ Ŕéñţåļ Çöðéš-----П國カ내]",
    "editcate144": "[Éðîţ Çåţéĝöŕý Ŕûļéš----П國カ내]",
    "editcode71": "[Éðîţ {vendorName} çöðéš-----П國カ내]",
    "editcust101": "[Éðîţ Çûšţöɱ Ƒîéļð----П國カ내]",
    "editcust56": "[Éðîţ Çûšţöɱ Ƒîéļð----П國カ내]",
    "editdele67": "[Éðîţ ðéļéĝåţé ĝŕöûþ----П國カ내]",
    "editdepa64": "[Éðîţ Ðéþåŕţɱéñţ----П國カ내]",
    "editempl146": "[Éðîţ Éɱþļöýééš îñ Þöļîçý-----П國カ내]",
    "editempl56": "[Éðîţ Éɱþļöýéé---П國カ내]",
    "editexpe3": "[Éðîţ Éẋþéñšé Ŕéþöŕţ----П國カ내]",
    "editexpe72": "[Éðîţ Éẋþéñšé Þöļîçý----П國カ내]",
    "editexpe93": "[Éðîţ Éẋþéñšé Þöļîçý----П國カ내]",
    "editgene112": "[Éðîţ Ĝéñéŕåļ Ŕûļéš----П國カ내]",
    "edititem180": "[Éðîţ {name} ţéẋţ ƒîéļð-----П國カ내]",
    "edititem181": "[Éðîţ {name} ðŕöþðöŵñ ƒîéļð------П國カ내]",
    "editprof1": "[Éðîţ Þŕöƒîļé Îñƒöŕɱåţîöñ--------П國カ내]",
    "editprov8": "[Éðîţ {provider}----П國カ내]",
    "editreim179": "[Éðîţ Ŕéîɱƀûŕšéɱéñţš----П國カ내]",
    "editrepo33": "[Éðîţ Ŕéþöŕţ---П國カ내]",
    "editrepo5": "[Éðîţ ŕéþöŕţ îñƒöŕɱåţîöñ-----П國カ내]",
    "editrowi87": "[Éðîţ Ŕöŵ {index}∶ {name}-----П國カ내]",
    "editstop7": "[Éðîţ Šţöþš----П國カ내]",
    "editthis67": "[Éðîţ {title} Éẋþéñšé Çåţéĝöŕý------П國カ내]",
    "edittitl1": "[Éðîţ {title}---П國カ내]",
    "edittrav8": "[Éðîţ Ţŕåṽéļéŕ Îñƒöŕɱåţîöñ------П國カ내]",
    "edityour2": "[Éðîţ ýöûŕ ŢŕåṽéļƁåñķ þŕöƒîļé·---------П國カ내]",
    "egbycate1": "[(é·ĝ· ƀý çåţéĝöŕý، åɱöûñţ، åþþŕöṽéŕ···)--------П國カ내]",
    "elan316": "[Éļåñ-П國カ내]",
    "elaneasy296": "[Éļåñ Éåšý Þåý---П國カ내]",
    "elaneasy298": "[Éļåñ Éåšý Þåý ⅋ Éļåñ Öñé Çåŕð------П國カ내]",
    "elanonec297": "[Éļåñ Öñé Çåŕð---П國カ내]",
    "elipsisc10": "[{ccType}···{ccLast4}-----П國カ내]",
    "ellipsis137": "[····{last4}---П國カ내]",
    "ellipsis158": "[···· {last4}---П國カ내]",
    "ellipsis202": "[····{last4}---П國カ내]",
    "ellipsis31": "[···{last4} ¦ {accountname}------П國カ내]",
    "ellipsis46": "[···{last4}---П國カ내]",
    "ellipsis57": "[···{last4}---П國カ내]",
    "ellipsis7": "[···⁺{hiddenfilters} ɱöŕé-----П國カ내]",
    "ellipsis70": "[··{last4}--П國カ내]",
    "ellipsis77": "[ ····{last4}---П國カ내]",
    "ellipsis9": "[···⁺{hiddenfiltercount} ɱöŕé------П國カ내]",
    "email17": "[Éɱåîļ--П國カ내]",
    "email172": "[Éɱåîļ--П國カ내]",
    "email190": "[Éɱåîļ--П國カ내]",
    "email3": "[Éɱåîļ--П國カ내]",
    "email33": "[Éɱåîļ--П國カ내]",
    "email4": "[Éɱåîļ--П國カ내]",
    "email44": "[Éɱåîļ--П國カ내]",
    "email8": "[Éɱåîļ--П國カ내]",
    "emailadd22": "[Éɱåîļ Åððŕéšš---П國カ내]",
    "emailcan4": "[Éɱåîļ çåñ´ţ ƀé ƀļåñķ·-------П國カ내]",
    "emailexp19": "[Éɱåîļ Éẋþöŕţ---П國カ내]",
    "emailisr35": "[Éɱåîļ îš ŕéǫûîŕéð·------П國カ내]",
    "emailkey1": "[Éɱåîļ--П國カ내]",
    "emailonl1": "[Éɱåîļ öñļý---П國カ내]",
    "emailplaceholder34": "[ñåɱé՞çöɱþåñý·çöɱ-----П國カ내]",
    "emailplaceholder9": "[ñåɱé՞çöɱþåñý·çöɱ-----П國カ내]",
    "emails13": "[Éɱåîļš--П國カ내]",
    "emailsdo40": "[Éɱåîļš ðö ñöţ ɱåţçĥ----П國カ내]",
    "emailsen25": "[Éɱåîļ Šéñţ---П國カ내]",
    "emailsmu11": "[Éɱåîļš ɱûšţ ɱåţçĥ----П國カ내]",
    "emailus31": "[Éɱåîļ Ûš---П國カ내]",
    "emailus32": "[Éɱåîļ Ûš---П國カ내]",
    "emailus33": "[Éɱåîļ Ûš---П國カ내]",
    "emailus34": "[Éɱåîļ Ûš---П國カ내]",
    "emailus35": "[Éɱåîļ Ûš---П國カ내]",
    "employe1": "[Éɱþļöýéé ŕéŵåŕðš⁏ îñçéñţîṽîžé šɱåŕţ šþéñðîñĝ---------П國カ내]",
    "employe2": "[Éɱþļöýéé ðéþåŕţɱéñţ----П國カ내]",
    "employee1": "[Éɱþļöýééš--П國カ내]",
    "employee102": "[Éɱþļöýéé ÎÐ---П國カ내]",
    "employee106": "[Éɱþļöýééš--П國カ내]",
    "employee118": "[{employee}´š Çŕéðîţ Çåŕð------П國カ내]",
    "employee121": "[Éɱþļöýéé ÎÐ---П國カ내]",
    "employee128": "[Éɱþļöýééš îñ Þöļîçý----П國カ내]",
    "employee141": "[Éɱþļöýééš îñ Þöļîçý----П國カ내]",
    "employee173": "[Éɱþļöýéé ÎÐ---П國カ내]",
    "employee183": "[Éɱþļöýééš ŵîļļ ṽîéŵ ţĥéšé öþţîöñš îñ åļþĥåñûɱéŕîçåļ öŕðéŕ·------------П國カ내]",
    "employee185": "[Éɱþļöýéé Îð---П國カ내]",
    "employee187": "[Éɱþļöýéé ÎÐ---П國カ내]",
    "employee195": "[Éɱþļöýéé--П國カ내]",
    "employee211": "[Éɱþļöýééš ŵîļļ ñöţ šéé ţĥîš åš åñ öþţîöñ ɱöṽîñĝ ƒöŕŵåŕð· Åñý éẋþéñšéš åššöçîåţéð ŵîţĥ îţ çûŕŕéñţļý ŵîļļ ñöţ ƀé åƒƒéçţéð·-------------------------П國カ내]",
    "employee62": "[⁎Éɱþļöýééš ŵîļļ ŕéçéîṽé ɱåñåĝéŕ åþþŕöṽåļ þéŕɱîššîöñš ŵĥéñ åððéð åš å ɱåñåĝéŕ ƒöŕ åñöţĥéŕ éɱþļöýéé·--------------------П國カ내]",
    "employee70": "[Éɱþļöýéé--П國カ내]",
    "employee72": "[Éɱþļöýééš--П國カ내]",
    "employee73": "[Éɱþļöýéé Çåŕð---П國カ내]",
    "employee76": "[Éɱþļöýéé--П國カ내]",
    "employee77": "[Éɱþļöýééš--П國カ내]",
    "employee78": "[Éɱþļöýééš îñ Þöļîçý----П國カ내]",
    "employee79": "[Éɱþļöýééš--П國カ내]",
    "employee80": "[Éɱþļöýéé ÎÐ---П國カ내]",
    "employee82": "[Éɱþļöýéé Ðîŕéçţöŕý----П國カ내]",
    "employee84": "[Éɱþļöýééš--П國カ내]",
    "employee86": "[Éɱþļöýéé Îñƒöŕɱåţîöñ Šåṽéð------П國カ내]",
    "employee87": "[Éɱþļöýéé Îñƒöŕɱåţîöñ Ûþðåţéð------П國カ내]",
    "employee88": "[Éɱþļöýéé Ĥåš Ɓééñ Ţéŕɱîñåţéð------П國カ내]",
    "employee89": "[Éɱþļöýéé Ŕéåçţîṽåţéð-----П國カ내]",
    "employeed1": "[Éɱþļöýéé Ðîŕéçţöŕý----П國カ내]",
    "empowere74": "[Éɱþöŵéŕ éɱþļöýééš ţö þåý ƒöŕ åîŕƒåŕé، šûþþļîéš، ƒûéļ، åñð éṽéŕýðåý éẋþéñšéš·<br aria-hidden=\"true\" />Çĥööšé ƒŕöɱ ṽîŕţûåļ åñð þĥýšîçåļ çåŕð öþţîöñš·------------------------------П國カ내]",
    "empty92": "[Éɱþţý--П國カ내]",
    "enablebi60": "[éñåƀļé ƀîļļåƀļé ţö çöšţûɱéŕ------П國カ내]",
    "enabled188": "[Éñåƀļéð--П國カ내]",
    "enabled191": "[Éñåƀļéð--П國カ내]",
    "enabled27": "[Éñåƀļéð--П國カ내]",
    "enabled278": "[Éñåƀļéð--П國カ내]",
    "enabled4": "[Éñåƀļéð--П國カ내]",
    "enabled5": "[Éñåƀļéð--П國カ내]",
    "enabled67": "[Éñåƀļéð--П國カ내]",
    "enabled87": "[Éñåƀļéð--П國カ내]",
    "enablepr15": "[Éñåƀļé Þŕéɱîûɱ Îñšîĝĥţš-------П國カ내]",
    "enablere186": "[Éñåƀļé Ŕéîɱƀûŕšéɱéñţš-----П國カ내]",
    "enablere188": "[Éñåƀļé Ŕéîɱƀûŕšéɱéñţ Ðîšɱîšš------П國カ내]",
    "enablere190": "[Éñåƀļé Ŕéîɱƀûŕšéɱéñţ Çĥåţ Ñöŵ------П國カ내]",
    "enablere76": "[Éñåƀļé ŕéçûŕŕîñĝ þåýɱéñţš ƒöŕ çöɱþåñý‐ŵîðé šûƀšçŕîþţîöñš åñð öţĥéŕ çĥåŕĝéš·<br aria-hidden=\"true\" />Åṽåîļåƀļé åš å ṽîŕţûåļ çåŕð öñļý·----------------------------П國カ내]",
    "enablero27": "[éñåƀļé ŕöûñð ţŕîþ----П國カ내]",
    "enableus1": "[Éñåƀļé ûšéŕš ţö ŕéðééɱ ŕéŵåŕðš ŵĥéñ ţĥéý çöɱé ûñðéŕ ƀûðĝéţ·------------П國カ내]",
    "enabling110": "[Éñåƀļîñĝ Ŕéîɱƀûŕšéɱéñţš-----П國カ내]",
    "enabling12": "[Éñåƀļîñĝ Ŕéîɱƀûŕšéɱéñţš-----П國カ내]",
    "endaddre10": "[Éñð Åððŕéšš----П國カ내]",
    "endaddre21": "[Éñð åððŕéšš----П國カ내]",
    "endaddre35": "[Éñð Åððŕéšš---П國カ내]",
    "enteramo106": "[Éñţéŕ Åɱöûñţ---П國カ내]",
    "enteramo118": "[Éñţéŕ Åɱöûñţ---П國カ내]",
    "enterane36": "[Éñţéŕ åñ éɱåîļ---П國カ내]",
    "entercod87": "[Éñţéŕ Çöðé---П國カ내]",
    "enterdet97": "[Éñţéŕ Ðéţåîļš---П國カ내]",
    "enterede38": "[Éñţéŕéð éɱåîļ îš åļŕéåðý ŕéĝîšţéŕéð--------П國カ내]",
    "enterema39": "[Éñţéŕ éɱåîļ çöñƒîŕɱåţîöñ-----П國カ내]",
    "enterpas43": "[Éñţéŕ þåššŵöŕð çöñƒîŕɱåţîöñ------П國カ내]",
    "enterval67": "[Éñţéŕ Ṽåļîð Åɱöûñţ ƀéţŵééñ ① åñð ①⓪⓪--------П國カ내]",
    "enterval74": "[Éñţéŕ Ṽåļîð Åɱöûñţ ƀéţŵééñ ① åñð ①⓪⓪--------П國カ내]",
    "error64": "[Éŕŕöŕ--П國カ내]",
    "error7": "[Éŕŕöŕ--П國カ내]",
    "errornot15": "[Éŕŕöŕ Ñöţîƒîçåţîöñš----П國カ내]",
    "errorupd75": "[Éŕŕöŕ ûþðåţîñĝ ýöûŕ þŕöƒîļé·---------П國カ내]",
    "exchange117": "[Éẋçĥåñĝé Ŕåţé---П國カ내]",
    "excludef94": "[Éẋçļûðé ƒŕöɱ Öŕĝåñîžåţîöñ Šýñç-------П國カ내]",
    "expdate11": "[Éẋþ∶ {date}---П國カ내]",
    "expense-reports": {
      "approvers": {
        "genericFinance": "[a Finance Manager-------П國カ내]",
        "genericManager": "[a Manager-------П國カ내]",
        "or": "[{userNames} or {last}-------П國カ내]"
      },
      "edit": {
        "deleteConfirm": {
          "cancel": "[No-------П國カ내]",
          "confirm": "[Yes-------П國カ내]",
          "message": "[Discarding this report will delete all associated expenses as well, do you want to continue?-------П國カ내]",
          "title": "[Please Confirm-------П國カ내]"
        },
        "messages": {
          "discardError": "[There was an error discarding your report.-------П國カ내]",
          "discardOk": "[{name} was discarded.-------П國カ내]"
        },
        "save": {
          "selectDepartment": "[Please select a department.-------П國カ내]"
        }
      },
      "single": {
        "buttons": {
          "markAsPaid": "[Mark as Paid-------П國カ내]",
          "reimburse": {
            "mobile": "[Reimburse-------П國カ내]",
            "tablet": "[Approve for Reimbursement-------П國カ내]"
          }
        }
      },
      "status": {
        "desktop": {
          "_paymentFailed": {
            "finance": "[There has been a bank error with this payment.-------П國カ내]",
            "manager": "[There has been a bank error with this payment.-------П國カ내]",
            "orgMember": "[Error with Payment. Please make sure your bank account information is correct.-------П國カ내]"
          },
          "_paymentPending": {
            "finance": "[Reimbursement scheduled for {date}.-------П國カ내]",
            "manager": "[Reimbursement scheduled for {date}.-------П國カ내]",
            "orgMember": "[Reimbursement scheduled for {date}.-------П國カ내]"
          },
          "_transactionProcessing": {
            "finance": "[Payment for this report is Processing.-------П國カ내]",
            "manager": "[Payment for this report is Processing.-------П國カ내]",
            "orgMember": "[Payment for this report is Processing.-------П國カ내]"
          },
          "active": {
            "finance": "[This Expense Report is still active.-------П國カ내]",
            "manager": "[This Expense Report is still active.-------П國カ내]",
            "orgMember": "[This Expense Report is active.-------П國カ내]",
            "selfService": "[This Expense Report is active.-------П國カ내]"
          },
          "approved": {
            "finance": "[This expense report is ready for your review.-------П國カ내]",
            "manager": "[Your expense report was submitted on {submittedDate} and is awaiting approval by {approverList}.-------П國カ내]",
            "orgMember": "[Your expense report was submitted on {submittedDate} and is awaiting approval by {approverList}.-------П國カ내]"
          },
          "bankError": {
            "finance": "[A reimbursement account has not been added for this user.-------П國カ내]",
            "manager": "[A reimbursement account has not been added for this user.-------П國カ내]",
            "orgMember": "[Please add a bank account to receive reimbursements.-------П國カ내]"
          },
          "new": {
            "orgMember": "[There was an issue submitting this report. Please try unsubmitting and resubmitting the report.-------П國カ내]"
          },
          "paid": {
            "finance": "[This expense report was paid on {date}.-------П國カ내]",
            "manager": "[This expense report was paid on {date}.-------П國カ내]",
            "orgMember": "[This expense report was paid on {date}.-------П國カ내]",
            "selfService": "[This expense report was paid on {date}.-------П國カ내]"
          },
          "returned": {
            "finance": "[This expense report was returned.-------П國カ내]",
            "manager": "[This expense report was returned.-------П國カ내]",
            "orgMember": "[This expense report was returned. Review and resubmit.-------П國カ내]"
          },
          "submitted": {
            "finance": "[Your expense report was submitted on {submittedDate} and is awaiting approval by {approverList}.-------П國カ내]",
            "manager": "[This expense report is ready for your review.-------П國カ내]",
            "orgMember": "[Your expense report was submitted on {submittedDate} and is awaiting approval by {approverList}.-------П國カ내]",
            "selfService": "[This expense report was completed on {submittedDate}.-------П國カ내]"
          }
        },
        "mobile": {
          "_paymentFailed": {
            "finance": "[Bank Error-------П國カ내]",
            "manager": "[Bank Error-------П國カ내]",
            "orgMember": "[Bank Error-------П國カ내]"
          },
          "_paymentPending": {
            "finance": "[Reimbursement scheduled for {date}-------П國カ내]",
            "manager": "[Reimbursement scheduled for {date}-------П國カ내]",
            "orgMember": "[Reimbursement scheduled for {date}-------П國カ내]"
          },
          "_transactionProcessing": {
            "finance": "[Payment Processing-------П國カ내]",
            "manager": "[Payment Processing-------П國カ내]",
            "orgMember": "[Payment Processing-------П國カ내]"
          },
          "active": {
            "finance": "[This Expense Report is still active.-------П國カ내]",
            "manager": "[This Expense Report is still active.-------П國カ내]",
            "orgMember": "[This Expense Report is active.-------П國カ내]",
            "selfService": "[This Expense Report is active.-------П國カ내]"
          },
          "approved": {
            "finance": "[Ready for review-------П國カ내]",
            "manager": "[Pending finance approval-------П國カ내]",
            "orgMember": "[Pending finance approval-------П國カ내]"
          },
          "bankError": {
            "finance": "[Bank Error-------П國カ내]",
            "manager": "[Bank Error-------П國カ내]",
            "orgMember": "[Bank Error-------П國カ내]"
          },
          "new": {
            "orgMember": "[Error submitting report. Please resubmit.-------П國カ내]"
          },
          "paid": {
            "finance": "[Paid on {date}.-------П國カ내]",
            "manager": "[Paid on {date}.-------П國カ내]",
            "orgMember": "[Paid on {date}.-------П國カ내]",
            "selfService": "[Paid on {date}.-------П國カ내]"
          },
          "returned": {
            "finance": "[Returned-------П國カ내]",
            "manager": "[Returned-------П國カ내]",
            "orgMember": "[Returned. Please review and resubmit.-------П國カ내]"
          },
          "submitted": {
            "finance": "[Pending manager approval.-------П國カ내]",
            "manager": "[Ready for review-------П國カ내]",
            "orgMember": "[Pending manager approval.-------П國カ내]",
            "selfService": "[Completed on {submittedDate}.-------П國カ내]"
          }
        },
        "paidNoDate": "[This expense report was paid.-------П國カ내]",
        "sharedWithFinance": "[Shared with Finance on {sharedDate}.-------П國カ내]"
      }
    },
    "expense2": "[Éẋþéñšé--П國カ내]",
    "expense30": "[Éẋþéñšé--П國カ내]",
    "expense38": "[éẋþéñšé--П國カ내]",
    "expense79": "[Éẋþéñšé--П國カ내]",
    "expensea101": "[Éẋþéñšé Åšîĝñéé----П國カ내]",
    "expensea133": "[Éẋþéñšé Åššîĝñéé Éɱåîļ-----П國カ내]",
    "expensea134": "[Éẋþéñšé Åļļöçåţîöñ----П國カ내]",
    "expensea157": "[Éẋþéñšé Åššîĝñéé(š) åš -----П國カ내]",
    "expensea17": "[Éẋþéñšé Åššîĝñéé----П國カ내]",
    "expensea186": "[Éẋþéñšé Åššîĝñéé ðŕöþðöŵñ------П國カ내]",
    "expensea193": "[Éẋþéñšé Åššîĝñéé----П國カ내]",
    "expensea200": "[Éẋþéñšé Åļļ çåţéĝöŕîéš ðŕöþðöŵñ ɱéñû--------П國カ내]",
    "expensea204": "[éẋþéñšé åɱöûñţ---П國カ내]",
    "expensea24": "[Éẋþéñšé Åššîĝñéé----П國カ내]",
    "expensea69": "[Éẋþéñšé åñð ţŕåñšåçţîöñ šûççéššƒûļļý ɱéŕĝéð·---------П國カ내]",
    "expenseaddsuccess90": "{length, plural, one {[Šûççéšš¡ Ŵé´ṽé åððéð # éẋþéñšé·---П國カ내]} other {[Šûççéšš¡ Ŵé´ṽé åððéð # éẋþéñšéš·---П國カ내]}}",
    "expensec1": "[Éẋþéñšé Çåţéĝöŕîéš----П國カ내]",
    "expensec109": "[Éẋþéñšé Çåţéĝöŕý----П國カ내]",
    "expensec149": "[Éẋþéñšé Çåţéĝöŕîéš----П國カ내]",
    "expensec155": "[Éẋþéñšé Çåţéĝöŕîéš åš -----П國カ내]",
    "expensec175": "[Éẋþéñšé Çåţéĝöŕý----П國カ내]",
    "expensec176": "[Éẋþéñšé Çåţéĝöŕý (öþţîöñåļ) ðŕöþðöŵñ--------П國カ내]",
    "expensec191": "[Éẋþéñšé Çåţéĝöŕý ðŕöþðöŵñ------П國カ내]",
    "expensec199": "[Éẋþéñšé Çåţéĝöŕîéš----П國カ내]",
    "expensec2": "[Éẋþéñšé çûšţöɱ ƒîéļðš-----П國カ내]",
    "expensec35": "[Éẋþéñšé Çåţéĝöŕý----П國カ내]",
    "expensec41": "[Éẋþéñšé Çåţéĝöŕý----П國カ내]",
    "expensed3": "[Éẋþéñšé Ðéţåîļš----П國カ내]",
    "expensed6": "[Éẋþéñšé Ðéţåîļš-----П國カ내]",
    "expensed87": "[Éẋþéñšé Ðéţåîļš----П國カ내]",
    "expensee1": "[Éẋþéñšé Éẋþöŕţ---П國カ내]",
    "expensee75": "[Éẋþéñšé Éẋþöŕţ---П國カ내]",
    "expensee93": "[Éẋþéñšé Éẋþöŕţ Ƒåîļéð-----П國カ내]",
    "expensei96": "[Éẋþéñšé Îţéɱîžåţîöñ----П國カ내]",
    "expensem1": "[Éẋþéñšé Ṁåñåĝéɱéñţ------П國カ내]",
    "expensem12": "[Éẋþéñšé Ṁåñåĝéɱéñţ------П國カ내]",
    "expensem46": "[Expense Management ({provider})----------П國カ내]",
    "expensem96": "[Éẋþéñšé Ṁåñåĝéɱéñţ----П國カ내]",
    "expensep1": "[Éẋþéñšé Þöļîçý---П國カ내]",
    "expensep106": "[Éẋþéñšé Þöļîçîéš----П國カ내]",
    "expensep122": "[Éẋþéñšé Þöļîçý---П國カ내]",
    "expensep134": "[Éẋþéñšé Þöļîçý---П國カ내]",
    "expensep63": "[Éẋþéñšé Þöļîçý---П國カ내]",
    "expensep65": "[Éẋþéñšé Þöļîçý Ɓåšîçš-----П國カ내]",
    "expensep74": "[Éẋþéñšé Þöļîçý---П國カ내]",
    "expensep86": "[Éẋþéñšé Þöļîçý---П國カ내]",
    "expensep90": "[Éẋþéñšé Þöļîçîéš----П國カ내]",
    "expensep92": "[Éẋþéñšé Þöļîçîéš----П國カ내]",
    "expenser1": "[Éẋþéñšé ŕéþöŕţ åþþŕöṽåļ ŵöŕķƒļöŵ-------П國カ내]",
    "expenser107": "[Éẋþéñšé Ŕéþöŕţ---П國カ내]",
    "expenser108": "[Éẋþéñšé Ŕéþöŕţ ÎÐ----П國カ내]",
    "expenser116": "[Éẋþéñšé Ŕéþöŕţ Šûƀɱîţţéð-----П國カ내]",
    "expenser139": "[Éẋþéñšé Ŕéþöŕţ ÎÐ----П國カ내]",
    "expenser145": "[Éẋþéñšé Ŕéþöŕţ Éẋþöŕţ-----П國カ내]",
    "expenser15": "[Éẋþéñšé Ŕéþöŕţš | Ṁåñåĝé----П國カ내]",
    "expenser192": "[Éẋþéñšé Ŕéþöŕţ Šţåţûš ðŕöþðöŵñ-------П國カ내]",
    "expenser2": "[Éẋþéñšé Ŕéþöŕţš----П國カ내]",
    "expenser21": "[Éẋþéñšé Ŕéþöŕţ---П國カ내]",
    "expenser3": "[Éẋþéñšé Ŕéþöŕţ-----П國カ내]",
    "expenser37": "[Éẋþéñšé Ŕéþöŕţ Šţåţûš-----П國カ내]",
    "expenser43": "[Éẋþéñšé Ŕéþöŕţ Šţåţûš-----П國カ내]",
    "expenser5": "[Éẋþéñšé Ŕéþöŕţš----П國カ내]",
    "expenser50": "[Éẋþéñšé Ŕéþöŕţ Åþþŕöṽéð-----П國カ내]",
    "expenser53": "[Éẋþéñšé Ŕéþöŕţš----П國カ내]",
    "expenser65": "[Éẋþéñšé Ŕéþöŕţš----П國カ내]",
    "expenser86": "[Éẋþéñšé Ŕéþöŕţîñĝ----П國カ내]",
    "expenser94": "[Éẋþéñšé Ŕéþöŕţš Ðéţåîļš-------П國カ내]",
    "expenses1": "[Éẋþéñšéš--П國カ내]",
    "expenses10": "[Éẋþéñšéš åñð ţŕåñšåçţîöñš ţĥåţ ĥåṽé ƀééñ þŕéṽîöûšļý ɱéŕĝéð çåññöţ ƀé ɱéŕĝéð ŵîţĥ åñöţĥéŕ îţéɱ·-----------------------------П國カ내]",
    "expenses105": "[Éẋþéñšéš--П國カ내]",
    "expenses109": "[Éẋþéñšéš Åŕé Ðîšåƀļéð Ƒöŕ Ýöûŕ Çöɱþåñý--------П國カ내]",
    "expenses120": "[Éẋþéñšéš ⅋ Ţŕåñšåçţîöñš Åššîĝñéð ţö Ŕéþöŕţ---------П國カ내]",
    "expenses142": "[Éẋþéñšéš--П國カ내]",
    "expenses148": "[Éẋþéñšéš--П國カ내]",
    "expenses16": "[Éẋþéñšéš ⅋ Ţŕåñšåçţîöñš-------П國カ내]",
    "expenses172": "[Éẋþéñšéš--П國カ내]",
    "expenses19": "[Éẋþéñšéš--П國カ내]",
    "expenses24": "[Éẋþéñšé šûççéššƒûļļý ûþðåţéð------П國カ내]",
    "expenses25": "[Éẋþéñšé šûççéššƒûļļý ðîšçåŕðéð·-------П國カ내]",
    "expenses28": "[Éẋþéñšéš ⅋ Ţŕåñšåçţîöñš-----П國カ내]",
    "expenses3": "[Éẋþéñšéš ⅋ Ţŕåñšåçţîöñš-------П國カ내]",
    "expenses39": "[Éẋþéñšéš ⅋ Ţŕåñšåçţîöñš-----П國カ내]",
    "expenses43": "{count, plural, one {[① Éẋþéñšé öŕ Ţŕåñšåçţîöñ Îţéɱ Šéļéçţéð----------П國カ내]} other {[# Éẋþéñšé öŕ Ţŕåñšåçţîöñ Îţéɱš Šéļéçţéð----------П國カ내]}}",
    "expenses47": "[Éẋþéñšéš ⅋ Ţŕåñšåçţîöñš-----П國カ내]",
    "expenses79": "[Éẋþéñšéš åñð ţŕåñšåçţîöñš ţĥåţ ĥåṽé ƀééñ þŕéṽîöûšļý ɱéŕĝéð çåññöţ ƀé ɱéŕĝéð ŵîţĥ åñöţĥéŕ îţéɱ·-------------------П國カ내]",
    "expenses89": "[Éẋþéñšéš--П國カ내]",
    "expenses91": "[Éẋþéñšéš--П國カ내]",
    "expensesmessage23": "{createdCount, plural, one {[{createdCount} Éẋþéñšé çŕéåţéð ƀûţ ŵé çöûļðñ´ţ çöɱþļéţé ţĥé {failedCount} éẋþéñšé ðéţåîļš·------------------П國カ내]} other {[{createdCount} Éẋþéñšéš çŕéåţéð ƀûţ ŵé çöûļðñ´ţ çöɱþļéţé ţĥé {failedCount} éẋþéñšé ðéţåîļš·------------------П國カ내]}}",
    "expenset3": "[Éẋþéñšé ⅋ Ţŕåñšåçţîöñ Ðéţåîļš Ðöñ’ţ Ṁåţçĥ-------------П國カ내]",
    "expenset83": "[ Éẋþéñšé ⅋ Ţŕåñšåçţîöñ-----П國カ내]",
    "expensif14": "[Éẋþéñšîƒý---П國カ내]",
    "expensif9": "[Éẋþéñšîƒý Ŕéçéîþţš------П國カ내]",
    "expirati211": "[Éẋþîŕåţîöñ Ðåţé----П國カ내]",
    "expirati9": "[Éẋþîŕåţîöñ Ðåţé-----П國カ내]",
    "expiry37": "[Éẋþîŕý <span>{expirydate}</span>-------П國カ내]",
    "expiryda141": "[Éẋþîŕý ðåţé----П國カ내]",
    "expiryda147": "[Éẋþîŕý ðåţé---П國カ내]",
    "expiryda180": "[Éẋþîŕý Ðåţé---П國カ내]",
    "expiryva16": "[Éẋþîŕý⁄Ṽåļîðîţý----П國カ내]",
    "export162": "[Éẋþöŕţ--П國カ내]",
    "export5": "[Éẋþöŕţ--П國カ내]",
    "exportal159": "[Éẋþöŕţ Åļļöçåţîöñš----П國カ내]",
    "exportal43": "[Éẋþöŕţ Åļļöçåţîöñš----П國カ내]",
    "exportco116": "[Éẋþöŕţ Çöļûɱñš---П國カ내]",
    "exportco161": "[Éẋþöŕţ Çöļûɱñš---П國カ내]",
    "exportco25": "[Éẋþöŕţ Çöļûɱñš---П國カ내]",
    "exportco81": "[Éẋþöŕţ Çöļûɱñš---П國カ내]",
    "exportcs119": "[Éẋþöŕţ ÇŠṼ---П國カ내]",
    "exportde28": "[Éẋþöŕţ Ðéţåîļš---П國カ내]",
    "exportfr31": "[Éẋþöŕţ Ƒŕéǫûéñçý----П國カ내]",
    "exportli157": "[Éẋþöŕţ Ļîñé Îţéɱš----П國カ내]",
    "exportli38": "[Éẋþöŕţ Ļîñé Îţéɱš----П國カ내]",
    "exportra143": "[Éẋþöŕţ Ŕåñĝé---П國カ내]",
    "exportse163": "[Éẋþöŕţ Šéñţ ţö Éɱåîļ-----П國カ내]",
    "exportsu118": "[Éẋþöŕţ Šûɱɱåŕý---П國カ내]",
    "exporttr108": "[Éẋþöŕţ Ţŕåñšåçţîöñš ¦ Ṁåñåĝé------П國カ내]",
    "exporttr163": "[Éẋþöŕţ Ţŕåñšåçţîöñš----П國カ내]",
    "external159": "[Éẋţéŕñåļ Åççöûñţ ÎÐ (Öþţîöñåļ)-------П國カ내]",
    "external163": "[Éẋţéŕñåļ Çåţéĝöŕý ÎÐ-----П國カ내]",
    "external164": "[Éẋţéŕñåļ ÎÐ---П國カ내]",
    "external176": "[Éẋţéŕñåļ Åççöûñţ ÎÐ (Öþţîöñåļ)-------П國カ내]",
    "external178": "[Éẋţéŕñåļ Çåţéĝöŕý ÎÐ-----П國カ내]",
    "external184": "[Éẋţéŕñåļ ÎÐ---П國カ내]",
    "external65": "[Éẋţéŕñåļ ÎÐ---П國カ내]",
    "external72": "[Éẋţéŕñåļ ÎÐ---П國カ내]",
    "failedt41": "[Ƒåîļéð ţö ðöŵñļöåð· Þļéåšé ţŕý åĝåîñ·--------П國カ내]",
    "failedto148": "[Ƒåîļéð ţö éẋþöŕţ· Þļéåšé ţŕý åĝåîñ·--------П國カ내]",
    "failedto15": "[Ƒåîļéð ţö ðöŵñļöåð· Þļéåšé ţŕý åĝåîñ·--------П國カ내]",
    "failedto162": "[Ƒåîļéð ţö ĝéñéŕåţé éẋþéñšé ŕéþöŕţ-------П國カ내]",
    "failedto196": "[Ƒåîļéð ţö ðöŵñļöåð· Þļéåšé ţŕý åĝåîñ·--------П國カ내]",
    "failedto210": "[Ƒåîļéð ţö Åðð⁄Éðîţ çûšţöɱ ƒîéļð-------П國カ내]",
    "failedto339": "[Ƒåîļéð ţö ŕéåššîĝñ çŕéðîţ çåŕð·-------П國カ내]",
    "failedto71": "[Ƒåîļéð ţö šåṽé éẋþéñšé çåţéĝöŕý-------П國カ내]",
    "failedto74": "[Ƒåîļéð ţö šåṽé éẋþéñšé çåţéĝöŕý-------П國カ내]",
    "failedto91": "[Ƒåîļéð ţö ĝéñéŕåţé éẋþéñšé ŕéþöŕţ-------П國カ내]",
    "fareclas120": "[Ƒåŕé çļåšš ðŕöþðöŵñ∶ {settingFareClass} selected - -----------П國カ내]",
    "fareclas136": "[Ƒåŕé Çļåšš---П國カ내]",
    "fareclas95": "[Ƒåŕé Çļåšš---П國カ내]",
    "female19": "[Ƒéɱåļé--П國カ내]",
    "fieldmod195": "[{title} ‐ Ŕûļéš ‐ Éñåƀļéð------П國カ내]",
    "fieldmod198": "[{title} ‐ Ŕûļéš ‐ Çåţéĝöŕý Šþéçîƒîç--------П國カ내]",
    "fieldmod205": "[{title} ‐ Ŕûļéš ‐ Ŕéǫûîŕéð------П國カ내]",
    "fieldnam175": "[Ƒîéļð ñåɱé çåññöţ ƀé çĥåñĝéð åƒţéŕ çŕéåţîöñ·---------П國カ내]",
    "fieldnam178": "[Ƒîéļð ñåɱé çåññöţ ƀé çĥåñĝéð åƒţéŕ çŕéåţîöñ·---------П國カ내]",
    "fieldnam185": "[Ƒîéļð Ñåɱé---П國カ내]",
    "fieldnam187": "[Ƒîéļð Ñåɱé---П國カ내]",
    "fieldnam188": "[Ƒîéļð ñåɱé ‐ ţĥîš çåññöţ ƀé çĥåñĝéð åƒţéŕ çŕéåţîöñ·-----------П國カ내]",
    "fieldtyp186": "[Ƒîéļð Ţýþé---П國カ내]",
    "fieldtyp187": "[Ƒîéļð Ţýþé Ðŕöþðöŵñ----П國カ내]",
    "fieldtyp189": "[Ƒîéļð Ţýþé---П國カ내]",
    "fieldtyp190": "[Ƒîéļð Ţýþé Ðŕöþðöŵñ----П國カ내]",
    "fieldtyp96": "[Ƒîéļð Ţýþé---П國カ내]",
    "fifteenm156": "[①⑤ Ṁîļéš--П國カ내]",
    "fileproc125": "[Ƒîļé þŕöçéššîñĝ· Ýöû ŵîļļ ŕéçéîṽé åñ éɱåîļ ŵĥéñ ţĥé ûþļöåð îš çöɱþļéţé·---------------П國カ내]",
    "filetype18": "[Ƒîļé Ţýþé--П國カ내]",
    "filetype53": "[Ƒîļé ţýþéš öƒ {invalidFileType} åŕé ñöţ šûþþöŕţéð åţ ţĥîš ţîɱé·-------------------П國カ내]",
    "filter17": "[Ƒîļţéŕ--П國カ내]",
    "filter3": "[Ƒîļţéŕ--П國カ내]",
    "filter35": "[Ƒîļţéŕ--П國カ내]",
    "filter4": "[Ƒîļţéŕ--П國カ내]",
    "filter99": "[Ƒîļţéŕ--П國カ내]",
    "filters109": "[Ƒîļţéŕš--П國カ내]",
    "filtersl164": "[...+{filterlength} ɱöŕé ƒîļţéŕ(š)----------П國カ내]",
    "finalamo2": "[Ƒîñåļ Åɱöûñţ---П國カ내]",
    "finalamo91": "[Ƒîñåļ Åɱöûñţ---П國カ내]",
    "finance57": "[Ƒîñåñçé--П國カ내]",
    "finance60": "[Ƒîñåñçé--П國カ내]",
    "finance61": "[Ƒîñåñçé--П國カ내]",
    "financea90": "[Ƒîñåñçé Åþþŕöṽéŕ ƒöŕ Éñţîŕé Öŕĝåñîžåţîöñ---------П國カ내]",
    "financea91": "[Ƒîñåñçé Åþþŕöṽéŕ Ðéþåŕţɱéñţš------П國カ내]",
    "financee61": "[Ƒîñåñçé Éɱåîļ---П國カ내]",
    "financew61": "[Ƒîñåñçé ŵîļļ ƀé šéñţ éẋþéñšé ŕéþöŕţš åƒţéŕ ţĥéý ĥåṽé ƀééñ åþþŕöṽéð ƀý ɱåñåĝéŕš· Ƒîñåñçé åþþŕöṽåļ ŵîļļ åûţĥöŕîžé ŕéîɱƀûŕšéɱéñţ·--------------------------П國カ내]",
    "findanot71": "[Ƒîñð Åñöţĥéŕ---П國カ내]",
    "finish138": "[Ƒîñîšĥ--П國カ내]",
    "finish39": "[Ƒîñîšĥ--П國カ내]",
    "finish84": "[Ƒîñîšĥ--П國カ내]",
    "finishem148": "[Ƒîñîšĥ Éɱþļöýééš îñ Þöļîçý------П國カ내]",
    "finishla1": "[Ƒîñîšĥ Ļåţéŕ---П國カ내]",
    "first154": "[Ƒîŕšţ--П國カ내]",
    "first155": "[Ƒîŕšţ--П國カ내]",
    "first57": "[Ƒîŕšţ--П國カ내]",
    "firstcfv35": "[Ƒîŕšţ ÇƑ Ṽåļûéš {customFields}----------П國カ내]",
    "firstnam14": "[Ƒîŕšţ Ñåɱé----П國カ내]",
    "firstnam25": "[Ƒîŕšţ Ñåɱé----П國カ내]",
    "firstnam26": "[Ƒîŕšţ Ñåɱé---П國カ내]",
    "firstnam29": "[Ƒîŕšţ ñåɱé ɱûšţ ƀé åţ ļéåšţ ţŵö çĥåŕåçţéŕš·-------------П國カ내]",
    "firstnam30": "[Ƒîŕšţ ñåɱé îš ŕéǫûîŕéð·-------П國カ내]",
    "firstnam4": "[Ƒîŕšţ Ñåɱé----П國カ내]",
    "firstnam76": "[Ƒîŕšţ Ñåɱé---П國カ내]",
    "firstnameplaceholder5": "[Ĵöĥñ--П國カ내]",
    "firsttim120": "[Ƒîŕšţţîɱé¿ Ðöŵñļöåð ţĥé <a class=\"link\"href=\"{url}\">ÇŠṼ ţéɱþļåţé</a>·----------------П國カ내]",
    "firsttim75": "[Ƒîŕšţ‐ţîɱé ÇŠṼ ûšéŕ¿ Ðöŵñļöåð ţĥé <a style=\"text-decoration: underline;\" class=\"link\" href=\"{url}assets/files/travelbank_company_import_template.csv\">ŕéåðý‐ţö‐ûšé ÇŠṼ ţéɱþļåţé</a> ţö éñšûŕé å šɱööţĥ šţåŕţ·-------------------------------------------П國カ내]",
    "flight57": "[Ƒļîĝĥţ--П國カ내]",
    "flightco101": "[Ƒļîĝĥţ Çöšţ Ĥåŕð Çåþ-----П國カ내]",
    "flightcr1": "[Ƒļîĝĥţ Çŕéðîţš-----П國カ내]",
    "flightcr20": "[Ƒļîĝĥţ Çŕéðîţ----П國カ내]",
    "flightli5": "[Ƒļîĝĥţ Ļîšţ----П國カ내]",
    "flightnu89": "[Ƒļîĝĥţ Ñûɱƀéŕš---П國カ내]",
    "flightpo134": "[Ƒļîĝĥţ Þöļîçý---П國カ내]",
    "flightpo76": "[Ƒļîĝĥţ Þöļîçý---П國カ내]",
    "flightpo92": "[Ƒļîĝĥţ Þöļîçý---П國カ내]",
    "flights3": "[Ƒļîĝĥţš---П國カ내]",
    "foramile12": "[Ƒöŕ å <span class=\"emphasis-text\">Ṁîļéåĝé öŕ Ŕéþéåţ Éẋþéñšé،</span> þļéåšé ûšé ţĥé ɱéñû îţéɱš ƀéļöŵ·-------------------------------П國カ내]",
    "foremplo1": "[Ƒöŕ éɱþļöýééš ŵĥö ţŕåṽéļ öƒţéñ åñð ñééð åççéšš ţö ţŕåṽéļ ƒéåţûŕéš·--------------П國カ내]",
    "foremplo2": "[Ƒöŕ éɱþļöýééš ŵĥö ðöñ´ţ ţŕåṽéļ öƒţéñ åñð ñééð åççéšš ţö éẋþéñšé ƒéåţûŕéš·---------------П國カ내]",
    "forfurth104": "[Ƒöŕ ƒûŕţĥéŕ çöñţŕöļ، ýöû çåñ ŕéšţŕîçţ ðéļéĝåţé åççéšš ţö çéŕţåîñ ţåšķš ƀéļöŵ ƀý ţûŕñîñĝ ţĥé þéŕɱîššîöñ öƒƒ·----------------------П國カ내]",
    "forgotpa14": "[Ƒöŕĝöţ Þåššŵöŕð¿----П國カ내]",
    "form40": "[Ƒöŕɱ-П國カ내]",
    "formatam58": "[{formatamount} ÛŠÐ----П國カ내]",
    "formatam72": "[{formatamount} ¦ {fxamount}-------П國カ내]",
    "formatam73": "[{formatamount} öṽéŕ {length} Šþļîţš ¦ {fxamount}----------П國カ내]",
    "formatam74": "[{formatamount} öṽéŕ {length} Šþļîţš--------П國カ내]",
    "formatam75": "[{formatamount} ¦ {fxamount}-------П國カ내]",
    "formatra1": "[{total} ⁄ɱöñţĥ ƀîļļéð åññûåļļý-------П國カ내]",
    "formatra2": "[{total} ⁄ɱöñţĥ ƀîļļéð ɱöñţĥļý-------П國カ내]",
    "forsecuritypurposes": "[Ƒöŕ šéçûŕîţý þûŕþöšéš، ţĥîš îš ýöûŕ ûñîǫûé ÅÞÎ Ţöķéñ، åñð îţ ŵîļļ öñļý ƀé ðîšþļåýéð öñçé· Îƒ ýöû ļöšé öŕ ƒöŕĝéţ ţĥé çöðé، þļéåšé çļîçķ ţĥé ″Ŕéšéţ ÅÞÎ Ţöķéñ″ ƀûţţöñ ţö ŕéţŕîéṽé å ñéŵ ÅÞÎ Ţöķéñ· Ŵĥéñ ýöû šéļéçţ ţĥé ″Ŕéšéţ ÅÞÎ Ţöķéñ″ ƀûţţöñ، ţĥé þŕéṽîöûš ÅÞÎ Ţöķéñ ŵîļļ ƀé ðéåçţîṽåţéð·----------------------------------------------------------П國カ내]",
    "forstate202": "[Ƒöŕ šţåţéɱéñţš öļðéŕ ţĥåñ ②④ ɱöñţĥš، þļéåšé ŕéåçĥ öûţ ţö çûšţöɱéŕ šûþþöŕţ·---------------П國カ내]",
    "fourteen144": "[①④ Ðåýš--П國カ내]",
    "freeplan1": "[Ƒŕéé Þļåñ--П國カ내]",
    "frequenc29": "[Ƒŕéǫûéñçý--П國カ내]",
    "frequent117": "[Ƒŕéǫûéñţļý Åšķéð Ǫûéšţîöñš------П國カ내]",
    "friday14": "[Ƒŕîðåý--П國カ내]",
    "friendor18": "[Ƒŕîéñð öŕ Çöļļéåĝûé----П國カ내]",
    "futuretr271": "[Ƒûţûŕé ţŕåñšåçţîöñš åñð éẋîšţîñĝ ûñ‐šûƀɱîţţéð ţŕåñšåçţîöñš ∖∖ ŵîļļ ƀé ɱåñåĝéð ƀý ţĥé ñéŵ åššîĝñéé{newuserfirstname}·------------------------П國カ내]",
    "fxamount116": "[ƑẊ Åɱöûñţ--П國カ내]",
    "fxinform123": "[Ƒẋ Îñƒöŕɱåţîöñ---П國カ내]",
    "gender17": "[Ĝéñðéŕ--П國カ내]",
    "generale41": "[Ĝéñéŕåļ Éẋþéñšéš----П國カ내]",
    "generale49": "[Ĝéñéŕåļ Éẋþéñšéš-----П國カ내]",
    "generall122": "[Ĝéñéŕåļ Ļéðĝéŕ Îñţéĝŕåţîöñ------П國カ내]",
    "generalr115": "[Ĝéñéŕåļ Ŕûļéš Ŕéçéîþţ Ŕéǫûîŕéɱéñţ ţéẋţ ƀöẋ---------П國カ내]",
    "generalr118": "[Ĝéñéŕåļ Ŕûļéš Ṁîļéåĝé Ŕéîɱƀûŕšéɱéñţ Ŕåţé ţéẋţ ƀöẋ----------П國カ내]",
    "generalr124": "[Ĝéñéŕåļ Ŕûļéš Ðéšçŕîþţîöñ Ŕéǫûîŕéɱéñţ--------П國カ내]",
    "generalr125": "[Ĝéñéŕåļ Ŕûļéš Çöŕþöŕåţé Çåŕð------П國カ내]",
    "generalr138": "[Ĝéñéŕåļ Ŕûļéš Šþļîţ Ðéþåŕţɱéñţ Åççéšš--------П國カ내]",
    "generalr81": "[Ĝéñéŕåļ Ŕéþöŕţ {today}-----П國カ내]",
    "generalr90": "[Ĝéñéŕåļ Ŕûļéš---П國カ내]",
    "generate34": "[Ĝéñéŕåţé ÞÐƑ---П國カ내]",
    "generate40": "[Ĝéñéŕåţîñĝ ÞÐƑ، ñåṽîĝåţîñĝ åŵåý ŵîļļ çåñçéļ ýöûŕ ðöŵñļöåð·------------П國カ내]",
    "generati42": "[Ĝéñéŕåţîñĝ ÞÐƑ---П國カ내]",
    "getstart1": "[Ĝéţ Šţåŕţéð---П國カ내]",
    "getstart195": "[Ĝéţ šţåŕţéð ŵîţĥöûţ å þöļîçý åñð éåšîļý ɱåķé çĥåñĝéš ļåţéŕ îƒ ýöû çĥåñĝé ýöûŕ ɱîñð·-----------------П國カ내]",
    "goback10": "[Ĝö Ɓåçķ--П國カ내]",
    "goback49": "[Ĝö ƀåçķ--П國カ내]",
    "gotit5": "[Ĝöţ Îţ--П國カ내]",
    "gotobank95": "[Ĝö ţö Ɓåñķ Åççöûñţš------П國カ내]",
    "gotoexpe35": "[Ĝö Ţö Éẋþéñšéš---П國カ내]",
    "gotoreim97": "[Ĝö ţö Ŕéîɱƀûŕšéɱéñţš-------П國カ내]",
    "gotorepo117": "[Ĝö Ţö Ŕéþöŕţ---П國カ내]",
    "gotorepo36": "[Ĝö Ţö Ŕéþöŕţš---П國カ내]",
    "greatert338": "[Ĝŕéåţéŕ ţĥåñ∶ €----П國カ내]",
    "greatert58": "[Ĝŕéåţéŕ ţĥåñ∶ €----П國カ내]",
    "hardcapp105": "[Ĥåŕð Çåþ Þŕîçé---П國カ내]",
    "hardcapp114": "[Ĥåŕð Çåþ Þŕîçé---П國カ내]",
    "hasrecei25": "[Ĥåš Ŕéçéîþţ---П國カ내]",
    "hasrecei342": "[Ĥåš Ŕéçéîþţ---П國カ내]",
    "havingtr6": "[Ĥåṽîñĝ ţŕöûƀļé åððîñĝ ýöûŕ åççöûñţ¿-----------П國カ내]",
    "heckboxf104": "[çĥéçķƀöẋ ƒöŕ Ƒļîĝĥţ Çöšţ Ĥåŕð Çåþ-------П國カ내]",
    "helpcent90": "[Ĥéļþ Çéñţéŕ åŕţîçļé----П國カ내]",
    "hidesplits86": "[Ĥîðé Šþļîţš---П國カ내]",
    "hifirstn73": "[Ĥî، {firstname}¡----П國カ내]",
    "hiimtryi180": "[Ĥî، Î´ɱ ţŕýîñĝ ţö öñƀöåŕð åš å ƒļåšĥ ûšéŕ، åñð ĥåṽé ƀééñ ɱéţ ŵîţĥ åñ éŕŕöŕ þåĝé----------------П國カ내]",
    "hiitried27": "[Ĥî، Î ţŕîéð åððîñĝ ɱý çŕéðîţ çåŕð åñð ĝöţ ðéçļîñéð----------------П國カ내]",
    "hiname1": "[Ĥî، {first} {last}------П國カ내]",
    "hitherei36": "[Ĥî ţĥéŕé، Î ĥåṽé ƀééñ ûšîñĝ ŢŕåṽéļƁåñķ åñð ŵöûļð ļîķé ţö ļéåŕñ ɱöŕé åƀöûţ ûšîñĝ Åñåļýţîçš ţö ţŕåçķ ɱý ţéåɱ´š šþéñð îñ ŕéåļ‐ţîɱé·---------------------------------------П國カ내]",
    "holdtigh20": "[Ĥöļð ţîĝĥţ¡ Ŵé’ŕé šçåññîñĝ ýöûŕ ŕéçéîþţš···-------------П國カ내]",
    "host45": "[Ĥöšţ-П國カ내]",
    "hostaree96": "[Ĥöšţ åŕé éɱþļöýééš ŵĥö ĥåṽé ðéļéĝåţéš þéŕƒöŕɱ ţåšķš öñ ţĥéîŕ ƀéĥåļƒ--------------П國カ내]",
    "hosts45": "[Ĥöšţš--П國カ내]",
    "hosts86": "[Ĥöšţš--П國カ내]",
    "hostsand70": "[Ĥöšţš åñð ţĥéîŕ åššîĝñéð ðéļéĝåţéš ŵîļļ åþþéåŕ ĥéŕé ŵĥéñ åððéð·-------------П國カ내]",
    "hostsare128": "[Ĥöšţš åŕé éɱþļöýééš ŵĥö ĥåṽé ðéļéĝåţéš þéŕƒöŕɱ ţåšķš öñ ţĥéîŕ ƀéĥåļƒ·--------------П國カ내]",
    "hotel58": "[Ĥöţéļ--П國カ내]",
    "hotel94": "[Ĥöţéļ--П國カ내]",
    "hotelcla151": "[Ĥöţéļ Çļåšš---П國カ내]",
    "hotelcla98": "[Ĥöţéļ Çļåšš---П國カ내]",
    "hotellis6": "[Ĥöţéļ Ļîšţ----П國カ내]",
    "hotelloy16": "[Ĥöţéļ Ļöýåļţý Þŕöĝŕåɱ Ñûɱƀéŕ---------П國カ내]",
    "hotelloy28": "[Ĥöţéļ Ļöýåļţý Þŕöĝŕåɱ-------П國カ내]",
    "hotelloy31": "[Ĥöţéļ Ļöýåļţý Þŕöĝŕåɱ ‐ {hotelLoyaltyName}-------------П國カ내]",
    "hotelloy33": "[Ĥöţéļ Ļöýåļţý Þŕöĝŕåɱ Ñûɱƀéŕ---------П國カ내]",
    "hotelloy34": "[Ĥöţéļ Ļöýåļţý Ñûɱƀéŕ-------П國カ내]",
    "hotelnig111": "[Ĥöţéļ Ñîĝĥţļý Ŕåţé Ĥåŕð Çåþ------П國カ내]",
    "hotelpol135": "[Ĥöţéļ Þöļîçý---П國カ내]",
    "hotelrat96": "[{settingHotelRating} Šţåŕ Ĥöţéļš-------П國カ내]",
    "hotelrew85": "[Ĥöţéļ Ŕéŵåŕðš---П國カ내]",
    "hotels4": "[Ĥöţéļš--П國カ내]",
    "hour57": "[{timeoutvalue} ĥöûŕ----П國カ내]",
    "hours58": "[({timeoutvalue} ĥöûŕš-----П國カ내]",
    "howdidyo31": "[Ĥöŵ ðîð ýöû ĥéåŕ åƀöûţ ŢŕåṽéļƁåñķ¿-------П國カ내]",
    "howoften144": "[Ĥöŵ Öƒţéñ--П國カ내]",
    "howwilly90": "[Ĥöŵ ŵîļļ ýöû ûšé ŢŕåṽéļƁåñķ¿------П國カ내]",
    "id136": "[ÎÐ-П國カ내]",
    "id94": "[ÎÐ-П國カ내]",
    "idealfor81": "[Îðéåļ ƒöŕ ļöñĝ‐ţéŕɱ ûšåĝé، çåñ ƀé ûšéð îñ þéŕšöñ öŕ öñļîñé·------------П國カ내]",
    "idealfor84": "[Îðéåļ ƒöŕ öñļîñé þûŕçĥåšéš، åñð ļöñĝ‐ţéŕɱ ûšåĝé· Çåñ åļšö ƀé åððéð ţö å ɱöƀîļé ŵåļļéţ ƒöŕ îñ‐þéŕšöñ ûšé·---------------------П國カ내]",
    "idealfor86": "[Îðéåļ ƒöŕ öñļîñé þûŕçĥåšéš åñð šĥöŕţ‐ţéŕɱ öŕ öñé‐ţîɱé ûšåĝé· Çåñ åļšö ƀé åððéð ţö å ɱöƀîļé ŵåļļéţ ƒöŕ îñ‐þéŕšöñ ûšé·------------------------П國カ내]",
    "ifnotspe150": "[Îƒ ñöţ šþéçîƒîéð، åļļ ŕéþöŕţ šţåţûšéš ŵîļļ ƀé éẋþöŕţéð·------------П國カ내]",
    "ifnotspe153": "[Îƒ ñöţ šþéçîƒîéð، éẋþöŕţ ŵîļļ éẋþöŕţ åļļ ðéþåŕţɱéñţš îñ ýöûŕ éẋþéñšé éẋþöŕţ·----------------П國カ내]",
    "ifnotspe36": "[Îƒ ñöţ šþéçîƒîéð، åļļ ŕéþöŕţ šţåţûšéš ŵîļļ ƀé éẋþöŕţéð·------------П國カ내]",
    "ifthecon85": "[Îƒ ţĥé <span>çöñðîţîöñ</span> ƀéļöŵ îš ɱéţ، <span>{approverLabel}</span> ŵîļļ ƀé åððéð ƒöŕ åþþŕöṽåļ·---------------------П國カ내]",
    "iftherep97": "[Îƒ ţĥé Ŕéþöŕţ Çöñţåîñš-----П國カ내]",
    "iftodayi34": "[Îƒ ţöðåý îš å ŵééķéñð، Ţĥåñķšĝîṽîñĝ، Çĥŕîšţɱåš Ðåý، Ñéŵ Ýéåŕš Ðåý، öŕ îţ’š þåšţ ③∶⑤⑨ ÞṀ ÇŢ، ţĥé þåýɱéñţ ŵîļļ ƀé þŕöçéššéð öñ ţĥé ñéẋţ ƀûšîñéšš ðåý·------------------------------П國カ내]",
    "ifturned156": "[Îƒ ţûŕñéð öñ، ţĥé éẋþöŕţ ŵîļļ îñçļûðé öñļý éẋþéñšéš ŵĥéŕé þåýɱéñţ ɱéţĥöð îš ŕéîɱƀûŕšåƀļé⁄þéŕšöñåļ çåŕð·---------------------П國カ내]",
    "ifyouare6": "[Îƒ ýöû åŕé åñ åþþŕöṽéŕ، ýöû çåñ ţåķé åçţîöñ öñ ţĥéɱ åƒţéŕ šûƀɱîššîöñ·--------------П國カ내]",
    "ifyouclo322": "[Îƒ ýöû çļöšé ţĥîš çåŕð، îţ çåñ ñö ļöñĝéŕ ƀé ûšéð· Ţĥé çåŕðĥöļðéŕ ŵîļļ ÑÖŢ ƀé ñöţîƒîéð------------------П國カ내]",
    "ifyoulea89": "[Îƒ ýöû ļéåṽé ŵîţĥöûţ šåṽîñĝ، åñý çĥåñĝéš ŵîļļ ƀé ðîšçåŕðéð· Ţĥîš çåññöţ ƀé ŕéṽéŕšéð-----------------П國カ내]",
    "ifyounee7": "[Îƒ ýöû ñééð ţö çĥåñĝé ţĥé ƀåñķ åççöûñţ ýöû´ŕé ûšîñĝ ƒöŕ ŕéîɱƀûŕšéɱéñţš þļéåšé çöñţåçţ ûš åţ <a href=\"mailto:support@travelbank.com\">support@travelbank.com</a>---------------------------------П國カ내]",
    "ifyoupro78": "[Îƒ ýöû þŕöçééð، ýöû çåñ ûšé ƒîļţéŕš ţö ƒîñð ðéļéţéð îţéɱš åñð ŕéšţöŕé ţĥéɱ·----------------П國カ내]",
    "ifyousel199": "[Îƒ ýöû šéļéçţ ţĥîš öþţîöñ، þŕöçééð ţö çöɱþåñý šéţţîñĝš åƒţéŕ šéţûþ ţö çûšţöɱîžé ýöûŕ þöļîçý·-------------------П國カ내]",
    "ihaverea4": "[Î ĥåṽé ŕéåð åñð åççéþţ ţĥé ļåţéšţ ţéŕɱš åñð çöñðîţîöñš· -----------------П國カ내]",
    "import124": "[Îɱþöŕţ--П國カ내]",
    "import85": "[Îɱþöŕţ--П國カ내]",
    "importho116": "[Îɱþöŕţ Ĥöšţš ⅋ Ðéļéĝåţéš-----П國カ내]",
    "importus70": "[Îɱþöŕţ Ûšéŕš---П國カ내]",
    "importvi117": "[Îɱþöŕţṽîåå ÇŠṼ ƒîļé ţö åðð šéṽéŕåļ ĥöšţš åñð ðéļéĝåţéš åţ öñçé· Þļéåšéŕéå ðöûŕ <a href=\"{url}\"target=\"blank\"class=\"link\">Ĥéļþ Çéñţéŕ åŕţîçļé</a>ţö ļéåŕñ ɱöŕé·-----------------------------------П國カ내]",
    "inactive45": "[Îñåçţîṽé--П國カ내]",
    "inactive52": "[Îñåçţîṽé--П國カ내]",
    "inactive6": "[Îñåçţîṽé--П國カ내]",
    "inactive69": "[Îñåçţîṽé--П國カ내]",
    "inactive71": "[Îñåçţîṽé--П國カ내]",
    "inactive88": "[Îñåçţîṽé--П國カ내]",
    "includea87": "[· Îñçļûðé Åļļöçåţîöñš-----П國カ내]",
    "includel86": "[· Îñçļûðé Ļîñé Îţéɱš-----П國カ내]",
    "incorrec1": "[Îñçöŕŕéçţ åţţåçĥɱéñţ ƒöŕɱåţ· Þļéåšé ţŕý ŵîţĥ åñöţĥéŕ ƒîļé·------------------П國カ내]",
    "incorrec114": "[Îñçöŕŕéçţ ṽéŕîƒîçåţîöñ çöðé·------П國カ내]",
    "indexoft9": "[{index} öƒ {total}----П國カ내]",
    "innovato305": "[Îññöṽåţöŕš Çåŕð----П國カ내]",
    "inputfie178": "[ Îñþûţ ƒîéļð ţýþé ṽåļûéš-----П國カ내]",
    "inputthe12": "[Îñþûţ ţĥé éɱåîļš åððŕéššéš öƒ ţĥé ŕéçîþîéñţš ŵĥö ŵîļļ ŕéçéîṽé ţĥé éẋþöŕţ (åðð å ɱåẋîɱûɱ öƒ ⑤ éɱåîļ åððŕéššéš)·-----------------------П國カ내]",
    "inputthe16": "[Îñþûţ ţĥé éɱåîļ åððŕéšš öƒ ţĥé ŕéçîþîéñţ ŵĥö ŵîļļ ŕéçéîṽé ñöţîƒîçåţîöñš (î·é· éŕŕöŕ ɱéššåĝéš)·-------------------П國カ내]",
    "instantc85": "[Îñšţåñţ Çåŕð---П國カ내]",
    "instantc93": "[Îñšţåñţ Çåŕð---П國カ내]",
    "instante173": "[Îñšţåñţ Éɱþļöýéé Çåŕð-----П國カ내]",
    "instante65": "[Îñšţåñţ Éɱþļöýéé Çåŕð-----П國カ내]",
    "integrat1": "[Îñţéĝŕåţîöñš---П國カ내]",
    "integrat2": "[Îñţéĝŕåţîöñ çŕéåţéð šûççéššƒûļļý¡----------П國カ내]",
    "integrat234": "[Îñţéĝŕåţé ŵîţĥ ţĥé ƀåñķ öƒ ýöûŕ çĥöîçé åñð šţåŕţ ɱåñåĝîñĝ åñð ţŕåçķîñĝ åļļ ýöûŕ çöŕþöŕåţé çåŕð šþéñð·---------------------П國カ내]",
    "integrat3": "[Îñţéĝŕåţîöñš---П國カ내]",
    "integrat4": "[Îñţéĝŕåţîöñ ðîšåƀļéð šûççéššƒûļļý¡-----------П國カ내]",
    "integrat44": "[Îñţéĝŕåţîöñš----П國カ내]",
    "integrat45": "[Îñţéĝŕåţîöñš ‐ Šéţ ƀý Öŕĝåñîžåţîöñ-----------П國カ내]",
    "integrat6": "[Îñţéĝŕåţîöñš Åŕé Åṽåîļåƀļé öñ ţĥé Þåîð Þļåñ---------П國カ내]",
    "integrationsetup": "[Îñţéĝŕåţîöñ Šéţûþ----П國カ내]",
    "invalidc54": "[Îñṽåļîð Çŕéðéñţîåļš·-----П國カ내]",
    "invalide5": "[Îñṽåļîð Éɱåîļ----П國カ내]",
    "invalidi73": "[Îñṽåļîð îɱåĝé ţýþé------П國カ내]",
    "invalids2": "[Îñṽåļîð Šţöþš----П國カ내]",
    "inviteem33": "[Îñṽîţé Éɱþļöýééš----П國カ내]",
    "invites20": "[Invites ({count})------П國カ내]",
    "invitese56": "[Îñṽîţé Šéñţ---П國カ내]",
    "invitese57": "[Îñṽîţé Šéñţ---П國カ내]",
    "invitese60": "[Îñṽîţé Šéñţ---П國カ내]",
    "invitese75": "[Îñṽîţé Šéñţ---П國カ내]",
    "irsrate74": "[ÎŔŠ Ŕåţé--П國カ내]",
    "irsrate93": "[ÎŔŠ Ŕåţé--П國カ내]",
    "issuerel134": "[{issuer} ····{last4}-----П國カ내]",
    "issuerla13": "[{issuer}····{last4}----П國カ내]",
    "isthisca196": "[Îš ţĥîš çåŕð ļöšţ öŕ šţöļéñ¿------П國カ내]",
    "iteminde2": "[Îţéɱ {index} Åɱöûñţ------П國カ내]",
    "iteminde4": "[Îţéɱ {index} Çåţéĝöŕý-------П國カ내]",
    "iteminde6": "[Îţéɱ {index} Ðéšçŕîþţîöñ--------П國カ내]",
    "itemizat98": "[Îţéɱîžåţîöñ Ŕéǫûîŕéɱéñţ-----П國カ내]",
    "itemizee30": "[Îţéɱîžé Éẋþéñšé-----П國カ내]",
    "itemizee53": "[Îţéɱîžé Éẋþéñšé----П國カ내]",
    "items18": "{n, plural, one {[① Îţéɱ-П國カ내]} other {[{n} Îţéɱš--П國カ내]}}",
    "items22": "{n, plural, one {[① Îţéɱ-П國カ내]} other {[{n} Îţéɱš--П國カ내]}}",
    "itemsare51": "[Îţéɱš åŕé <span data-test-error=\"mismatch\">{overspendAmount}</span> öṽéŕ ýöûŕ ţöţåļ·------------------П國カ내]",
    "itemsare52": "[Îţéɱš åŕé <span data-test-error=\"mismatch\">{overspendAmount}</span> ûñðéŕ ýöûŕ ţöţåļ·------------------П國カ내]",
    "itemsover27": "[Îţéɱš åŕé <span data-test-error=\"mismatch\">{amount}</span> öṽéŕ ýöûŕ ţöţåļ·-----------П國カ내]",
    "itemsunder28": "[Îţéɱš åŕé <span data-test-error=\"mismatch\">{amount}</span> ûñðéŕ ýöûŕ ţöţåļ·-----------П國カ내]",
    "itemsyou60": "[Îţéɱ⁄š Ýöû Šéļéçţéð Çåññöţ Ɓé Ðéļéţéð--------П國カ내]",
    "itemsyou77": "[Îţéɱš Ýöû Šéļéçţéð Çåññöţ Ɓé Ṁéŕĝéð--------П國カ내]",
    "itemsyou8": "[Îţéɱš Ýöû Šéļéçţéð Çåññöţ Ɓé Ṁéŕĝéð-----------П國カ내]",
    "itisvery320": "[Îţ îš ṽéŕý îɱþöŕţåñţ ţĥåţ ţĥé çåŕð ƀé ŕéþöŕţéð ļöšţ öŕ šţöļéñ· Ŵé çåñ ĥéļþ ýöû ŵîţĥ ñéẋţ šţéþš·--------------------П國カ내]",
    "itmaytak88": "[Îţ ɱåý ţåķé å ɱîñûţé ţö ŕéçéîṽé ýöûŕ çöðé·---------П國カ내]",
    "itseemsy61": "[Îţ šééɱš ýöû ĥåṽé šéļéçţéð å çöŕþöŕåţé çåŕð ţŕåñšåçţîöñ öŕ å ɱåñûåļ éẋþéñšé ɱéŕĝéð ŵîţĥ å çöŕþöŕåţé çåŕð ţŕåñšåçţîöñ·------------------------П國カ내]",
    "johnplaceholder26": "[Ĵöĥñ--П國カ내]",
    "justlook185": "[Ĵûšţ Ļööķîñĝ Åŕöûñð----П國カ내]",
    "keepcust4": "[Ķééþ Çûšţöɱîžîñĝ----П國カ내]",
    "keepsepa16": "[Ķééþ Šéþåŕåţé----П國カ내]",
    "keyerror250": "[{key}Éŕŕöŕ---П國カ내]",
    "knowntra21": "[Ķñöŵñ Ţŕåṽéļéŕ Ñûɱƀéŕ-------П國カ내]",
    "labeldat149": "[{label} Ðåţé {startstr} ‐ {endstr}-------П國カ내]",
    "last4wee7": "[Ļåšţ ④ ŵééķš----П國カ내]",
    "lastauth41": "[Ļåšţ Åûţĥ Ţýþé---П國カ내]",
    "lastfour23": "[Ļåšţ ④ ðîĝîţš ‐ {last4}-----П國カ내]",
    "lastfour84": "[Ļåšţ ④ ðîĝîţš ‐ {last4}-----П國カ내]",
    "lastly82": "[Ļåšţļý--П國カ내]",
    "lastly88": "[Ļåšţļý--П國カ내]",
    "lastname16": "[Ļåšţ Ñåɱé---П國カ내]",
    "lastname27": "[Ļåšţ Ñåɱé---П國カ내]",
    "lastname31": "[Ļåšţ ñåɱé ɱûšţ ƀé åţ ļéåšţ ţŵö çĥåŕåçţéŕš·-------------П國カ내]",
    "lastname32": "[Ļåšţ ñåɱé îš ŕéǫûîŕéð·-------П國カ내]",
    "lastname6": "[Ļåšţ Ñåɱé---П國カ내]",
    "lastname77": "[Ļåšţ Ñåɱé--П國カ내]",
    "lastnameplaceholder7": "[Šɱîţĥ--П國カ내]",
    "lastpaym80": "[Ļåšţ Þåýɱéñţ---П國カ내]",
    "laststat13": "[Ļåšţ Šţåţéɱéñţ Ñ⁄Å----П國カ내]",
    "laststat21": "[Ļåšţ Šţåţéɱéñţ Ðåţé ‐ Ţåƀļé ĥéåðéŕ-------П國カ내]",
    "laststat25": "[Ļåšţ Šţåţéɱéñţ Ñ⁄Å ðîšåƀļéð------П國カ내]",
    "laststat26": "[Ļåšţ Šţåţéɱéñţ Ðåţé ‐ {laststatementdate}---------П國カ내]",
    "laststat27": "[Ļåšţ Šţåţéɱéñţ Ðåţé ‐ Éɱþţý------П國カ내]",
    "laststat5": "[Ļåšţ Šţåţéɱéñţ Ðåţé----П國カ내]",
    "latesttr2": "[Ļåţéšţ Ţŕåñšåçţîöñš----П國カ내]",
    "launchcu7": "[Ļåûñçĥ Çûšţöɱîžéð Šéţţîñĝš Ŵåļķţĥŕöûĝĥ--------П國カ내]",
    "launchse9": "[Ļåûñçĥ Šéţûþ---П國カ내]",
    "learnmor1": "[Ļéåŕñ Ṁöŕé åƀöûţ Þļåñš Åñð Þŕîçîñĝ-------П國カ내]",
    "learnmor130": "[Ļéåŕñ Ṁöŕé ≥---П國カ내]",
    "learnmor132": "[Ļéåŕñ Ṁöŕé---П國カ내]",
    "learnmor175": "[Ļéåŕñ Ṁöŕé åƀöûţ Þļåñš Åñð Þŕîçîñĝ-------П國カ내]",
    "learnmor32": "[Ļéåŕñ Ṁöŕé----П國カ내]",
    "learnmor62": "[Ļéåŕñ ɱöŕé åƀöûţ <a href=\"https://travelbank.com/\">ŢŕåṽéļƁåñķ</a>·--------------П國カ내]",
    "ledgerin121": "[Ļéðĝéŕ Îñţéĝŕåţîöñ----П國カ내]",
    "legalfir164": "[Ļéĝåļ Ƒîŕšţ Ñåɱé----П國カ내]",
    "legallas166": "[Ļéĝåļ Ļåšţ Ñåɱé----П國カ내]",
    "legalmid165": "[Ļéĝåļ Ṁîððļé Ñåɱé----П國カ내]",
    "letemplo137": "[Ļéţ éɱþļöýééš šéļéçţ åñð šåṽé ţĥéîŕ þŕîɱåŕý ðéþåŕţɱéñţ ƒöŕ éẋþéñšéš· Ţûŕñîñĝ ţĥîš öƒƒ ŕéǫûîŕéš ɱåñûåļ éñţŕý ƒöŕ éṽéŕý éẋþéñšé·--------------------------П國カ내]",
    "letemplo80": "[Ļéţ éɱþļöýééš šéļéçţ åñý ðéþåŕţɱéñţ ƒöŕ šþļîţ· Ŵĥéñ öƒƒ، éɱþļöýééš ɱåý öñļý šéļéçţ ƒŕöɱ ţĥéîŕ åššîĝñéð ðéþåŕţɱéñţš·------------------------П國カ내]",
    "letemplo81": "[Ļéţ éɱþļöýééš šéļéçţ åñý ðéþåŕţɱéñţ ƒöŕ åļļöçåţîöñ· Ŵĥéñ öƒƒ، éɱþļöýééš ɱåý öñļý šéļéçţ ƒŕöɱ ţĥéîŕ åššîĝñéð ðéþåŕţɱéñţš·-------------------------П國カ내]",
    "licensec174": "[<div class=\"onboarding-subscriptions__overage-policy-info-box__license-name\">{title} çöšţš</div><div class=\"onboarding-subscriptions__overage-policy-info-box__license-price\">€{additionalusagecost} ⁄ûšéŕ⁄ɱö·</div><div class=\"onboarding-subscriptions__overage-policy-info-box__license-billing\">ƀîļļéð ɱöñţĥļý þéŕ ûšåĝé</div>------------------------------------------------------------------П國カ내]",
    "lifetime18": "[Ļîƒéţîɱé Šåṽîñĝš-----П國カ내]",
    "limit15": "[Ļîɱîţ--П國カ내]",
    "limit66": "[Ļîɱîţ--П國カ내]",
    "limits123": "[Ļîɱîţš--П國カ내]",
    "limits152": "[Ļîɱîţš--П國カ내]",
    "lineitem39": "[ļîñé‐îţéɱš---П國カ내]",
    "linkalla98": "[Ļîñķ åļļ åššöçîåţéð çåŕð ţŕåñšåçţîöñš ƒöŕ ŕéîɱƀûŕšéɱéñţš· Ţĥîš îš ñöţ å þåýɱéñţ ɱéţĥöð·------------------П國カ내]",
    "list35": "[Ļîšţ--П國カ내]",
    "loading2": "[Ļöåðîñĝ···----П國カ내]",
    "loading213": "[Ļöåðîñĝ···---П國カ내]",
    "loading55": "[Ļöåðîñĝ···---П國カ내]",
    "loadingO99": "[Ļöåðîñĝ öþţîöñš···----П國カ내]",
    "loadinge161": "[Ļöåðîñĝ Éɱþļöýééš···-----П國カ내]",
    "loadinge165": "[Ļöåðîñĝ Éẋþéñšé Éẋþöŕţ…-----П國カ내]",
    "loadinge41": "[Ļöåðîñĝ Éɱþļöýééš···-----П國カ내]",
    "loadinge46": "[Ļöåðîñĝ Éɱþļöýééš···-----П國カ내]",
    "loadingo1": "[Ļöåðîñĝ öþţîöñš···------П國カ내]",
    "loadingo7": "[Ļöåðîñĝ öþţîöñš···------П國カ내]",
    "loadprev15": "[Ļöåð Þŕéṽîöûš Ṁöñţĥ------П國カ내]",
    "locked25": "[Ļöçķéð--П國カ내]",
    "lodgingp77": "[Ļöðĝîñĝ Þöļîçý---П國カ내]",
    "lodgingp94": "[Ļöðĝîñĝ Þöļîçý---П國カ내]",
    "lodgingx148": "[ĻöðĝîñĝẊ--П國カ내]",
    "lodgingx151": "[Ļöðĝîñĝ‐Ẋ∶ Çöɱþåñý Ɓåñķ Åççöûñţ Ðŕöþðöŵñ---------П國カ내]",
    "lodgingx154": "[Ļöðĝîñĝ‐Ẋ∶ Ŕéîɱƀûŕšéɱéñţ Šçĥéðûļé Ðŕöþðöŵñ---------П國カ내]",
    "loggedin14": "[Ļöĝĝéð îñ åš (ûšéŕƒûļļñåɱé)------П國カ내]",
    "loggedin4": "[Ļöĝĝéð îñ åš {first} {last}---------П國カ내]",
    "login48": "[Ļöĝîñ--П國カ내]",
    "logout3": "[Ļöĝ Öûţ---П國カ내]",
    "lookarou42": "[Ļööķ Åŕöûñð Ƒîŕšţ----П國カ내]",
    "lookslik1": "[Ļööķš ļîķé ýöû’ŕé öñ å ɱöƀîļé ðéṽîçé·------------П國カ내]",
    "lookslik29": "[Ļööķš ļîķé ñö öñé åţ ýöûŕ çöɱþåñý ĥåš ƀööķéð ţŕåṽéļ ŵîţĥ ŢŕåṽéļƁåñķ ýéţ· Çöɱé ƀåçķ åƒţéŕ ýöûŕ çöɱþåñý ĥåš ɱåðé å ƒéŵ ƀööķîñĝš ţö ṽîéŵ Åñåļýţîçš·--------------------------------------------П國カ내]",
    "loststol130": "[Ļöšţ⁄Šţöļéñ åççöûñţ----П國カ내]",
    "lowertha337": "[Ļöŵéŕ ţĥåñ∶ €---П國カ내]",
    "lowertha57": "[Ļöŵéŕ ţĥåñ∶ €---П國カ내]",
    "loyaltyp1": "[Ļöýåļţý Þŕöĝŕåɱš-----П國カ내]",
    "maincont263": "[Ṁåîñ Çöñţåçţ Éɱåîļ----П國カ내]",
    "maincont264": "[Ṁåîñ Çöñţåçţ Ñåɱé----П國カ내]",
    "makeaboo67": "[Ṁåķé å Ɓööķîñĝ---П國カ내]",
    "makeanot11": "[Ṁåķé Åñöţĥéŕ Þåýɱéñţ-----П國カ내]",
    "makeaone21": "[Ṁåķé å Öñé‐ţîɱé Þåýɱéñţ-----П國カ내]",
    "makeapay42": "[Ṁåķé å Þåýɱéñţ---П國カ내]",
    "makeitea10": "[Ṁåķé îţ éåšý ƒöŕ éɱþļöýééš ţö ţŕåçķ šþéñð· Ŕéṽîéŵ åñð åþþŕöṽé éẋþéñšé ŕéþöŕţš ƒŕöɱ åñý ðéṽîçé· Ŵé’ļļ ñöţîƒý éɱþļöýééš ŵĥéñ ýöû’ṽé ţåķéñ åçţîöñ·-----------------------------П國カ내]",
    "makeitea8": "[Ṁåķé îţ éåšý ƒöŕ éɱþļöýééš ţö ţŕåçķ šþéñð· Ŕéṽîéŵ åñð åþþŕöṽé éẋþéñšé ŕéþöŕţš ƒŕöɱ åñý ðéṽîçé· Ŵé’ļļ ñöţîƒý éɱþļöýééš ŵĥéñ ýöû’ṽé ţåķéñ åçţîöñ·-----------------------------П國カ내]",
    "male18": "[Ṁåļé--П國カ내]",
    "manage1": "[Ṁåñåĝé--П國カ내]",
    "manage2": "[Ṁåñåĝé--П國カ내]",
    "manage3": "[Ṁåñåĝé--П國カ내]",
    "manage52": "[Ṁåñåĝé--П國カ내]",
    "manage64": "[Ṁåñåĝé--П國カ내]",
    "manage67": "[Ṁåñåĝé--П國カ내]",
    "manage9": "[Ṁåñåĝé--П國カ내]",
    "manageal14": "[Ṁåñåĝé åļļ ýöûŕ ŕéîɱƀûŕšéɱéñţš åñð îñţéĝŕåţé ýöûŕ éẋþéñšéš éåšîļý ŵîţĥîñ Ţŕåṽéļƀåñķ· ŢŕåṽéļƁåñķ þŕöţéçţš ýöûŕ îñƒöŕɱåţîöñ åñð îš ①⓪⓪‰ šéçûŕé·-------------------------------------------П國カ내]",
    "manageal177": "[Ṁåñåĝé åļļ ýöûŕ çûšţöɱ ƒîéļðš åššîĝñéð ţö éẋþéñšé ŕéþöŕţš· Éɱþļöýééš ŵîļļ šéé ţĥîš ŵĥéñ šûƀɱîţţîñĝ éẋþéñšéš·----------------------П國カ내]",
    "manageal6": "[Ṁåñåĝé åļļ ýöûŕ þåýɱéñţ ɱéţĥöðš åñð šţåŕţ ƀööķîñĝ åļļ ţŕåṽéļ ŵîţĥîñ Ţŕåṽéļƀåñķ·----------------П國カ내]",
    "manageca1": "[Ṁåñåĝé çåţéĝöŕîéš éɱþļöýééš šéļéçţ ƒŕöɱ åñð éðîţ ĜĻ ɱåþþîñĝ·-------------П國カ내]",
    "managecu201": "[Ṁåñåĝé Çûšţöɱ Ƒîéļðš-----П國カ내]",
    "managecu202": "[Ṁåñåĝé çûšţöɱ ƒîéļðš ƒöŕ ţŕåṽéļ ƀööķîñĝš· Ûþ ţö ⑤ çûšţöɱ ƒîéļðš çåñ ƀé åçţîṽé åţ å ţîɱé åñð ṽîšîƀļé ţö éɱþļöýééš ŵĥéñ ƀööķîñĝ ţŕîþš·---------------------------П國カ내]",
    "manageex173": "[Ṁåñåĝé Éẋþéñšé Çûšţöɱ Ƒîéļðš------П國カ내]",
    "manageex7": "[Ṁåñåĝé éẋþéñšé ŕéþöŕţš ƒöŕ ýöûŕ éñţîŕé çöɱþåñý·----------П國カ내]",
    "manageex9": "[Ṁåñåĝé Éẋþéñšé Ŕéþöŕţš Ļîķé å Þŕö-------П國カ내]",
    "managefi176": "[Ṁåñåĝé Ƒîéļðš---П國カ내]",
    "manageim1": "[Ṁåñåĝé، îɱþöŕţ åñð éẋþöŕţ ýöûŕ éɱþļöýééš îñ ŢŕåṽéļƁåñķ·------------П國カ내]",
    "manageke1": "[Ṁåñåĝé--П國カ내]",
    "manageor93": "[Ṁåñåĝé Öŕĝåñîžåţîöñ Ĥöšţš ⅋ Ðéļéĝåţéš--------П國カ내]",
    "manager1": "[Ṁåñåĝéŕ åþþŕöṽåļš ŕéǫûîŕéð ŵĥéñ öûţšîðé öƒ ţĥé ƀûðĝéţ öŕ þöļîçý šéţţîñĝš---------------П國カ내]",
    "manager56": "[Ṁåñåĝéŕ--П國カ내]",
    "manager62": "[Ṁåñåĝéŕ--П國カ내]",
    "manager70": "[Ṁåñåĝéŕ--П國カ내]",
    "manager82": "[Ṁåñåĝéŕ--П國カ내]",
    "managers95": "[Ṁåñåĝéŕš ŵîļļ ƀé ñöţîƒîéð îƒ åñý öƒ ţĥéšé ŕûļéš åŕé ƀŕöķéñ·------------П國カ내]",
    "managert86": "[Ṁåñåĝéŕ، ţĥéñ Ƒîñåñçé-----П國カ내]",
    "managetr170": "[Ṁåñåĝé Ţŕåṽéļ Çûšţöɱ Ƒîéļðš------П國カ내]",
    "manageyo1": "[Ṁåñåĝé ýöûŕ éɱþļöýééš åñð ðéļéĝåţéš îñ ŢŕåṽéļƁåñķ·-----------П國カ내]",
    "manageyo2": "[Ṁåñåĝé ýöûŕ éɱþļöýééš åñð ðéþåŕţɱéñţš îñ ŢŕåṽéļƁåñķ·-----------П國カ내]",
    "manageyo5": "[Ṁåñåĝé Ýöûŕ Çåŕðš----П國カ내]",
    "manageyo6": "[Ṁåñåĝé ýöûŕ çåŕð šþéñðîñĝ ļîɱîţš åñð šéé ţĥé šţåţûš öƒ ýöûŕ çåŕðš ŵîţĥîñ ŢŕåṽéļƁåñķ، åļļ îñ öñé þļåçé·---------------------П國カ내]",
    "manageyo93": "[Ṁåñåĝé ýöûŕ ƀûšîñéšš ţŕåṽéļ öŕ åûţöɱåţé šþéñð ɱåñåĝéɱéñţ· Ûšé ƀöţĥ ƒöŕ åñ åļļ‐îñ‐öñé éẋþéŕîéñçé·--------------------П國カ내]",
    "managing10": "[Ṁåñåĝîñĝ Åççöûñţš----П國カ내]",
    "managing113": "[Ṁåñåĝîñĝ Åççöûñţ----П國カ내]",
    "managing153": "[Ṁåñåĝîñĝ Åççöûñţ åš {label}------П國カ내]",
    "managing156": "[Ṁåñåĝîñĝ Åççöûñţ----П國カ내]",
    "managing157": "[Ṁåñåĝîñĝ åççöûñţ ðŕöþðöŵñ------П國カ내]",
    "managing183": "[Ṁåñåĝîñĝ Åççöûñţ Ñåɱé-----П國カ내]",
    "managing184": "[Ṁåñåĝîñĝ Åççöûñţ Ñûɱƀéŕ-----П國カ내]",
    "managing20": "[Ṁåñåĝîñĝ Åççöûñţ ‐ Ţåƀļé ĥéåðéŕ-------П國カ내]",
    "managing22": "[Ṁåñåĝîñĝ Åççöûñţ ðŕöþðöŵñ------П國カ내]",
    "managing24": "[Ṁåñåĝîñĝ åççöûñţ ‐ {managingaccount}--------П國カ내]",
    "managing4": "[Ṁåñåĝîñĝ Åççöûñţ----П國カ내]",
    "managing47": "[Ṁåñåĝîñĝ Åççöûñţ Ñåɱé-----П國カ내]",
    "managing69": "[Ṁåñåĝîñĝ Åççöûñţš----П國カ내]",
    "managing7": "[Ṁåñåĝîñĝ Åççöûñţ----П國カ내]",
    "manualex13": "[Ṁåñûåļ Éẋþéñšé Éñţŕý-------П國カ내]",
    "manualex43": "[Ṁåñûåļ Éẋþéñšé---П國カ내]",
    "maprecei1": "[Ṁåþ Ŕéçéîþţ----П國カ내]",
    "marktrav13": "[Ṁåŕķ ţŕåṽéļéŕ åš ɱýšéļƒ-------П國カ내]",
    "maxamoun159": "[Ṁåẋ Åɱöûñţ åš €{max}-----П國カ내]",
    "maxcardl52": "[Ṁåẋ· Çåŕð Ļîɱîţ----П國カ내]",
    "maxlengt49": "[Ṁåẋ Ļéñĝţĥ ②⓪⓪---П國カ내]",
    "mcc126": "[ṀÇÇ-П國カ내]",
    "mccandde85": "[ṀÇÇ ⅋ Ðéšçŕîþţîöñ----П國カ내]",
    "mcccontr126": "[ṀÇÇ Çöñţŕöļš---П國カ내]",
    "mccdescr127": "[ṀÇÇ Ðéšçŕîþţîöñ----П國カ내]",
    "mccmerch133": "[ṀÇÇ (Ṁéŕçĥåñţ Çåţéĝöŕý Çöðé) Ţéɱþļåţéš--------П國カ내]",
    "mccmerch134": "[ṀÇÇ (Ṁéŕçĥåñţ Çåţéĝöŕý Çöðé) Ţéɱþļåţéš ðŕöþðöŵñ----------П國カ내]",
    "measuret22": "[Ṁéåšûŕé Ţŕåṽéļ Šþéñð-------П國カ내]",
    "merchant12": "[Ṁéŕçĥåñţ---П國カ내]",
    "merchant128": "[Ṁéŕçĥåñţ Çîţý---П國カ내]",
    "merchant129": "[Ṁéŕçĥåñţ Šţåţé---П國カ내]",
    "merchant151": "[Ṁéŕçĥåñţ Çåţéĝöŕý Çöñţŕöļš------П國カ내]",
    "merchant19": "[Ṁéŕçĥåñţ--П國カ내]",
    "merchant2": "[Ṁéŕçĥåñţ---П國カ내]",
    "merchant46": "[Ṁéŕçĥåñţ--П國カ내]",
    "merchant73": "[Ṁéŕçĥåñţ، ŕéǫûîŕéð----П國カ내]",
    "merchant84": "[Ṁéŕçĥåñţ Ļöçåţîöñ----П國カ내]",
    "merchant99": "[Ṁéŕçĥåñţ--П國カ내]",
    "merge110": "[Ṁéŕĝé--П國カ내]",
    "merge13": "[Ṁéŕĝé--П國カ내]",
    "mergeany11": "[Ṁéŕĝé Åñýŵåý----П國カ내]",
    "mergeexp20": "[Ṁéŕĝé Éẋþéñšé---П國カ내]",
    "mergeexp9": "[Ṁéŕĝé Éẋþéñšé----П國カ내]",
    "messagee111": "[{message} Éŕŕöŕ çöðé {code}------П國カ내]",
    "middlena15": "[Ṁîððļé Ñåɱé----П國カ내]",
    "mileage10": "[Ṁîļéåĝé---П國カ내]",
    "mileage13": "[Ṁîļéåĝé---П國カ내]",
    "mileage28": "[Ṁîļéåĝé--П國カ내]",
    "mileage80": "[Ṁîļéåĝé--П國カ내]",
    "mileaged28": "[Ṁîļéåĝé Ðéţåîļš----П國カ내]",
    "mileaged4": "[Ṁîļéåĝé Ðéţåîļš-----П國カ내]",
    "mileagee4": "[Ṁîļéåĝé Éẋþéñšé-----П國カ내]",
    "mileagee46": "[Ṁîļéåĝé Éẋþéñšé----П國カ내]",
    "mileagee78": "[Ṁîļéåĝé éẋþéñšéš çåññöţ ƀé ɱéŕĝéð· Ţŕý šéļéçţîñĝ å ðîƒƒéŕéñţ éẋþéñšé öŕ ţŕåñšåçţîöñ·-----------------П國カ내]",
    "mileagee9": "[Ṁîļéåĝé éẋþéñšéš çåññöţ ƀé ɱéŕĝéð· Ţŕý šéļéçţîñĝ å ðîƒƒéŕéñţ éẋþéñšé öŕ ţŕåñšåçţîöñ·--------------------------П國カ내]",
    "mileagem4": "[Ṁîļéåĝé Ṁåþ----П國カ내]",
    "mileager116": "[Ṁîļéåĝé Ŕéîɱƀûŕšéɱéñţ Ŕåţé------П國カ내]",
    "mileager120": "[Ṁîļéåĝé Ŕéîɱƀûŕšéɱéñţ Ŕåţé Çûšţöɱ-------П國カ내]",
    "mileager121": "[Ṁîļéåĝé Ŕéîɱƀûŕšéɱéñţ Ŕåţé ÎŔŠ Ŕåţé--------П國カ내]",
    "mileager15": "[Ṁîļéåĝé Ŕåţé----П國カ내]",
    "mileager33": "[{mileageRate}ẋ---П國カ내]",
    "mileager71": "[Ṁîļéåĝé Ŕéîɱƀûŕšéɱéñţ Ŕåţé------П國カ내]",
    "mileager88": "[Ṁîļéåĝé Ŕåţé---П國カ내]",
    "mileaget31": "[{mileageTitle} (Ŕéåð Öñļý)------П國カ내]",
    "mileaget66": "[Ṁîļéåĝé Ţîţļé---П國カ내]",
    "miles76": "[Ṁîļéš--П國カ내]",
    "milesdri14": "[Ṁîļéš Ðŕîṽéñ----П國カ내]",
    "milesdri87": "[Ṁîļéš Ðŕîṽéñ---П國カ내]",
    "minamoun158": "[Ṁîñ Åɱöûñţ åš €{min}-----П國カ내]",
    "mincardl51": "[Ṁîñ· Çåŕð Ļîɱîţ----П國カ내]",
    "minimum8152": "[Ṁîñîɱûɱ ⑧ çĥåŕåçţéŕš ļöñĝ------П國カ내]",
    "minimume16": "[Ṁîñîɱûɱ ⑧ çĥåŕåçţéŕš ļöñĝ------П國カ내]",
    "minimumo5": "[Ṁîñîɱûɱ öƒ {count} çĥåŕåçţéŕš-------П國カ내]",
    "minutes56": "[{timeoutvalue} ɱîñûţéš-----П國カ내]",
    "minutest99": "[Ṁîñûţéš ţö šîĝñ öûţ ðéļéĝåţéš------П國カ내]",
    "missingc24": "[Ṁîššîñĝ Çöšţ---П國カ내]",
    "missingc25": "[Ṁîššîñĝ Çåţéĝöŕý----П國カ내]",
    "missingd23": "[Ṁîššîñĝ Ðåţé----П國カ내]",
    "missinge23": "[Ṁîššîñĝ Éẋþéñšé Åššîĝñéé-----П國カ내]",
    "missingi114": "[Ṁîššîñĝ Îñƒöŕɱåţîöñ----П國カ내]",
    "missingi2": "[Ṁîššîñĝ Îñƒö----П國カ내]",
    "missingi76": "[Ṁîššîñĝ Îñƒöŕɱåţîöñ----П國カ내]",
    "missingl69": "[Ṁiššîñĝ ļîñķ ţöķéñ, þļéåšé çöñţåçţ šûÞÞöŕţ·------------------П國カ내]",
    "missingm19": "[Ṁîššîñĝ Ṁéŕçĥåñţ----П國カ내]",
    "missingm21": "[Ṁîššîñĝ Ṁéŕçĥåñţ-----П國カ내]",
    "missingm26": "[Ṁîššîñĝ Ṁéŕçĥåñţ----П國カ내]",
    "missingr40": "[Ṁîššîñĝ ŕéǫûîŕéð îñƒöŕɱåţîöñ·---------П國カ내]",
    "missingt40": "[Ṁîššîñĝ Ţîţļé---П國カ내]",
    "missingt62": "[Ṁîššîñĝ Ţŕåñšåçţîöñ----П國カ내]",
    "modify10": "[Ṁöðîƒý--П國カ내]",
    "modifyca146": "[Ṁöðîƒý Çåŕð---П國カ내]",
    "modifyca246": "[Ṁöðîƒý Çåŕð---П國カ내]",
    "modifycr24": "[Ṁöðîƒý Çŕéðéñţîåļš----П國カ내]",
    "modifyre96": "[Ṁöðîƒý ŕéþöŕţ åþþŕöṽåļ ƒļöŵ ƀåšéð öñ ŕéþöŕţ çûšţöɱ çöñðîţîöñš·-------------П國カ내]",
    "monday10": "[Ṁöñðåý--П國カ내]",
    "monitoro27": "[Ṁöñîţöŕ öṽéŕåļļ šåṽîñĝš åĝåîñšţ ýöûŕ ţŕåṽéļ þöļîçý، åñð ṽîéŵ éɱþļöýééš ƀööķîñĝ öṽéŕ åñð ûñðéŕ ƀûðĝéţ·-------------------------------П國カ내]",
    "monthly4": "[Ṁöñţĥļý--П國カ내]",
    "monthly80": "[Ṁöñţĥļý--П國カ내]",
    "monthly9": "[Ṁöñţĥļý--П國カ내]",
    "monthlyk1": "[Ṁöñţĥļý--П國カ내]",
    "moredepa89": "[{visibleDepartmentNames}، ⁺{length} ɱöŕé Ðéþåŕţɱéñţš -----------П國カ내]",
    "moreitem75": "[Ṁöŕé Îţéɱš---П國カ내]",
    "mostchan247": "[Ṁöšţ çĥåñĝéš ţåķé éƒƒéçţ îɱɱéðîåţéļý· Çĥåñĝéš ţö ṀÇÇ Çåŕð Çöñţŕöļš ɱåý ţåķé ûþţö ②④ ĥöûŕš·-------------------П國カ내]",
    "multicar306": "[Ṁûļţî Çåŕð Ṁåšţéŕçåŕð-----П國カ내]",
    "multilevke1": "[Ṁûļţî‐ļéṽéļ åþþŕöṽåļš-----П國カ내]",
    "multilevke2": "[Ṁûļţî‐ļéṽéļ éẋþéñšé åþþŕöṽåļš------П國カ내]",
    "multiple1": "[Ṁûļţîþļé çûšţöɱ þöļîçîéš-----П國カ내]",
    "multiple94": "[Ṁûļţîþļé Çåŕðš Šéļéçţéð-----П國カ내]",
    "mustbeat93": "[Ṁûšţ ƀé åţ ļéåšţ ⑧ çĥåŕåçţéŕš---------П國カ내]",
    "mustincl151": "[Ṁûšţ îñçļûðé öñé šþéçîåļ çĥåŕåçţéŕ-------П國カ내]",
    "mustincl153": "[Ṁûšţ îñçļûðé öñé ûþþéŕçåšé åñð öñé ļöŵéŕçåšé çĥåŕåçţéŕ-----------П國カ내]",
    "mustincl154": "[Ṁûšţ îñçļûðé öñé ñûɱƀéŕ-----П國カ내]",
    "myaccoun15": "[Ṁý Åççöûñţ---П國カ내]",
    "myaccoun2": "[Ṁý Åççöûñţ----П國カ내]",
    "mycards1": "[Ṁý Çåŕðš--П國カ내]",
    "mycards11": "[Ṁý Çåŕðš--П國カ내]",
    "mycardst1": "[Ṁý Çåŕðš ⅋ Ţŕåñšåçţîöñš-----П國カ내]",
    "mydashbo75": "[Ṁý Ðåšĥƀöåŕð---П國カ내]",
    "myexpens16": "[Ṁý Éẋþéñšéš ⅋ Ŕéþöŕţš-----П國カ내]",
    "mytravel52": "[Ṁý Ţŕåṽéļ Ɓööķîñĝš----П國カ내]",
    "na1": "[Ñ⁄Å-П國カ내]",
    "na326": "[Ñ⁄Å-П國カ내]",
    "na41": "[Ñ⁄Å-П國カ내]",
    "na70": "[Ñ⁄Å-П國カ내]",
    "nadditio1": "{n, plural, =0 {} one {[① Åððîţîöñåļ Šţöþ--------П國カ내]} other {[{n} Åððîţîöñåļ Šţöþš--------П國カ내]}}",
    "name1": "[Ñåɱé-П國カ내]",
    "name224": "[Ñåɱé-П國カ내]",
    "name94": "[Ñåɱé-П國カ내]",
    "namedash28": "[Ñåɱé ‐ {name}---П國カ내]",
    "namedash61": "[Ñåɱé ‐ {accountname}-----П國カ내]",
    "nameisre81": "[Ñåɱé îš ŕéǫûîŕéð----П國カ내]",
    "namelast16": "[{name}<span>···{last4}</span>----П國カ내]",
    "namelast17": "[{bankname}<span class=\"last-four\" style=\"flex: 1\">···{banklast4}</span>----П國カ내]",
    "nameonca7": "[Ñåɱé öñ Çåŕð----П國カ내]",
    "nametabl18": "[Ñåɱé ‐ Ţåƀļé ĥéåðéŕ----П國カ내]",
    "navigati71": "[Ñåṽîĝåţîöñ---П國カ내]",
    "needhelp1": "[ Ñééð ĥéļþ çĥööšîñĝ å þļåñ öŕ ĥåṽé å ǫûéšţîöñ åƀöûţ ļîçéñšéš¿ Éɱåîļ ûš åţ <a href=mailto:{emailHref} class=\"link\"><b>{emailHref}</b></a> åñð ŵé´ļļ ƀé ĥåþþý ţö åššîšţ¡----------------------------------П國カ내]",
    "needhelp176": "[Ñééð ĥéļþ çĥööšîñĝ å þļåñ öŕ ĥåṽé å ǫûéšţîöñ åƀöûţ ļîçéñšéš¿ Éɱåîļ ûš åţ <a href=\"mailto:upgrade@travelbank.com\" class=\"link\"><b>ûþĝŕåðé՞ţŕåṽéļƀåñķ·çöɱ</b></a> åñð ŵé´ļļ ƀé ĥåþþý ţö åššîšţ¡---------------------------------------П國カ내]",
    "negative21": "[Ñéĝåţîṽé Ŕéîɱƀûŕšéɱéñţ Ŕéþöŕţ---------П國カ내]",
    "new75": "[Ñéŵ-П國カ내]",
    "new94": "[Ñéŵ-П國カ내]",
    "new98": "[Ñéŵ-П國カ내]",
    "newexpen4": "[Ñéŵ Éẋþéñšé Ŕéþöŕţ----П國カ내]",
    "newexpen51": "[Ñéŵ Éẋþéñšé Ŕéþöŕţ----П國カ내]",
    "newlycre74": "[Ñéŵļý çŕéåţéð çåţéĝöŕîéš ŵîļļ ƀé åððéð ţö åļļ éẋîšţîñĝ éẋþéñšé þöļîçîéš---------------П國カ내]",
    "newpassw14": "[Ñéŵ Þåššŵöŕð----П國カ내]",
    "newpassw3": "[Ñéŵ Þåššŵöŕð---П國カ내]",
    "newpassw80": "[Ñéŵ Þåššŵöŕð îš ŕéǫûîŕéð·--------П國カ내]",
    "newpassw86": "[Ñéŵ Þåššŵöŕð----П國カ내]",
    "newpassw94": "[Ñéŵ þåššŵöŕð îš ŕéǫûîŕéð·--------П國カ내]",
    "newpassw95": "[Ñéŵ þåššŵöŕð ɱûšţ ƀé åţ ļéåšţ ⑧ çĥåŕåçţéŕš-------------П國カ내]",
    "newpassw96": "[Ñéŵ þåššŵöŕð šĥöûļð ñöţ ƀé ţĥé šåɱé åš ţĥé öļð þåššŵöŕð·-----------------П國カ내]",
    "newtothe72": "[Ñéŵ ţö ţĥé þŕöçéšš¿ Ðîṽé îñţö öûŕ <a style=\"text-decoration: underline;\" class=\"link\" target=\"_blank\" href=\"{url}\">Ĥéļþ Çéñţéŕ åŕţîçļé</a> ƒöŕ ðéţåîļéð îñšţŕûçţîöñš·-------------------------------------------П國カ내]",
    "newtotra33": "[Ñéŵ ţö ŢŕåṽéļƁåñķ?------П國カ내]",
    "nexonia15": "[Ñéẋöñîå---П國カ내]",
    "nexoniar10": "[Ñéẋöñîå Ŕéçéîþţš-----П國カ내]",
    "next13": "[ñéẋţ-П國カ내]",
    "next35": "[Ñéẋţ-П國カ내]",
    "next57": "[Ñéẋţ-П國カ내]",
    "next73": "[Ñéẋţ-П國カ내]",
    "nextcycl208": "[Néẋţ çyçļé éñð ðåţé îš öñ {nextstatementdate}. Šţåţéɱéñţ ðåţés ŵîļļ ṽåŕÿ îƒ ţĥé çyçļé ðåţé ƒåļļś öñ å wéékéñð öŕ ĥöļiðåý·--------------------П國カ내]",
    "nextcycl22": "[Ñéẋţ Çýçļé Éñð Ðåţé ‐ Ţåƀļé ĥéåðéŕ ‐ Šţåţéɱéñţ ðåţéš ŵîļļ ṽåŕý îƒ ţĥé çýçļé ðåţé ƒåļļš öñ å ŵééķéñð öŕ ĥöļîðåý-----------------------П國カ내]",
    "nextcycl29": "[Ñéẋţ Çýçļé Éñð Ðåţé ‐ {cycleenddate}--------П國カ내]",
    "nextcycl30": "[Ñéẋţ Çýçļé Éñð Ðåţé ‐ Éɱþţý------П國カ내]",
    "nextcycl6": "[Ñéẋţ Çýçļé Éñð Ðåţé----П國カ내]",
    "nextdued64": "[Ñéẋţ ðûé ðåţé---П國カ내]",
    "ninetyni23": "[⑨⑨⁺-П國カ내]",
    "no108": "[Ñö-П國カ내]",
    "no13": "[Ñö·-П國カ내]",
    "no195": "[Ñö-П國カ내]",
    "no46": "[Ñö-П國カ내]",
    "no55": "[Ñö-П國カ내]",
    "no9": "[Ñö-П國カ내]",
    "noalloca12": "[Ñö åļļöçåţîöñš·-----П國カ내]",
    "noapprov63": "[Ñö Åþþŕöṽåļš---П國カ내]",
    "noapprov71": "[Ñö Åþþŕöṽåļš---П國カ내]",
    "noattach99": "[Ñö åţţåçĥɱéñţš·----П國カ내]",
    "nobookin28": "[Ñö Ɓööķîñĝš Ðåţå-----П國カ내]",
    "nocompat11": "[Ñö Çöɱþåţîƀļé Îţéɱš Ƒöûñð--------П國カ내]",
    "nodepart11": "[Ñö ðéþåŕţɱéñţš ƒöûñð-----П國カ내]",
    "nodepart19": "[Ñö ðéþåŕţɱéñţš ƒöûñð-------П國カ내]",
    "nodepart6": "[Ñö ðéþåŕţɱéñţš ƒöûñð-------П國カ내]",
    "nodepart7": "[Ñö ðéþåŕţɱéñţš ƒöûñð-------П國カ내]",
    "noemploy184": "[<div><div>Ñö éɱþļöýééš ƒöûñð· Çåŕðš çåñ öñļý ƀé åššîĝñéð ţö éɱþļöýééš ŵĥö ĥåṽé åñ åçţîṽé åççöûñţ öñ ŢŕåṽéļƁåñķ·</div><br><div>Çļîçķ <a class=\"link\" href=\"/organizations/{orgid}/employees/user-directory\">ĥéŕé</a> ţö åðð åñ éɱþļöýéé·</div></div>--------------------------------------------------П國カ내]",
    "noemploy59": "[Ñö Éɱþļöýéé Åššîĝñéð-----П國カ내]",
    "noemploy72": "[<div><div>Ñö éɱþļöýééš ƒöûñð· Çåŕðš çåñ öñļý ƀé åššîĝñéð ţö éɱþļöýééš ŵĥö ĥåṽé åñ åçţîṽé åççöûñţ öñ ŢŕåṽéļƁåñķ·</div><br> <div>Çļîçķ <a class=\"link\" tabindex=\"0\" role=\"button\" href=\"/organizations/{orgid}/employees/user-directory\">ĥéŕé</a> ţö åðð åñ éɱþļöýéé·</div></div>--------------------------------------------------------П國カ내]",
    "noexpens10": "[Ñö éẋþéñšéš åṽåîļåƀļé·-------П國カ내]",
    "noflight3": "[Ñö ƒļîĝĥţ çŕéðîţš åṽåîļåƀļé· Çŕéðîţš ɱåý ţåķé ûþ ţö ②④ ĥöûŕš ţö ûþðåţé·----------------------П國カ내]",
    "nohistor10": "[Ñö Ĥîšţöŕîçåļ Ţŕåñšåçţîöñš Ýéţ¡-------П國カ내]",
    "nomatche171": "[Ñö ɱåţçĥéš ƒöŕ <strong>{searchCriteria}</strong>----------П國カ내]",
    "nomatche175": "[Ñö ɱåţçĥéš ƒöŕ <strong>{searchCriteria}</strong>----------П國カ내]",
    "nomatche70": "[Ñö ɱåţçĥéš ƒöŕ ″<strong>{settingSearchCriteria}<⁄strong>″------------П國カ내]",
    "nomatche72": "[Ñö ɱåţçĥéš ƒöŕ ″<strong>{settingSearchCriteria}<⁄strong>″------------П國カ내]",
    "nomatche73": "[Ñö ɱåţçĥéš ƒöŕ ″<strong>{settingSearchCriteria}<⁄strong>″------------П國カ내]",
    "nomatche74": "[Ñö ɱåţçĥéš ƒöŕ ″<strong>{settingSearchCriteria}<⁄strong>″------------П國カ내]",
    "nomatche76": "[Ñö ɱåţçĥéš ƒöŕ ″<strong>{settingSearchCriteria}<⁄strong>″------------П國カ내]",
    "nomatchi131": "[Ñö ɱåţçĥîñĝ ûšéŕš·----П國カ내]",
    "nomatchi147": "[Ñö ɱåţçĥîñĝ ûšéŕš·----П國カ내]",
    "none45": "[Ñöñé--П國カ내]",
    "none59": "[Ñöñé-П國カ내]",
    "nonotifi62": "[Ñö Ñöţîƒîçåţîöñš----П國カ내]",
    "nonotifi70": "[Ñö Ñöţîƒîçåţîöñš----П國カ내]",
    "nonreimb72": "[Ñöñ‐Ŕéîɱƀûŕšåƀļé----П國カ내]",
    "nonstop141": "[Ñöñšţöþ--П國カ내]",
    "nopaymen27": "[Ñö þåýɱéñţ ðûé åţ ţĥîš ţîɱé·------П國カ내]",
    "nopaymen50": "[Ñö þåýɱéñţš ƒöûñð·----П國カ내]",
    "nopaymen81": "[Ñö þåýɱéñţ åççöûñţš åṽåîļåƀļé· Þļéåšé åðð öñé·----------П國カ내]",
    "noperiod3": "[Ñö·-П國カ내]",
    "noperiod76": "[Ñö·-П國カ내]",
    "nopolicy85": "[Ñö Þöļîçý Šéţ---П國カ내]",
    "noreceip19": "[Ñö ŕéçéîþţ----П國カ내]",
    "noreceip343": "[Ñö Ŕéçéîþţ---П國カ내]",
    "noreceip55": "[Ñö Ŕéçéîþţ---П國カ내]",
    "noreport5": "[Ñö ŕéþöŕţš ƒöûñð-----П國カ내]",
    "noresult05": "[Ñö ŕéšûļţš ƒöûñð----П國カ내]",
    "noresult11": "[Ñö ŕéšûļţš ƒöûñð-----П國カ내]",
    "noresult12": "[Ñö ŕéšûļţš ƒöûñð----П國カ내]",
    "noresult16": "[Ñö ŕéšûļţš ƒöûñð·------П國カ내]",
    "noresult162": "[Ñö Ŕéšûļţš Ƒöûñð----П國カ내]",
    "noresult169": "[Ñö ŕéšûļţš ƒöûñð----П國カ내]",
    "noresult20": "[Ñö ŕéšûļţš ƒöûñð----П國カ내]",
    "noresult40": "[Ñö ŕéšûļţš ƒöûñð----П國カ내]",
    "noresult45": "[Ñö ŕéšûļţš ƒöûñð----П國カ내]",
    "noresult47": "[Ñö ŕéšûļţš ƒöûñð----П國カ내]",
    "noresult55": "[Ñö ŕéšûļţš ƒöûñð·----П國カ내]",
    "noresult6": "[Ñö ŕéšûļţš ƒöûñð-----П國カ내]",
    "noresult63": "[Ñö ŕéšûļţš ƒöûñð ƒöŕ ∖″{searchTerm}∖″--------П國カ내]",
    "noresult68": "[Ñö ŕéšûļţš·---П國カ내]",
    "noresult77": "[Ñö ŕéšûļţš ƒöûñð----П國カ내]",
    "noresult81": "[Ñö ŕéšûļţš ƒöûñð·----П國カ내]",
    "noresult84": "[Ñö ŕéšûļţš ƒöûñð----П國カ내]",
    "noreward203": "[Ñö Ŕéŵåŕðš----П國カ내]",
    "nosplits13": "[Ñö Šþļîţš·----П國カ내]",
    "nosplits30": "[Ñö Šþļîţš ýéţ· Åðð ƒŕöɱ ţĥé éẋþéñšé öŕ ţŕåñšåçţîöñ ðéţåîļ þåĝé·-------------------П國カ내]",
    "nostatem207": "[Ñö šţåţéɱéñţš åṽåîļåbļé·----П國カ내]",
    "notetran3": "[Ñöţé∶ Ţŕåñšåçţîöñš ɱåý ţåķé ②‐④ ðåýš ţö åþþéåŕ·----------П國カ내]",
    "notifica109": "[Ñöţîƒîçåţîöñš---П國カ내]",
    "notifica115": "[Ñöţîƒîçåţîöñš åñð Åþþŕöṽåļš------П國カ내]",
    "notifica119": "[Ñöţîƒîçåţîöñš åñð Åþþŕöṽåļš------П國カ내]",
    "notifica120": "[Ñöţîƒîçåţîöñš---П國カ내]",
    "notifica145": "[Ñöţîƒîçåţîöñš---П國カ내]",
    "notifica92": "[Ñöţîƒîçåţîöñš åñð åþþŕöṽåļš åŕé ðîšåƀļéð· Ûšéŕš çåñ ƀööķ åñý ƒļîĝĥţ ŵîţĥöûţ ƀûðĝéţ ŕéšţŕîçţîöñš·--------------------П國カ내]",
    "notifica93": "[Ñöţîƒîçåţîöñš åñð åþþŕöṽåļš åŕé ðîšåƀļéð· Ûšéŕš çåñ ƀööķ åñý ĥöţéļ ŵîţĥöûţ ƀûðĝéţ ŕéšţŕîçţîöñš·--------------------П國カ내]",
    "notinclu178": "[Ñöţ îñçļûðéð îñ ţĥîš çåŕðĥöļðéŕ’š éẋþéñšé þöļîçý----------П國カ내]",
    "notinvit56": "[Ñöţ Îñṽîţéð---П國カ내]",
    "notinvit59": "[Ñöţ Îñṽîţéð---П國カ내]",
    "notinvit74": "[Ñöţ Îñṽîţéð---П國カ내]",
    "notitleg1": "[Ñö ţîţļé ĝîṽéñ-----П國カ내]",
    "notransa16": "[Ñö Ţŕåñšåçţîöñš Ýéţ¡-----П國カ내]",
    "notransa3": "[Ñö ţŕåñšåçţîöñš ţö šĥöŵ ƒŕöɱ ţĥé þåšţ ⑥⓪ ðåýš·----------П國カ내]",
    "notransa8": "[Ñö ţŕåñšåçţîöñš ɱåţçĥ ýöûŕ šéåŕçĥ çŕîţéŕîå·---------П國カ내]",
    "notravel194": "[Ñö Ţŕåṽéļ Þöļîçý----П國カ내]",
    "notsubmi36": "[Ñöţ Šûƀɱîţţéð---П國カ내]",
    "nouserso132": "[Ñö ûšéŕš öñ Þöļîçý----П國カ내]",
    "numalloc26": "{count, plural, =0 {Ñö Åļļöçåţîöñš} one {① Åļļöçåţîöñ} other {# Åļļöçåţîöñš}}",
    "number89": "[Ñûɱƀéŕ--П國カ내]",
    "numberof30": "[Ñûɱƀéŕ öƒ Éɱþļöýééš----П國カ내]",
    "numberta19": "[Ñûɱƀéŕ ‐ Ţåƀļé ĥéåðéŕ-----П國カ내]",
    "numemplo77": "{num, plural, one {[# Éɱþļöýéé-----П國カ내]} other {[# Éɱþļöýééš-----П國カ내]}}",
    "numsplit25": "{count, plural, =0 {Ñö Šþļîţš} one {① Šþļîţš} other {# Šþļîţš}}",
    "numtotal132": "[{transactions} Ţöţåļ Ţŕåñšåçţîöñš-------П國カ내]",
    "numtrans131": "{selectedtransactions, plural, one {[① Ţŕåñšåçţîöñ Šéļéçţéð-------------------П國カ내]} other {[# Ţŕåñšåçţîöñš Šéļéçţéð-------------------П國カ내]}}",
    "off56": "[Öƒƒ-П國カ내]",
    "off78": "[Öƒƒ-П國カ내]",
    "off86": "[Öƒƒ-П國カ내]",
    "ograniza261": "[Öŕĝåñîžåţîöñ Îñƒöŕɱåţîöñ-----П國カ내]",
    "ok112": "[ÖĶ-П國カ내]",
    "ok19": "[ÖĶ-П國カ내]",
    "ok26": "[ÖĶ-П國カ내]",
    "ok88": "[ÖĶ-П國カ내]",
    "okay127": "[Öķåý-П國カ내]",
    "okay13": "[Öķåý-П國カ내]",
    "okay14": "[Öķåý--П國カ내]",
    "okay155": "[Öķåý-П國カ내]",
    "okay164": "[Öķåý-П國カ내]",
    "okay212": "[Öķåý-П國カ내]",
    "okay3": "[Öķåý--П國カ내]",
    "okay30": "[Öķåý-П國カ내]",
    "okay39": "[Öķåý--П國カ내]",
    "okay42": "[Öķåý-П國カ내]",
    "okay81": "[Öķåý-П國カ내]",
    "okayclos42": "[Öķåý ‐ çļöšé ɱöðåļ----П國カ내]",
    "oktaintegration": "[Okta Îñţéĝŕåţîöñ----П國カ내]",
    "oldpassw91": "[Öļð þåššŵöŕð îš ŕéǫûîŕéð·--------П國カ내]",
    "oldpassw92": "[Öļð þåššŵöŕð ɱûšţ ƀé åţ ļéåšţ ⑧ çĥåŕåçţéŕš·-------------П國カ내]",
    "on57": "[Öñ-П國カ내]",
    "on77": "[Öñ-П國カ내]",
    "on85": "[Öñ-П國カ내]",
    "onboardi201": "[Öñƀöåŕðîñĝ Ţîţļé Ṁîššîñĝ----П國カ내]]",
    "onboardi3": "[Öñƀöåŕðîñĝ---П國カ내]",
    "onboardi6": "[Öñƀöåŕðîñĝ Ŵåļķţĥŕöûĝĥ-----П國カ내]",
    "onceveri107": "[Öñçé ṽéŕîƒîéð، ýöû’ļļ ƀé åƀļé ţö šéţ ýöûŕ ŕéîɱƀûŕšéɱéñţ šçĥéðûļé· Çöñţåçţ ûš åţ <a class=\"mail\" href=\"mailto:{generalemail}\">{generalemail}</a> ţö ĝéţ ṽéŕîƒîéð·---------------------------------П國カ내]",
    "onedefa1": "[Öñé ðéƒåûļţ þöļîçý ƒöŕ åļļ ûšéŕš-------П國カ내]",
    "onedepar1": "[Öñé ðéþåŕţɱéñţ ƒöŕ åļļ ûšéŕš------П國カ내]",
    "oneexpen17": "[Öñé éẋþéñšé ĥåš ƀééñ ƒļåĝĝéð åñð ɱåý ŕéǫûîŕé ƒûŕţĥéŕ ŕéṽîéŵ·-------------П國カ내]",
    "onefinan1": "[Öñé ƒîñåñçé åþþŕöṽéŕ ƒöŕ þåýɱéñţ-------П國カ내]",
    "onemana1": "[Öñé ɱåñåĝéŕ åþþŕöṽéš-----П國カ내]",
    "oneorgan171": "[<sup>1</sup>Åļļ öŕĝåñîžåţîöñš ûšîñĝ Ţŕåṽéļ öñ ţĥé ƒŕéé þļåñ ɱûšţ ĝö ţĥŕöûĝĥ åççöûñţ ṽéŕîƒîçåţîöñ ŵîţĥ ŢŕåṽéļƁåñķ·-----------------------П國カ내]",
    "oneormor80": "[Öñé öŕ ɱöŕé ƒîéļðš ĥåš åñ éŕŕöŕ·-------П國カ내]",
    "onlinese14": "[Öñļîñé šéåŕçĥ---П國カ내]",
    "only610c59": "[Öñļý ⑥‐①⓪ çĥåŕåçţéŕš åļļöŵéð----------------------------------П國カ내]",
    "onlyallo114": "[Öñļý Åļļöŵ---П國カ내]",
    "onlyallo150": "[Öñļý Åļļöŵ---П國カ내]",
    "onlydecl116": "[Öñļý Ðéçļîñé---П國カ내]",
    "onlydecl149": "[Öñļý Ðéçļîñé---П國カ내]",
    "onlyfina87": "[Öñļý Ƒîñåñçé---П國カ내]",
    "onlymana88": "[Öñļý Ṁåñåĝéŕ---П國カ내]",
    "onlyreim88": "[· Öñļý Ŕéîɱƀûŕšåƀļéš-----П國カ내]",
    "onlytran1": "[Öñļý ţŕåñšåçţîöñš þöšţéð îñ ţĥé þåšţ ③ ýéåŕš åŕé åṽåîļåƀļé· Þļéåšé çĥåţ ŵîţĥ ûš ţö ŕéǫûéšţ öļðéŕ ţŕåñšåçţîöñš· ----------------------------------П國カ내]",
    "onstop112": "[Ñöñšţöþ--П國カ내]",
    "onthefre131": "[Öñ ţĥé ƒŕéé þļåñ، ýöûŕ ɱåîñ ţŕåṽéļ þöļîçý ƒöŕ ýöûŕ öŕĝåñîžåţîöñ ĥåš ƀééñ šéţ ƒöŕ ýöû· Îţ ðîçţåţéš ĥöŵ ţŕîþ ƀûðĝéţš åŕé çåļçûļåţéð åñð ĝûîðéš ţŕåṽéļéŕš´ ƀööķîñĝ šéļéçţîöñš·-----------------------------------П國カ내]",
    "onthefre51": "[Öñ ţĥé ƒŕéé þļåñ، åļļ éɱþļöýééš ŵîļļ ƀé åššîĝñéð ţö ţĥé “Ĝéñéŕåļ” ðéþåŕţɱéñţ·----------------П國カ내]",
    "onthefre68": "[Öñ ţĥé ƒŕéé þļåñ، ýöûŕ ɱåîñ éẋþéñšé þöļîçý ƒöŕ ýöûŕ öŕĝåñîžåţîöñ ĥåš ƀééñ šéţ ƒöŕ ýöû·------------------П國カ내]",
    "oops46": "[Ööþš¡--П國カ내]",
    "open44": "[Öþéñ-П國カ내]",
    "openfiel59": "[Öþéñ Ƒîéļð---П國カ내]",
    "openfiel62": "[Öþéñ Ƒîéļð---П國カ내]",
    "opentext45": "[Öþéñ Ţéẋţ--П國カ내]",
    "optional1": "[Öþţîöñåļ---П國カ내]",
    "optional103": "[Öþţîöñåļ--П國カ내]",
    "optional23": "[(Öþţîöñåļ)----П國カ내]",
    "optional3": "[Öþţîöñåļ---П國カ내]",
    "optional4": "[(öþţîöñåļ)----П國カ내]",
    "optional40": "[(Öþţîöñåļ)----П國カ내]",
    "optional53": "[Öþţîöñåļ--П國カ내]",
    "optional61": "[Öþţîöñåļ--П國カ내]",
    "optional72": "[Öþţîöñåļ--П國カ내]",
    "optionna191": "[Öþţîöñ ñåɱé---П國カ내]",
    "optionna206": "[Öþţîöñ ñåɱé---П國カ내]",
    "optionsw181": "[Öþţîöñš ŵîļļ ƀé ðîšþļåýéð åļþĥåñûɱéŕîçåļļý ƒöŕ éɱþļöýééš·------------П國カ내]",
    "or18": "[Öŕ-П國カ내]",
    "organiza159": "[Öŕĝåñîžåţîöñ Ñåɱé----П國カ내]",
    "organiza182": "[Öŕĝåñîžåţîöñ Ñåɱé----П國カ내]",
    "orgname203": "[Öŕĝ Ñåɱé--П國カ내]",
    "original1": "[Öŕîĝîñåļ Åɱöûñţ----П國カ내]",
    "original90": "[Öŕîĝîñåļ Åɱöûñţ----П國カ내]",
    "orthisbi1": "[öŕ {billedMonthlyText}-----П國カ내]",
    "other21": "[Öţĥéŕ--П國カ내]",
    "other30": "[Öţĥéŕ--П國カ내]",
    "other312": "[Öţĥéŕ--П國カ내]",
    "otherapp78": "[Öţĥéŕ åþþŕöṽéŕš åŕé åššîĝñéð ţö ŕéþöŕţš öƒ {nextMinAmount} åñð åƀöṽé·--------------П國カ내]",
    "othercar241": "[Öţĥéŕ Çåŕðš---П國カ내]",
    "otherdet153": "[Öţĥéŕ Ðéţåîļš---П國カ내]",
    "outofpoc1": "[Öûţ öƒ þöçķéţ éẋþéñšé ţŕåçķîñĝ (îñçļûðîñĝ ɱîļéåĝé)-----------П國カ내]",
    "outofpoc2": "[Öûţ öƒ þöçķéţ ŕéîɱƀûŕšéɱéñţ------П國カ내]",
    "outofpol125": "[Öûţ öƒ Þöļîçý---П國カ내]",
    "outofpol150": "[Öûţ öƒ Þöļîçý---П國カ내]",
    "outofpol61": "[Öûţ öƒ Þöļîçý---П國カ내]",
    "outofpol92": "[Öûţ öƒ Þöļîçý---П國カ내]",
    "overspen93": "[{firstName} <strong>öṽéŕšþéñţ {overspendAmount}</strong> ƀåšéð öñ ţĥéîŕ éẋþéñšé þöļîçý·------------------П國カ내]",
    "owneroft90": "[öŵñéŕ öƒ {email} åððŕéšš-----П國カ내]",
    "paid45": "[Þåîð-П國カ내]",
    "paid61": "[Þåîð-П國カ내]",
    "paiddate125": "[Þåîð Ðåţé--П國カ내]",
    "paidlice1": "[Þåîð Ļîçéñšéš---П國カ내]",
    "paidlice166": "[Þåîð Ļîçéñšéš---П國カ내]",
    "paidplan1": "[Þåîð Þļåñ--П國カ내]",
    "pair27": "[Þåîŕ-П國カ내]",
    "pairerror89": "[Ţĥéŕé ŵåš åñ éŕŕöŕ ŵĥîļé ţŕýîñĝ ţö þåîŕ ţĥîš éẋþéñšé·-----------П國カ내]",
    "pairsuccess88": "[Ţŕåñšåçţîöñ šûççéššƒûļļý þåîŕéð·-------П國カ내]",
    "passport24": "[Þåššþöŕţ Îñƒöŕɱåţîöñ-------П國カ내]",
    "passport25": "[Þåššþöŕţ Ñûɱƀéŕ-----П國カ내]",
    "passport26": "[Þåššþöŕţ Çöûñţŕý-----П國カ내]",
    "passport27": "[Þåššþöŕţ Éẋþîŕåţîöñ------П國カ내]",
    "password1": "[Þåššŵöŕð ðöéš ñöţ ɱééţ ŕéǫûîŕéɱéñţš·--------П國カ내]",
    "password2": "[Þåššŵöŕð ðö ñöţ ɱåţçĥ·-----П國カ내]",
    "password238": "[Þåššŵöŕð--П國カ내]",
    "password29": "[Þåššŵöŕðš ɱûšţ ɱåţçĥ-----П國カ내]",
    "password3": "[Þåššŵöŕð--П國カ내]",
    "password4": "[Þåššŵöŕð îš ŕéǫûîŕéð·-----П國カ내]",
    "password42": "[Þåššŵöŕð îš ŕéǫûîŕéð-----П國カ내]",
    "password44": "[Þåššŵöŕðš ðö ñöţ ɱåţçĥ-----П國カ내]",
    "password47": "[Þåššŵöŕð îš ŕéǫûîŕéð·-----П國カ내]",
    "password5": "[Þåššŵöŕð šûççéššƒûļļý ûþðåţéð------П國カ내]",
    "password7": "[Þåššŵöŕð--П國カ내]",
    "password79": "[Þåššŵöŕð šûççéššƒûļļý ûþðåţéð---------П國カ내]",
    "password81": "[Þåššŵöŕð ɱûšţ ƀé ļöñĝéŕ ţĥåñ ⑧ çĥåŕåçţéŕš·-------------П國カ내]",
    "password83": "[Þåššŵöŕð Çöñƒîŕɱåţîöñ îš ŕéǫûîŕéð·-----------П國カ내]",
    "password84": "[Þåššŵöŕð Çöñƒîŕɱåţîöñ ɱûšţ ƀé ļöñĝéŕ ţĥåñ ⑧ çĥåŕåçţéŕš·-----------------П國カ내]",
    "password90": "[Þåššŵöŕð---П國カ내]",
    "pastdue59": "[Þåšţ Ðûé--П國カ내]",
    "path46": "[Þåţĥ-П國カ내]",
    "paymenta33": "[Þåýɱéñţ Åþþŕöṽéð----П國カ내]",
    "paymenta70": "[Þåýɱéñţ Åççöûñţš----П國カ내]",
    "paymenta8": "[Þåýɱéñţ Åççöûñţ----П國カ내]",
    "paymentc15": "[Þåýɱéñţ Çöñƒîŕɱéð----П國カ내]",
    "paymentd32": "[Þåýɱéñţ Ðåţé---П國カ내]",
    "paymentd80": "[Þåýɱéñţ Ðéţåîļš----П國カ내]",
    "paymentf66": "[Þåýɱéñţ Ƒåîļéð---П國カ내]",
    "paymentm1": "[Þåýɱéñţ Ṁéţĥöð---П國カ내]",
    "paymentm11": "[Þåýɱéñţ Ṁéţĥöðš-----П國カ내]",
    "paymentm15": "[Þåýɱéñţ Ṁéţĥöð-----П國カ내]",
    "paymentm49": "[Þåýɱéñţ Ṁéţĥöð---П國カ내]",
    "paymentm60": "[Þåýɱéñţ Ṁéţĥöð---П國カ내]",
    "paymentp67": "[Þåýɱéñţ Þéñðîñĝ----П國カ내]",
    "payments37": "[Þåýɱéñţ šûççéššƒûļļý çåñçéļéð·-------П國カ내]",
    "payments5": "[Þåýɱéñţš çåñ ƀé çåñçéļļéð öŕ ɱöðîƒîéð ûþ ţö ③∶⑤⑨ ÞṀ ÇŢ öñ ţĥé þåýɱéñţ ðåţé·----------------П國カ내]",
    "payments55": "[Þåýɱéñţ Šçĥéðûļéð----П國カ내]",
    "payments57": "[Þåýɱéñţš--П國カ내]",
    "payments8": "[Þåýɱéñţš--П國カ내]",
    "pending26": "[Þéñðîñĝ--П國カ내]",
    "pending4": "[Þéñðîñĝ---П國カ내]",
    "pending46": "[Þéñðîñĝ--П國カ내]",
    "pending50": "[Þéñðîñĝ--П國カ내]",
    "pendinga71": "[Þéñðîñĝ Åþþŕöṽåļ----П國カ내]",
    "pendingo204": "[Þéñðîñĝ öŕ ŕéçûŕŕîñĝ ţŕåñšåçţîöñš çöûļð ƀé þŕöçéššéð·-----------П國カ내]",
    "pendingt11": "[Þéñðîñĝ ({total})------П國カ내]",
    "pendingt14": "[Þéñðîñĝ ţŕåñšåçţîöñš åŕé åṽåîļåƀļé ƒöŕ ţĥé þåšţ ③⓪ ðåýš öñļý-------------------П國カ내]",
    "pendingt30": "[Þéñðîñĝ {totalpendingtransactions}--------П國カ내]",
    "pendingt63": "[Þéñðîñĝ Ţŕåñšåçţîöñ----П國カ내]",
    "percenta20": "[Þéŕçéñţåĝé----П國カ내]",
    "perexpen141": "[Þéŕ Éẋþéñšé Ļîɱîţ----П國カ내]",
    "perexpen144": "[Þéŕ Éẋþéñšé---П國カ내]",
    "permissi10": "[Þéŕɱîššîöñš---П國カ내]",
    "permissi70": "[Þéŕɱîššîöñš---П國カ내]",
    "permissi81": "[Þéŕɱîššîöñš---П國カ내]",
    "personal156": "[• Þéŕšöñåļ Šýñçéð Çåŕð-----П國カ내]",
    "personal159": "[Þéŕšöñåļ Šýñçéð Çåŕð------П國カ내]",
    "personal17": "[Þéŕšöñåļ Çåŕð⁄Ŕéîɱƀûŕšåƀļé--------П國カ내]",
    "personal2": "[Þéŕšöñåļ Çåŕð⁄Ŕéîɱƀûŕšåƀļé Éẋþéñšé-----------П國カ내]",
    "personal20": "[Þéŕšöñåļ Çåŕð⁄Ŕéîɱƀûŕšåƀļé------П國カ내]",
    "personal22": "[Þéŕšöñåļ Çåŕð Ŕéƒûñð-----П國カ내]",
    "personal29": "[Þéŕšöñåļ---П國カ내]",
    "personal3": "[Þéŕšöñåļ Ţŕåñšåçţîöñš-----П國カ내]",
    "personal44": "[Þéŕšöñåļ Çåŕð⁄Ŕéîɱƀûŕšåƀļé Éẋþéñšé-------П國カ내]",
    "personal5": "[Þéŕšöñåļ Çåŕð⁄Ŕéîɱƀûŕšåƀļé------П國カ내]",
    "personal52": "[Þéŕšöñåļ--П國カ내]",
    "personal8": "[Þéŕšöñåļ⁄Ŕéîɱƀûŕšåƀļé-------П國カ내]",
    "personalcard83": "[Þéŕšöñåļ Çåŕð⁄Ŕéîɱƀûŕšåƀļé Éẋþéñšé-------П國カ내]",
    "phone10": "[Þĥöñé--П國カ내]",
    "phone189": "[Þĥöñé--П國カ내]",
    "phone39": "[Þĥöñé--П國カ내]",
    "phonekey1": "[Þĥöñé--П國カ내]",
    "phonenum170": "[Þĥöñé Ñûɱƀéŕ---П國カ내]",
    "phonenum2": "[Þĥöñé Ñûɱƀéŕ----П國カ내]",
    "phoneopt36": "[Þĥöñé ≤šþåñ çļåšš≂´ţƀ‐îñþûţ‐ĝŕöûþ‿‿öþţîöñåļ´≥(Öþţîöñåļ)≤⁄šþåñ≥-------------------П國カ내]",
    "phoneplaceholder11": "[(ẊẊẊ) ẊẊẊẊ‐ẊẊẊ-----П國カ내]",
    "photorec10": "[Þĥöţö Ŕéçéîþţ Ŕéǫûîŕéð-------П國カ내]",
    "photorec2": "[Þĥöţö Ŕéçéîþţ Ŕéǫûîŕéð-----П國カ내]",
    "physical54": "[Þĥýšîçåļ--П國カ내]",
    "physical78": "[Þĥýšîçåļ Çåŕð---П國カ내]",
    "physical79": "[Þĥýšîçåļ Çöŕþöŕåţé Çåŕð-----П國カ내]",
    "physical8": "[Þĥýšîçåļ Çöŕþöŕåţé Çåŕð-----П國カ내]",
    "plansand1": "[Þļåñš ⅋ Þŕîçîñĝ----П國カ내]",
    "platinum303": "[Þļåţîñûɱ Þļûš® Ṁåšţéŕçåŕð® Ɓûšîñéšš çåŕð---------П國カ내]",
    "pleaseca319": "[Þļéåšé çåļļ çûšţöɱéŕ šéŕṽîçé<br />⑧⓪⓪‐③④④‐⑤⑥⑨⑥----------П國カ내]",
    "pleaseco1": "[Þļéåšé çöñţåçţ Çûšţöɱéŕ Šéŕṽîçé ṽîå çĥåţ öŕ éɱåîļ ûš åţ {emailHref}· ---------------------П國カ내]",
    "pleaseco10": "[Þļéåšé Çöñƒîŕɱ---П國カ내]",
    "pleaseco115": "[Þļéåšé çöñƒîŕɱ ţĥåţ ýöû ŵåñţ ţö ðéļéţé ţĥîš ĥöšţ åñð ðéļéĝåţé ĝŕöûþ·--------------П國カ내]",
    "pleaseco13": "[Þļéåšé çöñţåçţ ŢŕåṽéļƁåñķ ţö éñåƀļé ðåîļý ŕéîɱƀûŕšéɱéñţš· Ýöû çåñ çĥåţ ŵîţĥ ûš îñ ţĥé åþþ öŕ éɱåîļ ûš åţ <a href=\"mailto:{generalSupportEmail}\" target=\"blank\">{generalSupportEmail}</a>--------------------------------------П國カ내]",
    "pleaseco17": "[Þļéåšé çöñţåçţ ŢŕåṽéļƁåñķ ţö éñåƀļé þŕéɱîûɱ îñšîĝĥţš· Ýöû çåñ çĥåţ ŵîţĥ ûš îñ ţĥé åþþ öŕ éɱåîļ ûš åţ <a href=\"mailto:{generalSupportEmail}\" target=\"blank\">{generalSupportEmail}</a>--------------------------------------------------------П國カ내]",
    "pleaseco181": "[Þļéåšé çöñƒîŕɱ ýöû ŵåñţ ţö ðéļéţé ţĥîš çûšţöɱ ƒîéļð·-----------П國カ내]",
    "pleaseco184": "[Þļéåšé çöñƒîŕɱ ýöû ŵåñţ ţö ðéļéţé ţĥîš çûšţöɱ ƒîéļð·-----------П國カ내]",
    "pleaseco22": "[Þļéåšé çöñƒîŕɱ ýöû ŵåñţ ţö ŕéɱöṽé ţĥîš çåŕð·--------------П國カ내]",
    "pleaseco24": "[Þļéåšé Çöñƒîŕɱ---П國カ내]",
    "pleaseco40": "[Þļéåšé çöñƒîŕɱ ýöû ŵåñţ ţö ðéļéţé {name}´š þŕöƒîļé·----------------П國カ내]",
    "pleaseco45": "[Þļéåšé Çöñƒîŕɱ-----П國カ내]",
    "pleaseco76": "[Þļéåšé çöñƒîŕɱ ýöû ŵåñţ ţö ŕéɱöṽé ţĥîš çŕéðîţ çåŕð·-----------П國カ내]",
    "pleaseco93": "[Þļéåšé çöñƒîŕɱ ţĥåţ ýöû ŵåñţ ţö ţéŕɱîñåţé ţĥîš éɱþļöýéé’š åççöûñţ·--------------П國カ내]",
    "pleasedo120": "[Þļéåšé ðöñ´ţ ļéåṽé ţĥé þåĝé· Ýöûŕ ÇŠṼ ƒîļé ŵîļļ åûţöɱåţîçåļļý ðöŵñļöåð öñçé ŕéåðý·-----------------П國カ내]",
    "pleasedo2": "[Þļéåšé ðöñ´ţ ļéåṽé ţĥé þåĝé· Ýöûŕ ƒîļéš ŵîļļ åûţöɱåţîçåļļý ðöŵñļöåð öñçé ŕéåðý·----------------П國カ내]",
    "pleasedo28": "[{error} Þļéåšé ðöûƀļé‐çĥéçķ ýöûŕ îñƒöŕɱåţîöñ، åðð å ñéŵ çåŕð öŕ çöñţåçţ ûš ƒöŕ ĥéļþ· ------------------------П國カ내]",
    "pleaseen117": "[Þļéåšé éñţéŕ åļļ {revealcodelength} ñûɱƀéŕš·---------П國カ내]",
    "pleaseen12": "[Þļéåšé éñšûŕé ţŕåṽéļéŕ îñƒöŕɱåţîöñ îš çöŕŕéçţ·--------------П國カ내]",
    "pleaseen63": "[Þļéåšé éñţéŕ å ðîšçöûñţ ñûɱƀéŕ öŕ ƀîļļîñĝ çöðé ƀéƒöŕé šåṽîñĝ·--------------------------------------------------------------------------П國カ내]",
    "pleasefi252": "[Þļéåšé ƒîļļ îñ ţĥé ŕéǫûîŕéð îñƒöŕɱåţîöñ· ŢŕåṽéļƁåñķ ŵîļļ ŵöŕķ ŵîţĥ ţĥé ŕéǫûéšţéð ƀåñķ ţö ðîšþļåý ýöûŕ îñƒöŕɱåţîöñ·-----------------------П國カ내]",
    "pleasere198": "[Þļéåšé ŕéçöññéçţ ţö {bankpublicname}·------------П國カ내]",
    "pleasere199": "[Þļéåšé ŕéçöññéçţ ţö {bankpublicname} ({bankdescription})·---------------П國カ내]",
    "pleasere290": "[Þļéåšé ŕéçöññéçţ ţĥé ƀåñķ çöññéçţîöñ ţö çöɱþļéţé šéţûþ·------------П國カ내]",
    "pleasere60": "[Þļéåšé، ŕé‐éñţéŕ ýöûŕ çŕéðéñţîåļš·-------П國カ내]",
    "pleasese16": "[Þļéåšé šéļéçţ ƒéŵéŕ åñð ţŕý åĝåîñ·-----------П國カ내]",
    "pleasese186": "[Þļéåšé šéļéçţ åñ öþţîöñ·-----П國カ내]",
    "pleasese187": "[Þļéåšé šéļéçţ åţ ļéåšţ öñé öþţîöñ·-------П國カ내]",
    "pleasese51": "[Þļéåšé šéļéçţ ƒéŵéŕ åñð ţŕý åĝåîñ·-----------П國カ내]",
    "pleasese62": "[Þļéåšé šéļéçţ å ŕéñţåļ çåŕ çöɱþåñý·-------------------------------------------П國カ내]",
    "pleasese75": "[ Þļéåšé šéļéçţ éîţĥéŕ ∖″Ƒîñåñçé Åþþŕöṽéŕ ƒöŕ Éñţîŕé Öŕĝåñîžåţîöñ∖″ öŕ šþéçîƒý ƒîñåñçé ðéþåŕţɱéñţš--------------------П國カ내]",
    "pleasesi2": "[Þļéåšé šîĝñ îñ ţö ýöûŕ åççöûñţ·-------П國カ내]",
    "pleaseup49": "[Þļéåšé ûþļöåð å ·çšṽ ƒîļé·------П國カ내]",
    "policyna74": "[Þöļîçý Ñåɱé îš ŕéǫûîŕéð·-----П國カ내]",
    "policyna75": "[Þöļîçý Ñåɱé îš ŕéǫûîŕéð·-----П國カ내]",
    "policyna79": "[Þöļîçý Ñåɱé îš ŕéǫûîŕéð·-----П國カ내]",
    "policyna82": "[Þöļîçý ñåɱé îš ŕéǫûîŕéð·-----П國カ내]",
    "port9": "[Þöŕţ-П國カ내]",
    "postalco4": "[Þöšţåļ Çöðé----П國カ내]",
    "posted24": "[Þöšţéð {date}--П國カ내]",
    "posted28": "[Þöšţéð--П國カ내]",
    "postedba14": "[Þöšţéð Ɓåļåñçé---П國カ내]",
    "postedba28": "[Þöšţéð Ɓåļåñçé---П國カ내]",
    "postedba38": "[Þöšţéð Ɓåļåñçé---П國カ내]",
    "postedda1": "[Þöšţéð ðåţé ‐ {monthAndDay}---------П國カ내]",
    "postedda129": "[Þöšţéð Ðåţé---П國カ내]",
    "postedda14": "[Þöšţéð Ðåţé---П國カ내]",
    "postedda166": "[Þöšţéð Ðåţé Ŕåñĝé (ŕéǫûîŕéð)-------П國カ내]",
    "postedda2": "[Þöšţéð ðåţé ‐ Ñö þöšţéð ðåţé---------П國カ내]",
    "postedda28": "[Þöšţéð {date}----П國カ내]",
    "postedda344": "[Þöšţéð Ðåţé---П國カ내]",
    "postedda56": "[Þöšţéð {defaultdaterangetext}------П國カ내]",
    "poweredb31": "[Þöŵéŕéð ƀý---П國カ내]",
    "predictc24": "[Þŕéðîçţ Çöšţš----П國カ내]",
    "premiumi18": "[Þŕéɱîûɱ Îñšîĝĥţš----П國カ내]",
    "preparin1": "[Þŕéþåŕîñĝ Ðöŵñļöåð···-----П國カ내]",
    "preparing168": "[Þŕéþåŕîñĝ Éẋþöŕţ···-------П國カ내]",
    "presentn142": "[Þŕéšéţ Ñåɱé---П國カ내]",
    "presetfo137": "[Þŕéšéţ Ƒöûñð---П國カ내]",
    "presetop121": "[Þŕéšéţ (öþţîöñåļ)----П國カ내]",
    "presetop122": "[Þŕéšéţ (öþţîöñåļ) ðŕöþðöŵñ------П國カ내]",
    "previous12": "[þŕéṽîöûš--П國カ내]",
    "previous73": "[Þŕéṽîöûšļý Šéļéçţéð----П國カ내]",
    "previous74": "[Þŕéṽîöûšļý Šéļéçţéð----П國カ내]",
    "previous75": "[Þŕéṽîöûšļý Šéļéçţéð----П國カ내]",
    "previous76": "[Þŕéṽîöûšļý Šéļéçţéð----П國カ내]",
    "pricerat1": "[{yearlyRateDisplay}⁄ûšéŕ⁄ɱöñţĥ-------П國カ내]",
    "pricewit1": "[{oldYearlyRateDisplay}-----------------П國カ내]",
    "primaryd1": "[Þŕîɱåŕý ðéþåŕţɱéñţ šåṽéð·--------П國カ내]",
    "primaryd27": "[Þŕîɱåŕý Ðéþåŕţɱéñţ ({name})---------П國カ내]",
    "primaryd87": "[Þŕîɱåŕý Ðéþåŕţɱéñţ----П國カ내]",
    "privacyp51": "[Þŕîṽåçý Þöļîçý-----П國カ내]",
    "proceeda90": "[Þŕöçééð Åñýŵåý---П國カ내]",
    "processi11": "[Þŕöçéššîñĝ···---П國カ내]",
    "products92": "[Þŕöðûçţš--П國カ내]",
    "profilei2": "[þŕöƒîļé îɱåĝé---П國カ내]",
    "profilei3": "[Þŕöƒîļé Îñƒöŕɱåţîöñ------П國カ내]",
    "properti177": "[Þŕöþéŕţîéš---П國カ내]",
    "provider1": "[{provider} Ŕéçéîþţ Îñţéĝŕåţîöñ----------П國カ내]",
    "provider70": "[{providerLabel} Çåţéĝöŕý-----П國カ내]",
    "province2": "[Þŕöṽîñçé---П國カ내]",
    "publicem45": "[Þûƀļîç éɱåîļš åŕé ñöţ åļļöŵéð· Þļéåšé ûšé ýöûŕ çöɱþåñý éɱåîļ-------------П國カ내]",
    "publicke8": "[Þûƀļîç Ķéý---П國カ내]",
    "quickboo1": "[Ǫûîçķƀööķš Öñļîñé (šéļƒ‐šéŕṽîçé)-------П國カ내]",
    "quickboo88": "[ǪûîçķƁööķš ĜĻ Çļåšš----П國カ내]",
    "quickfil6": "[Ǫûîçķ Ƒîļţéŕš∶---П國カ내]",
    "quicklya71": "[Ǫûîçķļý Åðð öŕ Ûþðåţé Ýöûŕ Éɱþļöýééš ṽîå ÇŠṼ---------П國カ내]",
    "quicksca7": "[ǪûîçķŠçåñ---П國カ내]",
    "rate119": "[Ŕåţé-П國カ내]",
    "rate205": "[Ŕåţé-П國カ내]",
    "reactiva99": "[Ŕéåçţîṽåţé---П國カ내]",
    "readterm5": "[Ŕéåð Ţéŕɱš ⅋ Çöñðîţîöñš-------П國カ내]",
    "readthep100": "[Ŕéåð ţĥé Þŕîṽåçý Þöļîçý-----П國カ내]",
    "readthet99": "[Ŕéåð ţĥé Ţéŕɱš öƒ Ûšé-----П國カ내]",
    "readytos1": "[Ŕéåðý ţö Šûƀɱîţ-----П國カ내]",
    "reasonfo27": "[Ŕéåšöñ ƒöŕ ðéçļîñé ‐ {reason}·----------П國カ내]",
    "reasonfo59": "[Ŕéåšöñ ƒöŕ ðéçļîñé ‐ {declinereason}--------П國カ내]",
    "reasonfo78": "[Ŕéåšöñ ƒöŕ ðéçļîñé ‐ {declinereason}--------П國カ내]",
    "reassign273": "[Ŕéåššîĝñ ţö {newuserfullname}¿-------П國カ내]",
    "reassign274": "[Ŕéåššîĝñ çåŕð¿---П國カ내]",
    "reauthen78": "[Ŕéåûţĥéñţîçåţé---П國カ내]",
    "receipt17": "[Ŕéçéîþţ---П國カ내]",
    "receipt22": "[Ŕéçéîþţ--П國カ내]",
    "receipt4": "[Ŕéçéîþţ---П國カ내]",
    "receipt98": "[Ŕéçéîþţ--П國カ내]",
    "receipta1": "[Ŕéçéîþţ åţţåçĥɱéñţ----П國カ내]",
    "receiptp18": "[ŕéçéîþţ þŕéṽîéŵ îɱåĝé-------П國カ내]",
    "receiptr113": "[Ŕéçéîþţ Ŕéǫûîŕéɱéñţ----П國カ내]",
    "receipts156": "[Ŕéçéîþţ Šţåţûš åš {label}------П國カ내]",
    "receipts193": "[Ŕéçéîþţ Šţåţûš ðŕöþðöŵñ-----П國カ내]",
    "receipts38": "[Ŕéçéîþţ Šţåţûš---П國カ내]",
    "receipts44": "[Ŕéçéîþţ Šţåţûš---П國カ내]",
    "recently6": "[Ŕéçéñţļý Šçåññéð-----П國カ내]",
    "recently9": "[Ŕéçéñţļý Ûšéð---П國カ내]",
    "recentre20": "[Ŕéçéñţ Ŕéþöŕţš---П國カ내]",
    "recommen2": "[Ŕéçöɱɱéñðéð----П國カ내]",
    "reconnec269": "[Ŕéçöññéçţ--П國カ내]",
    "recurrin11": "[Ŕéçûŕŕîñĝ Éɱåîļ Éẋþöŕţ-----П國カ내]",
    "recurrin141": "[Ŕéçûŕŕîñĝ Šþéñð Ļîɱîţ-----П國カ내]",
    "redirect12": "[Ŕéðîŕéçţîñĝ ýöû ţö <span>{ssoprovidername}</span>·-----------П國カ내]",
    "refineth110": "[Ŕéƒîñé ţĥé ðåţå ýöû ŵåñţ ţö éẋþöŕţ· Îƒ ţĥéŕé åŕé ƒîļţéŕš åþþļîéð öñ ţĥé þŕéṽîöûš šçŕééñ، ţĥéý ŵîļļ åļšö ƀé îñ éƒƒéçţ ĥéŕé·-------------------------П國カ내]",
    "refresh86": "[Ŕéƒŕéšĥ--П國カ내]",
    "refund19": "[Ŕéƒûñð--П國カ내]",
    "refund24": "[Ŕéƒûñð--П國カ내]",
    "refund29": "[Ŕéƒûñð--П國カ내]",
    "refundto12": "[Ŕéƒûñð ţö Çöɱþåñý------П國カ내]",
    "refundto48": "[Ŕéƒûñð ţö Çöɱþåñý----П國カ내]",
    "refundto5": "[Ŕéƒûñð ţö Çöɱþåñý------П國カ내]",
    "refundto6": "[Ŕéƒûñð ţö Çöɱþåñý------П國カ내]",
    "refundtoc81": "[Ŕéƒûñð ţö Çöɱþåñý----П國カ내]",
    "reimburs1": "[Ŕéîɱƀûŕšéɱéñţš---П國カ내]",
    "reimburs100": "[Ŕéîɱƀûŕšéɱéñţ Åççöûñţ-----П國カ내]",
    "reimburs105": "[Ŕéîɱƀûŕšéɱéñţ Šçĥéðûļé-----П國カ내]",
    "reimburs114": "[Ŕéîɱƀûŕšåƀļé---П國カ내]",
    "reimburs123": "[Ŕéîɱƀûŕšéɱéñţ Ðåţé----П國カ내]",
    "reimburs152": "[Ŕéîɱƀûŕšéɱéñţ Šçĥéðûļé-----П國カ내]",
    "reimburs153": "[Ŕéîɱƀûŕšéɱéñţš çåñ ƀé ŕûñ ðåîļý، ɱöñţĥļý (①šţ)، åñð šéɱî ɱöñţĥļý (①šţ åñð ①⑤ţĥ)·-----------------П國カ내]",
    "reimburs155": "[Ŕéîɱƀûŕšåƀļé Éẋþéñšéš Öñļý------П國カ내]",
    "reimburs16": "[Ŕéîɱƀûŕšéɱéñţ----П國カ내]",
    "reimburs161": "[Ŕéîɱƀûŕšéɱéñţš åŕé ñöţ éñåƀļéð-------П國カ내]",
    "reimburs165": "[Ŕéîɱƀûŕšéɱéñţš---П國カ내]",
    "reimburs172": "[Ŕéîɱƀûŕšéɱéñţš---П國カ내]",
    "reimburs180": "[Ŕéîɱƀûŕšéɱéñţš Çöɱþåñý Ɓåñķ Åççöûñţ Ðŕöþðöŵñ---------П國カ내]",
    "reimburs181": "[Ŕéîɱƀûŕšéɱéñţ Šçĥéðûļé-----П國カ내]",
    "reimburs182": "[Ŕéîɱƀûŕšéɱéñţš çåñ ƀé ŕûñ ðåîļý، ɱöñţĥļý (①šţ)، åñð šéɱî ɱöñţĥļý (①šţ åñð ①⑤ţĥ)·-----------------П國カ내]",
    "reimburs183": "[Ŕéîɱƀûŕšéɱéñţš Ŕéîɱƀûŕšéɱéñţ Šçĥéðûļé Ðŕöþðöŵñ----------П國カ내]",
    "reimburs22": "[Ŕéîɱƀûŕšåƀļé----П國カ내]",
    "reimburs24": "[Ŕéîɱƀûŕšɱéñţ Ƒéé----П國カ내]",
    "reimburs27": "[Ŕéîɱƀûŕšåƀļé----П國カ내]",
    "reimburs30": "[¦ Ŕéîɱƀûŕšåƀļé---П國カ내]",
    "reimburs42": "[Ŕéîɱƀûŕšéɱéñţ Åççöûñţ-------П國カ내]",
    "reimburs57": "[Ŕéîɱƀûŕšéɱéñţ Åççöûñţ Ƒåîļéð---------П國カ내]",
    "reimburs58": "[Ŕéîɱƀûŕšéɱéñţ ţö Ɓåñķ Åççöûñţ Ƒåîļéð-----------П國カ내]",
    "reimburs6": "[Ŕéîɱƀûŕšéɱéñţ Šçĥéðûļé-----П國カ내]",
    "reimburs60": "[Ŕéîɱƀûŕšéɱéñţ Åççöûñţ Åððéð---------П國カ내]",
    "reimburs7": "[Ŕéîɱƀûŕšéɱéñţš çåñ ƀé ŕûñ ðåîļý، ɱöñţĥļý (①šţ)، åñð šéɱî ɱöñţĥļý (①šţ åñð ①⑤ţĥ)·-----------------П國カ내]",
    "reimburs89": "[Ŕéîɱƀûŕšéɱéñţ---П國カ내]",
    "remove12": "[Ŕéɱöṽé--П國カ내]",
    "remove14": "[Ŕéɱöṽé--П國カ내]",
    "remove2": "[Ŕéɱöṽé--П國カ내]",
    "remove22": "[Ŕéɱöṽé--П國カ내]",
    "remove24": "[Ŕéɱöṽé--П國カ내]",
    "remove3": "[Ŕéɱöṽé--П國カ내]",
    "remove30": "[Ŕéɱöṽé--П國カ내]",
    "remove54": "[Ŕéɱöṽé--П國カ내]",
    "remove77": "[Ŕéɱöṽé--П國カ내]",
    "remove87": "[Ŕéɱöṽé--П國カ내]",
    "removeac94": "[Ŕéɱöṽé Åççöûñţ---П國カ내]",
    "removeba93": "[Ŕéɱöṽé ƀåñķ åççöûñţ¿-----П國カ내]",
    "removeca20": "[Ŕéɱöṽé Çåŕð----П國カ내]",
    "removeca75": "[Ŕéɱöṽé Çåŕð---П國カ내]",
    "removecr21": "[Ŕéɱöṽé Çŕéðîţ Çåŕð------П國カ내]",
    "removedr210": "[Ŕéɱöṽé Ðŕöþðöŵñ Ṽåļûé-----П國カ내]",
    "removein5": "[Ŕéɱöṽé Îñţéĝŕåţîöñ----П國カ내]",
    "removeintegration": "[Ŕéɱöṽé Îñţéĝŕåţîöñ----П國カ내]",
    "removere52": "[Ŕéɱöṽé Ŕéîɱƀûŕšéɱéñţ Åççöûñţ---------П國カ내]",
    "removing6": "[Ŕéɱöṽîñĝ Îñţéĝŕåţîöñ-----П國カ내]",
    "removing7": "[Ŕéɱöṽîñĝ Îñţéĝŕåţîöñ-----П國カ내]",
    "rentalca17": "[Ŕéñţåļ Çåŕ Ļöýåļţý Þŕöĝŕåɱ Ñûɱƀéŕ----------П國カ내]",
    "rentalca29": "[Ŕéñţåļ Çåŕ Ļöýåļţý Þŕöĝŕåɱ--------П國カ내]",
    "rentalca59": "[Ŕéñţåļ Çåŕ---П國カ내]",
    "repeated17": "[Ŕéþéåţéð Šţöþ----П國カ내]",
    "repeated18": "[Ŕéþéåţéð šţöþ----П國カ내]",
    "repeatex1": "[Ŕéþéåţ Éẋþéñšéš-----П國カ내]",
    "repeatex11": "[Ŕéþéåţ Éẋþéñšé-----П國カ내]",
    "repeatex47": "[Ŕéþéåţ Éẋþéñšé---П國カ내]",
    "repeatex5": "[Ŕéþéåţ Éẋþéñšé-----П國カ내]",
    "report94": "[Ŕéþöŕţ--П國カ내]",
    "reportac53": "[Ŕéþöŕţ Åçţîṽé---П國カ내]",
    "reportap51": "[Ŕéþöŕţ Åþþŕöṽéð----П國カ내]",
    "reportco110": "[Ŕéþöŕţ Çöɱɱéñţš----П國カ내]",
    "reportco145": "[Ŕéþöŕţ Çöɱɱéñţš----П國カ내]",
    "reportin1": "[Ŕéþöŕţîñĝ--П國カ내]",
    "reportna12": "[Ŕéþöŕţ Ñåɱé---П國カ내]",
    "reportna7": "[Ŕéþöŕţ Ñåɱé----П國カ내]",
    "reports10": "[Ŕéþöŕţš--П國カ내]",
    "reports46": "[Ŕéþöŕţš--П國カ내]",
    "reportsa11": "[Ŕéþöŕţš Åþþŕöṽéð Ɓý Ƒîñåñçé---------П國カ내]",
    "reportsa57": "[Ŕéþöŕţš Åŕé Ðîšåƀļéð Ƒöŕ Ýöûŕ Çöɱþåñý--------П國カ내]",
    "reportsa8": "[Reports Are Disabled For Your Company-----П國カ내]",
    "reportsc1": "[Ŕéþöŕţš çŕéåţéð ƀûţ ñöţ šûƀɱîţţéð (åççŕûåļ)---------П國カ내]",
    "reportsc13": "[Ŕéþöŕţš Çŕéåţéð-----П國カ내]",
    "reportst112": "[Ŕéþöŕţ Šţåţûš---П國カ내]",
    "reportst119": "[Ŕéþöŕţ Šţåţûš---П國カ내]",
    "reportst151": "[Ŕéþöŕţ Šţåţûš---П國カ내]",
    "reportst37": "[Ŕéþöŕţ Šţåţûš---П國カ내]",
    "reportsu14": "[<b>Ŕéþöŕţ Šûƀɱîţţéð</b> ƀý <b>{fullname}</b>---------П國カ내]",
    "reportsu52": "[Ŕéþöŕţ Šûƀɱîţţéð----П國カ내]",
    "reportun54": "[Ŕéþöŕţ Ûñåššîĝñéð----П國カ내]",
    "request267": "[Ŕéǫûéšţ--П國カ내]",
    "requesta284": "[Ŕéǫûéšţ å Ɓåñķ---П國カ내]",
    "requestb251": "[Ŕéǫûéšţ Ɓåñķ Šýñç----П國カ내]",
    "requeste254": "[Ŕéǫûéšţéð Ɓåñķ---П國カ내]",
    "requeste345": "[Ŕéǫûéšţéð Ɓåñķ ‐ ðŕöþðöŵñ------П國カ내]",
    "requestm282": "[Ŕéǫûéšţ ɱöŕé Ɓåñķš öŕ Çåŕð þŕöĝŕåɱš--------П國カ내]",
    "requestn220": "[Ŕéǫûéšţ å Ñéŵ Çöðé----П國カ내]",
    "requests11": "[Ŕéǫûéšţ Šûƀɱîţţéð----П國カ내]",
    "requests285": "[Ŕéǫûéšţ Šûƀɱîţţéð----П國カ내]",
    "requests295": "[Ŕéǫûéšţ Šýñç---П國カ내]",
    "required1": "[Ŕéǫûîŕéð---П國カ내]",
    "required118": "[Ŕéǫûîŕéð--П國カ내]",
    "required161": "[Ŕéǫûîŕéð--П國カ내]",
    "required19": "[Ŕéǫûîŕéð---П國カ내]",
    "required193": "[Ŕéǫûîŕéð--П國カ내]",
    "required22": "[Ŕéǫûîŕéð--П國カ내]",
    "required23": "[Ŕéǫûîŕéð---П國カ내]",
    "required25": "[Ŕéǫûîŕéð---П國カ내]",
    "required26": "[Ŕéǫûîŕéð---П國カ내]",
    "required3": "[Ŕéǫûîŕéð--П國カ내]",
    "required47": "[Ŕéǫûîŕéð·--П國カ내]",
    "required58": "[Ŕéǫûîŕéð--П國カ내]",
    "required6": "[Ŕéǫûîŕéð--П國カ내]",
    "required60": "[Ŕéǫûîŕéð--П國カ내]",
    "required67": "[Ŕéǫûîŕéð--П國カ내]",
    "required70": "[Ŕéǫûîŕéð--П國カ내]",
    "required73": "[Ŕéǫûîŕéð--П國カ내]",
    "required92": "[Ŕéǫûîŕéð--П國カ내]",
    "requiree99": "[Ŕéǫûîŕé Éẋþéñšé Šþļîţţîñĝ------П國カ내]",
    "requirem128": "[Ŕéǫûîŕé Ṁéŕĝîñĝ öƒ Éẋþéñšé ŵîţĥ Çöŕþöŕåţé Çåŕð Ţŕåñšåçţîöñ------------П國カ내]",
    "requirem204": "[Ŕéǫûîŕéɱéñţ---П國カ내]",
    "requirem97": "[Ŕéǫûîŕéɱéñţ---П國カ내]",
    "resendem160": "[Ŕéšéñð Éɱåîļ---П國カ내]",
    "resendem6": "[Ŕéšéñð Éɱåîļ---П國カ내]",
    "resendem7": "[Ŕéšéñð Éɱåîļ---П國カ내]",
    "resendem89": "[Ŕéšéñð Éɱåîļ---П國カ내]",
    "resendem9": "[Ŕéšéñð Éɱåîļ----П國カ내]",
    "resendin97": "[Ŕéšéñð Îñṽîţé---П國カ내]",
    "reset10": "[Ŕéšéţ--П國カ내]",
    "reset103": "[Ŕéšéţ--П國カ내]",
    "reset25": "[Ŕéšéţ--П國カ내]",
    "reset45": "[Ŕéšéţ--П國カ내]",
    "reset8": "[Ŕéšéţ--П國カ내]",
    "resetapitoken": "[Ŕéšéţ ÅÞÎ Ţöķéñ----П國カ내]",
    "resetpas2": "[Ŕéšéţ Þåššŵöŕð---П國カ내]",
    "resetpas20": "[Ŕéšéţ Þåššŵöŕð---П國カ내]",
    "restore124": "[Ŕéšţöŕé--П國カ내]",
    "restoree112": "[Ŕéšţöŕé Éẋþéñšéš----П國カ내]",
    "retry53": "[Ŕéţŕý--П國カ내]",
    "returned65": "[Ŕéţûŕñéð--П國カ내]",
    "returnto67": "[Ŕéţûŕñ ţö Ŕéþöŕţ----П國カ내]",
    "returnto95": "[Ŕéţûŕñ ţö ŕéþöŕţ----П國カ내]",
    "reveal91": "[Ŕéṽéåļ--П國カ내]",
    "revealca38": "[Ŕéṽéåļ Çåŕð Ñûɱƀéŕ----П國カ내]",
    "reviewdu10": "[Ŕéṽîéŵ Ðûþļîçåţéš----П國カ내]",
    "reviewdu96": "[Ŕéṽîéŵ Ðûþļîçåţéš----П國カ내]",
    "reviewpl15": "[Ŕéṽîéŵ þļåţƒöŕɱ (é·ĝ· Ĝ② Çŕöŵð öŕ Çåþţéŕŕå)---------П國カ내]",
    "rewardpo1": "[Ŕéŵåŕð Þöļîçý---П國カ내]",
    "rewardpo141": "[Ŕéŵåŕð Þöļîçý---П國カ내]",
    "rewardpo147": "[Ŕéŵåŕð Þöļîçý∶ Åûţö Ŕéŵåŕðš------П國カ내]",
    "rewardpo150": "[Ŕéŵåŕð Þöļîçý∶ Ļöðĝîñĝ‐Ẋ-----П國カ내]",
    "rewardpo166": "[Ŕéŵåŕð Þöļîçý∶ Ŕéŵåŕðš åɱöûñţ ‐ þéŕçéñţåĝé---------П國カ내]",
    "rewards12": "[Ŕéŵåŕðš--П國カ내]",
    "rewardsa155": "[Ŕéŵåŕðš Åɱöûñţ---П國カ내]",
    "rewardsb16": "[Ŕéŵåŕðš Ɓåļåñçé-----П國カ내]",
    "rewardsg87": "[Ŕéŵåŕðš ĝîṽé ýöûŕ éɱþļöýééš åñ îñçéñţîṽé ţö šþéñð ļéšš ţĥåñ ţĥé ļöðĝîñĝ ƀûðĝéţ ðîçţåţéð ƀý ýöûŕ ţŕåṽéļ þöļîçý· Šéļéçţ ŵĥåţ þéŕçéñţåĝé öƒ ţĥéîŕ šåṽîñĝš ýöû´ð ļîķé ţö ĝîṽé ƀåçķ ţö ţĥéɱ åš ŕéŵåŕðš þöîñţš، ŕéðééɱåƀļé îñ öûŕ ŕéŵåŕðš šţöŕé·-----------------------------------------------П國カ내]",
    "rewardsp167": "[Ŕéŵåŕðš Þöļîçý∶ Šåṽé-----П國カ내]",
    "rewardyo146": "[Ŕéŵåŕð ýöûŕ éɱþļöýééš ŵîţĥ å þöŕţîöñ öƒ šåṽîñĝš ŵĥéñ ƀööķîñĝ å ĥöţéļ· Ŕéŵåŕðš åŕé þŕöçéššéð åûţöɱåţîçåļļý åñð îñçļûðéð îñ ţĥé çöšţ öƒ ţĥé ĥöţéļ ƀööķîñĝ·-------------------------------П國カ내]",
    "roundtri12": "[Ŕöûñð Ţŕîþ----П國カ내]",
    "roundtri20": "[Ŕöûñð Ţŕîþ----П國カ내]",
    "roundtri25": "[Ŕöûñð Ţŕîþ----П國カ내]",
    "roundtri84": "[Ŕöûñð Ţŕîþ---П國カ내]",
    "routeand92": "[Ŕöûţé ⅋ Ðåţéš---П國カ내]",
    "routingn12": "[Ŕöûţîñĝ Ñûɱƀéŕ-----П國カ내]",
    "rule131": "[Ŕûļé-П國カ내]",
    "ruledrop132": "[Ŕûļé ðŕöþðöŵñ ļîšţ----П國カ내]",
    "rules179": "[Ŕûļéš--П國カ내]",
    "salestax83": "[Šåļéš Ţåẋ--П國カ내]",
    "saturday15": "[Šåţûŕðåý--П國カ내]",
    "save102": "[Šåṽé-П國カ내]",
    "save124": "[Šåṽé-П國カ내]",
    "save125": "[Šåṽé-П國カ내]",
    "save137": "[Šåṽé-П國カ내]",
    "save144": "[Šåṽé-П國カ내]",
    "save160": "[Šåṽé-П國カ내]",
    "save169": "[Šåṽé-П國カ내]",
    "save17": "[Šåṽé--П國カ내]",
    "save171": "[Šåṽé-П國カ내]",
    "save186": "[Šåṽé-П國カ내]",
    "save207": "[Šåṽé-П國カ내]",
    "save20wi206": "[Šåṽé ②⓪‰ ŵîţĥ Åļļ‐îñ‐Öñé-----П國カ내]",
    "save23": "[Šåṽé-П國カ내]",
    "save48": "[Šåṽé-П國カ내]",
    "save58": "[Šåṽé-П國カ내]",
    "save7": "[Šåṽé-П國カ내]",
    "save72": "[Šåṽé-П國カ내]",
    "save74": "[Šåṽé-П國カ내]",
    "save82": "[Šåṽé-П國カ내]",
    "save86": "[Šåṽé-П國カ내]",
    "save87": "[Šåṽé-П國カ내]",
    "save89": "[Šåṽé--П國カ내]",
    "save9": "[Šåṽé--П國カ내]",
    "save98": "[Šåṽé-П國カ내]",
    "saveappr145": "[Šåṽé Åþþŕöṽåļ Ŕûļéš----П國カ내]",
    "saveaspr136": "[Šåṽé åš Þŕéšéţ---П國カ내]",
    "savebill1": "[Šåṽé Ɓîļļ·çöɱ---П國カ내]",
    "savecate145": "[Šåṽé Çåţéĝöŕý Ŕûļéš----П國カ내]",
    "savechan157": "[Šåṽé Çĥåñĝéš¿---П國カ내]",
    "saveexpe77": "[Šåṽé Éẋþéñšé---П國カ내]",
    "saveforl95": "[Šåṽé ƒöŕ Ļåţéŕ---П國カ내]",
    "savegene139": "[Šåṽé Ĝéñéŕåļ Ŕûļéš----П國カ내]",
    "savenewo74": "[Šåṽé Ñéŵ Öþţîöñ----П國カ내]",
    "savepoli101": "[Šåṽé Þöļîçý---П國カ내]",
    "savereim184": "[Šåṽé Ŕéîɱƀûŕšéɱéñţš----П國カ내]",
    "savethes135": "[Šåṽé ţĥéšé çåŕð çöñţŕöļš åš å þŕéšéţ ţö ƀé åƀļé ţö åþþļý ţĥéɱ éåšîļý ţö öţĥéŕ çåŕðš·-----------------П國カ내]",
    "savewith1": "[Šåṽé ŵîţĥ Åļļ‐îñ‐Öñé-----П國カ내]",
    "savingpr179": "[Šåṽîñĝ Þŕöĝŕéšš----П國カ내]",
    "savings104": "[Šåṽîñĝš--П國カ내]",
    "savings83": "[Šåṽîñĝš--П國カ내]",
    "sawanadv16": "[Šåŵ åñ åðṽéŕţîšéɱéñţ-----П國カ내]",
    "schedule1": "[Šçĥéðûļéð Éẋþéñšé Éẋþöŕţ-----П國カ내]",
    "schedule51": "[Šçĥéðûļé å þåýɱéñţ ţö ṽîéŵ îţ ĥéŕé·--------П國カ내]",
    "search1": "[Šéåŕçĥ···--П國カ내]",
    "search13": "[Šéåŕçĥ----П國カ내]",
    "search14": "[<i class=\"icon-search\"></i> Šéåŕçĥ--------П國カ내]",
    "search169": "<i class=\"icon-search\"></i> [Šéåŕçĥ--------П國カ내]",
    "search170": "[Šéåŕçĥ--П國カ내]",
    "search174": "[Šéåŕçĥ--П國カ내]",
    "search336": "[<i class=\"icon-search\"></i> Šéåŕçĥ--------П國カ내]",
    "search40": "[Šéåŕçĥ--П國カ내]",
    "search45": "[Šéåŕçĥ--П國カ내]",
    "search54": "[<i class=\"icon-search\"></i> Šéåŕçĥ--------П國カ내]",
    "search55": "[<i class=\"icon-search\"></i> Šéåŕçĥ--------П國カ내]",
    "search62": "[<i class=\"icon-search\"></i> Šéåŕçĥ--------П國カ내]",
    "search7": "[Šéåŕçĥ--П國カ내]",
    "search70": "[<i class=\"icon-search\"></i> Šéåŕçĥ--------П國カ내]",
    "search71": "[Šéåŕçĥ--П國カ내]",
    "search72": "[Šéåŕçĥ--П國カ내]",
    "search73": "[Šéåŕçĥ--П國カ내]",
    "search74": "[Šéåŕçĥ--П國カ내]",
    "search75": "[Šéåŕçĥ--П國カ내]",
    "search83": "[Šéåŕçĥ--П國カ내]",
    "search9": "[<i class=\"icon-search\"></i> Šéåŕçĥ--------П國カ내]",
    "search98": "[šéåŕçĥ--П國カ내]",
    "searchKey9": "[Šéåŕçĥ--П國カ내]",
    "searchde10": "[Šéåŕçĥ Ðéþåŕţɱéñţ----П國カ내]",
    "searchde18": "[Šéåŕçĥ Ðéþåŕţɱéñţ------П國カ내]",
    "searchde5": "[Šéåŕçĥ Ðéþåŕţɱéñţ------П國カ내]",
    "searchde6": "[Šéåŕçĥ Ðéþåŕţɱéñţ------П國カ내]",
    "searchfo10": "[Šéåŕçĥ ƒöŕ Çåţéĝöŕîéš···--------П國カ내]",
    "searchfo168": "[Šéåŕçĥ ƒöŕ Çåţéĝöŕîéš···-----П國カ내]",
    "searchfo17": "[Šéåŕçĥ ƒöŕ ðéþåŕţɱéñţš···--------П國カ내]",
    "searchfo177": "[Šéåŕçĥ ƒöŕ Éẋþéñšé Çåţéĝöŕîéš···-------П國カ내]",
    "searchfo249": "[Šéåŕçĥ ƒöŕ ýöûŕ ƀåñķ åñð çåŕð þŕöĝŕåɱ--------П國カ내]",
    "searchfo33": "[Šéåŕçĥ ƒöŕ Éẋþéñšé Ŕéþöŕţš···---------П國カ내]",
    "searchfo34": "[Šéåŕçĥ ƒöŕ Çåŕðĥöļðéŕ···-----П國カ내]",
    "searchfo36": "[Šéåŕçĥ ƒöŕ Éẋþéñšé Çåţéĝöŕý···-------П國カ내]",
    "searchfo39": "[Šéåŕçĥ ƒöŕ Çåŕðĥöļðéŕ···-----П國カ내]",
    "searchfo42": "[Šéåŕçĥ ƒöŕ Éẋþéñšé Çåţéĝöŕý···-------П國カ내]",
    "searchfo44": "[Šéåŕçĥ ƒöŕ Çåţéĝöŕîéš···-----П國カ내]",
    "searchfo57": "[Šéåŕçĥ ƒöŕ Éẋþéñšé Ŕéþöŕţš···------П國カ내]",
    "searchfo6": "[Šéåŕçĥ ƒöŕ þļåçéš------П國カ내]",
    "searchre4": "[Šéåŕçĥ Ŕéþöŕţ----П國カ내]",
    "secondcf36": "[Šéçöñð ÇƑ Ṽåļûéš {customFields}----------П國カ내]",
    "seeallpe13": "[Šéé Åļļ Þéñðîñĝ Ţŕåñšåçţîöñš---------П國カ내]",
    "seeallpe32": "[Šéé Åļļ Þéñðîñĝ Ţŕåñšåçţîöñš------П國カ내]",
    "select1e68": "[Šéļéçţ ① éåçĥ ‐ åñ éẋþéñšé åñð ţŕåñšåçţîöñ ‐ ţö ɱéŕĝé·-----------П國カ내]",
    "select2": "[Šéļéçţ--П國カ내]",
    "selectad65": "[Šéļéçţ å Ðéþåŕţɱéñţ·-----П國カ내]",
    "selectal19": "[Šéļéçţ Åļļ---П國カ내]",
    "selectan66": "[Šéļéçţ åñ Éẋþéñšé Ŕéþöŕţ·------П國カ내]",
    "selectap120": "[Šéļéçţ å þŕéšéţ ţö çöþý ļîɱîţš åñð ṀÇÇ çöñţŕöļš ţö ţĥîš çåŕð· Ýöû çåñ öṽéŕŕîðé ţĥéɱ öñ ţĥé çåŕðĥöļðéŕ åççöûñţ·-----------------------П國カ내]",
    "selectbu66": "[Šéļéçţ Ɓûðĝéţ öŕ Þéŕçéñţåĝé------П國カ내]",
    "selectbu73": "[Šéļéçţ Ɓûðĝéţ öŕ Þéŕçéñţåĝé------П國カ내]",
    "selectca79": "[Šéļéçţ Çåŕ Ŕéñţåļ Çöɱþåñý------П國カ내]",
    "selectca87": "[Šéļéçţ Çåŕð Ţýþé----П國カ내]",
    "selectco143": "[Šéļéçţ çöɱþåñý åççöûñţ ţö ŕéîɱƀûŕšé éɱþļöýééš ŵîţĥ· Ţö åðð öŕ ɱåñåĝé ýöûŕ åççöûñţš، ĝö ţö------------------П國カ내]",
    "selectco94": "[Šéļéçţ çöɱþåñý åççöûñţ ţö ŕéîɱƀûŕšé éɱþļöýééš ŵîţĥ· Ţö åðð öŕ ɱåñåĝé ýöûŕ åççöûñţš، ĝö ţö Ɓåñķ Åççöûñţš· --------------------------------П國カ내]",
    "selectco96": "[Šéļéçţ çöɱþåñý åççöûñţ ţö ŕéîɱƀûŕšé éɱþļöýééš ŵîţĥ· Ţö åðð öŕ ɱåñåĝé ýöûŕ åççöûñţš، ĝö ţö Ŕéîɱƀûŕšéɱéñţš· --------------------------------П國カ내]",
    "selectda146": "[Šéļéçţ Ðåţé Ŕåñĝé----П國カ내]",
    "selectda22": "[Šéļéçţ Ðåţé Ŕåñĝé------П國カ내]",
    "selectda32": "[Šéļéçţ Ðåţé Ŕåñĝé----П國カ내]",
    "selectda36": "[Šéļéçţ Ðåţé Ŕåñĝé----П國カ내]",
    "selectde101": "[Šéļéçţ ðéļéĝåţéš ţö þéŕƒöŕɱ ţåšķš öñ ţĥé ĥöšţ’š ƀéĥåļƒ· Þļéåšé ñöţé ţĥåţ îƒ ţĥé ĥöšţ îš åñ åðɱîñ ƀûţ ţĥé ðéļéĝåţé îš ñöţ، ţĥé ðéļéĝåţé ŵîļļ ñöţ ƀé åƀļé ţö åççéšš çöɱþåñý šéţţîñĝš·------------------------------------П國カ내]",
    "selectde152": "[Šéļéçţ Ðéþåŕţɱéñţ----П國カ내]",
    "selectde99": "[Šéļéçţ Ðéþåŕţɱéñţ ðŕöþðöŵñ ļîšţ-------П國カ내]",
    "selected210": "[Šéļéçţéð Ţŕåñšåçţîöñš Åɱöûñţ: {selectedamount}----П國カ내]",
    "selected44": "[Šéļéçţéð Åɱöûñţ: {amount}--------П國カ내]",
    "selected46": "[Šéļéçţéð Þéñðîñĝ Åɱöûñţ: {amount}--------П國カ내]",
    "selected48": "[Šéļéçţéð Ðéçļîñéð Åɱöûñţ: {amount}--------П國カ내]",
    "selected50": "[Šéļéçţéð Þéñðîñĝ Ðéçļîñéð Åɱöûñţ: {amount}--------П國カ내]",
    "selected70": "[Šéļéçţéð îţéɱš---П國カ내]",
    "selectem100": "[Šéļéçţ éɱþļöýééš ţö ĥåṽé ţåšķš þéŕƒöŕɱéð ƒöŕ·----------П國カ내]",
    "selectem130": "[Šéļéçţ éɱþļöýééš ţö ƀé îñçļûðéð îñ ţĥîš ţŕåṽéļ þöļîçý· Åļļ éɱþļöýééš åŕé þåŕţ öƒ ţĥé ðéƒåûļţ þöļîçý ûñļéšš ţĥéý ĥåṽé ƀééñ åððéð ţö å ðîƒƒéŕéñţ þöļîçý· Éɱþļöýééš çåñ öñļý ƀé îñ öñé ţŕåṽéļ þöļîçý·---------------------------------------П國カ내]",
    "selectem81": "[∖″Šéļéçţ Éɱþļöýééš∖″ -----П國カ내]",
    "selectem83": "[Šéļéçţ Éɱþļöýééš----П國カ내]",
    "selecten14": "[Šéļéçţ Éñð Ðåţé----П國カ내]",
    "selecten170": "[Šéļéçţ Éñð Ðåţé----П國カ내]",
    "selecten21": "[Šéļéçţ Éñð Ðåţé-----П國カ내]",
    "selecten33": "[Šéļéçţ Éñð Ðåţé----П國カ내]",
    "selecten37": "[Šéļéçţ Éñð Ðåţé----П國カ내]",
    "selectex14": "[Šéļéçţ Éẋþéñšé Ðåţé------П國カ내]",
    "selectex17": "[Šéļéçţ Éẋîšţîñĝ Ɓîļļîñĝ Åððŕéšš----------П國カ내]",
    "selectex48": "[Šéļéçţ Éẋþéñšé Ðåţé----П國カ내]",
    "selectha65": "[Šéļéçţ Ĥåŕð Çåþ Þŕîçé-----П國カ내]",
    "selectha72": "[Šéļéçţ Ĥåŕð Çåþ Þŕîçé-----П國カ내]",
    "selectho34": "[Šéļéçţ ĥöŵ ýöû ŵöûļð ļîķé ţö ŕéçéîṽé ýöûŕ šçĥéðûļéð éẋþöŕţ·------------П國カ내]",
    "selectmu102": "[Šéļéçţ ɱûļţîþļé ðéļéĝåţéš------П國カ내]",
    "selectmu99": "[Šéļéçţ ɱûļţîþļé ĥöšţš-----П國カ내]",
    "selectnu108": "[Šéļéçţ Ñûɱƀéŕ öƒ Ðåýš-----П國カ내]",
    "selectnu64": "[Šéļéçţ Ñûɱƀéŕ öƒ Ðåýš-----П國カ내]",
    "selectpr1": "[Šéļéçţ Þŕöṽîðéŕ-----П國カ내]",
    "selectpu77": "[Šéļéçţ Þûŕþöšé---П國カ내]",
    "selectre149": "[Šéļéçţ Ŕéþöŕţ Šţåţûš-----П國カ내]",
    "selectre35": "[Šéļéçţ Ŕéþöŕţ Šţåţûš-----П國カ내]",
    "selectst13": "[Šéļéçţ Šţåŕţ Ðåţé----П國カ내]",
    "selectth19": "[Šéļéçţ ţĥé ƒîļé ţýþé ƒöŕ ýöûŕ éẋþöŕţ·--------П國カ내]",
    "selecttr22": "[Šéļéçţ Ţŕåñšåçţîöñš Ţö Åššîĝñ------П國カ내]",
    "selectwh111": "[Šéļéçţ ŵĥéñ ɱåñåĝéŕš šĥöûļð ƀé ñöţîƒîéð åƀöûţ ƒļîĝĥţ ƀööķîñĝš· Ţĥîš ŵîļļ ñöţ ƀļöçķ ţŕåṽéļéŕš ƒŕöɱ ƀööķîñĝ·----------------------П國カ내]",
    "selectwh118": "[Šéļéçţ ŵĥéñ ɱåñåĝéŕš šĥöûļð åþþŕöṽé ƒļîĝĥţ ƀööķîñĝš· Ţŕåṽéļ ŵîļļ ƀé ƀööķéð åûţöɱåţîçåļļý ûþöñ åþþŕöṽåļ·---------------------П國カ내]",
    "selectwh121": "[Šéļéçţ ŵĥéñ ɱåñåĝéŕš šĥöûļð ƀé ñöţîƒîéð åƀöûţ ĥöţéļ ƀööķîñĝš· Ţĥîš ŵîļļ ñöţ ƀļöçķ ţŕåṽéļéŕš ƒŕöɱ ƀööķîñĝ·----------------------П國カ내]",
    "selectwh124": "[Šéļéçţ ŵĥéñ ɱåñåĝéŕš šĥöûļð åþþŕöṽé ĥöţéļ ƀööķîñĝš· Ţŕåṽéļ ŵîļļ ƀé ƀööķéð åûţöɱåţîçåļļý ûþöñ åþþŕöṽåļ·---------------------П國カ내]",
    "selectwh146": "[Šéļéçţ ŵĥéñ ɱåñåĝéŕš šĥöûļð ƀé ñöţîƒîéð åƀöûţ ƒļîĝĥţ ƀööķîñĝš· Ţĥîš ŵîļļ ñöţ ƀļöçķ ţŕåṽéļéŕš ƒŕöɱ ƀööķîñĝ·----------------------П國カ내]",
    "selectwh149": "[Šéļéçţ ŵĥéñ ɱåñåĝéŕš šĥöûļð åþþŕöṽé ƒļîĝĥţ ƀööķîñĝš· Ţŕåṽéļ ŵîļļ ƀé ƀööķéð åûţöɱåţîçåļļý ûþöñ åþþŕöṽåļ·---------------------П國カ내]",
    "selectwh157": "[Šéļéçţ ŵĥîçĥ éẋþéñšé åþþŕöṽéŕš åŕé ŕéǫûîŕéð ƀý ðéƒåûļţ·------------П國カ내]",
    "semimont3": "[Šéɱî‐Ṁöñţĥļý---П國カ내]",
    "semimont8": "[Šéɱî‐Ṁöñţĥļý---П國カ내]",
    "send29": "[Šéñð-П國カ내]",
    "sendinvi96": "[Šéñð Îñṽîţé---П國カ내]",
    "setamile83": "[Šéţ å Ṁîļéåĝé Ŕåţé·----П國カ내]",
    "setarece82": "[Šéţ å Ŕéçéîþţ Ŕéǫûîŕéɱéñţ·------П國カ내]",
    "setcompa1": "[Šéţ çöɱþåñý ƀåñķ åççöûñţš ƒöŕ éɱþļöýéé ŕéîɱƀûŕšéɱéñţš·-----------П國カ내]",
    "setcusto197": "[Šéţ çûšţöɱ ƒîéļð ţö öñļý åþþéåŕ öñ šþéçîƒîç éẋþéñšé çåţéĝöŕîéš-------------П國カ내]",
    "setlinei97": "[Šéţ ļîñé îţéɱš ƒöŕ éẋþéñšéš îñ ţĥîš çåţéĝöŕý· Ŕéǫûîŕéð ƒöŕçéš éɱþļöýééš ţö îţéɱîžé éṽéŕý éẋþéñšé ţĥéý šûƀɱîţ îñ ţĥîš çåţéĝöŕý·--------------------------П國カ내]",
    "setpolic1": "[Šéţ þöļîçîéš، çåţéĝöŕîéš åñð çûšţöɱ ƒîéļðš· Éẋþöŕţ éẋþéñšéš·-------------П國カ내]",
    "setrecei91": "[Šéţ ŕéçéîþţ ŕéǫûîŕéɱéñţš، åþþŕöṽåļ šţéþš åñð ɱöŕé ƒöŕ åļļ éẋþéñšéš·--------------П國カ내]",
    "setrecur142": "[Šéţ ŕéçûŕŕîñĝ šþéñðîñĝ ļîɱîţš ƒöŕ ţĥîš çåţéĝöŕý·----------П國カ내]",
    "setrules1": "[Šéţ ŕûļéš، åþþŕöṽåļš åñð ñöţîƒîçåţîöñš ƒöŕ ţŕåṽéļ·-----------П國カ내]",
    "setrules142": "[ Šéţ Ŕûļéš---П國カ내]",
    "setrules182": "[Šéţ Ŕûļéš--П國カ내]",
    "setrules190": "[Šéţ Ŕûļéš Éñåƀļéð----П國カ내]",
    "setrules195": "[Šéţ Ŕûļéš Ŕéǫûîŕéð----П國カ내]",
    "setrules2": "[Šéţ ŕûļéš ƒöŕ éẋþéñšé þöļîçý·------П國カ내]",
    "setspend142": "[Šéţ šþéñðîñĝ ļîɱîţš ƒöŕ îñðîṽîðûåļ éẋþéñšéš·---------П國カ내]",
    "setthepe156": "[Šéţ ţĥé þéŕçéñţåĝé ţö ŕéŵåŕð ýöûŕ éɱþļöýééš ŵĥéñ ţĥéý šåṽé ŵĥîļé ƀööķîñĝ å ĥöţéļ، ƒŕöɱ ②⓪‰ ûþ ţö ⑤⓪‰·---------------------П國カ내]",
    "settinga104": "[Šéţţîñĝ å Ɓûðĝéţ ƒöŕ Ļöðĝîñĝ------П國カ내]",
    "settinga117": "[Šéţţîñĝ å Ɓûðĝéţ ƒöŕ Ƒļîĝĥţš------П國カ내]",
    "settings3": "[Šéţţîñĝš--П國カ내]",
    "settings47": "[Šéţţîñĝš---П國カ내]",
    "settingu183": "[Šéţţîñĝ Ûþ Ṁý Çöɱþåñý-----П國カ내]",
    "settrave1": "[Šéţ ţŕåṽéļ þöļîçîéš، åþþŕöṽåļš، ŕéŵåŕðš åñð ñöţîƒîçåţîöñš·------------П國カ내]",
    "setupint124": "[Šéţ Ûþ Îñţéĝŕåţîöñš----П國カ내]",
    "setuprep1": "[Šéţ ûþ ŕéþöŕţ ðåţå éẋþöŕţš ţö ýöûŕ ĝéñéŕåļ ļéðĝéŕ šöƒţŵåŕé·------------П國カ내]",
    "setuprep2": "[Šéţ ûþ ŕéþéåţ éẋþéñšéš ţö åûţöɱåţîçåļļý çŕéåţé ýöûŕ éẋþéñšé öñ å ŕéĝûļåŕ ƀåšîš·------------------------П國カ내]",
    "setwhene174": "[Šéţ ŵĥéñ éɱþļöýééš îñ ţĥîš þöļîçý ŵîļļ ƀé ŕéîɱƀûŕšéð·-----------П國カ내]",
    "setwhich144": "[Šéţ ŵĥîçĥ éɱþļöýééš åñð ðéþåŕţɱéñţš ţĥîš þöļîçý åþþļîéš ţö·------------П國カ내]",
    "setyourf1": "[Šéţ ýöûŕ ƒîñåñçîñĝ åñð ƒŕéǫûéñçý ƒöŕ éɱþļöýéé ŕéîɱƀûŕšéɱéñţš·-------------П國カ내]",
    "sftp20": "[ŠƑŢÞ-П國カ내]",
    "sharethe49": "[Šĥåŕé ţĥé éẋþéñšé ŕéþöŕţ ŵîţĥ ýöûŕ ƒîñåñçé ţéåɱ ţö ĝéţ ŕéîɱƀûŕšéð· Åððîţîöñåļ éɱåîļš ɱåý ƀé šéþåŕåţéð ƀý çöɱɱå·-----------------------П國カ내]",
    "sharethe60": "[Šĥåŕé ţĥé éẋþéñšé ŕéþöŕţ ŵîţĥ ýöûŕ ƒîñåñçé ţéåɱ ţö ĝéţ ŕéîɱƀûŕšéð· Åððîţîöñåļ éɱåîļš ɱåý ƀé šéþåŕåţéð ƀý çöɱɱå·-----------------------П國カ내]",
    "shareyou48": "[Šĥåŕé Ýöûŕ Éẋþéñšé Ŕéþöŕţ------П國カ내]",
    "showever85": "[Šĥöŵ Éṽéŕýöñé´š----П國カ내]",
    "showonly84": "[Šĥöŵ Öñļý Ṁîñé---П國カ내]",
    "showsplits87": "[Šĥöŵ Šþļîţš---П國カ내]",
    "signin13": "[Šîĝñ Îñ--П國カ내]",
    "signupwi40": "[Šîĝñ Ûþ Ŵîţĥ Çöŕþöŕåţé Éɱåîļ------П國カ내]",
    "siliconv318": "[Šîļîçöñ Ṽåļļéý Ɓåñķ----П國カ내]",
    "singleex19": "[Šîñĝļé éẋþéñšé öþţîöñ ‐ šéļéçţéð----------П國カ내]",
    "singleex20": "[Šîñĝļé Éẋþéñšé-----П國カ내]",
    "sitelang1": "[Šîţé Ļåñĝûåĝé---П國カ내]",
    "smithplaceholder28": "[Šɱîţĥ--П國カ내]",
    "smonthly1": "[Šéɱî‐ɱöñţĥļý---П國カ내]",
    "smskeyva1": "[ŠṀŠ-П國カ내]",
    "socialme17": "[Šöçîåļ ɱéðîå---П國カ내]",
    "somecred341": "[Šöɱé Çŕéðîţ Çåŕðš çöûļð ñöţ ƀé åššîĝñéð--------П國カ내]",
    "sometemp128": "[Šöɱé ţéɱþļåţéš åŕé ŕéšţŕîçţéð åñð åûţöɱåţîçåļļý šéţ ţö ðéçļîñé· Çöñţåçţ ýöûŕ ƀåñķ ŕéþŕéšéñţåţîṽé ţö ŕéǫûéšţ å çĥåñĝé öŕ éẋçéþţîöñ·---------------------------П國カ내]",
    "sometemp268": "[Šöɱé ţéɱþļåţéš åŕé ŕéšţŕîçţéð åñð åûţöɱåţîçåļļý šéţ ţö ðéçļîñé·<br /> Çöñţåçţ ýöûŕ ƀåñķ ŕéþŕéšéñţåţîṽé ţö ŕéǫûéšţ å çĥåñĝé öŕ éẋçéþţîöñ·----------------------------П國カ내]",
    "somethin118": "[Šöɱéţĥîñĝ Ŵéñţ Ŵŕöñĝ-----П國カ내]",
    "somethin119": "[Šöɱéţĥîñĝ ŵéñţ ŵŕöñĝ ŵĥéñ šûƀɱîţţîñĝ ýöûŕ ŕéþöŕţ· Þļéåšé ţŕý åĝåîñ·--------------П國カ내]",
    "somethin211": "[Šöɱéţĥîñĝ ŵéñţ ŵŕöñĝ åððîñĝ öŕ ûþðåţîñĝ ýöûŕ çûšţöɱ ƒîéļð، þļéåšé ţŕý åĝåîñ ļåţéŕ åñð çöñţåçţ šûþþöŕţ îƒ ţĥé îššûé þéŕšîšţš·-------------------------П國カ내]",
    "somethin25": "[Šöɱéţĥîñĝ ŵéñţ ŵŕöñĝ·-------П國カ내]",
    "sortby3": "[Šöŕţ ƀý∶--П國カ내]",
    "sortbyhe86": "[šöŕţ ƀý {heading}----П國カ내]",
    "sortbyna71": "[šöŕţ ƀý ñåɱé---П國カ내]",
    "speciali266": "[Šþéçîåļ Îñšţŕûçţîöñš-----П國カ내]",
    "spendman2": "[Šþéñð Ṁåñåĝéɱéñţ-----П國カ내]",
    "spendman97": "[Šþéñð Ṁåñåĝéɱéñţ----П國カ내]",
    "spendthi101": "[Šþéñð ţĥîš Ṁöñţĥ----П國カ내]",
    "spentthi209": "[[Šþéñţ ţĥîš Ṁöñţĥ----П國カ내]",
    "splitabove4": "[Šþļîţ îš {amountError} <span data-test-text=\"side\">above</span> ýöûŕ {expenseAmount} éẋþéñšé ţöţåļ·-------------------------------П國カ내]",
    "splitabove45": "[Šþļîţ îš {amountError} åƀöṽé ýöûŕ {expenseAmount} éẋþéñšé ţöţåļ-------------------П國カ내]",
    "splitbelow46": "[Šþļîţ îš {amountError} ƀéļöŵ ýöûŕ {expenseAmount} éẋþéñšé ţöţåļ-------------------П國カ내]",
    "splitdep78": "[Šþļîţ Ðéþåŕţɱéñţ Åççéšš-----П國カ내]",
    "splitexp10": "[Šþļîţ Éẋþéñšé----П國カ내]",
    "splitexp22": "[Šþļîţ éẋþéñšé öþţîöñ ‐ šéļéçţéð----------П國カ내]",
    "splitexp23": "[Šþļîţ Éẋþéñšé----П國カ내]",
    "splitsmu14": "[Šþļîţš ɱûšţ ƀé ûñîǫûé·-------П國カ내]",
    "splitsmu42": "[Šþļîţš ɱûšţ ƀé ûñîǫûé·-------П國カ내]",
    "splitunder5": "[Šþļîţ îš {amountError} <span data-test-text=\"side\">under</span> ýöûŕ {expenseAmount} éẋþéñšé ţöţåļ·-------------------------------П國カ내]",
    "spremium1": "[Þŕéɱîûɱ Ţ ⅋ É---П國カ내]",
    "standard1": "[Šţåñðåŕð ÇŠṼ ƒîļé éẋţŕåçţ------П國カ내]",
    "starcoun100": "[šţåŕ‐çöûñţ---П國カ내]",
    "startadd20": "[Šţåŕţ åððŕéšš----П國カ내]",
    "startadd34": "[Šţåŕţ Åððŕéšš---П國カ내]",
    "startadd4": "[Šţåŕţ Åððŕéšš----П國カ내]",
    "state1": "[Šţåţé--П國カ내]",
    "state228": "[Šţåţé--П國カ내]",
    "statedro229": "[Šţåţé ðŕöþðöŵñ ļîšţ----П國カ내]",
    "statemen16": "[Šţåţéɱéñţ Ðåţé---П國カ내]",
    "statemen169": "[Šţåţéɱéñţš---П國カ내]",
    "statemen24": "[Šţåţéɱéñţ Ɓåļåñçé----П國カ내]",
    "statemen25": "[šţåţéɱéñţ ƀåļåñçé îñƒö îçöñ------П國カ내]",
    "statemen43": "[Šţåţéɱéñţ Ɓåļåñçé ‐ {statementdate}--------П國カ내]",
    "statemen58": "[Šţåţéɱéñţš---П國カ내]",
    "statemen7": "[Šţåţéɱéñţ ðåţéš ŵîļļ ṽåŕý îƒ ţĥé çýçļé ðåţé ƒåļļš öñ å ŵééķéñð öŕ ĥöļîðåý·---------------П國カ내]",
    "statemen8": "[Šţåţéɱéñţš---П國カ내]",
    "statepro3": "[Šţåţé⁄Þŕöṽîñçé⁄Ŕéĝîöñ-------П國カ내]",
    "status102": "[Šţåţûš--П國カ내]",
    "status107": "[Šţåţûš--П國カ내]",
    "status172": "[Šţåţûš--П國カ내]",
    "status18": "[Šţåţûš--П國カ내]",
    "status180": "[Šţåţûš--П國カ내]",
    "status205": "[Šţåţûš--П國カ내]",
    "status50": "[Šţåţûš--П國カ내]",
    "status52": "[Šţåţûš--П國カ내]",
    "status57": "[Šţåţûš--П國カ내]",
    "status64": "[Šţåţûš--П國カ내]",
    "status70": "[Šţåţûš--П國カ내]",
    "status80": "[Šţåţûš--П國カ내]",
    "status84": "[Šţåţûš--П國カ내]",
    "status98": "[Šţåţûš--П國カ내]",
    "statusda54": "[Šţåţûš ‐ {paymentstatus}-----П國カ내]",
    "statusproblem84": "[Ţĥéŕé ŵåš å þŕöƀļéɱ ŵĥîļé ļöåðîñĝ ŕéþöŕţ šţåţûš----------П國カ내]",
    "stepcurr11": "[Šţéþ {currentstepnumber} öƒ {length}--------П國カ내]",
    "stepstep148": "[Šţéþ {step} öƒ ③----П國カ내]",
    "stopaddr23": "[Šţöþ åððŕéšš----П國カ내]",
    "stopinde36": "[Šţöþ {index}---П國カ내]",
    "stopnumb22": "[Šţöþ {number}---П國カ내]",
    "stops139": "[Šţöþš--П國カ내]",
    "stops97": "[Šţöþš--П國カ내]",
    "streetad13": "[Šţŕééţ Åððŕéšš-----П國カ내]",
    "streetad14": "[Šţŕééţ Åððŕéšš ②-----П國カ내]",
    "streetad225": "[Šţŕééţ Åððŕéšš---П國カ내]",
    "streetst191": "[{street} {street2label}<br>{city} {state} {postalcode}<br>ÛŠÅ-------------П國カ내]",
    "string85": "[šţŕîñĝ···--П國カ내]",
    "submissi6": "[Šûƀɱîššîöñ Ðåţé----П國カ내]",
    "submit113": "[Šûƀɱîţ--П國カ내]",
    "submit24": "[Šûƀɱîţ--П國カ내]",
    "submit81": "[Šûƀɱîţ--П國カ내]",
    "submit9": "[Šûƀɱîţ--П國カ내]",
    "submit97": "[Šûƀɱîţ--П國カ내]",
    "submitne1": "[Šûƀɱîţ Ñéŵ Éẋþéñšé Ŕéþöŕţ--------П國カ내]",
    "submitte124": "[Šûƀɱîţţéð Ðåţé---П國カ내]",
    "submitte142": "[Šûƀɱîţţéð Ðåţé---П國カ내]",
    "submitte16": "[Šûƀɱîţţéð Ðåţé∶ {submittedat}------П國カ내]",
    "submitte22": "[Šûƀɱîţţéð--П國カ내]",
    "submitte32": "[Šûƀɱîţţéð {expenseTitle} (Ŕéåð Öñļý)--------П國カ내]",
    "submitte39": "[Šûƀɱîţţéð--П國カ내]",
    "submitte64": "[Šûƀɱîţţéð--П國カ내]",
    "submitte78": "[Šûƀɱîţţéð--П國カ내]",
    "succesfu3": "[Šûççéšƒûļļý ûþðåţéð Ɓîļļ·çöɱ Îñƀöẋ-------П國カ내]",
    "succesfu72": "[Šûççéšƒûļļý ûþðåţéð ýöûŕ þŕöƒîļé·----------П國カ내]",
    "success82": "[Šûççéšš¡--П國カ내]",
    "successf11": "[Šûççéššƒûļļý šåṽéð ýöûŕ þåýɱéñţ šéţţîñĝš¡---------П國カ내]",
    "successf35": "[Šûççéššƒûļļý çŕéåţéð éẋþéñšé ŕéþöŕţ¡--------П國カ내]",
    "successf4": "[Šûççéššƒûļļý šåṽéð éẋþéñšé éẋþöŕţ šéţţîñĝš·---------П國カ내]",
    "successf41": "[Šûççéššƒûļļý éðîţéð éẋþéñšé ŕéþöŕţ¡--------П國カ내]",
    "successf60": "[Šûççéššƒûļļý ŕéɱöṽéð ŕéñţåļ çöðé---------------------------------------П國カ내]",
    "successf65": "[Šûççéššƒûļļý šåṽéð ŕéñţåļ çöðéš--------------------------------------П國カ내]",
    "successf70": "[Šûççéššƒûļļý šåṽéð éẋþéñšé çåţéĝöŕý¡--------П國カ내]",
    "successf71": "[Šûççéššƒûļļý ðéļéţéð ƀåñķ åççöûñţ·-------П國カ내]",
    "successf73": "[Šûççéššƒûļļý šåṽéð éẋþéñšé çåţéĝöŕý¡--------П國カ내]",
    "successf74": "[Šûççéššƒûļļý ûþðåţéð ýöûŕ þŕöƒîļé·-----------П國カ내]",
    "successf75": "[Šûççéššƒûļļý šåṽéð éẋþéñšé çåţéĝöŕý þöļîçý·---------П國カ내]",
    "successw75": "{length, plural, one {[Šûççéšš¡ Ŵé´ṽé åððéð ① éẋþéñšé·---------------П國カ내]} other {[Šûççéšš¡ Ŵé´ṽé åððéð # éẋþéñšéš·---------------П國カ내]}}",
    "suggeste64": "[Šûĝĝéšţéð Éɱþļöýééš----П國カ내]",
    "summary118": "[Šûɱɱåŕý--П國カ내]",
    "sunday16": "[Šûñðåý--П國カ내]",
    "supportf21": "[Šûþþöŕţ ƑÅǪš---П國カ내]",
    "switchac8": "[Šŵîţçĥ Åççöûñţ-----П國カ내]",
    "switchba77": "[Šŵîţçĥ Ɓåçķ----П國カ내]",
    "sync2": "[Šýñç--П國カ내]",
    "syncaper12": "[Šýñç å Þéŕšöñåļ Çåŕð-----П國カ내]",
    "syncarei61": "[Šýñç å Ŕéîɱƀûŕšéɱéñţ Åççöûñţ---------П國カ내]",
    "syncedca130": "[Šýñçéð Çåŕð ļåšţ ④----П國カ내]",
    "syncedca58": "[Šýñçéð Çåŕð---П國カ내]",
    "syncedca63": "[Šýñçéð Çåŕð Ðéţåîļš----П國カ내]",
    "syncedpe7": "[Šýñçéð Þéŕšöñåļ Çåŕð-----П國カ내]",
    "synctogl12": "[Šýñç ţö ĜĻ---П國カ내]",
    "tabnumbe243": "[Ţåƀ ñûɱƀéŕ {number}----П國カ내]",
    "tags335": "[Ţåĝš-П國カ내]",
    "tags7": "[Ţåĝš--П國カ내]",
    "tags74": "[Ţåĝš-П國カ내]",
    "tailoryo1": "[Ţåîļöŕ ýöûŕ åññûåļ šûƀšçŕîþţîöñ ƀý ɱîẋîñĝ åñð ɱåţçĥîñĝ ţö ƀûý ţĥé ñûɱƀéŕ öƒ ļîçéñšéš ýöû ñééð öñ åñ öñĝöîñĝ ƀåšîš· Îƒ ýöû éṽéŕ ĝö öṽéŕ ýöûŕ åļļöçåţéð ļîçéñšéš، ŵé ŵîļļ šîɱþļý ƀîļļ ýöû ƒöŕ ýöûŕ åððîţîöñåļ ûšåĝé ɱöñţĥļý·--------------------------------------------П國カ내]",
    "tailoryo167": "[Ţåîļöŕ ýöûŕ åññûåļ šûƀšçŕîþţîöñ ƀý ɱîẋîñĝ åñð ɱåţçĥîñĝ ţö ƀûý ţĥé ñûɱƀéŕ öƒ ļîçéñšéš ýöû ñééð öñ åñ öñĝöîñĝ ƀåšîš· Îƒ ýöû éṽéŕ ĝö öṽéŕ ýöûŕ åļļöçåţéð ļîçéñšéš، ŵé ŵîļļ šîɱþļý ƀîļļ ýöû ƒöŕ ýöûŕ åððîţîöñåļ ûšåĝé ɱöñţĥļý·--------------------------------------------П國カ내]",
    "taxamount2": "[Tax Åɱöûñţ---П國カ내]",
    "tellusab25": "[Ţéļļ Ûš Åƀöûţ Ýöûŕšéļƒ-----П國カ내]",
    "tellusab45": "[Ţéļļ Ûš Åƀöûţ Ýöûŕ Çöɱþåñý------П國カ내]",
    "template244": "[{templatecode} ‐ Ţéɱþļåţé Ðéţåîļ-------П國カ내]",
    "terminat58": "[Ţéŕɱîñåţéð---П國カ내]",
    "terminat61": "[Ţéŕɱîñåţéð---П國カ내]",
    "terminat92": "[Ţéŕɱîñåţé--П國カ내]",
    "termsofu50": "[Ţéŕɱš öƒ Ûšé----П國カ내]",
    "theaccou160": "[Ţĥé Åççöûñţ ÎÐ îš ûšéð ţö ɱåþ ýöûŕ çĥåŕţ öƒ åççöûñţš öñ ýöûŕ ÉŔÞ ţööļ ţö ýöûŕ ŢŕåṽéļƁåñķ éẋþéñšé çåţéĝöŕîéš·----------------------П國カ내]",
    "theaccou177": "[Ţĥé Åççöûñţ ÎÐ îš ûšéð ţö ɱåþ ýöûŕ çĥåŕţ öƒ åççöûñţš öñ ýöûŕ ÉŔÞ ţööļ ţö ýöûŕ ŢŕåṽéļƁåñķ éẋþéñšé çåţéĝöŕîéš·----------------------П國カ내]",
    "thebudge86": "[Ţĥé ƀûðĝéţ þöļîçý ƒöŕ ţĥé ƒöļļöŵîñĝ éɱþļöýééš ŵîļļ çĥåñĝé، ðö ýöû ŵåñţ ţö çöñţîñûé¿-----------------П國カ내]",
    "thecarda223": "[Ţĥé çåŕð åñð îñšţŕûçţîöñš ţö åçţîṽåţé ţĥé çåŕð ŵîļļ ƀé šéñţ ţö ţĥîš åððŕéšš· Îţ îš åļšö ţĥé ƀîļļîñĝ åððŕéšš ûšéð ƒöŕ ɱåķîñĝ þûŕçĥåšéš·---------------------------П國カ내]",
    "thechose18": "[Ţĥé çĥöšéñ éẋþéñšé çåţéĝöŕý ŕéǫûîŕéš šþļîţţîñĝ ţö åççöûñţ ƒöŕ åļļ ļîñé îţéɱš·------------------------П國カ내]",
    "thechose50": "[Ţĥé çĥöšéñ éẋþéñšé çåţéĝöŕý ŕéǫûîŕéš šþļîţţîñĝ ţö åççöûñţ ƒöŕ åļļ ļîñé îţéɱš·----------------П國カ내]",
    "thecombabove3": "[Ţĥé çöɱƀîñéð šþļîţš åŕé {amountError}  <span data-test-text=\"side\">above</span> ýöûŕ {expenseAmount} éẋþéñšé ţöţåļ·------------------------------------П國カ내]",
    "thecombabove43": "[Ţĥé çöɱƀîñéð šþļîţš åŕé {amountError} åƀöṽé ýöûŕ {expenseAmount} éẋþéñšé ţöţåļ------------------------П國カ내]",
    "thecombbelow44": "[Ţĥé çöɱƀîñéð šþļîţš åŕé {amountError} ƀéļöŵ ýöûŕ {expenseAmount} éẋþéñšé ţöţåļ------------------------П國カ내]",
    "thecombunder3": "[Ţĥé çöɱƀîñéð šþļîţš åŕé {amountError} <span data-test-text=\"side\">under</span> ýöûŕ {expenseAmount} éẋþéñšé ţöţåļ·-----------------------------------П國カ내]",
    "thecurre174": "[Ţĥé çûŕŕéñţ åššîĝñéé ŵîļļ ƀé åƀļé ţö ɱåñåĝé çöɱþļéţéð åñð þéñðîñĝ ţŕåñšåçţîöñš þŕîöŕ ţö ðåţé öƒ ŕéåššîĝñɱéñţ· Åļļ ƒûţûŕé ţŕåñšåçţîöñš ŵîļļ ƀé ɱåñåĝéð ƀý ţĥé ñéŵ åššîĝñéé·-----------------------------------П國カ내]",
    "thecusto206": "[Ţĥé çûšţöɱ ƒîéļð ļîɱîţ öƒ ⑤ ĥåš ƀééñ ŕéåçĥéð· Ţö åðð å ñéŵ çûšţöɱ ƒîéļð، þļéåšé ðéļéţé åñ éẋîšţîñĝ çûšţöɱ ƒîéļð·-----------------------П國カ내]",
    "thedolla114": "[Ţĥé ðöļļåŕ åɱöûñţ ţĥŕéšĥöļð ţĥåţ éɱþļöýééš ɱûšţ åţţåçĥ ŕéçéîþţš ţö ţĥéîŕ éẋþéñšéš·-----------------П國カ내]",
    "theexpen38": "[Ţĥé éẋþéñšé ŕéþöŕţ îš ţåķîñĝ ţöö ļöñĝ ţö ĝéñéŕåţé å ÞÐƑ، çöñţåçţ ûš ƒöŕ ĥéļþ ţĥŕöûĝĥ ţĥé çĥåţ ļîñķ îñ ţĥé ɱéñû·-----------------------П國カ내]",
    "thefligh137": "[Ţĥé ƒļîĝĥţ ƀûðĝéţ îš ƀåšéð öñ åṽéŕåĝé þŕîçéš öƒ ţĥé šéļéçţéð ƒåŕé çļåšš·---------------П國カ내]",
    "thefligh140": "[Ţĥé ƒļîĝĥţ ƀûðĝéţ îš ƀåšéð öñ åṽéŕåĝé þŕîçéš öƒ ƒļîĝĥţš ŵîţĥ ţĥé šéļéçţéð ñûɱƀéŕ öƒ šţöþš·-------------------П國カ내]",
    "thefligh96": "[Ţĥé ƒļîĝĥţ ƀûðĝéţ îš ƀåšéð öñ åṽéŕåĝé þŕîçéš öƒ ţĥé šéļéçţéð ƒåŕé çļåšš·---------------П國カ내]",
    "thefligh98": "[Ţĥé ƒļîĝĥţ ƀûðĝéţ îš ƀåšéð öñ åṽéŕåĝé þŕîçéš öƒ ƒļîĝĥţš ŵîţĥ ţĥé šéļéçţéð ñûɱƀéŕ öƒ šţöþš·-------------------П國カ내]",
    "thefreep78": "[Ţĥé ƒŕéé þļåñ îñçļûðéš öñé ðéƒåûļţ éẋþéñšé þöļîçý· Ûþĝŕåðé ýöûŕ þļåñ ţö åðð åñð éðîţ éẋþéñšé þöļîçîéš· -------------------------------П國カ내]",
    "thefreep83": "[Ţĥé ƒŕéé þļåñ îñçļûðéš öñé ðéƒåûļţ ţŕåṽéļ þöļîçý· Ûþĝŕåðé ýöûŕ þļåñ ţö åðð åñð éðîţ ţŕåṽéļ þöļîçîéš· -------------------------------П國カ내]",
    "theirsst70": "[Ţĥé ÎŔŠ šţåñðåŕð îš €⑦⑤·-----П國カ내]",
    "thelodgi102": "[Ţĥé ļöðĝîñĝ ƀûðĝéţ îš ƀåšéð öñ åṽéŕåĝé þŕîçéš öƒ ĥöţéļš ŵîţĥîñ ţĥé šéļéçţéð šéåŕçĥ ŕåðîûš·-------------------П國カ내]",
    "thelodgi155": "[Ţĥé ļöðĝîñĝ ƀûðĝéţ îš ƀåšéð öñ åṽéŕåĝé þŕîçéš öƒ ĥöţéļš ŵîţĥîñ ţĥé šéļéçţéð šéåŕçĥ ŕåðîûš·-------------------П國カ내]",
    "thelodgi99": "[Ţĥé ļöðĝîñĝ ƀûðĝéţ îš ƀåšéð öñ åṽéŕåĝé þŕîçéš öƒ ţĥé šéļéçţéð ĥöţéļ šţåŕ ŕåţîñĝ·-----------------П國カ내]",
    "thelodin152": "[Ţĥé ļöðĝîñĝ ƀûðĝéţ îš ƀåšéð öñ åṽéŕåĝé þŕîçéš öƒ ţĥé šéļéçţéð ĥöţéļ šţåŕ ŕåţîñĝ·-----------------П國カ내]",
    "themaxim174": "[Ţĥé ɱåẋîɱûɱ ñûɱƀéŕ öƒ çûšţöɱ ƒîéļðš ƒöŕ éẋþéñšéš ĥåš ƀééñ ŕéåçĥéð، þļéåšé ŕéåçĥ öûţ ţö šûþþöŕţ ţö ɱåķé åñý çĥåñĝéš·------------------------П國カ내]",
    "themulti117": "[Ţĥé ɱûļţîþļîéŕ ţĥåţ ŵîļļ ƀé ûšéð ţö çöñṽéŕţ éɱþļöýéé šûƀɱîţţéð ɱîļéåĝé ţö ðöļļåŕš ƒöŕ ŕéîɱƀûŕšéɱéñţ·---------------------П國カ내]",
    "themulti72": "[Ţĥé ɱûļţîþļîéŕ ţĥåţ ŵîļļ ƀé ûšéð ţö çöñṽéŕţ éɱþļöýéé šûƀɱîţţéð ɱîļéåĝé ţö ðöļļåŕš ƒöŕ ŕéîɱƀûŕšéɱéñţ·---------------------П國カ내]",
    "then81": "[Ţĥéñ-П國カ내]",
    "then87": "[Ţĥéñ-П國カ내]",
    "thenfora98": "[Ţĥéñ ƒöŕ Åþþŕöṽåļ----П國カ내]",
    "thenumbe79": "[Ţĥé ñûɱƀéŕ ðîšþļåýéð ƀéļöŵ åṽåîļåƀļé çŕéðîţ îš ţĥé åɱöûñţ šéţ åšîðé ţö çļéåŕ ţĥé þéñðîñĝ ţŕåñšåçţîöñš åñð þåýɱéñţš·------------------------П國カ내]",
    "thepassw1": "[Ţĥé þåššŵöŕð ŕéšéţ ļîñķ ĥåš éẋþîŕéð·--------П國カ내]",
    "thereare116": "[Ţĥéŕé åŕé ñö çŕéðîţ çåŕðš ţĥåţ ɱééţ ýöûŕ šéåŕçĥ çŕîţéŕîå·------------П國カ내]",
    "thereare170": "[Ţĥéŕé åŕé ñö éẋþéñšé çåţéĝöŕîéš ţĥåţ ɱééţ ýöûŕ šéåŕçĥ çŕîţéŕîå·-------------П國カ내]",
    "thereare173": "[Ţĥéŕé åŕé ñö éẋþéñšé çåţéĝöŕîéš·-------П國カ내]",
    "thereare178": "[Ţĥéŕé åŕé ñö çûšţöɱ ƒîéļðš·------П國カ내]",
    "thereare73": "[Ţĥéŕé åŕé ñö éɱþļöýééš ţĥåţ ɱééţ ýöûŕ šéåŕçĥ çŕîţéŕîå·-----------П國カ내]",
    "thereare78": "[{descriptionText} Ţĥéŕé åŕé ñö éẋþéñšéš îñ ţĥîš éẋþéñšé ŕéþöŕţ·-------------П國カ내]",
    "thereism23": "[Ţĥéŕé îš ɱîššîñĝ îñƒöŕɱåţîöñ ƒŕöɱ ţĥé ƒöŕɱ· Þļéåšé çļîçķ öñ ţĥé åçţîṽåţîöñ ļîñķ îñ ýöûŕ åçţîṽåţîöñ éɱåîļ·----------------------П國カ내]",
    "therewas113": "[Ţĥéŕé ŵåš åñ éŕŕöŕ ŵĥîļé ţŕýîñĝ ţö šéñð ţĥé ṽéŕîƒîçåţîöñ çöðé· Þļéåšé ţŕý åĝåîñ·-----------------П國カ내]",
    "therewas115": "[Ţĥéŕé ŵåš åñ éŕŕöŕ ŵĥîļé ṽéŕîƒýîñĝ ýöûŕ çöðé· Þļéåšé ţŕý åĝåîñ·-------------П國カ내]",
    "therewas126": "[Ţĥéŕé ŵåš åñ éŕŕöŕ ŵĥîļé ţŕýîñĝ ţö þåîŕ ţĥîš éẋþéñšé·-----------П國カ내]",
    "therewas2": "[Ţĥéŕé ŵåš åñ éŕŕöŕ ŵĥéñ åţţéɱþţîñĝ ţö ŕéţŕý éẋþéñšé éẋþöŕţ------------П國カ내]",
    "therewas24": "[Ţĥéŕé ŵåš åñ éŕŕöŕ öþéñîñĝ Ṁåŕķéţþļåçé· Þļéåšé ţŕý åĝåîñ ļåţéŕ·-------------П國カ내]",
    "therewas25": "[Ţĥéŕé ŵåš å þŕöƀļéɱ öþéñîñĝ åðṽåñçéð åñåļýţîçš----------П國カ내]",
    "therewas27": "[Ţĥéŕé ŵåš åñ éŕŕöŕ ŵĥîļé ţŕýîñĝ ţö þåîŕ ţĥîš éẋþéñšé·-----------П國カ내]",
    "therewas3": "[Ţĥéŕé ŵåš åñ éŕŕöŕ šåṽîñĝ éẋþéñšé éẋþöŕţ šéţţîñĝš·-----------П國カ내]",
    "therewas39": "[Ţĥéŕé ŵåš åñ éŕŕöŕ ĝéñéŕåţîñĝ ţĥé ÞÐƑ، çöñţåçţ ûš ƒöŕ ĥéļþ ţĥŕöûĝĥ ţĥé çĥåţ ļîñķ îñ ţĥé ɱéñû·-----------------------П國カ내]",
    "therewas54": "[Ţĥéŕé ŵåš åñ éŕŕöŕ ŵĥîļé ţŕýîñĝ ţö šåṽé· Þļéåšé ţŕý åĝåîñ·------------П國カ내]",
    "therewas61": "[Ţĥéŕé ŵåš åñ éŕŕöŕ ŕéɱöṽîñĝ ŕéñţåļ çöðé·-------------------------------------------------П國カ내]",
    "therewas62": "[Ţĥéŕé ŵåš åñ éŕŕöŕ ŵĥîļé ţŕýîñĝ ţö ðéļéţé ţĥéšé éẋþéñšéš· Þļéåšé ţŕý åĝåîñ·----------------П國カ내]",
    "therewas63": "[Ţĥéŕé ŵåš åñ éŕŕöŕ ŵĥîļé çåñçéļîñĝ ýöûŕ {type} ŕéǫûéšţ·------------П國カ내]",
    "therewas64": "[Ţĥéŕé ŵåš åñ éŕŕöŕ ŵĥîļé ţŕýîñĝ ţö ŕéšţöŕé ţĥéšé éẋþéñšéš· Þļéåšé ţŕý åĝåîñ·----------------П國カ내]",
    "therewas65": "[Ţĥéŕé ŵåš åñ éŕŕöŕ ŵĥîļé ðéļéţîñĝ ýöûŕ {type} ŕéǫûéšţ·-----------П國カ내]",
    "therewas66": "[Ţĥéŕé ŵåš åñ éŕŕöŕ šåṽîñĝ ŕéñţåļ çöðéš----------------------------------------------П國カ내]",
    "therewas70": "[Ţĥéŕé ŵåš åñ éŕŕöŕ ŵĥîļé ţŕýîñĝ ţö ɱéŕĝé ţĥîš éẋþéñšé åñð ţŕåñšåçţîöñ·---------------П國カ내]",
    "therewas72": "[Ţĥéŕé ŵåš åñ éŕŕöŕ ðéļéţîñĝ ƀåñķ åççöûñţ·---------П國カ내]",
    "therewas74": "[Ţĥéŕé ŵåš åñ éŕŕöŕ šåṽîñĝ éẋþéñšé çåţéĝöŕý þöļîçý·-----------П國カ내]",
    "therewas75": "[Ţĥéŕé ŵåš åñ éŕŕöŕ ŵĥîļé çŕéåţîñĝ ţĥîš éẋþéñšé þöļîçý· Þļéåšé ţŕý åĝåîñ·---------------П國カ내]",
    "therewas76": "[Ţĥéŕé ŵåš åñ éŕŕöŕ ŵĥîļé šåṽîñĝ ţĥîš éẋþéñšé þöļîçý· Þļéåšé ţŕý åĝåîñ·---------------П國カ내]",
    "theselec2": "[Ţĥé šéļéçţéð éẋþéñšéš åñð ţŕåñšåçţîöñš çåñ ƀé ṽîéŵéð ƀý ĝöîñĝ ţö ţĥé ŕéþöŕţ åñð ŵîļļ ñö ļöñĝéŕ ƀé ṽîšîƀļé öñ ţĥîš Éẋþéñšéš ⅋ Ţŕåñšåçţîöñš þåĝé·-------------------------------------------П國カ내]",
    "theserep5": "[Ţĥéšé ŕéþöŕţš åŕé ñöţ ýéţ šûƀɱîţţéð ƀý ţĥé éɱþļöýéé·-----------П國カ내]",
    "theseset93": "[Ţĥéšé šéţţîñĝš åŕé ûšéð ţö çåļçûļåţé å ƀûðĝéţ ƒöŕ ƒļîĝĥţ þŕîçéš ƀåšéð öñ ţŕîþ ļöçåţîöñ åñð ðåţéš· Ƒļîĝĥţ ƀööķîñĝš ŵîļļ ƀé çöñšîðéŕéð îñ‐þöļîçý îƒ ţĥéý åŕé ļéšš éẋþéñšîṽé ţĥåñ ţĥé ƀûðĝéţéð þŕîçé· Ţĥîš ŵîļļ ñöţ ŕéšţŕîçţ ţĥé ţŕåṽéļéŕ ƒŕöɱ ƀööķîñĝ ðîƒƒéŕéñţ ƒåŕé çļåšš öŕ šţöþš åš ļöñĝ åš ţĥé çöšţ îš ŵîţĥîñ ţĥé ƀûðĝéţéð åɱöûñţ·-----------------------------------------------------------------П國カ내]",
    "theseset95": "[Ţĥéšé šéţţîñĝš åŕé ûšéð ţö çåļçûļåţé å ƀûðĝéţ ƒöŕ ĥöţéļ þŕîçéš ƀåšéð öñ ţŕîþ ļöçåţîöñ åñð ðåţéš· Ĥöţéļ ƀööķîñĝš ŵîļļ ƀé çöñšîðéŕéð îñ‐þöļîçý îƒ ţĥéý åŕé ļéšš éẋþéñšîṽé ţĥåñ ţĥé ƀûðĝéţéð þŕîçé· Ţĥîš ŵîļļ ñöţ ŕéšţŕîçţ ţĥé ţŕåṽéļéŕ ƒŕöɱ ƀööķîñĝ ðîƒƒéŕéñţ ĥöţéļ çļåšš öŕ ŕåðîûš åš ļöñĝ åš ţĥé çöšţ îš ŵîţĥîñ ţĥé ƀûðĝéţéð åɱöûñţ·-----------------------------------------------------------------П國カ내]",
    "thesetra12": "[Ţĥéšé ţŕåñšåçţîöñš åŕé þéñðîñĝ åûţĥöŕîžåţîöñ åñð ţĥé ƒîñåļ þöšţéð åɱöûñţ ɱåý ṽåŕý· Ýöû çåñ åššîĝñ، ɱéŕĝé öŕ šûƀɱîţ þéñðîñĝ ţŕåñšåçţîöñš åƒţéŕ ţĥéý åŕé þöšţéð·------------------------------------------------П國カ내]",
    "thesetra31": "[Ţĥéšé ţŕåñšåçţîöñš åŕé þéñðîñĝ åûţĥöŕîžåţîöñš åñð ţĥé ƒîñåļ þöšţéð åɱöûñţ ɱåý ṽåŕý·-----------------П國カ내]",
    "thespeci13": "[Ţĥé šþéçîƒîéð ðåţé ŕåñĝé îš ñöţ ṽåļîð------------П國カ내]",
    "theupdat158": "[Ţĥé ûþðåţéš ýöû’ṽé ɱåðé ţö ţĥé çöɱþåñý šéţţîñĝš ĥåṽé ñöţ ƀééñ šåṽéð·--------------П國カ내]",
    "theycanb121": "[Ţĥéý çåñ ƀé ƒöûñð îñ “Ŕéþöŕţš” ≥ “{title}”· Ñöţé ţĥåţ ţĥé ŕéþöŕţ îš šţîļļ åçţîṽé åñð ĥåš ñöţ ƀééñ šûƀɱîţţéð·----------------------П國カ내]",
    "thisappr149": "[Ţĥîš åþþŕöṽéŕ ŵîļļ ƀé åððéð ţö ţĥé ðéƒåûļţ åþþŕöṽåļ ƒļöŵ îƒ åñý çöñðîţîöñ ƀéļöŵ îš ɱéţ·------------------П國カ내]",
    "thisappr84": "[Ţĥîš åþþŕöṽåļ ŕûļé ĥåš þéñðîñĝ éẋþéñšé ŕéþöŕţš، îţ çåññöţ ƀé ðéļéţéð·--------------П國カ내]",
    "thiscann105": "[Ţĥîš çåññöţ ƀé ŕéṽéŕšéð·-----П國カ내]",
    "thiscard1": "[Ţĥîš çåŕð îš ñöţ åššîĝñéð· Þļéåšé åššîĝñ å ûšéŕ ţö ɱåñåĝé ţŕåñšåçţîöñš· ----------------------П國カ내]",
    "thiscard144": "[Ţĥîš çåŕð ĥåš ƀééñ ðéåçţîṽåţéð åñð çåñ ñö ļöñĝéŕ ƀé ûšéð·------------П國カ내]",
    "thiscard183": "[Ţĥîš çåŕð ŵîļļ åûţöɱåţîçåļļý ļöçķ öñ ţĥîš ðåţé· Îţ îš ðîƒƒéŕéñţ ƒŕöɱ ţĥé <strong> éẋþîŕý ðåţé ({expirydate})</strong> ûšéð ţö åûţĥéñţîçåţé þûŕçĥåšéš·------------------------------П國カ내]",
    "thiscard197": "[Ţĥîš çåŕð åççöûñţ ŵåš çļöšéð öñ {closeddate}· Çåŕð ðéţåîļš åñð ţŕåñšåçţîöñš çåñ ƀé åççéššéð ĥéŕé ûñţîļ {closeddetailsdateuntil}·--------------------------П國カ내]",
    "thiscard205": "[Ţĥîš çåŕð îš ñöţ åššîĝñéð ţö åñ Éẋþéñšé Åššîĝñéé· Þļéåšé åššîĝñ ţö å ûšéŕ ţö ɱåñåĝé ţŕåñšåçţîöñš·--------------------П國カ내]",
    "thiscard46": "[Ţĥîš çåŕð îš Ñöţ Éẋþîŕéð·------П國カ내]",
    "thiscard47": "[Ţĥîš çåŕð îš Ðéåçţîṽåţéð·------П國カ내]",
    "thiscard48": "[Ţĥîš çåŕð ĥåš ñöţ ƀééñ åçţîṽåţéð ýéţ·--------П國カ내]",
    "thiscard49": "[Ţĥîš çåŕð îš Éẋþîŕéð·-----П國カ내]",
    "thiscard51": "[Ţĥîš çåŕð ŵåš åššîĝñéð ţö ýöû ƀý ýöûŕ öŕĝåñîžåţîöñ· Éẋþéñšéš ƒŕöɱ ţĥéšé çåŕðš åŕé ñöñ‐ŕéîɱƀûŕšåƀļé·--------------------П國カ내]",
    "thiscard53": "[Ţĥîš çåŕð ŵåš åððéð ƀý ýöû· Éẋþéñšéš ƒŕöɱ ţĥéšé çåŕðš åŕé ŕéîɱƀûŕšåƀļé·---------------П國カ내]",
    "thiscard55": "[Ţĥîš çåŕð îš å þĥýšîçåļ çåŕð ŵîţĥîñ ýöûŕ öŕĝåñîžåţîöñ ţĥåţ çåñ ƀé ûšéð ƒöŕ éẋþéñšéš·-----------------П國カ내]",
    "thiscard57": "[Ţĥîš çåŕð îš å ṽîŕţûåļ çåŕð ŵîţĥîñ ýöûŕ öŕĝåñîžåţîöñ ţĥåţ çåñ ƀé ûšéð ƒöŕ éẋþéñšéš·-----------------П國カ내]",
    "thiscard59": "[Ţĥîš çåŕð ĥåš ƀééñ šýñçéð ṽîå Þļåîð· Åļļ åššöçîåţéð çåŕð ţŕåñšåçţîöñš çåñ ƀé ƒöûñð ŵîţĥîñ ýöûŕ ‘Çåŕð Ţŕåñšåçţîöñš’ ţö ƀé åššîĝñéð ţö éẋþéñšéš· Ţĥîš îš ñöţ å þåýɱéñţ ɱéţĥöð·-----------------------------------П國カ내]",
    "thiscard61": "[Ţĥîš çåŕð çåñ ƀé ûšéð åš þåýɱéñţ ƒöŕ ŢŕåṽéļƁåñķ ƀööķîñĝš· Ţŕåñšåçţîöñš ĥéŕé ŵöñ’ţ ƀé šýñçéð îñţö ŢŕåṽéļƁåñķ·----------------------П國カ내]",
    "thiscard83": "[Ţĥîš çåŕð ĥåš ƀééñ ðéåçţîṽåţéð ƀý ýöûŕ åðɱîñ åñð çåñ ñö ļöñĝéŕ ƀé ûšéð·---------------П國カ내]",
    "thiscate179": "[Ţĥîš çåţéĝöŕý îš çûŕŕéñţļý ñöţ îñçļûðéð öñ ţĥîš çåŕðĥöļðéŕ’š éẋþéñšé þöļîçý· Ýöû çåñ çöñţîñûé çŕéåţîñĝ ţĥîš çåŕð åñð ţĥéñ ûþðåţé ţĥéîŕ éẋþéñšé þöļîçý ûñðéŕ çöɱþåñý šéţţîñĝš·-----------------------------------П國カ내]",
    "thiscate75": "[ Ţĥîš çåţéĝöŕý ŕéǫûîŕéš ļîñé îţéɱš·--------П國カ내]",
    "thiscomp81": "[Ţĥîš çöɱþåñý ṽîŕţûåļ çåŕð îš çûŕŕéñţļý åçţîṽé åñð çåñ ƀé ûšéð ţö ɱåķé þûŕçĥåšéš ŵîţĥîñ ŢŕåṽéļƁåñķ·--------------------П國カ내]",
    "thiscomp84": "[Ţĥîš çöɱþåñý ṽîŕţûåļ çåŕð îšñ’ţ ƒûļļý šéţ ûþ ƀý ýöûŕ åðɱîñ ýéţ·-------------П國カ내]",
    "thiscomp85": "[Ţĥîš çöɱþåñý ṽîŕţûåļ çåŕð ĥåš éẋþîŕéð·--------П國カ내]",
    "thisconn288": "[Ţĥîš çöññéçţîöñ ŕéǫûîŕéš éẋţŕå ţîɱé ţö ṽéŕîƒý· Ýöû ŵîļļ ƀé ñöţîƒîéð ţĥŕöûĝĥ éɱåîļ öñçé ţĥé çöññéçţîöñ ĥåš ƀééñ çöɱþļéţéð·-------------------------П國カ내]",
    "thiscont97": "[ţĥîš çöñţåîñš ðûþļîçåţé éẋþéñšéš-------П國カ내]",
    "thiscorp82": "[Ţĥîš çöŕþöŕåţé çåŕð îš çûŕŕéñţļý åçţîṽé·---------П國カ내]",
    "thiscurr1": "[{currentLicenses} Ļîçéñšéš------П國カ내]",
    "thisempl78": "{employeeCount, plural, =0 {[Ñö éɱþļöýééš ƒöûñð·----П國カ내]} one {{employeeCount} Éɱþļöýéé-----П國カ내]} other {{employeeCount} Éɱþļöýééš-----П國カ내]}}",
    "thisesti1": "[Ţĥîš éšţîɱåţé öñļý îñçļûðéš åññûåļ ļîçéñšé ƒééš·----------П國カ내]",
    "thisesti12": "[Ţĥîš éšţîɱåţé öñļý îñçļûðéš åññûåļ ļîçéñšé ƒééš·----------П國カ내]",
    "thisexpe21": "[Ţĥîš éẋþéñšé ðöéš ñöţ ñééð ţö ƀé šþļîţ åñð çåñ ƀé šûƀɱîţţéð åš öñé ļîñé îţéɱ·------------------------П國カ내]",
    "thisexpe24": "[Ţĥîš éẋþéñšé ñééðš ţö ƀé šþļîţ ûþ ƀý ðéþåŕţɱéñţš، çåţéĝöŕîéš، öŕ öţĥéŕ ðéţåîļš·------------------------П國カ내]",
    "thisexpe25": "[ţĥîš éẋþéñšé ĥåš ɱîššîñĝ îñƒöŕɱåţîöñ-----------П國カ내]",
    "thisexpe27": "[Ţĥîš éẋþéñšé çöñţåîñš éŕŕöŕš åñð îš ɱîššîñĝ å þåîŕéð ţŕåñšåçţîöñ·--------------П國カ내]",
    "thisexpe28": "[Ţĥîš éẋþéñšé îš ɱîššîñĝ å þåîŕéð ţŕåñšåçţîöñ·----------П國カ내]",
    "thisexpe29": "[Ţĥîš éẋþéñšé çöñţåîñš éŕŕöŕš öŕ ɱîššîñĝ ðéţåîļš·----------П國カ내]",
    "thisexpe39": "[ţĥîš éẋþéñšé îš å ðûþļîçåţé------П國カ내]",
    "thisfeat2": "[Ţĥîš ƒéåţûŕé îšñ’ţ åṽåîļåƀļé öñ ɱöƀîļé ƀŕöŵšéŕ· Ţö ṽîéŵ åñð šûƀɱîţ éẋþéñšéš، ðöŵñļöåð ţĥé åþþ öŕ šŵîţçĥ ţö ðéšķţöþ·-----------------------------------П國カ내]",
    "thishidd102": "[···⁺{hiddenFilters} ɱöŕé-----П國カ내]",
    "thisinfo239": "[Ţĥîš îñƒöŕɱåţîöñ îš šåƒé åñð šéçûŕé·--------П國カ내]",
    "thisinte2": "[Ţĥîš îñţéĝŕåţîöñ ðöéš ñöţ šûþþöŕţ šýñçîñĝ öṽéŕ åļļöçåţîöñ ðåţå åţ ţĥîš ţîɱé----------------П國カ내]",
    "thisisad1": "[Ţĥîš îš å ðîåļöĝ ŵîñðöŵ ŵĥîçĥ öṽéŕļåýš ţĥé ɱåîñ çöñţéñţ öƒ ţĥé þåĝé· Þŕéššîñĝ ţĥé Çļöšé Ṁöðåļ ƀûţţöñ ŵîļļ çļöšé ţĥé ɱöðåļ åñð ƀŕîñĝ ýöû ƀåçķ ţö ŵĥéŕé ýöû ŵéŕé öñ ţĥé þåĝé·-----------------------------------П國カ내]",
    "thisisaf212": "[Ţĥîš îš å ④‐ðîĝîţ çöðé þŕöṽîðéð ƀý ýöûŕ åðɱîñ· Ýöû çåñ åļšö ƒîñð îţ ûñðéŕ çåŕð ðéţåîļš öñ ţĥé Ṁý Çåŕðš þåĝé öñ ţĥé ŢŕåṽéļƁåñķ åþþ·---------------------------П國カ내]",
    "thisisar101": "[Ţĥîš îš å ŕéþŕéšéñţåţîöñ öƒ ŵĥåţ ţĥé çåŕð ŵîļļ ļööķ ļîķé åçŕöšš ţĥé ŢŕåṽéļƁåñķ þļåţƒöŕɱ· Îţ ɱåý ðîƒƒéŕ öñ ýöûŕ ɱöƀîļé ŵåļļéţ öŕ öţĥéŕ åþþš·----------------------------П國カ내]",
    "thisisth26": "[Ţĥîš îš ţĥé åɱöûñţ öñ ýöûŕ ļåšţ šţåţéɱéñţ åñð ðöéš ñöţ îñçļûðé åñý þåýɱéñţš ɱåðé åƒţéŕ ţĥé šţåţéɱéñţ ðåţé·----------------------П國カ내]",
    "thispers66": "[Ţĥîš þéŕšöñåļ çåŕð îš çûŕŕéñţļý šýñçéð åñð îš ɱåŕķéð åš ñöñ‐ŕéîɱƀûŕšåƀļé·---------------П國カ내]",
    "thispers67": "[Ţĥîš þéŕšöñåļ çåŕð îš çûŕŕéñţļý šýñçéð åñð îš ɱåŕķéð åš ŕéîɱƀûŕšåƀļé·--------------П國カ내]",
    "thispers68": "[Ţĥîš þéŕšöñåļ çåŕð îš éẋþîŕéð·-------П國カ내]",
    "thisrepo32": "[Ţĥîš ŕéþöŕţ çöñţåîñš éŕŕöŕš öŕ ɱîššîñĝ ðéţåîļš·----------П國カ내]",
    "thissele1": "[{selectedLicensesText} ƒöŕ ,<b>{totalCostPerMonth}⁄ɱöñţĥ</b>-------------П國カ내]",
    "thistemp245": "[Ţĥîš ţéɱþļåţé çöñţåîñš ɱûļţîþļé ɱéŕçĥåñţ çåţéĝöŕîéš· Å ŕûļé çåñ ƀé åþþļîéð ţö <i>öñļý åļļöŵ</i> öŕ <i>öñļý ðéçļîñé</i> ţĥîš ţéɱþļåţé åñð åļļ çåţéĝöŕîéš îñçļûðéð îñ îţ·----------------------------------П國カ내]",
    "thistran68": "[Ţĥîš ţŕåñšåçţîöñ îš þéñðîñĝ åûţĥöŕîžåţîöñ åñð ţĥé ƒîñåļ þöšţéð åɱöûñţ ɱåý ṽåŕý· Ýöû çåñ åššîĝñ، ɱéŕĝé öŕ šûƀɱîţ þéñðîñĝ ţŕåñšåçţîöñš åƒţéŕ ţĥéý åŕé þöšţéð·--------------------------------П國カ내]",
    "thistran79": "[Ţĥîš ţŕåñšåçţîöñ îš þéñðîñĝ åûţĥöŕîžåţîöñ åñð ţĥé ƒîñåļ þöšţéð åɱöûñţ ɱåý ṽåŕý· Þéñðîñĝ ţŕåñšåçţîöñš ŕéðûçé åṽåîļåƀļé çŕéðîţ ļîɱîţ·---------------------------П國カ내]",
    "thistype64": "[Ţĥîš {type} ŕéǫûéšţ ðöéš ñöţ éẋîšţ·--------П國カ내]",
    "thistype66": "[Ţĥîš {type} ŕéǫûéšţ ðöéš ñöţ éẋîšţ·--------П國カ내]",
    "thisunsa77": "{unsavedRulesCount, plural, one {[# Ûñšåṽéð Çĥåñĝé-----П國カ내]} other {[# Ûñšåṽéð Çĥåñĝéš-----П國カ내]}}",
    "thiswill130": "[Ţĥîš ŵîļļ ƀé ţĥé ɱåšţéŕ ţŕåṽéļ þöļîçý ƒöŕ ýöûŕ öŕĝåñîžåţîöñ· Îţ ðîçţåţéš ĥöŵ ţŕîþ ƀûðĝéţš åŕé çåļçûļåţéð åñð ĝûîðéš ţŕåṽéļéŕš´ ƀööķîñĝ šéļéçţîöñš· Åððîţîöñåļ þöļîçîéš çåñ ƀé çŕéåţéð åñð åššîĝñéð ţö šþéçîƒîç ûšéŕš öŕ ðéþåŕţɱéñţš îñ çöɱþåñý šéţţîñĝš·--------------------------------------------------П國カ내]",
    "thiswill21": "[Ţĥîš ŵîļļ çöñţîñûé îñ ţĥé ƀåçķĝŕöûñð· <br /> Ƒééļ ƒŕéé ţö çļöšé ţĥîš þåĝé·-----------------------П國カ내]",
    "thiswill72": "[Ţĥîš ŵîļļ šéñð åļļ þéñðîñĝ éẋþéñšéš ţö ýöûŕ ɱåñåĝéŕ-----------П國カ내]",
    "thiswill96": "[Ţĥîš ŵîļļ þéŕɱåñéñţļý ŕéɱöṽé ţĥé åççöûñţ ƒŕöɱ ŢŕåṽéļƁåñķ·------------П國カ내]",
    "threesta153": "[③ Šţåŕ Ĥöţéļš---П國カ내]",
    "thursday13": "[Ţĥûŕšðåý--П國カ내]",
    "title155": "[Ţîţļé--П國カ내]",
    "titletit242": "[Ţîţļé {title}---П國カ내]",
    "today1": "[Ţöðåý---П國カ내]",
    "today33": "[Ţöðåý--П國カ내]",
    "today85": "[Ţöðåý--П國カ내]",
    "todeacti10": "[Ţö ðéåçţîṽåţé ţĥîš çåŕð، þļéåšé éñţéŕ ýöûŕ Û·Š· Ɓåñķ çŕéðéñţîåļš·--------------П國カ내]",
    "tolearnm118": "[ţö ļéåŕñ ɱöŕé·---П國カ내]",
    "tosetupo1": "[Ţö šéţ ûþ öŕ ɱåñåĝé åûţö‐þåý، çĥåţ ŵîţĥ ûš öŕ éɱåîļ ûš åţ {emailHref}· ----------------------П國カ내]",
    "tostartc236": "[Ţö šţåŕţ çŕéåţîñĝ îñšţåñţ çåŕðš، þļéåšé éñţéŕ ýöûŕ {bankpublicname} çŕéðéñţîåļš ţö šţåŕţ ţĥé þŕöçéšš·---------------------П國カ내]",
    "totalamo211": "[Ţöţåļ Ţŕåñšåçţîöñ Åɱöûñţ: {totalamount}----П國カ내]",
    "totalamo45": "[Total Åɱöûñţ: {amount}--------П國カ내]",
    "totalamo82": "[Ţöţåļ Åɱöûñţ---П國カ내]",
    "totalbo1": "[Ţöţåļ Ɓööķîñĝš (ţŕåṽéļ ţŕåñšåçţîöñš)--------П國カ내]",
    "totalcos25": "[Ţöţåļ Çöšţ---П國カ내]",
    "totaldec49": "[Total Ðéçļîñéð Åɱöûñţ: {amount}--------П國カ내]",
    "totalerr41": "[{totalErrors} éŕŕöŕš ŵîţĥ éẋþéñšé šþļîţš·-------------П國カ내]",
    "totalexp9": "[Ţöţåļ Éẋþéñšéš-----П國カ내]",
    "totalpen47": "[Total Þéñðîñĝ Åɱöûñţ: {amount}--------П國カ내]",
    "totalpen51": "[Total Þéñðîñĝ Ðéçļîñéð Åɱöûñţ: {amount}--------П國カ내]",
    "totalper182": "[€{total} ⁄ɱöñţĥ ƀîļļéð åññûåļļý-------П國カ내]",
    "totalper191": "[€{total} ⁄ɱöñţĥ ƀîļļéð ɱöñţĥļý-------П國カ내]",
    "totalrei1": "[Ţöţåļ ŕéîɱƀûŕšéð----П國カ내]",
    "totalrei10": "[Ţöţåļ Ŕéîɱƀûŕšéð-----П國カ내]",
    "totalrei26": "[Ţöţåļ Ŕéîɱƀûŕšåƀļé----П國カ내]",
    "totalrep1": "[Ţöţåļ ŕéþöŕţš åþþŕöṽéð ƀý ƒîñåñçé-------П國カ내]",
    "totaltax27": "[Ţöţåļ ţåẋ----П國カ내]",
    "tracksav26": "[Ţŕåçķ Šåṽîñĝš----П國カ내]",
    "tracktra31": "[Ţŕåçķ ţŕåṽéļ šþéñð، éɱþļöýéé šåṽîñĝš، åñð ţŕåṽéļ ţŕéñðš ŵîţĥ Åñåļýţîçš· Çöñţåçţ öûŕ ţéåɱ ţö ûñļöçķ ţĥîš ƒéåţûŕé·----------------------------------П國カ내]",
    "trackyou106": "[Ţŕåçķ Ýöûŕ Éẋþéñšéš----П國カ내]",
    "trandetails4": "[Ţŕåñšåçţîöñ ðéţåîļš ƒŕöɱ<br /> <b>{transactionVenueTitle}</b> öñ <b>{transactionDate}</b> ƒöŕ <b>{transactionCurrency}</b><br /> ŵîļļ öṽéŕŕîðé ţĥé ŕéšþéçţîṽé ƒîéļðš öñ ţĥé éẋþéñšé<br /> <b>{expenseVenueTitle}</b> on <b>{expenseDate}</b> ƒöŕ <b>{expenseCurrency}</b><br /><br /> Ţĥîš çåññöţ ƀé ŕéṽéŕšéð·-------------------------------------------------------------------------------------------П國カ내]",
    "transact1": "[Ţŕåñšåçţîöñš---П國カ내]",
    "transact110": "[Ţŕåñšåçţîöñš | Ṁåñåĝé----П國カ내]",
    "transact111": "[Ţŕåñšåçţîöñ Šţåţûš (ŕéǫûîŕéð)------П國カ내]",
    "transact12": "[Ţŕåñšåçţîöñ Šýñç Îš Ðîšåƀļéð Ƒöŕ Ýöûŕ Çöɱþåñý----------П國カ내]",
    "transact122": "[Ţŕåñšåçţîöñš šûççéššƒûļļý ðéļéţéð-------П國カ내]",
    "transact123": "[Ţŕåñšåçţîöñš ðéļéţîöñ ƒåîļéð¡------П國カ내]",
    "transact124": "[Ţŕåñšåçţîöñš åššîĝñéɱéñţ ƒåîļéð¡-------П國カ내]",
    "transact125": "[Ţŕåñšåçţîöñ šûççéššƒûļļý þåîŕéð·-------П國カ내]",
    "transact131": "[Ţŕåñšåçţîöñ Ŕéƒéŕéñçé ÎÐ-----П國カ내]",
    "transact150": "[Ţŕåñšåçţîöñ Šţåţûš åš {label}------П國カ내]",
    "transact189": "[Ţŕåñšåçţîöñ Šţåţûš ðŕöþðöŵñ------П國カ내]",
    "transact2": "[Ţŕåñšåçţîöñš---П國カ내]",
    "transact26": "[Ţŕåñšåçţîöñ šûççéššƒûļļý þåîŕéð·-------П國カ내]",
    "transact275": "[<sup class=\"cc-verify-save-modal__superscript\">*</sup>Ţŕåñšåçţîöñš åļŕéåðý åššîĝñéð ţö å ŕéþöŕţ ŵîļļ çöñţîñûé ţö ƀé ɱåñåĝéð ƀý ţĥé çûŕŕéñţ åššîĝñéé{currentuser}·---------------------------------П國カ내]",
    "transact28": "[Ţŕåñšåçţîöñš Åššîĝñéð-----П國カ내]",
    "transact31": "[Ţŕåñšåçţîöñ Šţåţûš----П國カ내]",
    "transact33": "[Ţŕåñšåçţîöñ Šţåţûš----П國カ내]",
    "transact4": "[Ţŕåñšåçţîöñ Ĥîšţöŕý----П國カ내]",
    "transact42": "{transactionCount, plural, one {[① Ţŕåñšåçţîöñš---П國カ내]} other {[# Ţŕåñšåçţîöñš---П國カ내]}}",
    "transact56": "[Ţŕåñšåçţîöñ {defaultdaterangetext}-------П國カ내]",
    "transact6": "[Ţŕåñšåçţîöñš---П國カ내]",
    "transact70": "[Ţŕåñšåçţîöñ Šýñç----П國カ내]",
    "transact76": "[Ţŕåñšåçţîöñ Ðéţåîļ----П國カ내]",
    "transact81": "[Ţŕåñšåçţîöñ Ðåţé----П國カ내]",
    "transacti167": "[Ţŕåñšåçţîöñ Ðåţé Ŕåñĝé (ŕéǫûîŕéð)-------П國カ내]",
    "transdat15": "[Ţŕåñš Ðåţé---П國カ내]",
    "travel140": "[Ţŕåṽéļ--П國カ내]",
    "travel169": "[Ţŕåṽéļ--П國カ내]",
    "travel214": "[Ţŕåṽéļ--------П國カ내]",
    "travel3": "[Ţŕåṽéļ--П國カ내]",
    "travel70": "[Ţŕåṽéļ--П國カ내]",
    "travel72": "[Ţŕåṽéļ--П國カ내]",
    "travel9": "[Ţŕåṽéļ--П國カ내]",
    "travelat23": "[Ţŕåṽéļåţöŕ Ţéŕɱš öƒ Ûšé-------П國カ내]",
    "travelba133": "[ŢŕåṽéļƁåñķ Ŕéçöɱɱéñðéð Þöļîçý------П國カ내]",
    "travelba196": "[ŢŕåṽéļƁåñķ Ŕéçöɱɱéñðéð-----П國カ내]",
    "travelba29": "[ŢŕåṽéļƁåñķ ûšéŕ ŵĥö çåñ ṽîéŵ ţŕåñšåçţîöñš، çŕéåţé ɱåñûåļ éẋþéñšéš، åñð (îƒ åþþļîçåƀļé) šûƀɱîţ éẋþéñšé ŕéþöŕţš öñ ƀéĥåļƒ öƒ ţĥé çåŕðĥöļðéŕ·----------------------------П國カ내]",
    "travelba32": "[Ţŕåṽéļƀåñķ Ļöĝö----П國カ내]",
    "travelba7": "[ŢŕåṽéļƁåñķ îñţéĝŕåţéš ŵîţĥ ţĥé šöƒţŵåŕé åñð ţööļš ýöû’ŕé åļŕéåðý ûšîñĝ ţö ķééþ ýöûŕ ƀûšîñéšš ŕûññîñĝ šɱööţĥļý، ļîķé Ñéţšûîţé، Ŵöŕķðåý، åñð ɱöŕé¡-----------------------------П國カ내]",
    "travelba90": "[ŢŕåṽéļƁåñķ Çöñƒîŕɱåţîöñ Ñûɱƀéŕ-------П國カ내]",
    "travelc1": "[Ţŕåṽéļ çûšţöɱ ƒîéļðš-----П國カ내]",
    "travelcu171": "[Ţŕåṽéļ çûšţöɱ ƒîéļðš åŕé çûŕŕéñţļý ðîšåƀļéð·---------П國カ내]",
    "traveler1": "[Ţŕåṽéļéŕ Îñƒöŕɱåţîöñ-----П國カ내]",
    "traveler10": "[Ţŕåṽéļéŕ Þŕöƒîļé-----П國カ내]",
    "travelke1": "[Ţŕåṽéļ--П國カ내]",
    "travelma94": "[Ţŕåṽéļ Ṁåñåĝéɱéñţ----П國カ내]",
    "travelpo1": "[Ţŕåṽéļ Þöļîçý---П國カ내]",
    "travelpo127": "[Ţŕåṽéļ Þöļîçý---П國カ내]",
    "travelpo70": "[Ţŕåṽéļ Þöļîçý---П國カ내]",
    "travelpo71": "[Ţŕåṽéļ Þöļîçý---П國カ내]",
    "travelpo83": "[Ţŕåṽéļ Þöļîçý---П國カ내]",
    "travelpo85": "[Ţŕåṽéļ Þöļîçý---П國カ내]",
    "travelre4": "[Ţŕåṽéļ Ŕéǫûéšţš----П國カ내]",
    "travels1": "[Ţŕåṽéļ<sup>①</sup>----П國カ내]",
    "traveltr1": "[Ţŕåṽéļ Ţŕåçķéŕ---П國カ내]",
    "traveltr8": "[Ţŕåṽéļ Ţŕåñšåçţîöñš------П國カ내]",
    "travistr80": "[Ţŕåṽîš Ţŕåṽéļéŕ----П國カ내]",
    "triggeredRule": {
      "intervalMessage": {
        "perDay": "[<strong>Öṽéŕ {limit} šþéñð</strong> ţĥîš ðåý ƒöŕ {name}·------------П國カ내]",
        "perExpense": "[<string>Öṽéŕ {limit} šþéñð</strong> þéŕ éẋþéñšé ƒöŕ {name}·------------П國カ내]",
        "perMonth": "[<strong>Öṽéŕ {limit} šþéñð</strong> ţĥîš ɱöñţĥ ƒöŕ {name}·------------П國カ내]",
        "perWeek": "[<strong>Öṽéŕ {limit} šþéñð</strong> ţĥîš ŵééķ ƒöŕ {name}·------------П國カ내]",
        "perYear": "[<strong>Öṽéŕ {limit} šþéñð</strong> ţĥîš ýéåŕ ƒöŕ {name}·------------П國カ내]",
        "unknown": "[<strong>Öṽéŕ {limit} šþéñð</strong> ûñķñöŵñ îñţéŕṽåļ ƒöŕ {name}·-------------П國カ내]"
      },
      "limitIntervalName": {
        "perDay": "[Þéŕ Ðåý Ļîɱîţ---П國カ내]",
        "perExpense": "[Þéŕ Éẋþéñšé Ļîɱîţ----П國カ내]",
        "perMonth": "[Þéŕ Ṁöñţĥ Ļîɱîţ----П國カ내]",
        "perWeek": "[Þéŕ Ŵééķ Ļîɱîţ---П國カ내]",
        "perYear": "[Þéŕ Ýéåŕ Ļîɱîţ---П國カ내]"
      },
      "totalIntervalMessage": {
        "perDay": "[Ðåý ţöţåļ∶ {total}----П國カ내]",
        "perMonth": "[Ṁöñţĥļý ţöţåļ∶ {total}-----П國カ내]",
        "perWeek": "[Ŵééķļý ţöţåļ∶ {total}-----П國カ내]",
        "perYear": "[Ýéåŕļý ţöţåļ∶ {total}-----П國カ내]"
      }
    },
    "trip103": "[Ţŕîþ-П國カ내]",
    "trip137": "[Ţŕîþ-П國カ내]",
    "tripid104": "[Ţŕîþ ÎÐ--П國カ내]",
    "tripid138": "[Ţŕîþ ÎÐ--П國カ내]",
    "tryagain27": "[Ţŕý Åĝåîñ---П國カ내]",
    "tryloggi30": "[Ţŕý ļöĝĝîñĝ îñ öŕ ûšé ţĥé ƒöŕĝéţ þåššŵöŕð îƒ ýöû ðöñ’ţ ŕéɱéɱƀéŕ ýöûŕ þåššŵöŕð·----------------П國カ내]",
    "tryusing17": "[Ţŕý ûšîñĝ å ðîƒƒéŕéñţ ķéýŵöŕð öŕ ƒîļţéŕ·-------------П國カ내]",
    "tryusing21": "[Ţŕý ûšîñĝ å ðîƒƒéŕéñţ ķéýŵöŕð öŕ ƒîļţéŕ--------П國カ내]",
    "tryusing56": "[Ţŕý ûšîñĝ å ðîƒƒéŕéñţ ķéýŵöŕð·-------П國カ내]",
    "tryusing82": "[Ţŕý ûšîñĝ å ðîƒƒéŕéñţ ķéýŵöŕð öŕ ƒîļţéŕ·---------П國カ내]",
    "tuesday11": "[Ţûéšðåý--П國カ내]",
    "turnonif127": "[Ţûŕñ öñ îƒ éɱþļöýééš ûšé å çöŕþöŕåţé çåŕð· Ţĥéšé éẋþéñšéš ŵîļļ ñöţ ƀé ŕéîɱƀûŕšéð·-----------------П國カ내]",
    "turnonre173": "[Ţûŕñ öñ ŕéîɱƀûŕšéɱéñţš ţö ŕéîɱƀûŕšé éẋþéñšéš îñ ţĥîš þöļîçý ţĥŕöûĝĥ ŢŕåṽéļƁåñķ·----------------П國カ내]",
    "turnonto135": "[Ţûŕñ öñ ţö ĝîṽé éɱþļöýééš ţĥé öþţîöñ ţö åļļöçåţé ţĥéîŕ éẋþéñšéš ţö ɱûļţîþļé ðéþåŕţɱéñţš· Åļļöçåţîöñ ðåţå ðöéš ñöţ šýñç öṽéŕ ţö îñţéĝŕåţîöñš ļîķé ǪƁÖ، ÑéţŠûîţé åñð Ẋéŕö·----------------------------------П國カ내]",
    "turnthis158": "[Ţûŕñ ţĥîš öñ ţö éẋþöŕţ ļîñé îţéɱš ƒŕöɱ éẋþéñšéš·----------П國カ내]",
    "turnthis160": "[Ţûŕñ ţĥîš öñ ţö éẋþöŕţ åļļöçåţîöñš ƒŕöɱ éẋþéñšéš·----------П國カ내]",
    "turnthis39": "[Ţûŕñ ţĥîš öñ ţö éẋþöŕţ ļîñé îţéɱš ƒŕöɱ éẋþéñšéš·----------П國カ내]",
    "turnthis44": "[Ţûŕñ ţĥîš öñ ţö éẋþöŕţ åļļöçåţîöñš ƒŕöɱ éẋþéñšéš·----------П國カ내]",
    "type19": "[Ţýþé--П國カ내]",
    "typedisplaynames": {
      "expensemanagement": "[Éẋþéñšé Ṁåñåĝéɱéñţ----П國カ내]",
      "generalledger": "[Ĝéñéŕåļ Ļéðĝéŕ---П國カ내]",
      "hris": "[Ĥûɱåñ Ŕéšöûŕçéš----П國カ내]",
      "humanresources": "[Ĥûɱåñ Ŕéšöûŕçéš----П國カ내]"
    },
    "typeinto204": "[Ţýþé îñ ţö šéåŕçĥ----П國カ내]",
    "typemana83": "[Ţýþé ɱåñåĝéŕ ñåɱé ţö šéåŕçĥ------П國カ내]",
    "typeofac90": "[Ţýþé öƒ Åççöûñţ----П國カ내]",
    "typerequ74": "[{type} Ŕéǫûéšţ Çåñçéļļéð·------П國カ내]",
    "typetose160": "[Ţýþé ţö šéåŕçĥ éɱþļöýéé ñåɱé···-------П國カ내]",
    "uid212": "UID",
    "unableto12": "[Ûñåƀļé ţö çåñçéļ ţĥé þåýɱéñţ åš îţ îš þåšţ ţĥé çåñçéļåţîöñ çûţ‐öƒƒ· Þåýɱéñţš ɱûšţ ƀé çåñçéļéð ƀéƒöŕé ③∶⑤⑨ ÞṀ ÇŢ öñ ţĥé þåýɱéñţ ðåţé·---------------------------П國カ내]",
    "unableto166": "[Ûñåƀļé ţö šéţ ṀÇÇ çöñţŕöļš·------П國カ내]",
    "unableto181": "[Ûñåƀļé ţö ļîñķ ƀåñķ åççöûñţ·------П國カ내]",
    "unableto189": "[Ûñåƀļé ţö ļîñķ ƀåñķ åççöûñţ· ({error})--------П國カ내]",
    "unableto27": "[Ûñåƀļé ţö çŕéåţé çåŕð-----П國カ내]",
    "unableto55": "[Ûñåƀļé ţö ĝéţ ǪƁÖ Çļåššéš، ţŕý åĝåîñ ļåţéŕ·---------П國カ내]",
    "unableto61": "[Ûñåƀļé ţö šéţ ǪƁÖ Çļåšš، þļéåšé ţŕý åĝåîñ·---------П國カ내]",
    "unableto69": "[Ûñåƀļé ţö šåṽé öþţîöñ-----П國カ내]",
    "unableto75": "[Ûñåƀļé ţö šåṽé öþţîöñ-----П國カ내]",
    "unableto90": "[Ûñåƀļé ţö ţöĝĝļé ƀļöçķ åûţö ĵöîñîñĝ--------П國カ내]",
    "unapprov77": "[Ûñåþþŕöṽé Ŕéþöŕţ----П國カ내]",
    "unassign24": "[Ûñåššîĝñéð---П國カ내]",
    "unassign60": "[Ûñåššîĝñéð---П國カ내]",
    "unassign80": "[Ûñåššîĝñéð---П國カ내]",
    "unitedst109": "[Ûñîţéð Šţåţéš---П國カ내]",
    "unknowni9": "[Ûñķñöŵñ îñţéĝŕåţîöñ þŕöṽîðéŕ∶ {provider}·---------П國カ내]",
    "unlimite1": "[Ûñļîɱîţéð ðéþåŕţɱéñţš-----П國カ내]",
    "unlockal1": "[Ûñļöçķ åļļ öƒ ŢŕåṽéļƁåñķ´š þŕéɱîûɱ ƒéåţûŕéš ƒŕöɱ Ţŕåṽéļ åñð Éẋþéñšé îñ öñé ļîçéñšé·-----------------П國カ내]",
    "unlockan30": "[Ûñļöçķ Åñåļýţîçš-----П國カ내]",
    "unlocked47": "[Ûñļöçķéð--П國カ내]",
    "unsavedc88": "[Ûñšåṽéð Çĥåñĝéš Ŵîļļ Ɓé Ðîšçåŕðéð-------П國カ내]",
    "update16": "[Ûþðåţé--П國カ내]",
    "update20": "[Ûþðåţé--П國カ내]",
    "update5": "[Ûþðåţé--П國カ내]",
    "update7": "[Ûþðåţé--П國カ내]",
    "updateai21": "[Ûþðåţé Åîŕļîñé Ļöýåļţý Þŕöĝŕåɱ----------П國カ내]",
    "updatebi1": "[Ûþðåţé ƀîļļîñĝ çöðéš åñð ðîšçöûñţ ñûɱƀéŕš ƒöŕ çåŕ ŕéñţåļš·------------П國カ내]",
    "updateca18": "[Ûþðåţé Çåŕ Ļöýåļţý Þŕöĝŕåɱ--------П國カ내]",
    "updatecr14": "[Ûþðåţé Çŕéðîţ Çåŕð Éñðîñĝ îñ {ccLast4}--------П國カ내]",
    "updateho23": "[Ûþðåţé Ĥöţéļ Ļöýåļţý Þŕöĝŕåɱ---------П國カ내]",
    "updatepa1": "[Ûþðåţé Þåššŵöŕð----П國カ내]",
    "updatepa37": "[Ûþðåţé Þåššŵöŕð-----П國カ내]",
    "updatere55": "[Ûþðåţé Ŕéîɱƀûŕšéɱéñţ Åççöûñţ---------П國カ내]",
    "updateyo15": "[Ûþðåţé ýöûŕ çŕéðîţ çåŕð îñƒöŕɱåţîöñ·--------П國カ내]",
    "updateyo19": "[Ûþðåţé ýöûŕ Ŕéñţåļ Çåŕ Ļöýåļţý Þŕöĝŕåɱ Ñûɱƀéŕ ţö éåŕñ þöîñţš åñð ûñļöçķ šţåţûš ţîéŕš·--------------------------П國カ내]",
    "updateyo22": "[Ûþðåţé ýöûŕ Åîŕļîñé Ļöýåļţý Þŕöĝŕåɱ Ñûɱƀéŕ ţö éåŕñ þöîñţš åñð ûñļöçķ šţåţûš ţîéŕš·-------------------------П國カ내]",
    "updateyo24": "[Ûþðåţé ýöûŕ Ĥöţéļ Ļöýåļţý Þŕöĝŕåɱ Ñûɱƀéŕ ţö éåŕñ þöîñţš åñð ûñļöçķ šţåţûš ţîéŕš·-------------------------П國カ내]",
    "updating56": "[Ûþðåţîñĝ ţĥîš îñƒöŕɱåţîöñ ŵîļļ ŕéþļåçé ýöûŕ éẋîšţîñĝ ƀåñķ åççöûñţ ûšéð ƒöŕ ŕéîɱƀûŕšéɱéñţš·----------------------------П國カ내]",
    "upgradep33": "[Ûþĝŕåðé Þļåñ---П國カ내]",
    "upgradep79": "[Ûþĝŕåðé Þļåñ----П國カ내]",
    "upgradep84": "[Ûþĝŕåðé Þļåñ----П國カ내]",
    "upgradet1": "[Ûþĝŕåðé ţö Þŕéɱîûɱ Îñšîĝĥţš ţö åççéšš ɱöŕé ŕéþöŕţš åñð ðåţå· -------------------П國カ내]",
    "upgradet103": "[Ûþĝŕåðé ţö Éðîţ----П國カ내]",
    "upgradet113": "[Ûþĝŕåðé ţö Ĝéţ Ṁöŕé ƒŕöɱ ŢŕåṽéļƁåñķ--------П國カ내]",
    "upgradet114": "[Ûþĝŕåðé ţö Éðîţ----П國カ내]",
    "upgradet126": "[Ûþĝŕåðé ţö Éðîţ----П國カ내]",
    "upgradet140": "[Ûþĝŕåðé ţö Éðîţ----П國カ내]",
    "upgradet146": "[Ûþĝŕåðé ţö Éðîţ----П國カ내]",
    "upgradet185": "[Ûþĝŕåðé ţö Éðîţ----П國カ내]",
    "upgradet2": "[Ûþĝŕåðé ţö Þŕéɱîûɱ Îñšîĝĥţš---------П國カ내]",
    "upgradet79": "[Ûþĝŕåðé ţö Åðð Ţŕåṽéļ Þöļîçý------П國カ내]",
    "upgradet8": "[Ûþĝŕåðé ţö Ûñļöçķ----П國カ내]",
    "upgradet85": "[Ûþĝŕåðé ţö Åðð---П國カ내]",
    "upgradet94": "[Ûþĝŕåðé ţö Åðð Éẋþéñšé Þöļîçý------П國カ내]",
    "upgradey82": "[Ûþĝŕåðé ýöûŕ þļåñ----П國カ내]",
    "uploadar107": "[Ûþļöåð å ŕéçéîþţ åñð ŵé'ļļ ƒîļļ îñ ţĥé ŕéšţ· Ýöû çåñ åļšö ƒöŕŵåŕð éɱåîļ éẋþéñšéš ţö <a class=\"empty-screen__text_inline-link\" href=\"{emailHref}\" >receipts@travelbank.com</a>·-------------------------------------П國カ내]",
    "uploadcs69": "[Ûþļöåð ÇŠṼ Ƒîļé----П國カ내]",
    "uploadcs82": "[Ûþļöåð ·çšṽ ƒîļé----П國カ내]",
    "uploadfi79": "[ûþļöåð ƒîļé---П國カ내]",
    "upto152": "[Ûþ ţö {amount}---П國カ내]",
    "usagecos1": "[{cost} ⁄ûšéŕ⁄ɱö·----П國カ내]",
    "usbank314": "[ÛŠ Ɓåñķ--П國カ내]",
    "usbankke1": "[Û·Š· Ɓåñķ--П國カ내]",
    "usbankse113": "[Û·Š· Ɓåñķ šéŕṽéŕ éŕŕöŕ·-----П國カ내]",
    "usd206": "[ÛŠÐ-П國カ내]",
    "usd23": "[ÛŠÐ-П國カ내]",
    "usd71": "[ÛŠÐ-П國カ내]",
    "usehisto25": "[Ûšé ĥîšţöŕîçåļ ðåţå åš ƀéñçĥɱåŕķš ţö åççûŕåţéļý ƒöŕéçåšţ ƒûţûŕé šþéñð åñð îɱþŕöṽé éɱþļöýéé ƀéĥåṽîöŕ·-------------------------------П國カ내]",
    "useourre197": "[Ûšé öûŕ ŕéçöɱɱéñðéð šéţţîñĝš öûţ öƒ ţĥé ƀöẋ ñöŵ، ŵîţĥ ţĥé ƒļéẋîƀîļîţý ţö åðĵûšţ ţĥéɱ ļåţéŕ·-------------------П國カ내]",
    "useourre200": "[Ûšé öûŕ ŕéçöɱɱéñðéð šéţţîñĝš öûţ öƒ ţĥé ƀöẋ ñöŵ·----------П國カ내]",
    "user": {
      "login": {
        "button": "[Ļöĝ Îñ--П國カ내]",
        "formheader": "[Ļöĝ Îñ--П國カ내]",
        "privacy_description": {
          "p1": "By signing up, I agree to the",
          "p2": "and the ",
          "privacy_policy": "privacy policy",
          "terms_of_use": "terms of use "
        }
      },
      "needsVerification": {
        "message": "[To join the {companyName} team on TravelBank, tap the button in the email we sent to ----П國カ내]",
        "title": "[Check Your Email----П國カ내]"
      },
      "needsVerificationNoOrg": {
        "message": "[To join TravelBank, tap the button in the email we sent to ----П國カ내]",
        "title": "[Check Your Email----П國カ내]"
      },
      "settings": {
        "removeBankAccount": {
          "fail": "[There was a problem removing the bank account.----П國カ내]",
          "link": "[Remove Reimbursement Account----П國カ내]",
          "success": "[Successfully removed bank account.----П國カ내]"
        }
      },
      "signup": {
        "account_exists": {
          "message": "[Try logging in or use the forget password if you don’t remember your password.----П國カ내]",
          "passwordForgotButton": "[Forgot Password----П國カ내]",
          "title": "[You already have an account----П國カ내]"
        },
        "button": "[Sign Up----П國カ내]",
        "confirmAccount": {
          "message": "[When you're ready, confirm your account by clicking the link we sent to your email.----П國カ내]",
          "title": "[We sent you an email----П國カ내]"
        },
        "formheader": "[Sign Up----П國カ내]",
        "invite_exists": {
          "message": "[We need you to verify your email address by accepting the invite in your email. We resent it so you have it at the top of your inbox.----П國カ내]",
          "title": "[You've already been invited!----П國カ내]"
        }
      }
    },
    "user102": "[Ûšéŕ-П國カ내]",
    "useremai120": "[Ûšéŕ Éɱåîļ---П國カ내]",
    "username6": "[Ûšéŕñåɱé--П國カ내]",
    "usethein68": "[Ûšé ţĥé îñþûţ åƀöṽé ţö šéåŕçĥ ƒöŕ öþţîöñš·---------П國カ내]",
    "usethisf208": "[Ûšé ţĥîš ƒöŕɱ öñļý îƒ ýöûŕ éɱþļöýéŕ ĥåš šéñţ ýöû å þĥýšîçåļ çŕéðîţ çåŕð îššûéð ƀý {banklabel}·-------------------П國カ내]",
    "usingfor184": "[Ûšîñĝ ƒöŕ Ṁýšéļƒ----П國カ내]",
    "utofpoli113": "[Öûţ öƒ Þöļîçý---П國カ내]",
    "validema4": "[Ṽåļîð éɱåîļ åððŕéšš----П國カ내]",
    "validema9": "[Ṽåļîð éɱåîļ åððŕéšš----П國カ내]",
    "validto333": "[Ṽåļîð Ţö--П國カ내]",
    "validunt179": "[Ṽåļîð Ûñţîļ---П國カ내]",
    "validunt182": "[Ṽåļîð Ûñţîļ---П國カ내]",
    "vendorca75": "[Ṽéñðöŕ Çåŕð---П國カ내]",
    "vendorna163": "[Ṽéñðöŕ Ñåɱé---П國カ내]",
    "vendorvi171": "[Ṽéñðöŕ Ṽîŕţûåļ Çåŕð----П國カ내]",
    "vendorvi68": "[Ṽéñðöŕ Ṽîŕţûåļ Çåŕð----П國カ내]",
    "verifica1": "[Ṽéŕîƒîçåţîöñ Ļîñķ Šéñţ-------П國カ내]",
    "verify4": "[Ṽéŕîƒý--П國カ내]",
    "verify86": "[Ṽéŕîƒý--П國カ내]",
    "verifyac13": "[Ṽéŕîƒý Åççöûñţ ţö Ṁåñåĝé Åļļ Éẋþéñšéš------------П國カ내]",
    "verifyac4": "[Ṽéŕîƒý Åççöûñţ ţö Åðð Ţŕåṽéļéŕ Þŕöƒîļé--------П國カ내]",
    "verifyac5": "[Ṽéŕîƒý Åççöûñţ ţö Çöɱþļéţé Šéţûþ----------П國カ내]",
    "verifyin222": "[Ṽéŕîƒýîñĝ···---П國カ내]",
    "verifyit218": "[Ṽéŕîƒý Îţ’š Ýöû----П國カ내]",
    "verifyyo59": "[Ṽéŕîƒý Ýöûŕ Ŕéîɱƀûŕšéɱéñţ Åççöûñţ----------П國カ내]",
    "version49": "[Ṽéŕšîöñ---П國カ내]",
    "versionv22": "[Version {versionNumber}-------П國カ내]",
    "vertical279": "[¦ {accountname}----П國カ내]",
    "view199": "[Ṽîéŵ-П國カ내]",
    "view3": "[Ṽîéŵ-П國カ내]",
    "view44": "[Ṽîéŵ-П國カ내]",
    "viewacti1": "[Ṽîéŵ åçţîṽé ţŕåṽéļéŕš åñð ţĥéîŕ ţŕîþ ðéţåîļš·----------П國カ내]",
    "viewall14": "[Ṽîéŵ Åļļ--П國カ내]",
    "viewanex82": "[Ṽîéŵ åñ Éẋþéñšé----П國カ내]",
    "viewdele108": "[Ṽîéŵ Ðéļéţéð Éẋþéñšéš-----П國カ내]",
    "viewedit1": "[Ṽîéŵ، éðîţ åñð ɱåñåĝé ţĥé éɱþļöýééš îñ ýöûŕ öŕĝåñîžåţîöñ·------------П國カ내]",
    "viewedit2": "[Ṽîéŵ، éðîţ åñð ɱåñåĝé ţĥé ðéþåŕţɱéñţš îñ ýöûŕ öŕĝåñîžåţîöñ·------------П國カ내]",
    "viewexpe96": "[Ṽîéŵ Éẋþéñšé---П國カ내]",
    "viewlast12": "[Ṽîéŵ Ļåšţ Šţåţéɱéñţ----П國カ내]",
    "viewmore15": "[Ṽîéŵ Ṁöŕé Çåŕðš----П國カ내]",
    "viewmore18": "[Ṽîéŵ Ṁöŕé Éẋþéñšéš----П國カ내]",
    "viewolde4": "[Ṽîéŵ Öļðéŕ Ţŕåñšåçţîöñš-----П國カ내]",
    "viewpast21": "[Ṽîéŵ Þåšţ Ŕéþöŕţš----П國カ내]",
    "viewpast42": "[Ṽîéŵ Þåšţ Ŕéþöŕţš----П國カ내]",
    "viewpast54": "[Ṽîéŵ Þåšţ Ɓööķîñĝš----П國カ내]",
    "viewpast69": "[Ṽîéŵ Þåšţ Ɓööķîñĝš----П國カ내]",
    "viewrest129": "[Ŕéšţŕîçţéð ṀÇÇš--П國カ내]",
    "viewrewa17": "[Ṽîéŵ Ŕéŵåŕðš----П國カ내]",
    "viewsemi280": "[Ṽîéŵ∶--П國カ내]",
    "viewsupp10": "[Ṽîéŵ Šûþþöŕţ Åŕţîçļé-------П國カ내]",
    "viewtota23": "[Ṽîéŵ ţöţåļ ţŕåṽéļ šþéñð öñ å çöɱþåñý، ðéþåŕţɱéñţ، öŕ éɱþļöýéé ƀåšîš·---------------------П國カ내]",
    "viewtran79": "[Ṽîéŵ Ţŕåñšåçţîöñš----П國カ내]",
    "viewtrav2": "[Ṽîéŵ ŢŕåṽéļƁåñķ Îñţéĝŕåţîöñš------П國カ내]",
    "virtual56": "[Ṽîŕţûåļ--П國カ내]",
    "virtual83": "[Ṽîŕţûåļ--П國カ내]",
    "virtualc172": "[Ṽîŕţûåļ Çöŕþöŕåţé Çåŕð-----П國カ내]",
    "virtualc325": "[Ṽîŕţûåļ Çåŕð Ðéţåîļš-----П國カ내]",
    "virtualc5": "[Ṽîŕţûåļ Çöŕþöŕåţé Çåŕð-----П國カ내]",
    "virtualc67": "[Ṽîŕţûåļ Çöŕþöŕåţé Çåŕð-----П國カ내]",
    "virtualc80": "[Ṽîŕţûåļ Çåŕð Ðéţåîļš-----П國カ내]",
    "virtualc82": "[Ṽîŕţûåļ Çåŕð---П國カ내]",
    "visitthe119": "[Ṽîšîţ ţĥé çöɱþåñý šéţţîñĝš ţåƀ ţö çûšţöɱîžé ýöûŕ ţŕåṽéļ åñð éẋþéñšé þöļîçîéš، šéţ ûþ åþþŕöṽåļ ƒļöŵš، åðð îñţéĝŕåţîöñš åñð îñṽîţé éɱþļöýééš·----------------------------П國カ내]",
    "visitthe120": "[Ṽîšîţ ţĥé çöɱþåñý šéţţîñĝš ţåƀ ţö ṽîéŵ ýöûŕ ţŕåṽéļ åñð éẋþéñšé þöļîçîéš، îñṽîţé éɱþļöýééš، åñð åðð îñţéĝŕåţîöñš·-----------------------П國カ내]",
    "visitthe83": "[Ṽîšîţ ţĥé çöɱþåñý šéţţîñĝš ţåƀ ţö ƒûŕţĥéŕ çûšţöɱîžé ýöûŕ ţŕåṽéļ åñð éẋþéñšé þöļîçîéš، šéţ ûþ åþþŕöṽåļ ƒļöŵš، åðð îñţéĝŕåţîöñš åñð îñṽîţé éɱþļöýééš·------------------------------П國カ내]",
    "waitingf37": "[Ŵåîţîñĝ ƒöŕ Åþþŕöṽåļ-----П國カ내]",
    "waitingf38": "[Ŵåîţîñĝ ƒöŕ Ŕéîɱƀûŕšéɱéñţ------П國カ내]",
    "wearesor24": "[Ŵé åŕé šöŕŕý، ŵé åŕé éẋþéŕîéñçîñĝ ţéçĥñîçåļ ðîƒƒîçûļţîéš·------------------П國カ내]",
    "wecouldn70": "[Ŵé çöûļðñ´ţ çåþţûŕé ţĥé îñƒöŕɱåţîöñ ƒŕöɱ ţĥîš ŕéçéîþţ·-----------П國カ내]",
    "wednesda12": "[Ŵéðñéšðåý--П國カ내]",
    "weekly6": "[Ŵééķļý--П國カ내]",
    "weekly79": "[Ŵééķļý--П國カ내]",
    "weencoun1": "[Ŵé éñçöûñţéŕéð åñ îššûé· Þļéåšé çĥéçķ ýöûŕ éñţŕîéš åñð ţŕý åĝåîñ· Îƒ îţ þéŕšîšţš، þļéåšé çĥåţ ŵîţĥ ûš ţö ļéţ ûš ķñöŵ· ------------------------------------П國カ내]",
    "weencoun12": "[Ŵé éñçöûñţéŕéð åñ îššûé· Þļéåšé çĥéçķ ţĥé ƒöŕɱ åñð ţŕý åĝåîñ· Îƒ ţĥé îššûé þéŕšîšţš، çĥåţ ŵîţĥ ûš öŕ éɱåîļ ûš åţ {emailHref}· ------------------------------------П國カ내]",
    "weencoun13": "[Ŵé éñçöûñţéŕéð åñ îššûé· Þļéåšé ţŕý åĝåîñ·<br />Îƒ ţĥé îššûé þéŕšîšţš، çĥåţ ŵîţĥ ûš öŕ éɱåîļ ûš åţ {emailHref}· ----------------------------------------П國カ내]",
    "weencoun14": "[Ŵé éñçöûñţéŕéð åñ îššûé· Þļéåšé ţŕý åĝåîñ·<br />Îƒ ţĥé îššûé þéŕšîšţš،çĥåţ ŵîţĥ ûš öŕ éɱåîļ ûš åţ {emailHref}· ----------------------------------П國カ내]",
    "weencoun4": "[Ŵé éñçöûñţéŕéð åñ îššûé· Þļéåšé çĥéçķ ţĥé ƒöŕɱ åñð ţŕý åĝåîñ· Îƒ ţĥé îššûé þéŕšîšţš، çĥåţ ŵîţĥ ûš öŕ éɱåîļ ûš åţ {emailHref}· ------------------------------------П國カ내]",
    "welcome1": "[Ŵéļçöɱé¡--П國カ내]",
    "welcomeb17": "[Ŵéļçöɱé Ɓåçķ¡-----------П國カ내]",
    "welcomet10": "[Ŵéļçöɱé ţö ŢŕåṽéļƁåñķ-----П國カ내]",
    "welcomet206": "[Ŵéļçöɱé ţö {agencylabel}-----П國カ내]",
    "welcomet217": "[Ŵéļçöɱé Ţö {agencylabel}-----П國カ내]",
    "wellguid8": "[Ŵé´ļļ ĝûîðé ýöû ţĥŕöûĝĥ å ƒéŵ éššéñţîåļ šéţţîñĝš šö ýöû çåñ çûšţöɱîžé ýöûŕ åççöûñţ·-----------------П國カ내]",
    "weneedyo32": "[Ŵé ñééð ýöû ţö ṽéŕîƒý ýöûŕ éɱåîļ åððŕéšš ƀý åççéþţîñĝ ţĥé îñṽîţé îñ ýöûŕ éɱåîļ· Ŵé ŕéšéñţ îţ šö ýöû ĥåṽé îţ åţ ţĥé ţöþ öƒ ýöûŕ îñƀöẋ·---------------------------П國カ내]",
    "werecomm123": "[Ŵé ŕéçöɱɱéñð šéţţîñĝ ûþ åñý ĝéñéŕåļ ļéðĝéŕ îñţéĝŕåţîöñš ƀéƒöŕé îñṽîţîñĝ éɱþļöýééš ţö ûšé ţĥé åþþ·--------------------П國カ내]",
    "weresorr59": "[Ŵé’ŕé šöŕŕý، ŵé’ṽé ŕûñ îñţö åñ éŕŕöŕ·--------П國カ내]",
    "wereunab60": "[Ŵé’ŕé ûñåƀļé ţö çöñţîñûé ŵîţĥ ýöûŕ åççöûñţ šéţûþ åţ ţĥîš ţîɱé· Þļéåšé ŕéåçĥ öûţ ţö ŢŕåṽéļƁåñķ šûþþöŕţ ƒöŕ ƒûŕţĥéŕ åššîšţåñçé·--------------------------П國カ내]",
    "wereyoui18": "[Ŵéŕé ýöû îššûéð å çŕéðîţ çåŕð ƒŕöɱ ýöûŕ éɱþļöýéŕ¿----------П國カ내]",
    "wesentfo219": "[Ŵé šéñţ å ④‐ðîĝîţ ṽéŕîƒîçåţîöñ çöðé ţö ýöûŕ éɱåîļ· Þļéåšé çĥéçķ ýöûŕ îñƀöẋ åñð éñţéŕ ţĥé çöðé ƀéļöŵ·---------------------П國カ내]",
    "wevedete11": "[Ŵé´ṽé ðéţéçţéð {duplicateCount, plural, one {å ðûþļîçåţé} other {# ðûþļîçåţéš}} åñð îţ ŕéǫûîŕéš ýöûŕ ŕéṽîéŵ·----------------------П國カ내]",
    "wevedete95": "[Ŵé´ṽé ðéţéçţéð ðûþļîçåţé ŕéçéîþţ(š) ţĥåţ ŕéǫûîŕé ýöûŕ ŕéṽîéŵ--------------------П國カ내]",
    "wevesent83": "[Ŵé´ṽé šéñţ å ÞÐƑ çöþý öƒ ţĥé ŕéþöŕţ ţö ýöûŕ éɱåîļ·-----------П國カ내]",
    "wevesent86": "[Ŵé’ṽé šéñţ åñ Îñšţåñţ Çåŕð Öñé Ţîɱé Þåššçöðé ţö ýöûŕ éɱåîļ åţ {useremail}·---------------П國カ내]",
    "wevesucc25": "[Ŵé´ṽé šûççéššƒûļļý åððéð ýöûŕ ŕéçéîþţš، ƀûţ çöûļðñ´ţ ŕéåð ţĥé ðåţå· Þļéåšé ƒîļļ îñ ɱåñûåļļý·----------------------------П國カ내]",
    "whatsnex82": "[Ŵĥåţ’š ñéẋţ¿---П國カ내]",
    "whatsthe69": "[Ŵĥåţ’š ţĥé ɱîñîɱûɱ éẋþéñšé åɱöûñţ ţĥåţ ŕéǫûîŕéš å ŕéçéîþţ¿------------П國カ내]",
    "when32": "[Ŵĥéñ-П國カ내]",
    "whenenab100": "[Ŵĥéñ éñåƀļéð، éɱþļöýééš ɱûšţ šþļîţ ţĥé éẋþéñšé ƀý çåţéĝöŕîéš ţö åççöûñţ ƒöŕ åļļ ļîñé îţéɱš· Ŵĥéñ öƒƒ، éẋþéñšé šþļîţţîñĝ îš öþţîöñåļ·---------------------------П國カ내]",
    "whenenab101": "[Ŵĥéñ éñåƀļéð، ţŕåṽéļéŕš ɱûšţ ƀööķ ƒļîĝĥţš ŵîţĥîñ ţĥé šéļéçţéð ñûɱƀéŕ öƒ ðåýš ƀéƒöŕé ðéþåŕţûŕé ţö ƀé çöñšîðéŕéð îñ þöļîçý·-------------------------П國カ내]",
    "whenenab102": "[Ŵĥéñ éñåƀļéð، ţŕåṽéļéŕš ɱûšţ šéļéçţ ƒļîĝĥţš çĥéåþéŕ ţĥåñ ţĥé ðéƒîñéð çåþ ţö ƀé çöñšîðéŕéð îñ þöļîçý·---------------------П國カ내]",
    "whenenab106": "[Ŵĥéñ éñåƀļéð، ţŕåṽéļéŕš ɱûšţ ƀööķ ĥöţéļš ŵîţĥîñ ţĥé šéļéçţéð ñûɱƀéŕ öƒ ðåýš ƀéƒöŕé ðéþåŕţûŕé ţö ƀé çöñšîðéŕéð îñ þöļîçý·-------------------------П國カ내]",
    "whenenab108": "[Ŵĥéñ éñåƀļéð، ţŕåṽéļéŕš åŕé ĝîṽéñ å þéŕçéñţåĝé öŕ ðöļļåŕ åɱöûñţ ƒļéẋîƀîļîţý ŵîţĥ ţĥéîŕ ƀûðĝéţ ţö ƀé çöñšîðéŕéð îñ þöļîçý·-------------------------П國カ내]",
    "whenenab112": "[Ŵĥéñ éñåƀļéð، ţŕåṽéļéŕš ɱûšţ šéļéçţ ĥöţéļš çĥéåþéŕ ţĥåñ ţĥé ðéƒîñéð çåþ ţö ƀé çöñšîðéŕéð îñ þöļîçý·--------------------П國カ내]",
    "whenenab116": "[Ŵĥéñ éñåƀļéð، ţŕåṽéļéŕš åŕé ĝîṽéñ å þéŕçéñţåĝé öŕ ðöļļåŕ åɱöûñţ ƒļéẋîƀîļîţý ŵîţĥ ţĥéîŕ ƀûðĝéţ ţö ƀé çöñšîðéŕéð îñ þöļîçý·-------------------------П國カ내]",
    "whenenab123": "[Ŵĥéñ éñåƀļéð، éɱþļöýééš åŕé ŕéǫûîŕéð ţö åðð å ðéšçŕîþţîöñ ţö åļļ öƒ ţĥéîŕ šûƀɱîţţéð ɱåñûåļ éẋþéñšéš· Ṁîļéåĝé éẋþéñšéš ŵîļļ åļŵåýš ŕéǫûîŕé å ðéšçŕîþţîöñ·-------------------------------П國カ내]",
    "whenenab129": "[Ŵĥéñ éñåƀļéð، éɱþļöýééš åŕé ŕéǫûîŕéð ţö ɱéŕĝé ţĥéîŕ ɱåñûåļ çöŕþöŕåţé çåŕð éẋþéñšé ŵîţĥ ţĥé ŕéšþéçţîṽé çöŕþöŕåţé çåŕð ţŕåñšåçţîöñ ƀéƒöŕé ţĥéý çåñ šûƀɱîţ ţĥéîŕ éẋþéñšé ŕéþöŕţ· Þļéåšé ñöţé، ţĥîš ŵîļļ ñöţ åþþļý ţö ŕéƒûñð ţö çöɱþåñý، ɱîļéåĝé öŕ ţŕåṽéļ éẋþéñšéš·----------------------------------------------------П國カ내]",
    "whenenab132": "[Ŵĥéñ éñåƀļéð، ţĥé éɱþļöýéé´š þåýɱéñţ ɱéţĥöð ŵîļļ ðéƒåûļţ ţö çöŕþöŕåţé çåŕð öñ Ŕéþéåţ åñð Ŕéƒûñð ţö Çöɱþåñý éẋþéñšéš·------------------------П國カ내]",
    "whenenab143": "[Ŵĥéñ éñåƀļéð، ţŕåṽéļéŕš ɱûšţ ƀööķ ƒļîĝĥţš ŵîţĥîñ ţĥé šéļéçţéð ñûɱƀéŕ öƒ ðåýš ƀéƒöŕé ðéþåŕţûŕé ţö ƀé çöñšîðéŕéð îñ þöļîçý·-------------------------П國カ내]",
    "whenenab181": "[ Ŵĥéñ éñåƀļéð، ţĥîš çåţéĝöŕý ŵîļļ ƀé ṽîšîƀļé ƒöŕ éɱþļöýééš ƀý ðéƒåûļţ åñð åñý öţĥéŕ ŕéļéṽåñţ éẋþéñšé þöļîçîéš·-----------------------П國カ내]",
    "whenenab189": "[Ŵĥéñ éñåƀļéð، éɱþļöýééš ŵîļļ šéé ţĥîš çûšţöɱ ƒîéļð ŵĥéñ šûƀɱîţţîñĝ åñ éẋþéñšé·----------------П國カ내]",
    "whenenab192": "[Ŵĥéñ éñåƀļéð، éɱþļöýééš ŵîļļ šéé ţĥîš çûšţöɱ ƒîéļð ŵĥéñ šûƀɱîţţîñĝ åñ éẋþéñšé·----------------П國カ내]",
    "whenenab3": "[Ŵĥéñ éñåƀļéð، ţĥé îñţéĝŕåţîöñ ƒöŕ {title} ĝéñéŕåļ ļéðĝéŕ ŵîļļ ƀé ţûŕñéð öñ---------------П國カ내]",
    "whenenab36": "[Ŵĥéñ éñåƀļéð، ţĥîš éẋþéñšé ŵîļļ ƀé ɱåŕķéð åš å çĥåŕĝé ţö ţĥé çûšţöɱéŕ·----------------------П國カ내]",
    "whenenab4": "[Ŵĥéñ éñåƀļéð، ţĥé Šçĥéðûļéð Éẋþéñšé Éẋþöŕţ îñţéĝŕåţîöñ ŵîļļ ƀé ţûŕñéð öñ·---------------П國カ내]",
    "whenenab59": "[Ŵĥéñ éñåƀļéð، ţĥîš éẋþéñšé ŵîļļ ƀé ɱåŕķéð åš å çĥåŕĝé ţö ţĥé çûšţöɱéŕ·---------------П國カ내]",
    "whenenab71": "[Ŵĥéñ éñåƀļéð، åļļ ţŕåñšåçţîöñš öñ ţĥîš çåŕð ŵîļļ šĥöŵ ûþ îñ ýöûŕ Éẋþéñšé ļîšţ·----------------П國カ내]",
    "whenenab73": "[Ŵĥéñ éñåƀļéð، ţĥîš éẋþéñšé ŵîļļ ñöţ ƀé ŕéîɱƀûŕšéð ƀåçķ ţö ýöû·-------------П國カ내]",
    "whenenab76": "[Ŵĥéñ éñåƀļéð، éẋþéñšéš ɱåðé ŵîţĥ å çöŕþöŕåţé çåŕð çåñ ƀé ɱåŕķéð åš Ñöñ‐Ŕéîɱƀûŕšåƀļé·-----------------П國カ내]",
    "whenenab81": "[Ŵĥéñ éñåƀļéð، ûšéŕš ŵîţĥ ýöûŕ öŕĝåñîžåţîöñ´š éɱåîļ ðöɱåîñ ŵöñ´ţ åûţö‐ĵöîñ ýöûŕ åççöûñţ ŵĥéñ ţĥéý šîĝñ ûþ ƒöŕ ŢŕåṽéļƁåñķ·-------------------------П國カ내]",
    "whenrequ194": "[Ŵĥéñ ŕéǫûîŕéð، éɱþļöýééš ɱûšţ åñšŵéŕ ţĥîš ǫûéšţîöñ·-----------П國カ내]",
    "whenyoua14": "[Ŵĥéñ ýöû åŕé åššîĝñéð å çöŕþöŕåţé çåŕð، îţ ŵîļļ åþþéåŕ ĥéŕé åļöñĝ ŵîţĥ ýöûŕ ɱöšţ ŕéçéñţ ţŕåñšåçţîöñš·---------------------П國カ내]",
    "whenyout11": "[Ŵĥéñ ýöû ţåķé åçţîöñ öñ ýöûŕ ñéŵ ţŕåñšåçţîöñš، ţĥéšé ţŕåñšåçţîöñš ŵîļļ åþþéåŕ ĥéŕé ƒöŕ ýöûŕ ŕéçöŕðš، ûþ ţö å þéŕîöð öƒ ⑥⓪ ðåýš·--------------------------П國カ내]",
    "workemai3": "[Ŵöŕķ Éɱåîļ---П國カ내]",
    "workemai43": "[Ŵöŕķ Éɱåîļ----П國カ내]",
    "workemai78": "[Ŵöŕķ Éɱåîļ---П國カ내]",
    "workemai8": "[Ŵöŕķ Éɱåîļ---П國カ내]",
    "year201": "[Ýéåŕ-П國カ내]",
    "yearly81": "[Ýéåŕļý--П國カ내]",
    "yes107": "[Ýéš-П國カ내]",
    "yes194": "[Ýéš-П國カ내]",
    "yes47": "[Ýéš-П國カ내]",
    "yes56": "[Ýéš-П國カ내]",
    "yesterda2": "[Ýéšţéŕðåý----П國カ내]",
    "youalrea27": "[Ýöû åļŕéåðý ĥåṽé åñ åççöûñţ------П國カ내]",
    "youareal24": "[Ýöû åŕé åļŕéåðý ṽéŕîƒîéð· Þļéåšé ļöĝ îñ·---------П國カ내]",
    "youareno11": "[Ýöû åŕé ñöŵ éðîţîñĝ <b>{name}'s</b> þŕöƒîļé· Ţö ɱåķé å ñéŵ ţŕåṽéļéŕ þŕöƒîļé، þļéåšé åðð å ñéŵ ţŕåṽéļéŕ·-------------------------------П國カ내]",
    "youareus30": "[Ýöû åŕé ûšîñĝ å çûšţöɱ éẋþöŕţ ţéɱþļåţé· Þļéåšé çöñţåçţ ŢŕåṽéļƁåñķâ'š šûþþöŕţ ţéåɱ ţö ŕéṽîéŵ ýöûŕ éẋþöŕţ ƒîļé·-----------------------П國カ내]",
    "youcanat15": "[Ýöû Çåñ Åţţåçĥ Å Ṁåẋîɱûɱ Öƒ ①⓪ Ŕéçéîþţš------------П國カ내]",
    "youcanat50": "[Ýöû Çåñ Åţţåçĥ Å Ṁåẋîɱûɱ Öƒ ①⓪ Ŕéçéîþţš------------П國カ내]",
    "youcanno147": "[Ýöû çåññöţ ɱöðîƒý ţĥîš çåŕð ŵĥéñ îţ´š ļöçķéð·----------П國カ내]",
    "youcanno162": "[Ýöû çåññöţ éñåƀļé ĻöðĝîñĝẊ îƒ ŕéîɱƀûŕšéɱéñţš åŕé ñöţ éñåƀļéð-------------П國カ내]",
    "youcanno62": "[Ýöû çåññöţ ŕé‐îñṽîţé åñ åçţîṽé ûšéŕ·--------П國カ내]",
    "youcanno67": "[Ýöû Çåññöţ Ṁéŕĝé Ṁöŕé Ţĥåñ ② Îţéɱš Åţ Öñçé---------П國カ내]",
    "youcanno80": "[Ýöû çåññöţ ɱéŕĝé å þöšîţîṽé åñð ñéĝåţîṽé éẋþéñšé·----------П國カ내]",
    "youcanre31": "[Ýöû çåñ ŕéšţöŕé ðéļéţéð îţéɱš öñ ţĥé Éẋþéñšéš ⅋ Ţŕåñšåçţîöñš þåĝé ƀý ĝöîñĝ ţö Ƒîļţéŕš åñð šéļéçţîñĝ ðéļéţéð šţåţûš ƒîļţéŕ·-------------------------П國カ내]",
    "youcansh127": "[Ýöû çåñ çĥööšé ţö <i>öñļý åļļöŵ</i> öŕ <i>öñļý ðéçļîñé</i> ɱéŕçĥåñţ çåţéĝöŕîéš ƀý šéļéçţîñĝ ûþ ţö ⑨ ţéɱþļåţéš·-----------------------П國カ내]",
    "youcanst3": "[Ýöû çåñ šţåŕţ ûšîñĝ ŢŕåṽéļƁåñķ ŕîĝĥţ åŵåý· Ƒîñîšĥ çûšţöɱîžîñĝ ýöûŕ çöɱþåñý´š šéţûþ ļåţéŕ öñ ţĥé Çöɱþåñý Šéţţîñĝš þåĝé·------------------------П國カ내]",
    "youcanta186": "[Ýöû çåñ´ţ åðð ɱöŕé ţĥåñ ①⓪⓪ öþţîöñš ŵĥéñ çŕéåţîñĝ å çûšţöɱ ƒîéļð· Þļéåšé šåṽé ƒîŕšţ·-----------------П國カ내]",
    "youdonot12": "[Ýöû ðö ñöţ ĥåṽé åñý ûñɱéŕĝéð éẋþéñšéš öŕ ţŕåñšåçţîöñš ţö ɱéŕĝé ŵîţĥ·---------------------П國カ내]",
    "youdonot36": "[Ýöû ðö ñöţ ĥåṽé å ƀåļåñçé ţö þåý öƒƒ åţ ţĥîš ţîɱé·-----------П國カ내]",
    "youdonth1": "[Ýöû ðöñ´ţ ĥåṽé åñý éẋþéñšé ŕéþöŕţš·-------П國カ내]",
    "youdonth10": "[Ýöû ðöñ’ţ ĥåṽé åñý öûţšţåñðîñĝ åþþŕöṽåļš·---------П國カ내]",
    "youdonth13": "[Ýöû ðöñ’ţ ĥåṽé åñý éẋþéñšé ŕéþöŕţš·--------П國カ내]",
    "youdonth19": "[Ýöû ðöñ’ţ ĥåṽé åñý åçţîṽé éẋþéñšéš ţö šĥöŵ·---------П國カ내]",
    "youdonth22": "[Ýöû ðöñ’ţ ĥåṽé åñý ŕéþöŕţš ţö šĥöŵ·--------П國カ내]",
    "youdonth23": "[Ýöû ðöñ’ţ ĥåṽé åñý åçţîṽé éẋþéñšéš öŕ ŕéþöŕţš·----------П國カ내]",
    "youdonth68": "[Ýöû ðöñ´ţ ĥåṽé åñý ûþçöɱîñĝ ƀööķîñĝš ƒöŕ ƒļîĝĥţš، ĥöţéļš، öŕ çåŕš·--------------П國カ내]",
    "youhavea8": "[Ýöû ĥåṽé åţţåçĥéð ţĥé ɱåẋîɱûɱ öƒ ①⓪ ŕéçéîþţš·----------П國カ내]",
    "youhavee135": "[Ýöû ĥåṽé éðîţéð ţĥé éɱþļöýéé ļîšţ ƒöŕ ţĥîš þöļîçý·-----------П國カ내]",
    "youhaven75": "[Ýöû ĥåṽéñ´ţ ƀééñ ŕéîɱƀûŕšéð ƒöŕ åñý éẋþéñšé ŕéþöŕţš·--------П國カ내]",
    "youhaveo14": "[Ýöû ĥåṽé öļðéŕ éẋþéñšéš·--------П國カ내]",
    "youhaver72": "[Ýöû ĥåṽé ŕéåçĥéð ţĥé ļîɱîţ ({globalCustomFieldLimit}) çûšţöɱ ƒîéļðš ƒöŕ åļļ çåţéĝöŕîéš·------------------П國カ내]",
    "youissue150": "[Ýöû îššûéð å ṽîŕţûåļ éɱþļöýéé çåŕð·--------П國カ내]",
    "youissue151": "[Ýöû îššûéð å þĥýšîçåļ éɱþļöýéé çåŕð·--------П國カ내]",
    "youissue152": "[Ýöû îššûéð å ṽîŕţûåļ ṽéñðöŕ çåŕð·-------П國カ내]",
    "youllget71": "[Ýöû´ļļ ĝéţ å çöþý öƒ ţĥé ŕéþöŕţ ţö ýöûŕ éɱåîļ·----------П國カ내]",
    "yourbank65": "[Ýöûŕ ƀåñķ åççöûñţ çåññöţ ƀé åððéð åţ ţĥîš ţîɱé· Þļéåšé ṽéŕîƒý ýöûŕ åççöûñţ îñƒöŕɱåţîöñ åñð ţŕý åĝåîñ öŕ åðð ýöûŕ ƀåñķ åççöûñţ ɱåñûåļļý îñ öŕðéŕ ţö ŕéçéîṽé ŕéîɱƀûŕšéɱéñţš·----------------------------------------------------П國カ내]",
    "yourbank68": "[Ýöûŕ ƀåñķ åççöûñţ îš šéţ ţö ŕéçéîṽé ŕéîɱƀûŕšéɱéñţš· Ûþðåţé ýöûŕ ŕéîɱƀûŕšéɱéñţ åççöûñţ ţö çĥåñĝé ţĥé ƀåñķ åççöûñţ ýöû ĥåṽé öñ ƒîļé·----------------------------------------П國カ내]",
    "yourchan126": "[Ýöûŕ çĥåñĝéš ŵîļļ ƀé åṽåîļåƀļé îñ ŢŕåṽéļƁåñķ ŵĥéñ ţĥé ƒîļé ĥåš þŕöçéššéð·---------------П國カ내]",
    "yourcomp108": "[Ýöûŕ Çöɱþåñý Ɓåñķ Åççöûñţ------П國カ내]",
    "yourcsvd50": "[Ýöûŕ çšṽ ðöéšñ’ţ çöñţåîñ ţĥé çöŕŕéçţ çöļûɱñš·----------П國カ내]",
    "yourcsve167": "[Ýöûŕ ÇŠṼ éẋþöŕţ ŵåš šéñţ ţö <span data-test-email>{email}</span>------П國カ내]",
    "yourcsvf166": "[Ýöûŕ ÇŠṼ ƒîļé ŵîļļ åûţöɱåţîçåļļý ðöŵñļöåð öñçé ŕéåðý·-----------П國カ내]",
    "youreing116": "[Ýöû´ŕé îñ Ĝööð Çöɱþåñý-----П國カ내]",
    "yourempl88": "[Ýöûŕ éɱþļöýééš ŵîļļ <span class=\"rewardAmount\">ŕéçéîṽé {rewardsamount} öƒ ţĥéîŕ šåṽîñĝš</span> öñ ĥöţéļ ƀööķîñĝš·------------------------П國カ내]",
    "yourempl89": "[Ýöûŕ éɱþļöýééš ŵîļļ ñöţ ŕéçéîṽé å šĥåŕé öƒ ţĥéîŕ šåṽîñĝš öñ ĥöţéļ ƀööķîñĝš·----------------П國カ내]",
    "youresav1": "[Ýöû´ŕé šåṽîñĝ öñ ļîçéñšé çöšţš ŵîţĥ ýöûŕ þŕéƒéŕŕéð {preferredRateBrand} ŕåţé¡----------------П國カ내]",
    "youresav168": "[Ýöû´ŕé šåṽîñĝ öñ ļîçéñšé çöšţš ŵîţĥ ýöûŕ þŕéƒéŕŕéð {preferredratebrand} ŕåţé¡----------------П國カ내]",
    "youresav192": "[Ýöû´ŕé šåṽîñĝ ŵîţĥ ýöûŕ {preferredratebrand} þŕéƒéŕŕéð ŕåţé¡-------------П國カ내]",
    "youresav193": "[Ýöû´ŕé šåṽîñĝ ŵîţĥ ýöûŕ þŕéƒéŕŕéð ŕåţé¡--------П國カ내]",
    "youresav2": "[Ýöû´ŕé šåṽîñĝ ŵîţĥ ýöûŕ {preferredRateBrand} þŕéƒéŕŕéð ŕåţé¡-------------П國カ내]",
    "youresav3": "[Ýöû´ŕé šåṽîñĝ ŵîţĥ ýöûŕ þŕéƒéŕŕéð ŕåţé¡--------П國カ내]",
    "yourexpe56": "[Ýöûŕ éẋþéñšé ŕéþöŕţ ŵåš šûƀɱîţţéð åš “{expenseReportName}”·------------П國カ내]",
    "yourexpe63": "[Ýöûŕ éẋþéñšéš ĥåṽé ƀééñ ŕéšţöŕéð-------П國カ내]",
    "yourorga31": "[Ýöûŕ öŕĝåñîžåţîöñ ðöéš ñöţ ĥåṽé å çûšţöɱ ţéɱþļåţé· Þļéåšé çöñţåçţ šûþþöŕţ ţö šéţ öñé ûþ·------------------П國カ내]",
    "yourorga91": "[Ýöûŕ öŕĝåñîžåţîöñ´š éẋþéñšé þöļîçý ŕéǫûîŕéš ýöû ţö ɱéŕĝé ɱåñûåļļý çŕéåţéð çöŕþöŕåţé éẋþéñšéš ŵîţĥ å çöŕŕéšþöñðîñĝ çöŕþöŕåţé çåŕð ţŕåñšåçţîöñ·-----------------------------П國カ내]",
    "yourpaid1": "[Ýöûŕ Þåîð Þļåñ Éšţîɱåţé-----П國カ내]",
    "yourpaid169": "[<div class=\"onboarding-subscriptions__calculator__estimation__title\">Ýöûŕ Þåîð Þļåñ Éšţîɱåţé <InfoIcon id=\"estimate-info\"/></div><div class=\"onboarding-subscriptions__calculator__estimation__billed-annually\">{billedannuallytext}</div><div class=\"onboarding-subscriptions__calculator__estimation__billed-monthly\">öŕ {billedmonthlytext}</div>----------------------------------------------------------------------П國カ내]",
    "yourpdfd36": "[Ýöûŕ ÞÐƑ ðöŵñļöåð šĥöûļð ƀéĝîñ åûţöɱåţîçåļļý· Îƒ îţ ðöéšñ´ţ، ýöû çåñ çļîçķ ţĥé ļîñķ ƀéļöŵ ţö ðöŵñļöåð ţĥé ƒîļé·-----------------------П國カ내]",
    "yourphon28": "[Ýöûŕ Þĥöñé Ñûɱƀéŕ----П國カ내]",
    "yourreim67": "[Ýöûŕ ŕéîɱƀûŕšéɱéñţ åççöûñţ îš ŵåîţîñĝ ƒöŕ ýöûŕ ṽéŕîƒîçåţîöñ· Öñçé ṽéŕîƒîéð، ýöûŕ ƀåñķ åççöûñţ ŵîļļ ƀé åƀļé ţö ŕéçéîṽé ŕéîɱƀûŕšéɱéñţš·----------------------------------------П國カ내]",
    "yourrepo59": "[Ýöûŕ ŕéþöŕţ ĥåš ƀééñ šûƀɱîţţéð¡-------П國カ내]",
    "yourrewa68": "[Ýöûŕ ŕéŵåŕðš åñð ƀûðĝéţ öþţîöñš ĥåṽé ƀééñ šåṽéð¡----------П國カ내]",
    "yourteam22": "[Ýöûŕ ţéåɱ´š çöŕþöŕåţé çåŕðš ŵîļļ åþþéåŕ ĥéŕé---------П國カ내]",
    "yourtran29": "[Ýöûŕ ţŕåñšåçţîöñ ŵåš åššîĝñéð ţö “<span>{transactionassignreportname}</span>”----------------П國カ내]",
    "yourtrav10": "[Ýöûŕ ţŕåṽéļéŕ îñƒöŕɱåţîöñ ŵîļļ ƀé ûšéð ţö ƀööķ åļļ ţŕåṽéļ ŵîţĥîñ ŢŕåṽéļƁåñķ· Þļéåšé éñšûŕé ýöûŕ ţŕåṽéļéŕ îñƒöŕɱåţîöñ ɱåţçĥéš ýöûŕ ĝöṽéŕñɱéñţ ÎÐ ûšéð ƒöŕ ţŕåṽéļ·-------------------------------------------------П國カ내]",
    "youvealr31": "[Ýöû´ṽé åļŕéåðý ƀééñ îñṽîţéð¡------П國カ내]",
    "youvecom81": "[Ýöû´ṽé çöɱþļéţéð îñîţîåļ šéţûþ¡-------П國カ내]",
    "youwilll215": "[Ýöû ŵîļļ ļöšé åñý ðåţå ýöû ĥåṽé éñţéŕéð·---------П國カ내]",
    "youwillr16": "[Ýöû ŵîļļ ŕéçéîṽé åñ éɱåîļ çöñƒîŕɱåţîöñ öñ ţĥé þåýɱéñţ ðåţé·------------П國カ내]",
    "zerozero146": "[⓪⓪⓪⓪ ⓪⓪⓪⓪ ⓪⓪⓪⓪ ⓪⓪⓪⓪----П國カ내]",
    "zipcode210": "[Žîþ Çöðé--П國カ내]",
    "zipcode5": "[Žîþ Çöðé---П國カ내]"
  }]];
  _exports.default = _default;
});